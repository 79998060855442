import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { User } from '../shared/model/user';
import { McsUserService } from '../service/mcs-user.service';
import { Subject, Subscription } from 'rxjs';
import { DashboardService } from './dashboard/service/dashboard.service';
import { Dashboards } from './dashboard/model/dashboards';

@Component({
  selector: 'app-correspondence',
  templateUrl: './correspondence.component.html',
  styleUrls: ['./correspondence.component.css']
})
export class CorrespondenceComponent implements OnInit {
  loading: boolean = false;
  title: string;
  private unsubscribe: Subject<void> = new Subject();
  user: User;
  dashboard: Dashboards;
  subscriptions: Array<Subscription> = [];


  constructor(
    private router: Router,
    private userService: McsUserService,
    private dashboardService: DashboardService
  ) {
  }

  ngOnInit(): void {
    this.user = this.userService.getStoredUser();
    this.title = `Correspondence Dashboard`
    this.getDashboard();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  startNewLetter() {
    this.router.navigate(['/selectCriteria']);
  }

  getDashboard(): void {
    this.loading = true;
    const sub = this.dashboardService.getDashboardData()
      .subscribe(
        dashboard => {
          this.dashboard = dashboard;
          this.checkRegulatorRole();
          this.loading = false;
        });
        this.subscriptions.push(sub);
  }

  checkRegulatorRole() : boolean {
    if(this.user.role === "MCAS_ISITE_USER_PR") {
      return false;
    } else {
      return true;
    }
  }
}
