import { Injectable } from '@angular/core';
import { McsUserService } from '../service/mcs-user.service';

@Injectable({
  providedIn: 'root'
})
export class CocodesService {

  constructor(private userService: McsUserService) { }

  hasCocodes(): boolean {
    let cocodes = this.userService.getStoredUser().cocodesAsPrimary.concat(this.userService.getStoredUser().cocodesAsSecondary);
    if (this.userService.isExternalUser()) {
      return cocodes.length > 0;
    } else {
      return true;
    }
  }
}
