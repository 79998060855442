<ng-container *ngIf="navItem.subNav.length > 0; else: noSections">
  <li class="nav-item">
    <a [attr.id]="navItem.target" (click)="isCollapsed = !isCollapsed" [ngClass]="getParentStyle()" [attr.aria-expanded]="!isCollapsed" [attr.aria-controls]="getCollapseId()">
        <fa-icon [icon]="isCollapsed ? faPlus : faMinus" [size]="'sm'"></fa-icon>&nbsp;&nbsp;{{navItem.name}}
    </a>
    <ul [ngClass]="getSubNavStyle()" *ngFor="let nav of navItem.subNav" [id]="getCollapseId()" [collapse]="isCollapsed">
      <app-nav-list [navItem]="nav" [level]="level + 1" [parentId]="navItem.name" (target)="goToSection($event)"></app-nav-list>
    </ul>
  </li>
</ng-container>

<ng-template #noSections>
  <li class="nav-item">
    <a *ngIf="isDataChanged(); else noDataChange" [attr.id]="navItem.target" (click)="goToSection(navItem.target)" tooltip="Save and reset validations" [ngClass]="getStyle()">
      <fa-icon [icon]="faCircle" [size]="'xs'"></fa-icon>&nbsp;&nbsp;{{navItem.name}}
    </a>
    <ng-template #noDataChange>
      <a [attr.id]="navItem.target" (click)="goToSection(navItem.target)" [ngClass]="getStyle()">
        <fa-icon [icon]="faCircle" [size]="'xs'"></fa-icon>&nbsp;&nbsp;{{navItem.name}}
      </a>
    </ng-template>
  </li>
</ng-template>
