import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';
import { Company } from '../../../shared/model/company';

@Injectable()
export class CompanyService {
  private selectedCompany: Company;
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('CompanyService');
  }

  getCompanies(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}companies`)
      .pipe(catchError(this.handleError('getCompanies', null)));
  }

  setSelectedCompany(company: Company): void {
    this.selectedCompany = company;
  }

  getSelectedCompany(): Company {
    return this.selectedCompany;
  }

  getDisplayName(longName: string, cocode: number): string {
    const cocodeIndex = longName.indexOf(cocode.toString());
    let companyName = cocodeIndex > -1 ? longName.substring(0, cocodeIndex - 1) : longName;
    return `${companyName} (${cocode})`;
  }

}
