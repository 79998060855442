import { FormQuestion } from './form-question';

export class FormSection {
  key: string;
  name: string;
  description: string;
  sectionNumber: number;
  sections: FormSection[];
  questions: FormQuestion[];
  order?: number;
  active?: boolean;

  constructor(key?: string, sectionNumber?: number, name?: string, description?: string, order?: number, sections?: FormSection[], questions?: FormQuestion[]) {
    this.key = key || '';
    this.sectionNumber = sectionNumber || 1;
    this.name = name || '';
    this.description = description || '';
    this.order = order || 0;
    this.sections = sections || [];
    this.questions = questions || [];
    this.active = false;
  }
}
