import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpDeskComponent } from './help-desk.component';
import { RoleGuard } from '../shared/role.guard';

const routes: Routes = [
  { path: 'helpDesk', component: HelpDeskComponent, canActivate: [RoleGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HelpDeskRoutingModule { }
