<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form #waiverModalForm="ngForm">
    <div class="form-group">
      <div class="font-weight-bold">State</div>
      <div>{{waiver.state.stateName}}</div>
    </div>
    <div class="form-group">
      <div class="font-weight-bold">Line of Business</div>
      <span>{{waiver.lineOfBusiness.name}}</span>
    </div>
    <div class="form-group">
      <div class="font-weight-bold">Status</div>
      <span class="text-capitalize">{{waiver.status.toLowerCase()}}</span>
    </div>
    <div class="form-group">
      <div class="font-weight-bold" for="waiver-reason">Waiver Request Reason</div>
      <textarea class="form-control form-control-sm" id="waiver-reason" name="reason" required notOnlyWhitespace [(ngModel)]="waiver.reason" [disabled]="isUpdateDisabled()"></textarea>
    </div>
    <ng-container *ngIf="waiver.status.toLowerCase() === 'approved' || waiver.status.toLowerCase() === 'denied'">
      <div class="form-group">
        <div class="font-weight-bold">Decision Date</div>
        <div>{{waiver.decisionDate}}</div>
      </div>
      <div class="form-group">
        <div class="font-weight-bold">Decision Comments</div>
        <div>{{waiver.decisionComments}}</div>
      </div>
      </ng-container>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-link btn-sm" (click)="bsModalRef.hide()">Close</button>
  <button type="button" class="btn btn-danger" (click)="onDelete()" [disabled]="isUpdateDisabled()">Delete</button>
  <button type="button" class="btn btn-primary" (click)="onUpdate()" [disabled]="!waiverModalForm.form.valid || isUpdateDisabled()">Update Waiver</button>
</div>
