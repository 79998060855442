import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McasToolbarComponent } from './mcas-toolbar.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppRoutingModule } from '../app-routing.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),

  ],
  declarations: [McasToolbarComponent],
  exports: [McasToolbarComponent]
})
export class McasToolbarModule { }
