import { Component, TemplateRef } from '@angular/core';
import { faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ICellRenderer } from 'ag-grid-community';

@Component({
  selector: 'app-delete-correspondence-renderer',
  templateUrl: './delete-correspondence-renderer.component.html',
  styleUrls: ['./delete-correspondence-renderer.component.css']
})
export class DeleteCorrespondenceRendererComponent implements ICellRenderer {
  params: any;
  faTimes = faTimes;
  fas = fas;
  deleteKey: string;
  modalRef: BsModalRef;
  visible: boolean;

  constructor(
    private modalService: BsModalService,
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.onlyForDraft();
  }

  onlyForDraft() {
    if (this.params.data.letterProgress === 'Draft') {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  refresh(): boolean {
    return true;
  }

  deleteCorrespondence(key: string, template: TemplateRef<any>): void {
    this.deleteKey = key;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  cancelDelete(): void {
    this.modalRef.hide();
  }

  confirmDelete(): void {
    this.modalRef.hide();
    this.params.context.componentParent.confirmDelete(this.deleteKey);
  }

}
