import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from './service/message.service';
import { AlertMessage } from './model/alert-message';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  @Input() parentName: string;

  constructor(
    public service: MessageService
  ) { }

  ngOnInit() {
  }

  isVisible(message: AlertMessage): boolean {
    if (message.componentName && this.parentName) {
      if (message.componentName === this.parentName || message.componentName === 'all') {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  isDismissableTime(message: AlertMessage): boolean {
    return message.dismissTime && message.dismissTime > 0;
  }

}
