import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Year} from '../../../shared/model/year';
import { HttpClient } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class YearsService {
  currentFilingYear: number;
  private currentYear: Year;
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('YearsService');
  }

  getYears(): Observable<Year[]> {
    let url = `${environment.apiUrl}years`;
    return this.httpClient.get<Year[]>(url)
      .pipe(catchError(this.handleError('getYears', null)));
  }

  getCurrentYear(): Year {
    return this.currentYear;
  }

  putCurrentYear(year: Year): void {
    this.currentYear = year;
  }

}
