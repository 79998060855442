import { MOCK_HEALTH_FILING } from './mock-health-filing';
import { MOCK_PPA_FILING, MOCK_PPA_FILING_PRIOR, MOCK_PPA_FILING_2015, MOCK_PPA_FILING_2016 } from './mock-ppa-filing';
import { MOCK_LTC_FILING } from './mock-ltc-filing';

export const MOCK_FILING_BLANKS: any[] = [
  MOCK_PPA_FILING,
  MOCK_HEALTH_FILING,
  MOCK_LTC_FILING,
  MOCK_PPA_FILING_PRIOR,
  MOCK_PPA_FILING_2016,
  MOCK_PPA_FILING_2015,
];
