import { Injectable } from '@angular/core';
import { QuestionBase } from './question-base';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormSection } from '../filing-wizard/model/form-section';

@Injectable()
export class QuestionControlService {
  private disabledInputs: string[] = [];

  constructor() { }

  // Create the form control
  toFormGroup(inputs: QuestionBase<any>[], disabled: boolean): FormGroup {
    let defaultDisabled = disabled;
    let group: any = {};

    inputs.forEach(input => {
      if (input.disableableInputs) {
        if (input.value === input.disableableInputs.disabledValue) {
          input.disableableInputs.disabledInputs.forEach((disabledInput: string) => {
            const keys = disabledInput.split('.');
            this.disabledInputs.push(keys[keys.length - 1]);
          });
        }
      }
      if (this.disabledInputs.indexOf(input.key) > -1) {
        disabled = true;
      }
      if (input.disabled) {
        disabled = true;
      }
      let formControl: FormControl;
      formControl = input.required ? new FormControl(input.value || '', Validators.required)
        : new FormControl(input.value || '');
      if (disabled) {
        formControl.disable();
      }
      disabled = defaultDisabled;
      group[input.key] = formControl;
    });
    return new FormGroup(group);
  }

  generateFormGroup(sections: FormSection[], disabled: boolean): FormGroup {
    let group: any = {};

    sections.forEach(section => {
      if (section.sections.length > 0) {
        group[section.key] = this.generateFormGroup(section.sections, disabled);
      }
      if (section.questions.length > 0) {
        group[section.key] = this.generateQuestions(section.questions, disabled);
      }
    });

    return new FormGroup(group);
  }

  generateForm(section: FormSection, disabled: boolean): FormGroup {
    let group: any = {};
    if (section.sections.length > 0) {
      group[section.key] = this.generateFormGroup(section.sections, disabled);
    }
    if (section.questions.length > 0) {
      group[section.key] = this.generateQuestions(section.questions, disabled);
    }
    return new FormGroup(group);
  }

  generateQuestions(questions: any[], disabled: boolean): FormGroup {
    let group: any = {};

    questions.forEach(question => {
      group[question.key] = this.toFormGroup(question.inputs, disabled);
    });

    return new FormGroup(group);
  }

}
