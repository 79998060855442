import { RulesResults } from "../../correspondence/rules-results/model/rules-results";
import { MOCK_CORRESPONDENCE_COMPANY } from "./mock-correspondence-company";

export const MOCK_CORRESPONDENCE_RULESRESULTS: RulesResults =
{
    company: MOCK_CORRESPONDENCE_COMPANY,
    contactName: 'Joan Jett',
    rules: [
        {
            baseTableName: "X2015A0CASLTCGENINF0",
            ruleName: 'XZABU050140',
            description: "This has warnings 4, 3 and 2",
            filing: 0,
            ruleResults: [{
                stabbr: 'AR',
                resultStatus: 4,
                leftSide: 0,
                rightSide: 0,
                difference: -200,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },{
                stabbr: 'MO',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: -200,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: true,
                cocode: 60054
            },{
                stabbr: 'IA',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: -200,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            }]
        },{
            baseTableName: "X2015A0CASLTCGENINF0",
            ruleName: 'XZABU050171',
            description: "This has warnings 4, 2 and 2",
            filing: 5,
            ruleResults: [{
                stabbr: 'MO',
                resultStatus: 4,
                leftSide: 0,
                rightSide: 0,
                difference: -5,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: true,
                cocode: 60054
            },{
                stabbr: 'AR',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: -6,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },{
                stabbr: 'IA',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },{
                stabbr: 'DE',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'AB',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'DC',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'AE',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'IA',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'MD',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'IA',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'FE',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'OP',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'IE',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'ER',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'AQ',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },
            {
                stabbr: 'QW',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            }]
        },{
            baseTableName: "X2015A0CASLTCGENINF0",
            ruleName: 'XZABU050371',
            description: "This has warnings 3, 3 and 4",
            filing: 5,
            ruleResults: [{
                stabbr: 'IA',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },{
                stabbr: 'AR',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            },{
                stabbr: 'MO',
                resultStatus: 4,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            }]
        },{
            baseTableName: "X2015A0CASLTCGENINF0",
            ruleName: 'XZABU051371',
            description: "This has warnings 4",
            filing: 5,
            ruleResults: [{
                stabbr: 'IA',
                resultStatus: 4,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            }]
        },{
            baseTableName: "X2015A0CASLTCGENINF0",
            ruleName: 'XZABU150371',
            description: "This has warnings 2",
            filing: 5,
            ruleResults: [{
                stabbr: 'MO',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            }]
        },{
            baseTableName: "X2015A0CASLTCGENINF0",
            ruleName: 'XZABU050331',
            description: "This has warnings 4",
            filing: 5,
            ruleResults: [{
                stabbr: 'NY',
                resultStatus: 4,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            }]
        },{
            baseTableName: "X2015A0CASLTCGENINF0",
            ruleName: 'XZABU050321',
            description: "This has warnings 3",
            filing: 5,
            ruleResults: [{
                stabbr: 'NJ',
                resultStatus: 3,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            }]
        },{
            baseTableName: "X2015A0CASLTCGENINF0",
            ruleName: 'XZABU090371',
            description: "This has warnings 4",
            filing: 5,
            ruleResults: [{
                stabbr: 'TX',
                resultStatus: 4,
                leftSide: 0,
                rightSide: 0,
                difference: 0,
                modifiedByUser: 'string',
                dateModified: '03/06/2020',
                reviewedStatus: 'REVIEWED',
                includeInLetter: false,
                cocode: 60054
            }]
        },
    ]
};