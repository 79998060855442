import { MOCK_YEARS } from './mock-years';
import { MOCK_IN_PROGRESS } from './mock-filing-statuses';
import { MOCK_MCAS_STATES } from './mock-mcas-states';

export const MOCK_PPA_FILING: any = {
  state: MOCK_MCAS_STATES[0],
  lineOfBusiness: {id: 1, code: 'PPA', name: 'Private Passenger Auto'},
  year: MOCK_YEARS[MOCK_YEARS.length - 1],
  status: MOCK_IN_PROGRESS,
  sections: [
    {
        key: 'Interrogatories',
        name: 'Interrogatories',
        sectionNumber : 1,
        sections : [],
        questions : [
            {
                key : 'Interrogatories-10',
                text : 'Was the company actively writing policies in the state at year end?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT.10.yes_no_response',
                        label : 'Response',
                        controlType : 'dropdown',
                        options : [
                            {
                                key : 'Y',
                                value : 'Y'
                            },
                            {
                                key : 'N',
                                value : 'N'
                            }
                        ],
                        disabled : false,
                        required : false
                    }
                ]
            },
            {
                key : 'Interrogatories-11',
                text : 'Does the company write in the non-standard market?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT-11-yes_no_response',
                        label : 'Response',
                        controlType : 'dropdown',
                        options : [
                            {
                                key : 'Y',
                                value : 'Y'
                            },
                            {
                                key : 'N',
                                value : 'N'
                            }
                        ],
                        disabled : false,
                        required : false,
                        disableableInputs : {
                          disabledValue : 'N',
                          disabledInputs : [
                            'Interrogatories.Interrogatories-12.P2018MCASPPAINT-12-explanation',
                            'Interrogatories.Interrogatories-13.P2018MCASPPAINT-13-explanation',

                          ]
                        }
                    }
                ]
            },
            {
                key : 'Interrogatories-12',
                text : 'If Yes, what percentage of your business is non-standard?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT-12-explanation',
                        label : 'Response',
                        controlType : 'textbox',
                        options : [],
                        disabled : false,
                        required : false
                    }
                ]
            },
            {
                key : 'Interrogatories-13',
                text : 'If Yes, how is non-standard defined?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT-13-explanation',
                        label : 'Response',
                        controlType : 'textbox',
                        options : [],
                        disabled : false,
                        required : false
                    }
                ]
            }
        ]
    },
    {
        key : 'Claims',
        name : 'Claims',
        sectionNumber : 1,
        sections : [
            {
                key : 'Collision',
                name : 'Collision',
                sectionNumber : 2,
                sections : [],
                questions : [
                    {
                        key : 'Claims-Collision-21',
                        text : 'Number of claims open at the beginning of the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS-21-collision',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    },
                    {
                        key : 'Claims.Collision-22',
                        text : 'Number of claims opened during the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.22.collision',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    }
                ]
            },
            {
                key : 'Comprehensive',
                name : 'Comprehensive',
                sectionNumber : 2,
                sections : [],
                questions : [
                    {
                        key : 'Claims.Comprehensive-21',
                        text : 'Number of claims open at the beginning of the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.21.comprehensive',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    },
                    {
                        key : 'Claims.Comprehensive-22',
                        text : 'Number of claims opened during the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.22.comprehensive',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    }
                ]
            }
        ],
        questions : []
    }
],
questions : []
};

export const MOCK_PPA_FILING_PRIOR: any = {
  state: MOCK_MCAS_STATES[0],
  lineOfBusiness: {id: 1, code: 'PPA', name: 'Private Passenger Auto'},
  year: MOCK_YEARS[MOCK_YEARS.length - 2],
  status: MOCK_IN_PROGRESS,
  sections: [
    {
        key: 'Interrogatories',
        name: 'Interrogatories',
        sectionNumber : 1,
        sections : [],
        questions : [
            {
                key : 'Interrogatories-01',
                text : 'Were there policies in force during the reporting period that provided Collision coverage?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT.01.yes_no_response',
                        label : 'Response',
                        controlType : 'dropdown',
                        options : [
                            {
                                key : 'Y',
                                value : 'Y'
                            },
                            {
                                key : 'N',
                                value : 'N'
                            }
                        ],
                        disabled : false,
                        required : false
                    }
                ]
            },
            {
                key : 'Interrogatories-02',
                text : 'Were there policies in force during the reporting period that provided Comprehensive coverage?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT.02.yes_no_response',
                        label : 'Response',
                        controlType : 'dropdown',
                        options : [
                            {
                                key : 'Y',
                                value : 'Y'
                            },
                            {
                                key : 'N',
                                value : 'N'
                            }
                        ],
                        disabled : false,
                        required : false
                    }
                ]
            }
        ]
    },
    {
        key : 'Claims',
        name : 'Claims',
        sectionNumber : 1,
        sections : [
            {
                key : 'Collision',
                name : 'Collision',
                sectionNumber : 2,
                sections : [],
                questions : [
                    {
                        key : 'Claims.Collision-21',
                        text : 'Number of claims open at the beginning of the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.21.collision',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    },
                    {
                        key : 'Claims.Collision-22',
                        text : 'Number of claims opened during the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.22.collision',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    }
                ]
            },
            {
                key : 'Comprehensive',
                name : 'Comprehensive',
                sectionNumber : 2,
                sections : [],
                questions : [
                    {
                        key : 'Claims.Comprehensive-21',
                        text : 'Number of claims open at the beginning of the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.21.comprehensive',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    },
                    {
                        key : 'Claims.Comprehensive-22',
                        text : 'Number of claims opened during the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.22.comprehensive',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    }
                ]
            }
        ],
        questions : []
    }
],
questions : []
};

export const MOCK_PPA_FILING_2016: any = {
  state: MOCK_MCAS_STATES[0],
  lineOfBusiness: {id: 1, code: 'PPA', name: 'Private Passenger Auto'},
  year: MOCK_YEARS[MOCK_YEARS.length - 3],
  status: MOCK_IN_PROGRESS,
  sections: [
    {
        key: 'Interrogatories',
        name: 'Interrogatories',
        sectionNumber : 1,
        sections : [],
        questions : [
            {
                key : 'Interrogatories-01',
                text : 'Were there policies in force during the reporting period that provided Collision coverage?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT.01.yes_no_response',
                        label : 'Response',
                        controlType : 'dropdown',
                        options : [
                            {
                                key : 'Y',
                                value : 'Y'
                            },
                            {
                                key : 'N',
                                value : 'N'
                            }
                        ],
                        disabled : false,
                        required : false
                    }
                ]
            },
            {
                key : 'Interrogatories-02',
                text : 'Were there policies in force during the reporting period that provided Comprehensive coverage?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT.02.yes_no_response',
                        label : 'Response',
                        controlType : 'dropdown',
                        options : [
                            {
                                key : 'Y',
                                value : 'Y'
                            },
                            {
                                key : 'N',
                                value : 'N'
                            }
                        ],
                        disabled : false,
                        required : false
                    }
                ]
            }
        ]
    },
    {
        key : 'Claims',
        name : 'Claims',
        sectionNumber : 1,
        sections : [
            {
                key : 'Collision',
                name : 'Collision',
                sectionNumber : 2,
                sections : [],
                questions : [
                    {
                        key : 'Claims.Collision-21',
                        text : 'Number of claims open at the beginning of the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.21.collision',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    },
                    {
                        key : 'Claims.Collision-22',
                        text : 'Number of claims opened during the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.22.collision',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    }
                ]
            },
            {
                key : 'Comprehensive',
                name : 'Comprehensive',
                sectionNumber : 2,
                sections : [],
                questions : [
                    {
                        key : 'Claims.Comprehensive-21',
                        text : 'Number of claims open at the beginning of the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.21.comprehensive',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    },
                    {
                        key : 'Claims.Comprehensive-22',
                        text : 'Number of claims opened during the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.22.comprehensive',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    }
                ]
            }
        ],
        questions : []
    }
  ],
questions : []
};

export const MOCK_PPA_FILING_2015: any = {
  state: MOCK_MCAS_STATES[0],
  lineOfBusiness: {id: 1, code: 'PPA', name: 'Private Passenger Auto'},
  year: MOCK_YEARS[MOCK_YEARS.length - 4],
  status: MOCK_IN_PROGRESS,
  sections: [
    {
        key: 'Interrogatories',
        name: 'Interrogatories',
        sectionNumber : 1,
        sections : [],
        questions : [
            {
                key : 'Interrogatories-01',
                text : 'Were there policies in force during the reporting period that provided Collision coverage?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT.01.yes_no_response',
                        label : 'Response',
                        controlType : 'dropdown',
                        options : [
                            {
                                key : 'Y',
                                value : 'Y'
                            },
                            {
                                key : 'N',
                                value : 'N'
                            }
                        ],
                        disabled : false,
                        required : false
                    }
                ]
            },
            {
                key : 'Interrogatories-02',
                text : 'Were there policies in force during the reporting period that provided Comprehensive coverage?',
                inputs : [
                    {
                        key : 'P2018MCASPPAINT.02.yes_no_response',
                        label : 'Response',
                        controlType : 'dropdown',
                        options : [
                            {
                                key : 'Y',
                                value : 'Y'
                            },
                            {
                                key : 'N',
                                value : 'N'
                            }
                        ],
                        disabled : false,
                        required : false
                    }
                ]
            }
        ]
    },
    {
        key : 'Claims',
        name : 'Claims',
        sectionNumber : 1,
        sections : [
            {
                key : 'Collision',
                name : 'Collision',
                sectionNumber : 2,
                sections : [],
                questions : [
                    {
                        key : 'Claims.Collision-21',
                        text : 'Number of claims open at the beginning of the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.21.collision',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    },
                    {
                        key : 'Claims.Collision-22',
                        text : 'Number of claims opened during the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.22.collision',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    }
                ]
            },
            {
                key : 'Comprehensive',
                name : 'Comprehensive',
                sectionNumber : 2,
                sections : [],
                questions : [
                    {
                        key : 'Claims.Comprehensive-21',
                        text : 'Number of claims open at the beginning of the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.21.comprehensive',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    },
                    {
                        key : 'Claims.Comprehensive-22',
                        text : 'Number of claims opened during the period.',
                        inputs : [
                            {
                                key : 'P2018MCASPPACLMS.22.comprehensive',
                                label : 'Total',
                                controlType : 'textbox',
                                options : [],
                                disabled : false,
                                required : false
                            }
                        ]
                    }
                ]
            }
        ],
        questions : []
    }
  ],
questions : []
};
