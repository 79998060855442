export class MultipleExtensions {
  label: string;
  value: number;
  extensionNumber: number;

  constructor(label?: string, value?: number, extensionNumber?: number) {
    this.label = label || "";
    this.value = value || null;
    this.extensionNumber = extensionNumber || null;
  }
}

export const multipleExtensions: MultipleExtensions[] = [
  {
    label: "Initial Request (14 days past deadline)",
    value: 14,
    extensionNumber: 1
  },
  {
    label: "Second Request (28 days past deadline)",
    value: 28,
    extensionNumber: 2
  },
  {
    label: "Third Request (42 days past deadline)",
    value: 42,
    extensionNumber: 3
  },
  {
    label: "Final Request (56 days past deadline)",
    value: 56,
    extensionNumber: 4
  },
]