import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserAdminComponent } from './user-admin.component';
import { RoleGuard } from '../shared/role.guard';

const routes: Routes = [
  { path: 'userAdmin', component: UserAdminComponent, canActivate: [RoleGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserAdminRoutingModule { }
