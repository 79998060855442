import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../shared/model/user';
import { UserHistory } from '../filing-matrix/model/user-history';
import { HttpClient } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from '../shared/http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Company } from '../shared/model/company';


@Injectable()
export class McsUserService {
  isAppClosed: boolean;
  private storedUser: User;
  private storedUserProfile: any;
  private storedUserInfo: any;
  private userHistory: UserHistory[] = [];
  private lastActivity: Date;
  readonly handleError: HandleError;
  isCurrentYear: boolean = false;
  appMessage: string;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('McsUserService');
    this.setLastActivity();
  }

  setLastActivity(): void {
    this.lastActivity = new Date();
  }

  getLastActivity(): Date {
    return this.lastActivity;
  }

  getMcsUser(username: string): Observable<User> {
    let url = `${environment.apiUrl}users/${username}`;
    return this.httpClient.get<User>(url)
      .pipe(catchError(this.handleError('getMcsUser', null)));
  }

  getStoredUser(): User {
    return this.storedUser;
  }

  setStoredUser(user: User) {
    this.storedUser = user;
  }

  getStoredUserProfile(): any {
    return this.storedUserProfile;
  }

  setStoredUserProfile(userProfile: any) {
    this.storedUserProfile = userProfile;
  }

  getStoredUserInfo(): any {
    return this.storedUserInfo;
  }

  setStoredUserInfo(userInfo: any): void {
    this.storedUserInfo = userInfo;
  }

  getUserHistory(): UserHistory[] {
    return this.userHistory;
  }

  addUserHistory(historyItem: UserHistory) {
    historyItem.date = new Date().toString();
    this.userHistory.unshift(historyItem);
  }

  isUserAdmin(cocode: number): boolean {
    if (this.storedUser && this.storedUser !== null) {
      if (this.storedUser.cocodesAsPrimary && this.storedUser.cocodesAsPrimary.length > 0) {
        let passed = this.storedUser.cocodesAsPrimary.filter((company: Company) => company.cocode === cocode);
        return passed.length > 0;
      }
    }
    return false;
  }

  isExternalUser(): boolean {
    if (this.storedUser && this.storedUser.role) {
      return this.storedUser.role === 'MCAS_USER_PR';
    } else {
      return false;
    }

  }

  isInternalUser(): boolean {
    if (this.storedUser && this.storedUser.role) {
      return this.storedUser.role === 'MCAS_NAIC_PR';
    }
    return false;
  }

  isRegulator(): boolean {
    if (this.storedUser && this.storedUser.role) {
      return this.storedUser.role === 'MCAS_ISITE_USER_PR';
    }
    return false;
  }

  isHelpDeskUser(): boolean {
    if (this.storedUser && this.storedUser.role) {
      return this.storedUser.role === 'MCAS_HELP_PR';
    }
    return false;
  }

}
