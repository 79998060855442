import { Company } from '../../shared/model/company';
import { Year } from '../../shared/model/year';

export class UserHistory {
  year: Year;
  company: Company;
  cocode: number;
  date: string;
  currentFilingYear: number;

  constructor(year: Year, company: Company, cocode: number, date: string, currentFilingYear: number) {
    this.year = year;
    this.company = company;
    this.cocode = cocode;
    this.date = date;
    this.currentFilingYear = currentFilingYear;
  }
}
