import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../shared/model/user';
import { McsUserService } from '../service/mcs-user.service';
import { Company } from '../shared/model/company';
import { Subscription } from 'rxjs';
import { UserAdminService } from './service/user-admin.service';
import { CompanyAssignment } from './model/company-assignment';
import { DataTableHelperService } from '../shared/data-table-helper.service';
import { BsModalRef, BsModalService } from '../../../node_modules/ngx-bootstrap/modal';
import { Subject } from '../../../node_modules/rxjs';
import { UserRemoveComponent } from './user-remove/user-remove.component';
import { MessageService } from '../messages/service/message.service';
import { AlertMessage } from '../messages/model/alert-message';
import { UserCompany } from './model/user-company';
import { takeUntil } from '../../../node_modules/rxjs/operators';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CompanyService } from '../filing-matrix/company-year-select/services/company.service';
import { DataService } from '../filing-matrix/data/data.service';
import { UserHistory } from '../filing-matrix/model/user-history';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.css']
})
export class UserAdminComponent implements OnInit, OnDestroy {
  title = 'User Administration';
  companyFilterTerm: string;
  user: User;
  companyAssignment: CompanyAssignment;
  editMode = false;
  primaryTableRows = [];
  primaryTableColumns = [];
  allPrimaryTableColumns = [];
  secondaryTableRows = [];
  secondaryTableColums = [];
  allSecondaryColumns = [];
  loadingIndicator = true;
  scrollBarHorizontal = (window.innerWidth < 1200);
  admin: string;
  contact: string;
  newUser: string;
  bsModalRef: BsModalRef;
  modalSubscriptions: Subscription[];
  userCompanies: UserCompany[] = [];
  selectedUserCompany: UserCompany = null;
  enteredCocode: number;
  isSelectCompanyCollapsed = false;
  isActionsCollapsed = false;
  faPlus = faPlus;
  faMinus = faMinus;
  private unsubscribe: Subject<void> = new Subject();
  loading = false;
  testRadio = '2';

  constructor(
    private userService: McsUserService,
    private userAdminService: UserAdminService,
    private dataTableHelper: DataTableHelperService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private companyService: CompanyService,
    private dataService: DataService
  ) {
    this.modalSubscriptions = [];
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit(): void {
    this.user = this.userService.getStoredUser();
    if (!this.isSuperUser()) {
      this.setUserCompanies();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.messageService.clear();
  }

  setUserCompanies(): void {
    this.userCompanies.length = 0;
    this.user.cocodesAsPrimary.forEach((company: Company) => {
      let userCompany = new UserCompany();
      userCompany.user = this.user;
      userCompany.company = company;
      userCompany.isAdmin = true;
      this.userCompanies.push(userCompany);
    });
    this.user.cocodesAsSecondary.forEach((company: Company) => {
      let userCompany = new UserCompany();
      userCompany.user = this.user;
      userCompany.company = company;
      userCompany.isAdmin = false;
      this.userCompanies.push(userCompany);
    });
    for (let index = 0; index < this.userCompanies.length; index++) {
      if (this.userCompanies[index].company === this.dataService.company) {
        this.selectedUserCompany = this.userCompanies[index];
      }
    }
    if (!this.dataService.company) {
      this.selectedUserCompany = this.userCompanies[0];
    }
    this.getCompanyAssignments(this.selectedUserCompany.company.cocode);
  }

  getCompanyAssignments(cocode: number): void {
    this.messageService.clear();
    this.loading = true;
    this.userAdminService.getCompanyAssignment(cocode)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        companyAssignment => {
          if (companyAssignment) {
            this.companyAssignment = companyAssignment;
            if (this.companyAssignment.primaryUser) {
              this.setPrimaryTableData();
            } else {
              this.primaryTableColumns = [];
              this.allPrimaryTableColumns = [];
              this.primaryTableRows = [];
            }
            if (this.companyAssignment.secondaryUser && this.companyAssignment.secondaryUser.length >= 0) {
              this.setSecondaryTableData();
            } else {
              this.secondaryTableColums = [];
              this.allSecondaryColumns = [];
              this.secondaryTableRows = [];
            }
            for (let index = 0; index < this.userCompanies.length; index++) {
              if (this.userCompanies[index].company.cocode === cocode) {
                this.userAdminService.userAdminCompany = this.userCompanies[index].company;
                this.dataService.company = this.userCompanies[index].company;
                this.selectedUserCompany.company = this.userCompanies[index].company;
                let historyItem: UserHistory = new UserHistory(this.dataService.year, this.dataService.company, this.dataService.company.cocode, '', 2019);
                this.userService.addUserHistory(historyItem);
              }
            }
            this.title = `User Administration - ${this.companyService.getDisplayName(this.companyAssignment.company.longName, this.companyAssignment.company.cocode)}`;
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        error => {
          let message = 'Error getting company assignments';
          if (error.status === 404) {
            message = 'Cocode not found';
          }
          this.loading = false;
          this.messageService.add(new AlertMessage(message, 'danger', true, 0, 'userAdmin'));
        }
      );
  }

  private setPrimaryTableData(): void {
    this.primaryTableColumns = [];
    this.allPrimaryTableColumns = [];
    this.primaryTableRows = [];
    const data = this.setAssignmentData([this.companyAssignment.primaryUser]);
    const tempColumns = this.dataTableHelper.getColumns(data);
    const tempRows = this.dataTableHelper.getRows(data);
    this.primaryTableColumns = [...tempColumns];
    this.allPrimaryTableColumns = [...tempColumns];
    this.primaryTableRows = [...tempRows];
  }

  private setSecondaryTableData(): void {
    this.secondaryTableColums = [];
    this.allSecondaryColumns = [];
    this.secondaryTableRows = [];
    const data = this.setAssignmentData(this.companyAssignment.secondaryUser);
    const tempColumns = this.dataTableHelper.getColumns(data);
    const tempRows = this.dataTableHelper.getRows(data);
    this.secondaryTableColums = [...tempColumns];
    this.allSecondaryColumns = [...tempColumns];
    this.secondaryTableRows = [...tempRows];
  }

  private setAssignmentData(userArray: User[]): any[] {
    const assignmentData: any[] = [];
    userArray.forEach(user => {
      const data = {};
      if (user.username === this.companyAssignment.primaryUser.username) {
        this.admin = user.username;
      }
      if (user.isContact) {
        this.contact = user.username;
      }
      data['administrator'] = user.username;
      data['contact'] = user.username;
      data['userId'] = user.username;
      data['name'] = `${user.firstName} ${user.lastName}`;
      data['phone'] = user.phone;
      data['email'] = user.email;
      data['actions'] = user;
      assignmentData.push(data);
    });
    return assignmentData;
  }

  isContact(user: string): boolean {
    return user.toLocaleLowerCase() === this.contact.toLowerCase();
  }

  setContact(username: string): void {
    this.messageService.clear();
    this.loading = true;
    this.userAdminService.setContact(this.selectedUserCompany ? this.selectedUserCompany.company.cocode : this.enteredCocode, username)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        companyAssignment => {
          if (companyAssignment && companyAssignment !== null) {
            this.companyAssignment = companyAssignment;
            this.setPrimaryTableData();
            this.setSecondaryTableData();
            this.loading = false;
            this.messageService.add(new AlertMessage('Contact has been updated', 'success'));
          } else {
            this.loading = false;
          }
        },
        error => {
          this.loading = false;
          this.messageService.add(new AlertMessage('Unable to set contact', 'danger'));
        }
      );
  }

  getColumnMode(): string {
    return this.scrollBarHorizontal ? 'force' : 'flex';
  }

  addUser(): void {
    this.messageService.clear();
    this.loading = true;
    if (!this.companyAssignment.primaryUser || this.companyAssignment.primaryUser === null) {
      this.userAdminService.setPrimary(this.selectedUserCompany ? this.selectedUserCompany.company.cocode : this.enteredCocode, this.newUser)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          companyAssignment => {
            if (companyAssignment && companyAssignment !== null) {
              this.companyAssignment = companyAssignment;
              this.setPrimaryTableData();
              this.setSecondaryTableData();
              this.newUser = '';
              this.loading = false;
              let userDisplay = `${this.companyAssignment.primaryUser.firstName} ${this.companyAssignment.primaryUser.lastName}`;
              this.messageService.add(new AlertMessage(`${userDisplay.length > 1 ? userDisplay : this.companyAssignment.primaryUser.username} has been successfully added`, 'success'));
            } else {
              this.loading = false;
            }
          },
          error => {
            let message = 'Error adding user';
            if (error.status === 403) {

            }
            this.loading = false;
            this.messageService.add(new AlertMessage(message, 'danger'));
          }
        );
    } else {
      this.userAdminService.setSecondary(this.selectedUserCompany ? this.selectedUserCompany.company.cocode : this.enteredCocode, this.newUser)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          companyAssignment => {
            if (companyAssignment && companyAssignment !== null) {
              this.companyAssignment = companyAssignment;
              this.setPrimaryTableData();
              this.setSecondaryTableData();
              let addedUser: User;
              this.companyAssignment.secondaryUser.forEach((user: User) => {
                addedUser = user.username === this.newUser ? user : undefined;
              });
              this.newUser = '';
              let message = 'User has been added';
              if (addedUser) {
                let userDisplay = `${addedUser.firstName} ${addedUser.lastName}`;
                message = `${userDisplay.length > 1 ? userDisplay : addedUser.username} has been successfully added`;
              }
              this.loading = false;
              this.messageService.add(new AlertMessage(message, 'success'));
            } else {
              this.loading = false;
            }
          },
          error => {
            let message = 'Error adding user';
            if (error.status === 403) {

            } else if (error.status === 404) {
              message = 'User not found';
            }
            this.loading = false;
            this.messageService.add(new AlertMessage(message, 'danger'));
          }
        );
    }
  }

  setAdmin(username: string): void {
    this.messageService.clear();
    this.userAdminService.replacePrimary(this.selectedUserCompany ? this.selectedUserCompany.company.cocode : this.enteredCocode, username)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        companyAssignment => {
          if (companyAssignment && companyAssignment !== null) {
            this.companyAssignment = companyAssignment;
            this.setPrimaryTableData();
            this.setSecondaryTableData();
            this.messageService.add(new AlertMessage('Administrator has been updated', 'success'));
          }
        },
        error => {
          let message = 'Error setting administrator';
          if (error.status === 403) {
            message = 'User does not have the authority to set administrator';
          } else if (error.status === 400) {
            message = 'Cocode has no user assignments';
          }
          this.loading = false;
          this.messageService.add(new AlertMessage(message, 'danger'));
        }
      );
  }

  removeUser(user: User): void {
    this.messageService.clear();
    const initialState = { user: user, cocode: this.selectedUserCompany ? this.selectedUserCompany.company.cocode : this.enteredCocode };

    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        if (this.bsModalRef.content.remove) {
          this.remove(this.selectedUserCompany ? this.selectedUserCompany.company.cocode : this.enteredCocode, user);
        }
        this.unsubscribeModal();
      })
    );
    this.bsModalRef = this.modalService.show(UserRemoveComponent, { initialState: initialState, class: 'modal-sm' });
  }

  private remove(company: number, user: User) {
    this.loading = true;
    this.userAdminService.removeUser(company, user.username)
      .subscribe(
        companyAssignment => {
          if (companyAssignment && companyAssignment !== null) {
            this.companyAssignment = companyAssignment;
            this.setPrimaryTableData();
            this.setSecondaryTableData();
            this.loading = false;
            let userDisplay = `${user.firstName} ${user.lastName}`;
            this.messageService.add(new AlertMessage(`${userDisplay.length > 1 ? userDisplay : user.username} has been successfully removed`, 'success'));
          } else {
            this.loading = false;
          }
        },
        error => {
          let message = 'Error removing user';
          if (error.status === 403) {
            message = 'User does not have the authority to remove contacts';
          } else if (error.status === 400) {
            message = 'Cocode has no user assignments';
          }
          this.loading = false;
          this.messageService.add(new AlertMessage(message, 'danger'));
        }
      );
  }

  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }

  isAdmin(): boolean {
    if (this.user.role === 'MCAS_NAIC_PR') {
      return true;
    } else if (this.user.role === 'MCAS_USER_PR') {
      return (this.companyAssignment.primaryUser && this.companyAssignment.primaryUser.username === this.user.username);
    } else {
      return false;
    }
  }

  isSuperUser(): boolean {
    return this.user.role === 'MCAS_NAIC_PR';
  }

  companyInputType(): string {
    if (this.user.role === 'MCAS_NAIC_PR') {
      return 'text';
    } else {
      return 'select';
    }
  }

  getCompanyDisplayName(company: Company): string {
    return this.companyService.getDisplayName(company.longName, company.cocode);
  }

}
