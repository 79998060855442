import { Component, OnInit, OnDestroy, HostListener, Input } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { DashboardService } from "./service/dashboard.service";
import { Dashboards } from "./model/dashboards";
import { DeleteCorrespondenceRendererComponent } from "./cell-renderer/delete-correspondence-renderer/delete-correspondence-renderer.component";
import { AlertMessage } from "../../messages/model/alert-message";
import { MessageService } from "../../messages/service/message.service";
import { PdfRendererComponent } from "./cell-renderer/pdf-renderer/pdf-renderer.component";
import { ChangeStatusRendererComponent } from "./cell-renderer/change-status-renderer/change-status-renderer.component";
import { ActionsRendererComponent } from "./cell-renderer/actions-renderer/actions-renderer.component";
import { FinalResolutionRendererComponent } from "./cell-renderer/final-resolution-renderer/final-resolution-renderer.component";
import { AnalystCompanyNotesRendererComponent } from "./cell-renderer/analyst-company-notes-renderer/analyst-company-notes-renderer.component";
import { FollowupDateRendererComponent } from "./cell-renderer/followup-date-renderer/followup-date-renderer.component";
import { ClosedDateRendererComponent } from "./cell-renderer/closed-date-renderer/closed-date-renderer.component";
import { HttpErrorResponse } from "@angular/common/http";
import { McsUserService } from "../../service/mcs-user.service";
import { User } from "../../shared/model/user";
import { FilingYearOptions, StatusOptions, LobOptions } from "./model/dashboard-filters-constants";




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit, OnDestroy {
  @Input() dashboard: Dashboards;
  loading: boolean = true;
  private unsubscribe: Subject<void> = new Subject();
  private getRowHeight;
  rowData = [];
  initialRowData = [];
  columnDefs: any[];
  alive: boolean;
  scrollBarHorizontal = (window.innerWidth < 1200);
  defaultColDef: any;
  context: any;
  dataForCellRenderer: any[] = [];
  columnValues: any[];
  frameworkComponents: any;
  gridApi: any;
  private paginationPageSize;
  user: User;
  filingYearOptions: any[];
  statusOptions: any[];
  lobOptions: any[];
  selectedFilingYear: number;
  selectedStatus: string;
  selectedLob: string;
  selectedUser: string;
  selectedGroupCode: any;
  selectedCocode: number;
  filteredRowData: any[];
  subscriptions: Array<Subscription> = [];
  das: any;
  private mutationObserver: MutationObserver;

  constructor(
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private userService: McsUserService
  ) {
    this.alive = true;
    this.paginationPageSize = 15;
  }

  @HostListener('window:resize')
  onResize() {
    if (!this.gridApi) return;

    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    });
  }


  ngOnInit(): void {
    this.user = this.userService.getStoredUser();
    this.checkRegulatorRole();
    this.checkForRole();
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.getRowHeight = params => {
      return 40;
    }
    this.context = { componentParent: this };
    this.frameworkComponents = {
      deleteCorresponceRenderer: DeleteCorrespondenceRendererComponent,
      pdfRenderer: PdfRendererComponent,
      changeStatusRenderer: ChangeStatusRendererComponent,
      actionsRenderer: ActionsRendererComponent,
      finalResolutionRenderer: FinalResolutionRendererComponent,
      analystCompanyNotesRenderer: AnalystCompanyNotesRendererComponent,
      followupDateRenderer: FollowupDateRendererComponent,
      closedDateRenderer: ClosedDateRendererComponent
    };
    this.clearFilters();
    this.filters();
  }

  ngOnDestroy() {
    // stop observing
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.unsubscribe.complete();
  }

  checkForRole() {
    if (this.checkRegulatorRole() === true) {
      this.columnValues = this.dashboardService.getColumnDefsForAnalyst();
    } else {
      this.columnValues = this.dashboardService.getColumnDefsforRegulator();
    }
  }

  checkRegulatorRole(): boolean {
    if (this.user.role === "MCAS_ISITE_USER_PR") {
      return false;
    } else {
      return true;
    }
  }

  getDashboard(): void {
    this.rowData = this.dashboardService.getCorrespondenceDashboardData(this.dashboard);
    this.filterOnlySentLetters();
    this.rowData.sort(this.sortByProperty("letterProgress"));
    this.formattingDates();
    this.retainingFilters();
  }

  filterOnlySentLetters() {
    if (this.checkRegulatorRole() === false) {
      this.rowData = this.rowData.filter(onlySent => onlySent.letterProgress === 'Sent');
      this.initialRowData = this.rowData;
    } else {
      this.rowData = this.rowData;
      this.initialRowData = this.rowData;
    }
  }

  formattingDates() {
    let correspondenceDateToStr: any;
    let discoverDateToStr: any;
    this.rowData.forEach(d => {
      if (d.correspondenceDate || d.followUpDate) {
        correspondenceDateToStr = d.correspondenceDate.toString().split('T').reverse().pop();
        discoverDateToStr = d.discoverDate.toString().split('T').reverse().pop();
        d.correspondenceDate = correspondenceDateToStr;
        d.discoverDate = discoverDateToStr;
      }
    });
  }

  sortByProperty(property) {
    return function (a, b) {
      if (a[property] > b[property])
        return 1;
      else if (a[property] < b[property])
        return -1;
      return 0;
    }
  }

  onGridReady(params: any): void {
    let value = 10;
    this.gridApi = params.api;
    this.gridApi.paginationSetPageSize(Number(value));
    // css class selectors
    const scrollSelector = '.ag-body-horizontal-scroll';
    const scrollViewportSelector = '.ag-body-horizontal-scroll-viewport';
    const scrollContainerSelector = '.ag-body-horizontal-scroll-container';

    // get scrollbar elements
    const scrollElement = document.querySelector(scrollSelector);
    const scrollViewportElement = document.querySelector(scrollViewportSelector);
    const scrollContainerElement = document.querySelector(scrollContainerSelector);

    // create scrollbar clones
    const cloneElement = scrollElement.cloneNode(true) as Element;
    const cloneViewportElement = cloneElement.querySelector(scrollViewportSelector);
    const cloneContainerElement = cloneElement.querySelector(scrollContainerSelector);

    // insert scrollbar clone
    const headerElement = document.querySelector('.ag-header');
    headerElement.insertAdjacentElement('afterend', cloneElement);

    // add event listeners to keep scroll position synchronized
    scrollViewportElement.addEventListener('scroll', () => cloneViewportElement.scrollTo({ left: scrollViewportElement.scrollLeft }));
    cloneViewportElement.addEventListener('scroll', () => scrollViewportElement.scrollTo({ left: cloneViewportElement.scrollLeft }));

    // create a mutation observer to keep scroll size synchronized
    this.mutationObserver = new MutationObserver(mutationList => {
      for (const mutation of mutationList) {
        switch (mutation.target) {
          case scrollElement:
            cloneElement.setAttribute('style', scrollElement.getAttribute('style'));
            break;
          case scrollViewportElement:
            cloneViewportElement.setAttribute('style', `${scrollViewportElement.getAttribute('style')};"visibility: hidden"`);
            break;
          case scrollContainerElement:
            cloneContainerElement.setAttribute('style', scrollContainerElement.getAttribute('style'));
            break;
        }
      }
    });

    // start observing the scroll elements for `style` attribute changes
    this.mutationObserver.observe(scrollElement, { attributeFilter: ['style'], subtree: true });
    params.api.sizeColumnsToFit();
  }

  filters() {
    this.filingYearOptions = FilingYearOptions;
    this.statusOptions = StatusOptions;
    this.lobOptions = LobOptions;
  }

  retainingFilters() {
    !this.dashboardService.selectedUser ? this.selectedUser = '' : this.selectedUser = this.dashboardService.selectedUser;
    !this.dashboardService.selectedLob ? this.selectedLob = 'ALL' : this.selectedLob = this.dashboardService.selectedLob;
    !this.dashboardService.selectedGroupCode ? this.selectedGroupCode = '' : this.selectedGroupCode = this.dashboardService.selectedGroupCode;
    !this.dashboardService.selectedCocode ? this.selectedCocode = null : this.selectedCocode = this.dashboardService.selectedCocode;
    !this.dashboardService.selectedFilingYear ? this.selectedFilingYear = 2024 : this.selectedFilingYear = this.dashboardService.selectedFilingYear;
    !this.dashboardService.selectedStatus ? this.selectedStatus = 'All' : this.selectedStatus = this.dashboardService.selectedStatus;
    this.filterDashboard();
  }

  filterUser(value: any) {
    let user: any = value.target.value;
    this.selectedUser = user;
    this.dashboardService.selectedUser = this.selectedUser;
    this.filterDashboard();
  }

  filterGroupCode(value: any) {
    let groupCode: any = value.target.value;
    this.selectedGroupCode = groupCode;
    this.dashboardService.selectedGroupCode = this.selectedGroupCode;
    this.filterDashboard();
  }

  filterCocode(value: any) {
    let cocode: any = value.target.value;
    this.selectedCocode = cocode;
    this.dashboardService.selectedCocode = this.selectedCocode;
    this.filterDashboard();
  }

  filterLob(value: any) {
    this.dashboardService.selectedLob = this.selectedLob;
    this.filterDashboard();
  }

  filterStatus(value: any) {
    this.dashboardService.selectedStatus = this.selectedStatus;
    this.filterDashboard();
  }

  filterFilingYear(value: any) {
    this.dashboardService.selectedFilingYear = this.selectedFilingYear;
    this.filterDashboard();
  }

  filterDashboard() {
    this.filteredRowData = [];
    this.rowData = this.initialRowData;
    //Status Filter
    if (this.selectedStatus === 'All') {
      this.rowData = this.initialRowData;
    } else {
      this.rowData = this.rowData.filter(status => status.status === this.selectedStatus);
    }
    this.filteredRowData = this.rowData;
    //LOB Filter
    if (this.selectedLob === 'ALL') {
      this.rowData = this.filteredRowData;
    } else {
      this.rowData = this.filteredRowData.filter(lob => lob.lobCode === this.selectedLob);
    }
    this.filteredRowData = this.rowData;
    //User Filter
    if (this.selectedUser === undefined || this.selectedUser === null) {
      this.rowData = this.filteredRowData;
    } else {
      this.rowData = this.filteredRowData.filter(item => item.senderId.includes(this.selectedUser));
    }
    this.filteredRowData = this.rowData;
    //Cocode Filter
    if (this.selectedCocode === undefined || this.selectedCocode === null) {
      this.rowData = this.filteredRowData;
    } else {
      this.rowData = this.filteredRowData.filter(item => (item.cocode).toString().includes((this.selectedCocode).toString()));
    }
    this.filteredRowData = this.rowData;
    //Group Cocode Filter
    if (this.selectedGroupCode === undefined || this.selectedGroupCode === null || this.selectedGroupCode === '') {
      this.rowData = this.filteredRowData;
    } else {
      this.rowData = this.filteredRowData.filter(item => (item.groupCode).toString().includes((this.selectedGroupCode).toString()));
    }
    //Year Filter
    if (this.selectedFilingYear === undefined || this.selectedFilingYear === null) {
      this.rowData = this.filteredRowData;
    }
    else {
      this.rowData = this.filteredRowData.filter(item => (item.filingYear).toString().includes((this.selectedFilingYear).toString()));
    }
    this.filteredRowData = this.rowData;
    this.dashboardService.filteredRowData = this.filteredRowData;
  }

  clearFilters() {
    this.selectedFilingYear = 2024;
    this.selectedLob = 'ALL';
    this.selectedStatus = 'All';
    this.selectedUser = '';
    this.selectedGroupCode = '';
    this.selectedCocode = null;
    this.dashboardService.selectedUser = '';
    this.dashboardService.selectedLob = 'ALL';
    this.dashboardService.selectedGroupCode = '';
    this.dashboardService.selectedCocode = null;
    this.dashboardService.selectedFilingYear = 2024;
    this.dashboardService.selectedStatus = 'All'
    this.clearFiltersAndGetLatestDashboard();
  }

  clearFiltersAndGetLatestDashboard(): void {
    const sub = this.dashboardService.getDashboardData()
      .subscribe(
        dashboard => {
          this.dashboard = dashboard;
          this.checkRegulatorRole();
          this.getDashboard();
        });
        this.subscriptions.push(sub);
  }

  changeLetterStatus(id: string, status: any) {
    this.loading = true;
    this.dashboardService.putChangeStatus(id, status)
      .subscribe(
        () => {
          const message = `Letter Status Changed`;
          this.messageService.clear();
          this.messageService.add(new AlertMessage(message, 'success', true, 0));
          this.getDashboard();
          this.loading = false;
        }
      );
  }

  setClosedDate(id: string, closedDate: string) {
    this.loading = true;
    this.dashboardService.putClosedDate(id, closedDate)
      .subscribe(
        (res) => {
          const message = "Closed Date Updated";
          this.messageService.clear();
          this.messageService.add(new AlertMessage(message, 'success', true, 0));
          this.getDashboard();
          this.loading = false;
        }, (err: HttpErrorResponse) => {
          const message = err.error.message;
          this.messageService.clear();
          this.messageService.add(new AlertMessage(message, 'danger', true, 0));
          this.getDashboard();
          this.loading = false;
        }
      )
  }

  setFollowupDate(id: string, followupDate: string) {
    this.loading = true;
    this.dashboardService.putFollowUpDate(id, followupDate)
      .subscribe(
        (res) => {
          const message = "Followup Date Updated";
          this.messageService.clear();
          this.messageService.add(new AlertMessage(message, 'success', true, 0));
          this.getDashboard();
          this.loading = false;
        }, (err: HttpErrorResponse) => {
          const message = err.error.message;
          this.messageService.clear();
          this.messageService.add(new AlertMessage(message, 'danger', true, 0));
          this.getDashboard();
          this.loading = false;
        }
      )
  }

  updateAnalystCompanyNotes(id: string, notes: any) {
    this.loading = true;
    this.dashboardService.putAnalystCompanyNotes(id, notes)
      .subscribe(
        () => {
          const message = 'Analyst Notes and Company Response Saved Succesfully';
          this.messageService.clear();
          this.messageService.add(new AlertMessage(message, 'success'));
          this.getDashboard();
          this.loading = false;
        }, (error: HttpErrorResponse) => {
          this.messageService.clear();
          this.messageService.add(new AlertMessage('Error Saving Notes', 'danger', true, 0));
          this.getDashboard();
          this.loading = false;
        }
      )
  }

  setFinalResolution(id: string, finalResolution: any) {
    this.loading = true;
    this.dashboardService.putFinalResolution(id, finalResolution)
      .subscribe(
        () => {
          const message = `Final Resolution Updated`;
          this.messageService.clear();
          this.messageService.add(new AlertMessage(message, 'success', true, 0));
          this.getDashboard();
          this.loading = false;
        }
      );
  }

  confirmDelete(id: string) {
    this.dashboardService.deleteCorrespondence(id)
      .subscribe(
        () => {
          const message = `Correspondence has been deleted`;
          this.messageService.clear();
          this.messageService.add(new AlertMessage(message, 'success', true, 0));
          this.getDashboard();
        }
      );
  }

}
