import { Injectable } from '@angular/core';
import { Year } from '../../shared/model/year';
import { Observable, Subject } from 'rxjs';

import { HttpClient, HttpParams, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from '../../shared/http-error-handler.service';
import { FilingMatrix } from '../model/filing-matrix';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FilteredFiling } from '../model/filtered-filing';
import { Filter } from '../../shared/model/filter';

@Injectable()
export class FilingMatrixService {
  filingSummaryNavigation: boolean;
  private filingMatrixUrl = `${environment.apiUrl}filingmatrix/`;
  private storedCollapses: any;
  readonly handleError: HandleError;

   // retaining data for filters while moving between pages (Filing Matrix)
  matrixFilteredFilings: FilteredFiling[] = [];
  requiredFilters: Filter[] = [];
  lobFilters: Filter[] = [];
  lockedFilters: Filter[] = [];
  filingStatusFilters: Filter[] = [];
  warningsFilters: Filter[] = [];
  errorsFilters: Filter[] = [];
  extensionFilters: Filter[] = [];
  waiverFilters: Filter[] = [];
  stateFilterTerm: string;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('FilingMatrixService');
  }

  static rowColor(warnings: number, errors: number): string {
    if (errors > 0) {
      return 'table-danger';
    } else if (warnings > 0) {
      return 'table-warning';
    }
    return '';
  }

  static doFiltering(filter: string, filterArray: string[]): string[] {
    let filters: string[] = filterArray.slice();
    let termIndex = filters.indexOf(filter);
    if (termIndex === -1) {
      filters.push(filter);
    } else if (termIndex > -1) {
      filters.splice(termIndex, 1);
    }
    return filters;
  }

  getFilingMatrixByCompanyAndYear(cocode: number, year: Year): Observable<FilingMatrix> {
    const url = `${this.filingMatrixUrl}${cocode}`;
    let params = new HttpParams().set('year', year.year.toString());

    return this.httpClient.get<FilingMatrix>(url, {params})
      .pipe(catchError(this.handleError('getFilingMatrixByCompanyAndYear', null)));
  }

  postUploadData(cocode: number, year: Year, data: any): Observable<any> {
    const url = `${this.filingMatrixUrl}${cocode}/upload`;
    let params = new HttpParams().set('year', year.year.toString());

    const req = new HttpRequest('POST', url, data, {
      params,
      reportProgress: true
    });
    return this.httpClient.request(req);
    // .pipe(catchError(this.handleError('postUploadData', null)));
  }

  storeCollapses(filteredFilings: FilteredFiling[]): void {
    if (!this.storedCollapses) {
      this.storedCollapses = {};
    }
    filteredFilings.forEach(filteredFiling => {
      this.storedCollapses[filteredFiling.stateFiling.state.stabbr] = filteredFiling.matrixExpanded;
    });
  }

  setCollapses(filteredFilings: FilteredFiling[]): FilteredFiling[] {
    if (!this.storedCollapses) {
      this.storedCollapses = {};
    }
      filteredFilings.forEach(filteredFiling => {
        filteredFiling.matrixExpanded = this.storedCollapses[filteredFiling.stateFiling.state.stabbr];
      });
      this.storeCollapses(filteredFilings);
    return filteredFilings;
  }

  // fileUpload(cocode: number, year: number, files: Set<File>): { [key: string]: Observable<number> } {
  //   const status = {};
  //   const url = `${this.filingMatrixUrl}${cocode}/upload`;
  //   const params = new HttpParams().set('year', year.toString());

  //   files.forEach(file => {
  //     const formData: FormData = new FormData();
  //     formData.append('file', file, file.name);

  //     const req = new HttpRequest('POST', url, formData, {
  //       params,
  //       reportProgress: true
  //     });

  //     const progress = new Subject<number>();

  //     let startTime = new Date().getTime();
  //     this.httpClient.request(req).subscribe(event => {
  //       if (event.type === HttpEventType.UploadProgress) {
  //         const percentDone = Math.round((100 * event.loaded) / event.total);

  //         progress.next(percentDone);
  //       } else if (event instanceof HttpResponse) {
  //         console.log(`event status:: ${event.status}`);
  //         progress.complete();
  //       } else {
  //         progress.complete();
  //       }
  //     });

  //     status[file.name] = {
  //       progress: progress.asObservable()
  //     };
  //   });
  //   return status;
  // }

}
