import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarningsErrorsComponent } from './warnings-errors.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
  ],
  declarations: [WarningsErrorsComponent],
  exports: [WarningsErrorsComponent]
})
export class WarningsErrorsModule { }
