export const MockFilingBlank = {
  INTERROGATORIES: {
    lineOfBusiness: { code: 'HEALTH', name: 'Health' },
    year: { year: 2018, isCurrentYear: true },
    status: null,
    cocode: 99508,
    state: 'AL',
    sections: [
      {
        key: 'INTERROGATORIES',
        name: 'INTERROGATORIES',
        description: '',
        sectionNumber: 0,
        sections: [],
        questions: [
          {
            key: '-01',
            text:
              'In-Exchange - Does the company have Individual Health insurance coverage other than transitional, grandfathered, multi-state, catastrophic, or student data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.01_InExchangeDoesthecompanyhav.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: 'Y',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-02',
            text:
              'In-Exchange - Does the company have Small Group Health insurance coverage other than transitional, grandfathered, or multi-state policies data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.02_InExchangeDoesthecompanyhav.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: 'Y',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-03',
            text:
              'In-Exchange - Does the company have Catastrophic data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.03_InExchangeDoesthecompanyhav.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: 'Y',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-04',
            text:
              'In-Exchange - Does the company have Multi-State (Individual) data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.04_InExchangeDoesthecompanyhav.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-05',
            text:
              'In-Exchange - Does the company have Multi-State (Small Group) data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.05_InExchangeDoesthecompanyhav.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-06',
            text:
              'In-Exchange - Number of small groups in-force at the end of the reporting period.',
            inputs: [
              {
                key: 'HLTHINT.06_InExchangeNbrofsmallGrpinfo.comments',
                label: 'Comments',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: 'asdfasdfadf',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-07',
            text:
              'In-Exchange - Does the company have an additional voluntary level of review for grievances? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.07_InExchangeDoesthecompanyhav.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-08',
            text: 'In-Exchange Comments.',
            inputs: [
              {
                key: 'HLTHINT.08_InExchangeComments.comments',
                label: 'Comments',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-09',
            text:
              'Out-of-Exchange - Does the company have Individual Health insurance coverage other than transitional, grandfathered, multi-state, catastrophic, or student data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.09_OutofExchangeDoesthecompany.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-10',
            text:
              'Out-of-Exchange - Does the company have Small Group Health insurance coverage other than transitional, grandfathered, or multi-state policies data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.10_OutofExchangeDoesthecompany.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-11',
            text:
              'Out-of-Exchange - Does the company have Grandfathered or Transitional plan data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.11_OutofExchangeDoesthecompany.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-12',
            text:
              'Out-of-Exchange - Does the company have Catastrophic data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.12_OutofExchangeDoesthecompany.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-13',
            text:
              'Out-of-Exchange - Does the company have Large Group comprehensive major medical and managed care (Minimum Essential Coverage policies) data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.13_OutofExchangeDoesthecompany.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-14',
            text:
              'Out-of-Exchange - Does the company have Student Coverage data to report? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.14_OutofExchangeDoesthecompany.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-15',
            text:
              'Out-of-Exchange - Number of small groups in-force at the end of the reporting period.',
            inputs: [
              {
                key: 'HLTHINT.15_OutofExchangeNbrofsmallGrpi.comments',
                label: 'Comments',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-16',
            text:
              'Out-of-Exchange - Number of large groups in-force at the end of the reporting period.',
            inputs: [
              {
                key: 'HLTHINT.16_OutofExchangeNbroflargeGrpi.comments',
                label: 'Comments',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-17',
            text:
              'Out-of-Exchange - Does the company have an additional voluntary level of review for grievances? (Y/N)',
            inputs: [
              {
                key: 'HLTHINT.17_OutofExchangeDoesthecompany.response',
                label: 'Response (Yes/No)',
                controlType: 'dropdown',
                options: [
                  {
                    key: 'Y',
                    value: 'Y'
                  },
                  {
                    key: 'N',
                    value: 'N'
                  }
                ],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-18',
            text: 'Out-of-Exchange Comments.',
            inputs: [
              {
                key: 'HLTHINT.18_OutofExchangeComments.comments',
                label: 'Comments',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          }
        ],

      }
    ],
    previous: '',
    next: 'INEXCHANGEINDIVIDUALPOLICYADMINISTRATION'
  },
  INEXCHANGEINDIVIDUALPOLICYADMINISTRATION: {
    lineOfBusiness: { code: 'HEALTH', name: 'Health' },
    year: { year: 2018, isCurrentYear: true },
    status: null,
    cocode: 99508,
    state: 'AL',
    sections: [
      {
        key: 'POLICY ADMINISTRATION',
        name: 'POLICY ADMINISTRATION',
        description: '',
        sectionNumber: 0,
        sections: [],
        questions: [
          {
            key: '-19',
            text: 'Earned premiums for the reporting year.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.19_ErndPremforthereportingYr.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.19_ErndPremforthereportingYr.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.19_ErndPremforthereportingYr.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.19_ErndPremforthereportingYr.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.19_ErndPremforthereportingYr.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-20',
            text: 'Number of new policies issued during the period.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.20_NbrofnewPlcyissuedDurtheper.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.20_NbrofnewPlcyissuedDurtheper.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.20_NbrofnewPlcyissuedDurtheper.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.20_NbrofnewPlcyissuedDurtheper.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.20_NbrofnewPlcyissuedDurtheper.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-21',
            text: 'Number of policies renewed during the period.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.21_NbrofPlcyrenewedDurtheperio.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.21_NbrofPlcyrenewedDurtheperio.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.21_NbrofPlcyrenewedDurtheperio.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.21_NbrofPlcyrenewedDurtheperio.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.21_NbrofPlcyrenewedDurtheperio.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-22',
            text: 'Member months for policies issued during the period.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.22_MemmonthsforPlcyissuedDurth.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.22_MemmonthsforPlcyissuedDurth.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.22_MemmonthsforPlcyissuedDurth.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.22_MemmonthsforPlcyissuedDurth.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.22_MemmonthsforPlcyissuedDurth.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-23',
            text: 'Member months for policies renewed during the period.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.23_MemmonthsforPlcyrenewedDurt.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.23_MemmonthsforPlcyrenewedDurt.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.23_MemmonthsforPlcyrenewedDurt.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.23_MemmonthsforPlcyrenewedDurt.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.23_MemmonthsforPlcyrenewedDurt.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-24',
            text: 'Number of policy terminations and cancellations initiated by the consumer.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.24_Nbrofpolicyterminationsandc.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.24_Nbrofpolicyterminationsandc.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.24_Nbrofpolicyterminationsandc.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.24_Nbrofpolicyterminationsandc.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.24_Nbrofpolicyterminationsandc.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-25',
            text: 'Number of policy terminations and cancellations due to non-payment of premium.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.25_Nbrofpolicyterminationsandc.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.25_Nbrofpolicyterminationsandc.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.25_Nbrofpolicyterminationsandc.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.25_Nbrofpolicyterminationsandc.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.25_Nbrofpolicyterminationsandc.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-26',
            text: 'Number of lives impacted on terminations and cancellations initiated by the policyholder.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.26_Nbroflivesimpactedontermina.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.26_Nbroflivesimpactedontermina.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.26_Nbroflivesimpactedontermina.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.26_Nbroflivesimpactedontermina.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.26_Nbroflivesimpactedontermina.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-27',
            text: 'Number of lives impacted on policies terminated and cancelled due to non-payment.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.27_NbroflivesimpactedonPlcyter.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.27_NbroflivesimpactedonPlcyter.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.27_NbroflivesimpactedonPlcyter.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.27_NbroflivesimpactedonPlcyter.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.27_NbroflivesimpactedonPlcyter.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-28',
            text: 'Number of rescissions.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.28_Nbrofrescissions.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-29',
            text: 'Number of lives impacted by rescissions.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.29_Nbroflivesimpactedbyresciss.bronze_ieih',
                label: 'Bronze',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.29_Nbroflivesimpactedbyresciss.silver_ieih',
                label: 'Silver',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.29_Nbroflivesimpactedbyresciss.gold_ieih',
                label: 'Gold',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.29_Nbroflivesimpactedbyresciss.platinum_ieih',
                label: 'Platinum',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              },
              {
                key: 'HLTHIEXINDIV.29_Nbroflivesimpactedbyresciss.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          }
        ],

      }
    ],
    previous: 'INTERROGATORIES',
    next: 'INEXCHANGEINDIVIDUALPRIORAUTHORIZATIONSEXCLUDINGPHARMACY'
  },
  INEXCHANGEINDIVIDUALPRIORAUTHORIZATIONSEXCLUDINGPHARMACY: {
    lineOfBusiness: { code: 'HEALTH', name: 'Health' },
    year: { year: 2018, isCurrentYear: true },
    status: null,
    cocode: 99508,
    state: 'AL',
    sections: [
      {
        key: 'PRIOR AUTHORIZATIONS (PROSPECTIVE UTILIZATION REVIEW REQUESTS) EXCLUDING PHARMACY',
        name: 'PRIOR AUTHORIZATIONS (PROSPECTIVE UTILIZATION REVIEW REQUESTS) EXCLUDING PHARMACY',
        description: '',
        sectionNumber: 0,
        sections: [],
        questions: [
          {
            key: '-30',
            text: 'Number of prior authorizations requested.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.30_NbrofPrauthorizationsreques.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-31',
            text: 'Number of prior authorizations approved.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.31_NbrofPrauthorizationsapprov.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-32',
            text: 'Number of prior authorizations denied.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.32_NbrofPrauthorizationsdenied.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-33',
            text: 'Number of prior authorizations requested for mental health benefits, behavioral health benefits, and substance use disorders.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.33_NbrofPrauthorizationsreques.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-34',
            text: 'Number of prior authorizations for mental health benefits, behavioral health benefits, and substance use disorders denied.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.34_NbrofPrauthorizationsformen.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '-35',
            text: 'Number of prior authorizations for mental health benefits, behavioral health benefits, and substance use disorders approved.',
            inputs: [
              {
                key: 'HLTHIEXINDIV.35_NbrofPrauthorizationsformen.tot_ieih',
                label: 'Total',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: null,
                warnings: [],
                errors: []
              }
            ]
          }
        ],

      }
    ],
    previous: 'INEXCHANGEINDIVIDUALPOLICYADMINISTRATION',
    next: 'summary'
  },
  // INEXCHANGEINDIVIDUALPRIORAUTHORIZATIONSPHARMACYONLY: {},
  // INEXCHANGEINDIVIDUALCLAIMSADMINISTRATIONEXCLUDINGPHARMACY: {},
  // INEXCHANGEINDIVIDUALCLAIMSADMINISTRATIONPHARMACYONLY: {},
  // INEXCHANGEINDIVIDUALCONSUMERREQUESTEDINTERNALREVIEWSGRIEVANCESINCLUDINGPHARMACY: {},
  // INEXCHANGEINDIVIDUALCONSUMERREQUESTEDEXTERNALREVIEWSINCLUDINGPHARMACY: {}
};
