import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import { McsUserService } from '../../service/mcs-user.service';
import { Company } from '../../shared/model/company';
import { User } from '../../shared/model/user';
import { Year } from '../../shared/model/year';
import { DataService } from '../data/data.service';
import { UserHistory } from '../model/user-history';
import { CompanyService } from './services/company.service';
import { YearsService } from './services/years.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserAdminService } from '../../user-admin/service/user-admin.service';
import { FilingMatrixService } from '../services/filing-matrix.service';
//import { Router } from '@angular/router';

@Component({
  selector: 'app-company-year-select',
  templateUrl: './company-year-select.component.html',
  styleUrls: ['./company-year-select.component.css']
})
export class CompanyYearSelectComponent implements OnInit, OnDestroy {
  @Input() user: User;
  @Input() userHistory: UserHistory;
  @Output() companyYear = new EventEmitter<UserHistory>();
  selectedCompany: Company = null;
  selectedYear: Year = null;
  enteredCocode: number;
  years: Year[] = [];
  currentFilingYear = 0;
  companies: Company[] = [];
  error = false;
  message: string;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private yearsService: YearsService,
    private companyService: CompanyService,
    private userService: McsUserService,
    private dataService: DataService,
    private userAdminService: UserAdminService,
    private filingMatrixService: FilingMatrixService
    //private router: Router
  ) { }

  ngOnInit() {
    this.setSelectedFromHistory();

    // Sort companies alphabetically by display name
    this.companies.sort((a, b) => this.getCompanyDisplayName(a).localeCompare(this.getCompanyDisplayName(b)));
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setSelectedFromHistory() {
    if (this.userHistory) {
      this.selectedCompany = this.userHistory.company;
      this.dataService.company = this.selectedCompany;
      this.selectedYear = this.userHistory.year;
      this.enteredCocode = this.userHistory.cocode;
    }
    this.getYears();
    this.getCompanies();
  }

  getYears(): void {
    this.yearsService.getYears()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        years => {
          years.forEach(currentYear => {
            if (currentYear.year > this.currentFilingYear) {
              this.currentFilingYear = currentYear.year;
              this.yearsService.currentFilingYear = this.currentFilingYear;
            }
          });
          if (years) {
            this.yearsService.putCurrentYear(years.find(year => year.isCurrentYear));
            this.years = years.sort((leftSide, rightSide): number => {
              if (leftSide.year < rightSide.year) {
                return 1;
              }
              if (leftSide.year > rightSide.year) {
                return -1;
              }
              return 0;
            });
          }
        }
      );
  }

  getCompanies(): void {
    if (this.user.type === 'internal') {
      this.companyService.getCompanies()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          companies => {
            if (companies) {
              this.companies = companies;
            }
          }
        );
    } else {

      this.companies = this.user.cocodesAsPrimary.concat(this.user.cocodesAsSecondary);
      if (this.userAdminService.userAdminCompany) {
        for (let index = 0; index < this.companies.length; index++) {
          if (this.userAdminService.userAdminCompany && (this.companies[index].cocode === this.userAdminService.userAdminCompany.cocode)) {
            this.selectedCompany = this.companies[index];
            this.userAdminService.userAdminCompany = null;
          }
        }
      }
      if (this.companies.length === 1) {
        this.selectedCompany = this.companies[0];
      }
    }
  }

  setCompanyYear() {
    if (this.selectedCompany !== null && this.selectedYear !== null) {
      this.filingMatrixService.matrixFilteredFilings = [];
      this.dataService.year = this.selectedYear;
      this.dataService.company = this.selectedCompany;
      let historyItem: UserHistory = new UserHistory(this.selectedYear, this.selectedCompany, this.selectedCompany.cocode, '', this.currentFilingYear);
      this.userService.addUserHistory(historyItem);
      this.companyYear.emit(historyItem);
    }
  }

  setCocodeYear() {
    if (this.enteredCocode && this.enteredCocode !== null && this.selectedYear !== null) {
      this.filingMatrixService.matrixFilteredFilings = [];
      let historyItem: UserHistory = new UserHistory(this.selectedYear, null, this.enteredCocode, '', this.currentFilingYear);
      this.userService.addUserHistory(historyItem);
      this.companyYear.emit(historyItem);
    }
  }

  customCompareYear(o1: Year, o2: Year) {
    if (o1 === null && o2 === null) {
      return true;
    }
    if (o1 === null || o2 === null) {
      return false;
    }
    return o1.year === o2.year && o1.isCurrentYear === o2.isCurrentYear;
  }

  isSuperUser(): boolean {
    return this.user.role === 'MCAS_NAIC_PR';
  }

  getCompanyDisplayName(company: Company): string {
    return this.companyService.getDisplayName(company.longName, company.cocode);
  }

}
