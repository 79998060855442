import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyYearSelectComponent } from './company-year-select.component';
import { YearsService } from './services/years.service';
import { CompanyService } from './services/company.service';
import { DataModule } from '../data/data.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataModule
  ],
  declarations: [CompanyYearSelectComponent],
  providers: [YearsService, CompanyService],
  exports: [CompanyYearSelectComponent]
})
export class CompanyYearSelectModule { }
