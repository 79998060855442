<ng-container *ngIf="visible; else notVisible">
  <ng-container *ngIf="singlePdf; else link">
    <div class="btn-group">
      <button id="drop:pdfList" class="btn btn-primary btn-md dropdown-toggle" type="button"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{title}}
      </button>
      <ul class="dropdown-menu">
        <li *ngFor="let status of data">
          <a (click)=" viewPdfFromList(params.value.id, status.pdfLink)">{{status.dateSent}}</a>
          <div *ngIf="visible" class="dropdown-divider"></div>
        </li>
      </ul>
    </div>
  </ng-container>
</ng-container>

<ng-template #link>
  <button id="singlePdf" type="button" class="btn btn-primary"
    (click)="viewPdfFromLink(params.value.id, params.data)">{{title}}</button>
</ng-template>

<ng-template #notVisible>
  -
</ng-template>