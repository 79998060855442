import { MOCK_COMPANIES } from './mock-companies';
import { MOCK_YEARS } from './mock-years';
import { MOCK_MCAS_STATES } from './mock-mcas-states';
import { MOCK_LINES_OF_BUSINESS } from './mock-lines-of-business';

export const MOCK_WAIVERS: any[] = [
  {
    id: 1,
    company: MOCK_COMPANIES[1],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    state: MOCK_MCAS_STATES[0],
    lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
    requestedDate: '2017-10-15T09:23:16',
    approvedDate: '',
    status: 'PENDING',
    reason: 'Test pending waiver'
  },
  {
    id: 2,
    company: MOCK_COMPANIES[1],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    state: MOCK_MCAS_STATES[0],
    lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
    requestedDate: '2017-05-23T14:03:46',
    approvedDate: '2017-06-12T08:36:29',
    status: 'APPROVED',
    reason: 'Test approved waiver'
  },
  {
    id: 3,
    company: MOCK_COMPANIES[1],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    state: MOCK_MCAS_STATES[0],
    lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
    requestedDate: '2017-05-23T14:03:46',
    approvedDate: '2017-06-12T08:36:29',
    status: 'DENIED',
    reason: 'Test approved waiver'
  }
];


