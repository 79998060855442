import { Company } from '../../shared/model/company';
import { Year } from '../../shared/model/year';
import { StateFiling } from './state-filing';

export class FilingMatrix {
  id: number;
  company: Company;
  year: Year;
  stateFilings: StateFiling[];

  constructor(company?: Company, year?: Year,
              stateFilings?: StateFiling[]) {
    this.company = company;
    this.year = year;
    this.stateFilings = stateFilings;
  }
}
