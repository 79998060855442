import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MessagesModule } from '../../messages/messages.module';
import { PreviewLetterComponent } from './preview-letter.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PreviewLetterService } from './service/preview-letter.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MessagesModule,  
    PdfJsViewerModule
  ],
  declarations: [PreviewLetterComponent],
  providers: [PreviewLetterService],
  exports: [PreviewLetterComponent]
})
export class PreviewLetterModule { }
