<ng-container *ngIf="loading; else filingWizard">
  <ngx-loading [show]="loading"></ngx-loading>
</ng-container>

<ng-template #filingWizard>
  <ngx-loading [show]="loading"></ngx-loading>
  <div *ngIf="navigationList && filingBlank" id="filing-wizard" class="row">
    <div *ngIf="!sidebarCollapsed" class="col-md-2 col-sm-3 bg-light sidebar d-print-none">
      <div class="sidebar-sticky">
        <div id="collapseSidebar" class="pt-3 pb-2 mb-3 px-4 border-bottom">
          <span class="cursor-pointer" (click)="sidebarCollapsed = true">
            <fa-icon [icon]="faDoubleLeft"></fa-icon>&nbsp;Collapse Sidebar
          </span>
        </div>
        <!-- Actions -->
        <div id="filingWizardActions" class="pb-2 mb-3 px-4 border-bottom">
          <h4>Actions</h4>
          <div id="actions">
            <div *ngIf="!filingBlank.locked && user.role === 'MCAS_USER_PR'" class="pb-2">
              <button id="save" type="button" class="btn btn-secondary btn-sm btn-block" (click)="onSave()"
                [disabled]="isProcessing()">Save
              </button>
            </div>
            <div *ngIf="!filingBlank.locked && user.role === 'MCAS_USER_PR'" class="pb-2">
              <button id="saveAndValidate" type="button" class="btn btn-secondary btn-sm btn-block"
                (click)="onValidate()" [disabled]="isProcessing()">Save and Validate
              </button>
            </div>
            <div *ngIf="!filingBlank.locked && user.role === 'MCAS_USER_PR' && isSummary()" class="pb-2">
              <button id="submit" type="button" class="btn btn-primary btn-sm btn-block"
                (click)="onSubmit(submitWithWarnings)" [disabled]="isSubmitDissabled()">Submit
              </button>
            </div>
            <div *ngIf="!filingBlank.year.isCurrentYear && user.role === 'MCAS_NAIC_PR' && !(currentYear - year > 2)"
              class="pb-2">
              <button id="lockOrUnlock" type="button" class="btn btn-secondary btn-sm btn-block"
                (click)="putLock(!filingBlank.locked)">{{filingBlank.locked ? 'Unlock' : 'Lock'}}
              </button>
            </div>
            <div class="pb-2">
              <button id="close" type="button" class="btn btn-danger btn-sm btn-block"
                (click)="onCancel()">{{getCancelButtonText()}}
              </button>
            </div>
          </div>
        </div>
        <!-- Print Options -->
        <div id="filingWizardPrintOptions" class="pb-2 mb-3 px-4 border-bottom">
          <h4>
            <span class="cursor-pointer" (click)="isPrintOptionsCollapsed = !isPrintOptionsCollapsed"
              [attr.aria-expanded]="!isPrintOptionsCollapsed" aria-controls="printOptions">
              <fa-icon [icon]="isPrintOptionsCollapsed ? faPlus : faMinus"></fa-icon> Print Options
            </span>
          </h4>
          <div id="printOptions" [collapse]="isPrintOptionsCollapsed">
            <div class="pb-2">
              <a class="btn btn-secondary btn-sm btn-block" [href]="currentYearUrl" role="button" target="_blank">View
                {{year}} submitted data</a>
              <a *ngIf="isPreviousYearPrintAvailable()" class="btn btn-secondary btn-sm btn-block"
                [href]="previousYearUrl" role="button" target="_blank">View {{year - 1}} submitted data</a>
              <button type="button" class="btn btn-secondary btn-sm btn-block" (click)="print()">Print displayed
                data</button>
            </div>
          </div>
        </div>
        <!-- Filing Navigation -->
        <div id="filingWizardNavigation" class="sticky-sticky pb-2 mb-3 px-4 border-bottom">
          <h4>
            <span class="cursor-pointer" (click)="isFileNavigationCollapsed = !isFileNavigationCollapsed"
              [attr.aria-expanded]="!isFileNavigationCollapsed" aria-controls="fileNavigation">
              <fa-icon [icon]="isFileNavigationCollapsed ? faPlus : faMinus"></fa-icon> Filing Navigation
            </span>
          </h4>
          <div id="fileNavigation" [collapse]="isFileNavigationCollapsed" *ngIf="navigationList.length > 0">
            <div class="tab-menu" style="padding: 16px; margin: 0;">
              <div class="list-group">
                <ng-container *ngFor="let navItem of navigationList">
                  <ul class="nav nav-pills flex-column">
                    <app-nav-list [navItem]="navItem" [level]="0" [parentId]=""
                      (target)="isDataChanged() ? goToTarget($event) : getFilingBlank($event)"
                      [activeSection]="getActiveSection()"></app-nav-list>
                  </ul>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <main role="main" id="mainDisplay" [ngClass]="getMainStyle()">
      <app-messages [parentName]="'filingWizard'"></app-messages>
      <div id="filingMain" class="pt-3 pb-3 main-content d-print-block">
        <div class="row pb-3">
          <div class="col-12">
            <h3>
              <ng-container *ngIf="sidebarCollapsed">
                <span class="cursor-pointer" (click)="sidebarCollapsed = false" title="Expand Sidebar">
                  <fa-icon [icon]="faDoubleRight"></fa-icon>
                </span>
              </ng-container>
              {{title}}
              <span *ngIf="!filingBlank.year.isCurrentYear">
                <fa-icon [icon]="filingBlank.locked ? faLock : faLockOpen"></fa-icon>
              </span>
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <form novalidate *ngIf="filingBlank.sections.length" autocomplete="off">
              <app-filing-section *ngFor="let section of filingBlank.sections; let i = index;" 
                [section]="section"
                [parentId]="" 
                [previous]="isSummary() ? getLastTarget() : filingBlank.previous"
                [next]="filingBlank.next" 
                [index]="i"
                [disabled]="isSummary() || isInternal() || isProcessing() || filingBlank.locked"
                (goToSection)="goTo($event)">
              </app-filing-section>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-print-none pb-3 clearfix">
            <span class="float-right">
              <button *ngIf="getActiveSection() === '' || filingBlank.previous && filingBlank.previous.length > 0"
                type="button" class="btn btn-primary mr-2"
                [title]="getActiveSection() === '' ? formatSectionName(getLastTarget()) : formatSectionName(filingBlank.previous)"
                (click)="goTo('previous')">Previous
              </button>
              <button *ngIf="filingBlank.next && filingBlank.next.length > 0" type="button" class="btn btn-primary mr-2"
                [title]="formatSectionName(filingBlank.next)" (click)="goTo('next')">Next
              </button>
              <button *ngIf="getActiveSection().length > 0" type="button" class="btn btn-primary"
                (click)="goTo('summary')">Summary
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <footer class="col-12 footer d-print-none">
          <ncui-footer></ncui-footer>
        </footer>
      </div>

    </main>

    <main role="main" id="mainPrint" class="col-sm-12 ml-sm-auto d-none d-print-block">
      <app-messages [parentName]="'filingWizard'"></app-messages>
      <div id="filingMain" class="pt-3 pb-3 main-content d-print-block">
        <div class="row">
          <div class="col-12">
            <h3>
              {{title}}
              <span *ngIf="!filingBlank.year.isCurrentYear">
                  <fa-icon [icon]="filingBlank.locked ? faLock : faLockOpen"></fa-icon>
                </span>
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <form novalidate *ngIf="filingBlank.sections.length" autocomplete="off">
              <app-filing-section *ngFor="let section of filingBlank.sections" [section]="section" [parentId]=""
                [disabled]="isSummary() || isInternal() || isProcessing()"></app-filing-section>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <footer class="col-12 footer d-print-none">
          <ncui-footer></ncui-footer>
        </footer>
      </div>

    </main>
  </div>
</ng-template>

<ng-template #submitWithWarnings>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Submit with warnings</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p>Your MCAS filing contains data validation warnings. If the data is correct, please consider entering
          explanatory comments in the optional Interrogatory comments fields.</p>
        <p>Click Submit to continue or Cancel to enter comments.</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-link" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="submitFiling()">Submit</button>
  </div>
</ng-template>