import { User } from '../../shared/model/user';

export const MOCK_EXTERNAL_ADMIN: User = {
  userId: '123456',
  username: 'userA',
  type: 'external',
  role: 'MCAS_USER_PR',
  cocodesAsPrimary: [
    {id: 1, cocode: 123456, longName: 'Company A', shortName: 'compA'},
    {id: 2, cocode: 134567, longName: 'Company B', shortName: 'compB'},
  ],
  cocodesAsSecondary: [
    {id: 3, cocode: 145678, longName: 'Company C', shortName: 'compC'},
    {id: 4, cocode: 156789, longName: 'Company D', shortName: 'compD'},
  ],
  isDisclaimerAccepted: false,
  firstName: 'Admin',
  middleName: '',
  lastName: 'Industry',
  phone: '555-555-5555',
  email: 'iadmin@email',
  isContact: true,
};

export const MOCK_INTERNAL: User = {
  userId: '134567',
  username: 'userB',
  type: 'internal',
  role: 'MCAS_NAIC_PR',
  cocodesAsPrimary: [],
  cocodesAsSecondary: [],
  isDisclaimerAccepted: false,
  firstName: 'Super',
  middleName: '',
  lastName: 'User',
  phone: '555-555-5555',
  email: 'suser@email',
  isContact: false,
};

export const MOCK_HELP_DESK: User = {
  userId: '145678',
  username: 'userC',
  type: 'internal',
  role: 'MCAS_HELP_PR',
  cocodesAsPrimary: [],
  cocodesAsSecondary: [],
  isDisclaimerAccepted: false,
  firstName: 'Help',
  middleName: '',
  lastName: 'Desk',
  phone: '555-555-5555',
  email: 'hdesk@email',
  isContact: false,
};
 
// Mock User

export const MOCK_EXTERNAL: User = {
  userId: '23456789',
  username: 'userD',
  type: 'external',
  role: 'MCAS_USER_PR',
  // role: 'MCAS_NAIC_PR',
  // role: 'MCAS_HELP_PR',
  // role: 'MCAS_ISITE_USER_PR',
  cocodesAsPrimary: [
    {id: 1, cocode: 123456, longName: 'First Company', shortName: 'compA'},
    {id: 2, cocode: 134567, longName: 'Second Company', shortName: 'compB'},
    {id: 5, cocode: 167891, longName: 'Company E', shortName: 'compE'},
    {id: 6, cocode: 178912, longName: 'Company F', shortName: 'compF'},
  ],
  cocodesAsSecondary: [
    {id: 3, cocode: 145678, longName: 'Company C', shortName: 'compC'},
    {id: 4, cocode: 156789, longName: 'Company D', shortName: 'compD'},
  ],
  isDisclaimerAccepted: false,
  firstName: 'Industry',
  middleName: '',
  lastName: 'User',
  phone: '555-555-5555',
  email: 'iuser@email',
  isContact: false,
};

export const MOCK_REGULATOR: User = {
  userId: '23456789',
  username: 'userD',
  type: 'external',
  role: 'MCAS_ISITE_USER_PR',
  cocodesAsPrimary: [
    {id: 1, cocode: 123456, longName: 'First Company', shortName: 'compA'},
    {id: 2, cocode: 134567, longName: 'Second Company', shortName: 'compB'},
    {id: 5, cocode: 167891, longName: 'Company E', shortName: 'compE'},
    {id: 6, cocode: 178912, longName: 'Company F', shortName: 'compF'},
  ],
  cocodesAsSecondary: [
    {id: 3, cocode: 145678, longName: 'Company C', shortName: 'compC'},
    {id: 4, cocode: 156789, longName: 'Company D', shortName: 'compD'},
  ],
  isDisclaimerAccepted: false,
  firstName: 'Industry',
  middleName: '',
  lastName: 'User',
  phone: '555-555-5555',
  email: 'iuser@email',
  isContact: false,
};

export const MOCK_EXTERNAL_NO_COCODES: User = {
  userId: '987654',
  username: 'userF',
  type: 'external',
  role: 'MCAS_USER_PR',
  cocodesAsPrimary: [],
  cocodesAsSecondary: [],
  isDisclaimerAccepted: false,
  firstName: 'Industry',
  middleName: '',
  lastName: 'No Cocodes',
  phone: '555-555-5555',
  email: 'inococodes@email',
  isContact: false,
};

export const MOCK_EXTERNAL_BAD_ROLE: User = {
  userId: '654789',
  username: 'userE',
  type: 'external',
  role: 'MCAS_BAD_PR',
  cocodesAsPrimary: [],
  cocodesAsSecondary: [
    {id: 1, cocode: 123456, longName: 'Company A', shortName: 'compA'}
  ],
  isDisclaimerAccepted: false,
  firstName: 'Industry',
  middleName: '',
  lastName: 'User',
  phone: '555-555-5555',
  email: 'iuser@email',
  isContact: false,

};

export const MOCK_USERS: User[] = [
  MOCK_EXTERNAL_ADMIN,
  MOCK_INTERNAL,
  MOCK_HELP_DESK,
  MOCK_EXTERNAL,
  MOCK_EXTERNAL_NO_COCODES,
  MOCK_EXTERNAL_BAD_ROLE
];
