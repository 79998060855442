<div class="row">
  <form class="col-12">
    <h4>
      Filters
    </h4>
    <div class="form-inline">
      <div class="col-md form-group" style="padding-bottom: 10px;">
        <!-- Group Code Filter -->
        <label class="col-sm-0 col-form-label" for="groupCode" style="padding-right: 25px;">Group Code</label>
        <input type="text" class="form-control" name="groupCode" id="groupCode" placeholder="Group Code" #groupCodeFilter
        (keyup)="filterGroupCode($event)" [(ngModel)]="selectedGroupCode">
        <!-- User Filter -->
        <label class="col-sm-0 col-form-label" for="user" style="padding-right: 25px; padding-left: 25px;">User</label>
        <input type="text" class="form-control" name="user" id="user" placeholder="User" #userFilter
        (keyup)="filterUser($event)" [(ngModel)]="selectedUser">
        <!-- Filing Year Filter -->
        <label class="col-sm-0 col-form-label" for="filingYear" style="padding-right: 25px;  padding-left: 25px;">Filing Year</label>
        <select class="form-control form-control-sm" id="filingYear" name="filingYear"
          (change)="filterFilingYear($event)" [(ngModel)]="selectedFilingYear">
          <option [ngValue]="null" disabled hidden></option>
          <option *ngFor="let filingYear of filingYearOptions" [ngValue]="filingYear.value">
            {{filingYear.name}}</option>
        </select>
      </div>
      <div class="col-md-12 form-group" style="padding-bottom: 10px;">
        <!-- Cocode Filter -->
        <label class="col-sm-0 col-form-label" for="cocode" style="padding-right: 25px; padding-left: 30px;">Cocode</label>
        <input type="text" class="form-control" name="cocode" id="cocode" placeholder="Cocode" #cocodeFilter
        (keyup)="filterCocode($event)" [(ngModel)]="selectedCocode">
        <!-- LOB Filter -->
        <label class="col-sm-0 col-form-label" for="lob" style="padding-right: 25px;  padding-left: 25px;">LOB</label>
        <select class="form-control form-control-sm" id="lob" name="lob" (change)="filterLob($event)"
          [(ngModel)]="selectedLob">
          <option [ngValue]="null" disabled hidden></option>
          <option *ngFor="let lob of lobOptions" [ngValue]="lob.value">
            {{lob.name}}</option>
        </select>
        <!-- Status Filter -->
        <label class="col-sm-0 col-form-label" for="status" style="padding-right: 25px;  padding-left: 25px;">Status</label>
        <select class="form-control form-control-sm" id="status" name="status" (change)="filterStatus($event)"
          [(ngModel)]="selectedStatus">
          <option [ngValue]="null" disabled hidden></option>
          <option *ngFor="let status of statusOptions" [ngValue]="status.value">
            {{status.name}}</option>
        </select>
        <!-- Clear Filters -->
        <div class="form-group col-sm-2">
          <button type="button" id="clearFilters" class="form-control btn btn-secondary" (click)="clearFilters()">Clear Filters</button>
        </div>
      </div>
    </div>

  </form>
</div>

<ag-grid-angular *ngIf="rowData && rowData.length > 0; else noDashboard" 
  id="correspondenceDashboard"
  style="width: auto; height: 532px;" 
  class="ag-theme-balham"
  [rowData]="rowData" 
  [columnDefs]="columnValues"
  [defaultColDef]="defaultColDef"
  [pagination]="true"
  [paginationAutoPageSize]="false"
  [frameworkComponents]="frameworkComponents" 
  [getRowHeight]="getRowHeight"
  [context]="context" 
  (gridReady)="onGridReady($event)">
</ag-grid-angular>

<ng-template #noDashboard>
<div class="pt-3 pb-3">
  <h2 class="text-center">No Letters to Display</h2>
</div>
</ng-template>