import { Component, TemplateRef } from '@angular/core';
import { faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ICellRenderer } from 'ag-grid-community';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-actions-renderer',
  templateUrl: './actions-renderer.component.html',
  styleUrls: ['./actions-renderer.component.css']
})
export class ActionsRendererComponent implements ICellRenderer {
  params: any;
  faTimes = faTimes;
  fas = fas;
  deleteKey: string;
  modalRef: BsModalRef;
  visible: boolean;
  title: string;

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.onlyForDraft();
  }

  refresh(): boolean {
    return true;
  }

  onlyForDraft() {
    if (this.params.data.letterProgress === 'Sent') {
      this.visible = true;
      this.title = 'Actions';
    } else {
      this.visible = false;
      this.title = 'Actions';
    }

  }

  edit(id: string) {
    let actionsEditFlag: boolean = true;
    this.router.navigate(['/rulesResults', {
      actionsEditFlag: actionsEditFlag,
      id: id,
      actionsEditYear: this.params.data.filingYear,
      actionsEditCocode: this.params.data.cocode,
      actionsEditLob: this. params.data.lobCode,
    }], { skipLocationChange: true });
  }

  viewQuickSend(id: string) {
    let actionsViewQuickFlag: boolean = true;
    this.router.navigate(['/reviewLetter', {
      actionsViewQuickFlag: actionsViewQuickFlag,
      id: id,
      actionsViewQuickYear: this.params.data.filingYear,
      actionsViewQuickCocode: this.params.data.cocode,
      actionsViewQuickLob: this. params.data.lobCode,
      actionsViewQuickCompanyContact: this.params.data.senderId,
      actionsViewQuickStatus: this.params.data.status,
      actionsViewQuickcompanyName: this.params.data.companyName
    }], { skipLocationChange: true });
  }

}
