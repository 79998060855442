import { HttpEvent, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import { User } from '../shared/model/user';
import { MOCK_USERS } from './data/mock-users';
import { MOCK_COMPANIES } from './data/mock-companies';
import { MOCK_YEARS } from './data/mock-years';
import { MOCK_MCAS_STATES } from './data/mock-mcas-states';
import { MOCK_LINES_OF_BUSINESS } from './data/mock-lines-of-business';
import { MOCK_FILING_MATRIX } from './data/mock-filing-matrix';
import { FilingMatrix } from '../filing-matrix/model/filing-matrix';
import { MOCK_WAIVERS } from './data/mock-waivers';
import { MOCK_EXTENSIONS } from './data/mock-extensions';
import { MOCK_FILING_BLANKS } from './data/mock-filing-blanks';
import { MOCK_DISCLAIMER } from './data/mock-disclaimers';
import { MOCK_COMPANY_ASSIGNMENTS } from './data/mock-company-assignments';
import { CompanyAssignment } from '../user-admin/model/company-assignment';
import * as _ from 'lodash';
import { Company } from '../shared/model/company';
import { MOCK_HELP_DESK_COMPANIES } from './data/mock-help-desk-companies';
import { MOCK_HELP_DESK_USERS } from './data/mock-help-desk-users';
import { Waiver } from '../filing-matrix/model/waiver';
import { Extension } from '../filing-matrix/model/extension';
import { FilingBlank } from '../filing-matrix/filing-wizard/model/filing-blank';
import { FilingData } from '../filing-matrix/filing-wizard/model/filing-data';
import { MOCK_WARNINGS } from './data/mock-messages';
import { MOCK_APPLICATION_INFORMATION } from './data/mock-application-administration';
import { MOCK_CORRESPONDENCE_LOB } from './data/mock-correspondence-lob';
import { MOCK_CORRESPONDENCE_RULESRESULTS } from './data/mock-correspondence-rulesResults';
import { MOCK_CORRESPONDENCE_DASHBOARD } from './data/mock-correspondence-dashboard';
import { MOCK_REVIEW_LETTER } from './data/mock-review-letter';

let userAssignments: CompanyAssignment[] = _.cloneDeep(MOCK_COMPANY_ASSIGNMENTS);
let users: User[] = _.cloneDeep(MOCK_USERS);

export function mcsMockBackend(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
  const urlParts = url.split('/');

  let testNav = {
    navigationItems: [
      { name: 'INTERROGATORIES', target: 'INTERROGATORIES', subNav: [] },
      { name: 'IN-EXCHANGE (INDIVIDUAL)', target: '', subNav: [
        { name: 'POLICY ADMINISTRATION', target: 'INEXCHANGEINDIVIDUALPOLICYADMINISTRATION', subNav: [] },
        { name: 'PRIOR AUTHORIZATIONS (PROSPECTIVE UTILIZATION REVIEW REQUESTS) EXCLUDING PHARMACY', target: 'INEXCHANGEINDIVIDUALPRIORAUTHORIZATIONSEXCLUDINGPHARMACY', subNav: []}]
        // { name: 'PRIOR AUTHORIZATIONS (PROSPECTIVE UTILIZATION REVIEW REQUESTS) - PHARMACY ONLY', target: 'INEXCHANGEINDIVIDUALPRIORAUTHORIZATIONSPHARMACYONLY', subNav: [] },
        // { name: 'CLAIMS ADMINISTRATION (EXCLUDING PHARMACY)', target: 'INEXCHANGEINDIVIDUALCLAIMSADMINISTRATIONEXCLUDINGPHARMACY', subNav: [] },
        // { name: 'CLAIMS ADMINISTRATION (PHARMACY ONLY)', target: 'INEXCHANGEINDIVIDUALCLAIMSADMINISTRATIONPHARMACYONLY', subNav: [] },
        // { name: 'CONSUMER REQUESTED INTERNAL REVIEWS (GRIEVANCES - INCLUDING PHARMACY)', target: 'INEXCHANGEINDIVIDUALCONSUMERREQUESTEDINTERNALREVIEWSGRIEVANCESINCLUDINGPHARMACY', subNav: [] },
        // { name: 'CONSUMER REQUESTED EXTERNAL REVIEWS (INCLUDING PHARMACY)', target: 'INEXCHANGEINDIVIDUALCONSUMERREQUESTEDEXTERNALREVIEWSINCLUDINGPHARMACY', subNav: [] }]
      }
    ]
  };

  let testBlank = {
  lineOfBusiness: {id: 6, code: 'HEALTH', name: 'Health', year: 2017},
  year: MOCK_YEARS[MOCK_YEARS.length - 1],
  status: null,
    cocode: 99508,
    state: 'AL',
  sections: [
    {
      key: 'HE-INT',
      sectionNumber: 1,
      name: 'Interrogatories',
      description: '',
      sections: [
        {
          key: 'HE-INT-INEX',
          sectionNumber: 2,
          name: 'In-Exchange',
          description: '',
          sections: [],
          questions: [
            {
              key: 'HE-INT-INEX-01',
              text: 'Does the company have Individual Health insurance coverage other than transitional, grandfathered, multi-state, catastrophic, or student data to report?',
              inputs: [
                {
                  key: 'HE-INT-INEX-01-response',
                  name: 'response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [
                    {key: 'LTC-INT-01-response', message: MOCK_WARNINGS[0]},
                    {key: 'LTC-INT-01-response', message: MOCK_WARNINGS[1]}
                  ],
                  errors: [],
                  value: 'yes'
                }
              ]
            },
            {
              key: 'HE-INT-INEX-02',
              text: 'Does the company have Small Group Health insurance coverage other than transitional, grandfathered, or multi-state policies data to report?',
              inputs: [
                {
                  key: 'HE-INT-INEX-02-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  value: 'no'
                }
              ]
            },
            {
              key: 'HE-INT-INEX-03',
              text: 'Does the company have Catastrophic data to report?',
              inputs: [
                {
                  key: 'HE-INT-INEX-03-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  disableableInputs : {
                    disabledValue : 'no',
                    disabledInputs : [
                      'HE-INT.HE-INT-INEX.HE-INT-INEX-04.HE-INT-INEX-04-explanation'
                    ]
                  },
                  value: 'no'
                }
              ]
            },
            {
              key : 'HE-INT-INEX-04',
              text : 'If Yes, blah blah blah?',
              inputs : [
                  {
                      key : 'HE-INT-INEX-04-explanation',
                      label : 'Response',
                      controlType : 'textbox',
                      options : [],
                      disabled : false,
                      required : false
                  }
              ]
          },
          ]
        },
        {
          key: 'HE-INT-OOEX',
          sectionNumber: 2,
          name: 'Out-of-Exchange',
          description: '',
          sections: [],
          questions: [
            {
              key: 'HE-INT-OOEX-09',
              text: 'Does the company have Individual Health insurance coverage other than transitional, grandfathered, multi-state, catastrophic, or student data to report?',
              inputs: [
                {
                  key: 'HE-INT-OOEX-09-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  value: 'no'
                }
              ]
            },
            {
              key: 'HE-INT-OOEX-10',
              text: 'Does the company have Small Group Health insurance coverage other than transitional, grandfathered, or multi-state policies data to report?',
              inputs: [
                {
                  key: 'HE-INT-OOEX-10-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  value: 'yes'
                }
              ]
            },
            {
              key: 'HE-INT-OOEX-11',
              text: 'Does the company have Grandfathered or Transitional plan data to report?',
              inputs: [
                {
                  key: 'HE-INT-OOEX-11-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  value: 'yes'
                }
              ]
            },
          ]
        }
      ],
      questions: []
    },
    {
      key: 'HE-INEX',
      sectionNumber: 1,
      name: 'In-exchange',
      description: '',
      sections: [
        {
          key: 'HE-INEX-IH',
          sectionNumber: 2,
          name: 'Individual Health',
          description: 'insurance coverage other than transitional, grandfathered, multi-state, catastrophic or student',
          sections: [
            {
              key: 'HE-INEX-IH-PA',
              sectionNumber: 3,
              name: 'Policy Administration',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-INEX-IH-PA-19',
                  text: 'Earned premiums for Reporting Year.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PA-19-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-19-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-19-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-19-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-19-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-IH-PA-20',
                  text: 'Number of new policies issued during the period.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PA-20-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-20-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-20-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-20-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-20-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-IH-PA-21',
                  text: 'Number of policies renewed during the period.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PA-21-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-21-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-21-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-21-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-21-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            },
            {
              key: 'HE-INEX-IH-PRAUTH',
              sectionNumber: 3,
              name: 'Prior Authorizations',
              description: 'Prospective Utilization Review Requests Excluding Pharmacy',
              sections: [],
              questions: [
                {
                  key: 'HE-INEX-IH-PRAUTH-30',
                  text: 'Number of prior authorizations requested.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-IH-PRAUTH-31',
                  text: 'Number of prior authorizations approved.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-IH-PRAUTH-32',
                  text: 'Number of prior authorizations denied.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            }
          ],
          questions: []
        },
        {
          key: 'HE-INEX-SGH',
          sectionNumber: 2,
          name: 'Small Group Health',
          description: 'insurance coverage other than transitional, grandfathered or multi-state policies',
          sections: [
            {
              key: 'HE-INEX-SGH-PA',
              sectionNumber: 3,
              name: 'Policy Administration',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-INEX-SGH-PA-19',
                  text: 'Earned premiums for Reporting Year.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PA-19-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-19-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-19-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-19-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: []
                    },
                    {
                      key: 'HE-INEX-SGH-PA-19-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-SGH-PA-20',
                  text: 'Number of new policies issued during the period.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PA-20-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-20-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-20-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-20-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-20-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-SGH-PA-21',
                  text: 'Number of policies renewed during the period.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PA-21-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-21-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-21-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-21-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-21-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                }
              ]
            },
            {
              key: 'HE-INEX-SGH-PRAUTH',
              sectionNumber: 3,
              name: 'Prior Authorizations',
              description: 'Prospective Utilization Review Requests Excluding Pharmacy',
              sections: [],
              questions: [
                {
                  key: 'HE-INEX-SGH-PRAUTH-30',
                  text: 'Number of prior authorizations requested.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-SGH-PRAUTH-31',
                  text: 'Number of prior authorizations approved.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-SGH-PRAUTH-32',
                  text: 'Number of prior authorizations denied.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            }
          ],
          questions: []
        },
      ],
      questions: []
    },
    {
      key: 'HE-OOEX',
      sectionNumber: 1,
      name: 'Out-of-exchange',
      description: '',
      sections: [
        {
          key: 'HE-OOEX-IH',
          sectionNumber: 2,
          name: 'Individual Health',
          description: 'insurance coverage other than transitional, grandfathered, multi-state, catastrophic or student',
          sections: [
            {
              key: 'HE-OOEX-IH-PA',
              sectionNumber: 3,
              name: 'Policy Administration',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-OOEX-IH-PA-76',
                  text: 'Earned premiums for Reporting Year.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-76-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-76-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-76-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-76-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-76-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-IH-PA-77',
                  text: 'Number of new policies issued during the period.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-77-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-77-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-77-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-77-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-77-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-IH-PA-78',
                  text: 'Number of policies renewed during the period.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-78-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-78-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-78-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-78-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-78-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                }
              ]
            },
            {
              key: 'HE-OOEX-IH-PA-PRAUTH',
              sectionNumber: 3,
              name: 'Prior Authorizations (Prospective Utilization Review Requests) Excluding Pharmacy',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-OOEX-IH-PA-PRAUTH-87',
                  text: 'Number of prior authorizations requested.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-IH-PA-PRAUTH-88',
                  text: 'Number of prior authorizations approved.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-IH-PA-PRAUTH-89',
                  text: 'Number of prior authorizations denied.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            }
          ],
          questions: []
        },
        {
          key: 'HE-OOEX-SGH',
          sectionNumber: 2,
          name: 'Small Group Health',
          description: 'insurance coverage other than transitional, grandfathered or multi-state policies',
          sections: [
            {
              key: 'HE-OOEX-SGH-PA',
              sectionNumber: 3,
              name: 'Policy Administration',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-OOEX-SGH-PA-76',
                  text: 'Earned premiums for Reporting Year.',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PA-76-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-76-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-76-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-76-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-76-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-SGH-PA-77',
                  text: 'Number of new policies issued during the period.\n',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PA-77-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-77-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-77-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-77-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-77-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-SGH-PA-78',
                  text: 'Number of policies renewed during the period.\n',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PA-78-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: []
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-78-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-78-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-78-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-78-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                }
              ]
            },
            {
              key: 'HE-OOEX-SGH-PRAUTH',
              sectionNumber: 3,
              name: 'Prior Authorizations (Prospective Utilization Review Requests) Excluding Pharmacy',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-OOEX-SGH-PRAUTH-87',
                  text: 'Number of prior authorizations requested.',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-SGH-PRAUTH-88',
                  text: 'Number of prior authorizations approved.',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-SGH-PRAUTH-89',
                  text: 'Number of prior authorizations denied.',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            }
          ],
          questions: []
        }
      ],
      questions: []
    }
  ]

/*     lineOfBusiness: { code: 'TEST', name: 'Test' },
    year: { year: 2018, isCurrentYear: true },
    status: null,
    cocode: 99508,
    state: 'AL',
    sections: [
      {
        key: 'testsection',
        name: 'Test Section',
        description: '',
        sectionNumber: 0,
        sections: [],
        questions: [
          {
            key: '01',
            text: 'Test textbox 5 inputs',
            inputs: [
              {
                key: 'TEST_testsection_01_test1',
                label: 'Test 1',
                controlType: 'textbox',
                dataType: 'integer',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              },
              {
                key: 'TEST_testsection_01_test2',
                label: 'Test 2',
                controlType: 'textbox',
                dataType: 'integer',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              },
              {
                key: 'TEST_testsection_01_test3',
                label: 'Test 3',
                controlType: 'textbox',
                dataType: 'integer',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              },
              {
                key: 'TEST_testsection_01_test4',
                label: 'Test 4',
                controlType: 'textbox',
                dataType: 'integer',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              },
              {
                key: 'TEST_testsection_01_test5',
                label: 'Test 5',
                controlType: 'textbox',
                dataType: 'integer',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              },
            ]
          },
          {
            key: '02',
            text: 'Test textbox 1 input',
            inputs: [
              {
                key: 'TEST_testsection_02_test1',
                label: 'Test 1',
                controlType: 'textbox',
                dataType: 'decimal',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              }
            ]
          },
          {
            key: '03',
            text: 'Test textbox 2 inputs',
            inputs: [
              {
                key: 'TEST_testsection_03_test1',
                label: 'Test 1',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: '',
                warnings: [],
                errors: []
              },
              {
                key: 'TEST_testsection_03_test2',
                label: 'Test 2',
                controlType: 'textbox',
                options: [],
                disabled: false,
                value: '',
                warnings: [{key: 'LTC-INT-01-response', message: MOCK_WARNINGS[0]}],
                errors: []
              },
            ]
          },
        ],

      }
    ],
    previous: '',
    next: 'INEXCHANGEINDIVIDUALPOLICYADMINISTRATION' */
  };


  // MCS Users
  if (url.match(/\/users\/\w+$/) && url.indexOf('helpDesk') === -1) {
    switch (method) {
      case 'GET': {
        const username = urlParts[urlParts.length - 1];
        const matchedUsers = users.filter(user => user.username === username);
        const mcsUser: User = matchedUsers.length ? matchedUsers[0] : null;
        if (mcsUser) {
          return getOK(mcsUser);
        } else {
          return getNotFound(username);
        }
      }
      default: {
        return getBadRequest('users');
      }
    }
  }

  // Disclaimer APIs
  if (url.endsWith('/disclaimer')) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_DISCLAIMER);
      }
      case 'PUT': {
        return getNoContent();
      }
      default: {
        return getBadRequest('disclaimer');
      }
    }
  }

  // Companies
  if (url.endsWith('/companies')) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_COMPANIES);
      }
      default: {
        return getBadRequest('companies');
      }
    }
  }

  // Help Desk
  if (url.indexOf('helpDesk') > -1) {
    if (url.indexOf('companies') > -1) {
      switch (method) {
        case 'GET': {
          return getOK(MOCK_HELP_DESK_COMPANIES);
        }
        default: {
          return getBadRequest('helpDesk companies');
        }
      }
    } else if (url.indexOf('users') > -1) {
      switch (method) {
        case 'GET': {
          return getOK(MOCK_HELP_DESK_USERS);
        }
        default: {
          return getBadRequest('helpDesk users');
        }
      }
    } else {
      return getBadRequest('unknown helpDesk url');
    }
  }

  // Company Assignment
  for (let index = 0; index < userAssignments.length; index++) {
  if (url.indexOf('userAssignments') > -1) {
    if (url.indexOf('remove') > -1) {
      switch (method) {
        case 'DELETE': {
          const parts = url.split('/');
          const username = parts[parts.length - 1];
          if (username === userAssignments[index].primaryUser.username) {
            return getBadRequest('unable to remove admin');
          }
          let tempSecondary: User[] = [];
          userAssignments[index].secondaryUser.forEach(user => {
            if (user.username !== username) {
              tempSecondary.push(user);
            }
          });
          userAssignments[index].secondaryUser = [];
          userAssignments[index].secondaryUser = [...tempSecondary];
          return getOK(userAssignments[index]);
        }
        default: {
          return getBadRequest('company assignment remove');
        }
      }
    } else if (url.indexOf('secondary') > -1) {
      switch (method) {
        case 'POST': {
          const body = request.body;
          const newUser = body.username;
          let foundUser: User;
          users.forEach(user => {
            if (user.username === newUser) {
              foundUser = user;
            }
          });
          if (foundUser) {
            if (userAssignments[index].primaryUser.username === foundUser.username) {
              return getForbidden('userAssignment secondary');
            } else {
              let alreadySecondary = false;
              userAssignments[index].secondaryUser.forEach(user => {
                if (user.username === foundUser.username) {
                  alreadySecondary = true;

                }
              });
              if (alreadySecondary) {
                return getForbidden('userAssignment secondary');
              } else {
                userAssignments[index].secondaryUser.push(foundUser);
                return getOK(userAssignments[index]);
              }
            }
          } else {
            return getNotFound('Not found');
          }
        }
        default: {
          return getBadRequest('company assignment secondary');
        }
      }
    } else if (url.indexOf('primary') > -1) {
      const parts = url.split('/');
      const cocode = +parts[parts.length - 2];
      switch (method) {
        case 'PATCH': { // Set new primary
          const body = request.body;
          // Update user assignments
          const oldPrimary = userAssignments[index].primaryUser;
          const newSecondaryUsers: User[] = [];
          newSecondaryUsers.push(oldPrimary);
          userAssignments[index].secondaryUser.forEach(user => {
            if (user.username === body.username) {
              userAssignments[index].primaryUser = user;
            } else {
              newSecondaryUsers.push(user);
            }
          });
          userAssignments[index].secondaryUser = [];
          userAssignments[index].secondaryUser = [...newSecondaryUsers];

          // Update users
          users.forEach(user => {
            let tempPrimary: Company[] = [];
            let tempSecondary: Company[] = [];
            user.cocodesAsPrimary.forEach(company => {
              if (company.cocode !== cocode) {
                tempPrimary.push(company);
              }
            });
            user.cocodesAsSecondary.forEach(company => {
              if (company.cocode === cocode) {
                tempPrimary.push(company);
              } else {
                tempSecondary.push(company);
              }
            });
            user.cocodesAsPrimary = [];
            user.cocodesAsSecondary = [];
            user.cocodesAsPrimary = [...tempPrimary];
            user.cocodesAsSecondary = [...tempSecondary];
          });
          return getOK(userAssignments[index]);
        }
        default: {
          return getBadRequest('company assignment primary');
        }
      }
    } else if (url.indexOf('contact') > -1) {
      switch (method) {
        case 'PATCH': {
          const body: any = request.body;
          userAssignments[index].primaryUser.isContact = body.username === userAssignments[index].primaryUser.username ? true : false;
          userAssignments[index].secondaryUser.forEach(user => {
            user.isContact = body.username === user.username ? true : false;
          });
          return getOK(userAssignments[index]);
        }
        default: {
          return getBadRequest('company assignment contact');
        }
      }
    } else {
      switch (method) {
        case 'GET': {
          return getOK(userAssignments[index]);
        }
        case 'POST': {
          return getOK(userAssignments[index]);
        }
        case 'PATCH': {
          return getOK(userAssignments[index]);
        }
        default: {
          return getBadRequest('company assignment');
        }
      }
    }
  }

  }

  // Years
  if (url.endsWith('/years')) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_YEARS);
      }
      default: {
        return getBadRequest('years');
      }
    }
  }

  // Navigation items
  if (url.endsWith('filingblank/navigation')) {
    return getOK(testNav);
  }

  // MCAS States
  if (url.endsWith('/mcasstates')) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_MCAS_STATES);
      }
      default: {
        return getBadRequest('mcasstates');
      }
    }
  }

  // Lines of Business
  if (url.endsWith('/linesofbusiness')) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_LINES_OF_BUSINESS);
      }
      default: {
        return getBadRequest('linesofbusiness');
      }
    }
  }

  // Filing Matrix by cocode and year
  if (url.match(/\/filingmatrix\/.+$/)) {
    switch (method) {
      case 'GET': {
        let cocode = +urlParts[urlParts.length - 1];
        let year = +request.params.get('year');
        let matchedMatrices = MOCK_FILING_MATRIX.filter((matrix: FilingMatrix) => {
          if (matrix.company.cocode === cocode && matrix.year.year === year) {
            return matrix;
          }
        });
        const filingMatrix = matchedMatrices.length ? matchedMatrices[0] : null;
        return filingMatrix ? getOK(filingMatrix) : getNotFound(`${+urlParts[urlParts.length - 1]}, ${+request.params.get('year')}`);
      }
      case 'POST': {
        return getOK({data: 'it\'s ok'});
      }
      default: {
        return getBadRequest('filingmatrix');
      }
    }
  }


  // Waiver
  if (url.match(/\/waivers\/\w+$/)) {
    switch (method) {
      case 'POST': {
        const newWaivers: Waiver[] = request.body;
        newWaivers.forEach(newWaiver => {
          let duplicateWaiver = MOCK_WAIVERS.filter(waiver => (
            waiver.company.cocode === newWaiver.company.cocode
            && waiver.state.stabbr === newWaiver.state.stabbr
            && waiver.lineOfBusiness.code === newWaiver.lineOfBusiness.code
            && waiver.year.year === newWaiver.year.year)).length;
          if (duplicateWaiver) {
            return getConfict('waivers');
          }
        });
        return getCreated();
      }
      default: {
        return getBadRequest('waivers');
      }
    }
  }

  // Extensions
  if (url.match(/\/extensions\/\w+$/)) {
    switch (method) {
      case 'POST': {
        let newExtensions: Extension[] = request.body;
        newExtensions.forEach(newExtension => {
          let duplicateExtension = MOCK_EXTENSIONS.filter(extension => (
            extension.company.cocode === newExtension.company.cocode
            && extension.state.stabbr === newExtension.state.stabbr
            && extension.lineOfBusiness.code === newExtension.lineOfBusiness.code
            && extension.year.year === newExtension.year.year)).length;
          if (duplicateExtension) {
            return getConfict('extensions');
          }
        });
        return getCreated();
      }
      default: {
        return getBadRequest('extensions');
      }
    }
  }

  // Application Info
  if (url.match(/\w+\/application/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith('applicationInfo')) {
          return getOK(MOCK_APPLICATION_INFORMATION);
        }
      }
      case 'POST': {
        return getNoContent();
      }
      default: {
        return getBadRequest('application');
      }
    }
  }

  //Correspondence LOB
  if (url.endsWith('lineofbusiness')) {
    return getOK(MOCK_CORRESPONDENCE_LOB);
  }

  if (url.endsWith('correspondences/123/ruleResults')) {
    return getOK(MOCK_CORRESPONDENCE_RULESRESULTS);
  }

  if (url.endsWith('correspondences/123/reviewLetter')) {
    return getOK(MOCK_REVIEW_LETTER);
  }

  if  (url.endsWith('correspondences/newLetter')) {
    return getOK(MOCK_CORRESPONDENCE_DASHBOARD)
  }

  if (url.endsWith('/ruleResults')) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_CORRESPONDENCE_RULESRESULTS);
      }
      default: {
        return getBadRequest('ruleResults');
      }
    }
  }

  if (url.endsWith('/dashboard')) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_CORRESPONDENCE_DASHBOARD);
      }
      default: {
        return getBadRequest('dashboard');
      }
    }
  }


  // Filing Blank by Line of Business
  if (url.endsWith('filingblank')) {
    // switch (method) {
    //  case 'GET': {
    //     const params = request.params;
    //     const fb_lob = params.get('lob');
    //     const fb_year = +params.get('year');
    //     const matchedBlanks = MOCK_FILING_BLANKS.filter(blank => {
    //       if (blank.lineOfBusiness.code === fb_lob && blank.year.year === fb_year) {
    //         return blank;
    //       }
    //     });
    //     const filingBlank = matchedBlanks.length > 0 ? matchedBlanks[0] : null;
    //     return filingBlank ? getOK(filingBlank) : getNotFound(`${fb_lob} - ${fb_year}`);
    //   }
    //   default: {
    //     return getBadRequest('filingblank');
    //   }
    // }
    return getOK(testBlank);
  }

  if (url.endsWith('unlock')) {
    switch (method) {
      case 'PUT': {
        const params = request.params;
        const state = params.get('state');
        const lob = params.get('lob');
        const year = +params.get('year');
        const matchedBlanks = MOCK_FILING_BLANKS.filter(blank => {
          if (blank.state.stabbr === state && blank.lineOfBusiness.code === lob && blank.year.year === year) {
            return blank;
          }
        });
        let filingBlank = matchedBlanks.length > 0 ? matchedBlanks[0] : null;
        filingBlank['unlocked'] = true;
        return filingBlank ? getOK(filingBlank) : getNotFound(`${state} - ${lob} - ${year}`);
      }
    }
  }

  if (url.endsWith('lock')) {
    switch (method) {
      case 'PUT': {
        const params = request.params;
        const state = params.get('state');
        const lob = params.get('lob');
        const year = +params.get('year');
        const matchedBlanks = MOCK_FILING_BLANKS.filter(blank => {
          if (blank.state.stabbr === state && blank.lineOfBusiness.code === lob && blank.year.year === year) {
            return blank;
          }
        });
        let filingBlank = matchedBlanks.length > 0 ? matchedBlanks[0] : null;
        filingBlank['unlocked'] = false;
        return filingBlank ? getOK(filingBlank) : getNotFound(`${state} - ${lob} - ${year}`);
      }
    }
  }

  if (url.endsWith('/filings')) {
    switch (method) {
      case 'PUT': {
        return getCreated();
      }
      case 'POST': {
        return getCreated();
      }
      default: {
        return getBadRequest('filings');
      }
    }
  }

  if (url.endsWith('/filingblank/filingdata')) {
    switch (method) {
      case 'PUT': {
        return getCreated();
      }
      default: {
        return getBadRequest(url);
      }
    }
  }

  if (url.endsWith('/filingblank/filingdata/submission')) {
    const filingData: FilingData = request.body;
    let filing: FilingBlank;
    MOCK_FILING_BLANKS.forEach(mockFiling => {
      if (mockFiling.lineOfBusiness.code === filingData.lineOfBusiness.code && mockFiling.year.year === filingData.year.year) {
        filing = mockFiling;
      }
    });
    switch (method) {
      case 'POST': {
        return getCreated(filing);
      }
      default: {
        return getBadRequest(url);
      }
    }
  }

  if (url.endsWith('/filingblank/filingdata/validation')) {
    const filingData: FilingData = request.body;
    let filing: FilingBlank;
    MOCK_FILING_BLANKS.forEach(mockFiling => {
      if (mockFiling.lineOfBusiness.code === filingData.lineOfBusiness.code && mockFiling.year.year === filingData.year.year) {
        filing = mockFiling;
      }
    });
    switch (method) {
      case 'POST': {
        return getCreated(filing);
      }
      default: {
        return getBadRequest(url);
      }
    }
  }

  if (url.endsWith('/filings/validate')) {
    switch (method) {
      case 'POST': {
        return getNoContent();
      }
      default: {
        return getBadRequest('filings/validate');
      }
    }
  }

  return getBadRequest('unknownApi');
}


function getOK(data: any): Observable<any> {
  return new Observable(resp => {
    resp.next(new HttpResponse({
      status: 200,
      body: JSON.parse(JSON.stringify(data))
    }));
    resp.complete();
  });
}

function getNoContent(): Observable<any> {
  return new Observable(resp => {
    resp.next(new HttpResponse({
      status: 204
    }));
    resp.complete();
  });
}

function getCreated(data?: any): Observable<any> {
  return new Observable(resp => {
    resp.next(new HttpResponse({
      status: 201,
      body: data ? JSON.parse(JSON.stringify(data)) : {}
    }));
    resp.complete();
  });
}

function getBadRequest(apiName: string): Observable<any> {
  return new Observable(resp => {
    resp.error(new HttpErrorResponse({
      status: 400,
      error: `MockBackend - Unknown ${apiName} url`
    }));
    resp.complete();
  });
}

function getNotFound(notFoundItem: string): Observable<any> {
  return new Observable(resp => {
    resp.next(),
    resp.error(new HttpErrorResponse({
      status: 404,
      statusText: `MockBackend - ${notFoundItem} not found`
    }));
    resp.complete();
  });
}

function getConfict(apiName: string): Observable<any> {
  return new Observable(resp => {
    resp.error(new HttpErrorResponse({
      status: 404,
      error: `MockBackend - ${apiName} conflict`
    }));
    resp.complete();
  });
}

function getForbidden(apiName: string): Observable<any> {
  return new Observable(resp => {
    resp.error(new HttpErrorResponse({
      status: 403,
      error: `MockBackend - ${apiName} conflict`
    }));
    resp.complete();
  });
}
