import { FilingMatrixService } from '../services/filing-matrix.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaiversExtensionsComponent } from './waivers-extensions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WaiverExtensionService } from './services/waiver-extension.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { WaiversModalComponent } from './waivers-modal/waivers-modal.component';
import { ExtensionModalComponent } from './extension-modal/extension-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { MessagesModule } from '../../messages/messages.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MultipleExtensionsModalComponent} from './multiple-extensions-modal/multiple-extensions-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    MessagesModule,
    SharedModule,
  ],
  declarations: [WaiversExtensionsComponent, WaiversModalComponent, ExtensionModalComponent, MultipleExtensionsModalComponent],
  providers: [WaiverExtensionService, FilingMatrixService],
  exports: [WaiversExtensionsComponent]
})
export class WaiversExtensionsModule { }
