import { MOCK_WARNINGS, MOCK_ERRORS } from './mock-messages';
import { MOCK_YEARS } from './mock-years';
import { MOCK_IN_PROGRESS } from './mock-filing-statuses';
import { MOCK_MCAS_STATES } from './mock-mcas-states';

export const MOCK_LTC_FILING: any = {
  state: MOCK_MCAS_STATES[0],
  lineOfBusiness: {id: 3, code: 'LTC', name: 'Long Term Care'},
  year: MOCK_YEARS[MOCK_YEARS.length - 1],
  status: MOCK_IN_PROGRESS,
  sections: [
    {
      key: 'LTC-INT',
      sectionNumber: 1,
      name: 'Interrogatories',
      description: '',
      sections: [],
      questions: [
        {
          key: 'LTC-INT-01',
          text: 'Were there policies in force during the reporting period that provided Collision coverage?',
          inputs: [{
            key: 'LTC-INT-01-response',
            label: 'Response',
            controlType: 'dropdown',
            options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
            disabled: false,
            warnings: [
              {key: 'LTC-INT-01-response', message: MOCK_WARNINGS[0]},
              {key: 'LTC-INT-01-response', message: MOCK_WARNINGS[1]}
            ],
            errors: [],
            value: ''
          }]
        },
        {
          key: 'LTC-INT-02',
          text: 'Were there policies in force during the reporting period that provided Comprehensive coverage?',
          inputs: [{
            key: 'LTC-INT-02-response',
            label: 'Response',
            controlType: 'dropdown',
            options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
            disabled: false,
            warnings: [],
            errors: [
              {key: 'LTC-INT-02-response', message: MOCK_ERRORS[0]}
            ],
            value: ''
          }]
        },
        {
          key: 'LTC-INT-03',
          text: 'Were there policies in force during the reporting period that provided Bodily Injury coverage?',
          inputs: [{
            key: 'LTC-INT-03-response',
            label: 'Response',
            controlType: 'dropdown',
            options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
            disabled: false,
            warnings: [],
            errors: [],
            value: ''
          }]
        },
        {
          key: 'LTC-INT-04',
          text: 'Were there policies in force during the reporting period that provided Property Damage coverage?',
          inputs: [{
            key: 'LTC-INT-04-response',
            label: 'Response',
            controlType: 'dropdown',
            options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
            disabled: false,
            warnings: [],
            errors: [],
            value: ''
          }]
        },
        {
          key: 'LTC-INT-05',
          text: 'Were there policies in force during the reporting period that provided Uninsured Motorists and Underinsured Motorists (UMBI) coverage?',
          inputs: [
            {
              key: 'LTC-INT-05-response',
              label: 'Response',
              controlType: 'dropdown',
              options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
              disabled: false,
              warnings: [],
              errors: [],
              value: ''
            }
          ]
        }
      ]
    },
    {
      key: 'LTC-CA',
      sectionNumber: 1,
      name: 'Claims Activity',
      description: '',
      sections: [
        {
          key: 'LTC-CA-COL',
          sectionNumber: 2,
          name: 'Collision',
          description: '',
          sections: [],
          questions: [
            {
              key: 'LTC-CA-COL-21',
              text: 'Number of claims open at the beginning of the period.',
              inputs: [{
                key: 'LTC-CA-COL-21-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            },
            {
              key: 'LTC-CA-COL-22',
              text: 'Number of claims opened during the period.',
              inputs: [{
                key: 'LTC-CA-COL-22-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            },
            {
              key: 'LTC-CA-COL-23',
              text: 'Number of claims closed with payment during the period.',
              inputs: [{
                key: 'LTC-CA-COL-23-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            },
            {
              key: 'LTC-CA-COL-24',
              text: 'Number of claims closed without payment during the period.',
              inputs: [{
                key: 'LTC-CA-COL-24-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            },
            {
              key: 'LTC-CA-COL-25',
              text: 'Number of claims remaining open at the end of the period.',
              inputs: [{
                key: 'LTC-CA-COL-25-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            }
          ]
        },
        {
          key: 'LTC-CA-COMP',
          sectionNumber: 2,
          name: 'Comprehensive',
          description: '',
          sections: [],
          questions: [
            {
              key: 'LTC-CA-COMP-21',
              text: 'Number of claims open at the beginning of the period.',
              inputs: [{
                key: 'LTC-CA-COMP-21-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            },
            {
              key: 'LTC-CA-COMP-22',
              text: 'Number of claims opened during the period.',
              inputs: [{
                key: 'LTC-CA-COMP-22-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            },
            {
              key: 'LTC-CA-COMP-23',
              text: 'Number of claims closed with payment during the period.',
              inputs: [{
                key: 'LTC-CA-COMP-23-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            },
            {
              key: 'LTC-CA-COMP-24',
              text: 'Number of claims closed without payment during the period.',
              inputs: [{
                key: 'LTC-CA-COMP-24-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            },
            {
              key: 'LTC-CA-COMP-25',
              text: 'Number of claims remaining open at the end of the period.',
              inputs: [{
                key: 'LTC-CA-COMP-25-total',
                label: 'Total',
                controlType: 'textbox',
                type: 'number',
                disabled: false,
                warnings: [],
                errors: [],
                value: ''
              }]
            }
          ]
        }
      ],
      questions: []
    },
    {
      key: 'LTC-UA',
      sectionNumber: 1,
      name: 'Underwriting Activity',
      description: '',
      sections: [],
      questions: [
        {
          key: 'LTC-UA-43',
          text: 'Number of autos which have policies in force at the end of the period.',
          inputs: [
            {
              key: 'LTC-UA-43-total',
              label: 'Total',
              controlType: 'textbox',
              type: 'number',
              disabled: false,
              warnings: [],
              errors: [],
              value: ''
            }
          ]
        },
        {
          key: 'LTC-UA-44',
          text: 'Number of policies in force at the end of the period.',
          inputs: [
            {
              key: 'LTC-UA-44-total',
              label: 'Total',
              controlType: 'textbox',
              type: 'number',
              disabled: false,
              warnings: [],
              errors: [],
              value: ''
            }
          ]
        },
        {
          key: 'LTC-UA-45',
          text: 'Number of new policies written during the period.',
          inputs: [
            {
              key: 'LTC-UA-45-total',
              label: 'Total',
              controlType: 'textbox',
              type: 'number',
              disabled: false,
              warnings: [],
              errors: [],
              value: ''
            }
          ]
        },
        {
          key: 'LTC-UA-46',
          text: 'Dollar amount of direct written premium during the period.',
          inputs: [
            {
              key: 'LTC-UA-46-total',
              label: 'Total',
              controlType: 'textbox',
              type: 'number',
              disabled: false,
              warnings: [],
              errors: [],
              value: ''
            }
          ]
        },
        {
          key: 'LTC-UA-47',
          text: 'Number of company-initiated non-renewals during the period.',
          inputs: [
            {
              key: 'LTC-UA-47-total',
              label: 'Total',
              controlType: 'textbox',
              type: 'number',
              disabled: false,
              warnings: [],
              errors: [],
              value: ''
            }
          ]
        }
      ]
    }
  ]
};

