import { Year } from '../../shared/model/year';

export class LineOfBusiness {
  code: string;
  name: string;
  year?: Year;
  dueDate?: string;

  constructor(code?: string, name?: string, year?: Year, dueDate?: string) {
    this.code = code || '';
    this.name = name || '';
    this.year = year || null;
    this.dueDate = dueDate || '';
  }
}
