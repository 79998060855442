import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormSection } from '../model/form-section';
import { QuestionControlService } from '../../dynamic-forms/question-control-service';
import { Collapsed } from '../model/collapsed';
import { InputErrorWarning } from '../model/input-error-warning';
import { FormQuestion } from '../model/form-question';
import * as _ from 'lodash';
import { DisableableInputs } from '../../dynamic-forms/disableableInputs';
import { FilingWizardService } from '../service/filing-wizard.service';

@Component({
  selector: 'app-filing-section',
  templateUrl: './filing-section.component.html',
  styleUrls: ['./filing-section.component.css']
})
export class FilingSectionComponent implements OnInit {
  @Input() section: FormSection;
  @Input() parentId: string;
  @Input() disabled: boolean;
  @Input() parentPath: string;
  @Input() previous: string;
  @Input() next: string;
  @Input() index: number;
  @Output() goToSection = new EventEmitter<string>();
  formData: any[] = [];
  data: any = {};
  path: string;

  sectionForm: FormGroup;
  collapsed: Collapsed = {};

  sectionErrors: InputErrorWarning[] = [];
  questionErrors: any = {};
  sectionWarnings: InputErrorWarning[] = [];
  questionWarnings: any = {};

  inputLabels: string[] = [];
  sectionHeaders: any = {};

  constructor(
    private qcs: QuestionControlService,
    private filingWizardService: FilingWizardService
  ) {}

  ngOnInit() {
    if (this.filingWizardService.activeSection.length > 0) {
      this.filingWizardService.data = {};
    }
    this.path = this.parentPath && this.parentPath.length > 0 ? `${this.parentPath}.${this.section.key}` : this.section.key;
    this.getErrorsAndWarnings();
    this.getInputLabels(this.section);
    this.sectionForm = this.qcs.generateForm(this.section, this.disabled);
    // if (this.filingWizardService.activeSection.length > 0) {
      const keys = _.keys(this.sectionForm.value);
      keys.forEach(key => {
        this.filingWizardService.data[key] = this.sectionForm.value[key];
      });
    // }
    this.onChange();
  }

  getActiveSection(): string {
    return this.filingWizardService.activeSection;
  }

  disableChildren(disableableInputs: DisableableInputs, inputKey: string, questionKey: string) {
    if (disableableInputs) {
      if (disableableInputs.disabledValue === this.sectionForm.get(`${this.path}.${questionKey}.${inputKey}`).value) {
        disableableInputs.disabledInputs.forEach(input =>
          this.sectionForm.get(input).disable()
        );
      } else {
        disableableInputs.disabledInputs.forEach(input =>
          this.sectionForm.get(input).enable()
        );
      }
    }
  }

  onChange(): void {
    if (this.filingWizardService.activeSection.length > 0) {
      this.sectionForm.valueChanges.subscribe(val => {
        this.filingWizardService.dataChanged = true;
        const valKeys = _.keys(val);
        valKeys.forEach(key => {
          const data = val[key];
          this.filingWizardService.data[key] = data;
        });
      });
    }
  }

  getErrorsAndWarnings() {
    this.section.questions.forEach((question: FormQuestion) => {
      let errors: string[] = [];
      let warnings: string[] = [];
      question.inputs.forEach(input => {
        if (input.errors) {
          input.errors.forEach(error => {
            if (errors.indexOf(error.message) === -1) {
              errors.push(error.message);
            }
            // errors.push(error.message);
            if (this.sectionErrors.indexOf(error.message) === -1) {
              this.sectionErrors.push(error.message);
            }
          });
        }
        if (input.warnings) {
          input.warnings.forEach(warning => {
            if (warnings.indexOf(warning.message) === -1) {
              warnings.push(warning.message);
            }
            // warnings.push(warning.message);
            if (this.sectionWarnings.indexOf(warning.message) === -1) {
              this.sectionWarnings.push(warning.message);
            }
          });
        }
      });
      this.questionErrors[question.key] = errors;
      this.questionWarnings[question.key] = warnings;
    });
  }

  getInputLabels(section: FormSection) {
    this.inputLabels = [];
    if (section.questions.length > 0) {
      let question = section.questions[0];
      question.inputs.forEach((input, index) => {
        this.sectionHeaders[input.label] = index;
        this.inputLabels.push(input.label);
      });
    }
  }

  getInputs(section: FormSection) {

  }

  sectionCollapseName(): string {
    return this.getSectionId() + 'Collapse';
  }

  calculateQuestionColumn(question: FormQuestion): string[] {
    let styles = ['border', 'border-danger'];
    const inputCount = question.inputs.length;
    if (inputCount > 1) {
      const inputColSize = Math.floor(12 / inputCount);
      const totalInputColSize = inputCount * inputColSize;
      // return `col-${12 - totalInputColSize}`;
      styles.push('col-4');
    } else {
      styles.push('col-4');
    }
    return styles;
  }

  calculateInputColumns(question: FormQuestion) {
    const inputCount = question.inputs.length;
    if (inputCount > 1) {
      // return `col-${Math.floor(12 / inputCount)}`;
      return 'col-1';
    } else {
      return 'col';
    }
  }

  calculateQuestionHeaderColumns(inputCount: number) {
    if (inputCount > 1) {
      const inputColSize = Math.floor(12 / inputCount);
      const totalInputColSize = inputCount * inputColSize;
      // return `col-${12 - totalInputColSize}`;
      return 'col-4';
    } else {
      return 'col-4';
    }
  }

  calculateInputHeaderColumns(inputCount: number) {
    if (inputCount > 1) {
      // return `col-${Math.floor(12 / inputCount)}`;
      return 'col';
    } else {
      return 'col';
    }
  }

  inputCols(): string {
    let colClass = 'col';

    return colClass;
  }

  headerValue(): string {
    let headerNumber = this.section.sectionNumber + 3;
    return `h${headerNumber}`;
  }

  getSectionStyles(): string[] {
    let headerNumber = this.section.sectionNumber + 3;
    let marginLeftNumber =
      (this.section.sectionNumber === 1
        ? this.section.sectionNumber - 1
        : this.section.sectionNumber) + 2;
    let styles: string[] = [];
    // styles.push(`h${headerNumber}`);
    styles.push(`align-bottom`);
    return styles;
  }

  getSectionId(): string {
    let sectionId = '';
    if (this.parentId) {
      sectionId = `${this.parentId}-${this.section.key}`;
    } else {
      sectionId = this.section.key;
    }
    return sectionId;
  }

  getQuestionNumber(key: string): number {
    let keyArray = key.split('-');
    return parseInt(keyArray[keyArray.length - 1], 10);
  }

  getErrorText(error: InputErrorWarning): string {
    let keyArray = error.key.split('-');
    return `${parseInt(keyArray[keyArray.length - 2], 10)}. ${error.message}`;
  }

  isErrorOrWarning(input: any): string {
    if (input.errors && input.errors.length > 0) {
      return 'is-invalid';
    } else if (input.warnings && input.warnings.length > 0) {
      return 'is-warning';
    } else {
      return '';
    }
  }

  getInputStyle(input: any): string[] {
    // let styles: string[] = ['border', 'border-danger'];
    let styles: string[] = ['form-control', 'form-control-sm'];
    let errorOrWarning = this.isErrorOrWarning(input);
    if (errorOrWarning.length > 0) {
      styles.push(errorOrWarning);
    }
    return styles;
  }

  goTo(section: string): void {
    this.goToSection.emit(section);
  }

  formatSectionName(sectionName: string): string {
    return this.filingWizardService.formatSectionName(sectionName);
  }
}
