
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form #extensionModalForm="ngForm">
      <div class="form-group">
        <div class="font-weight-bold">State</div>
        <div>{{extension.state.stateName}}</div>
      </div>
      <div class="form-group">
        <div class="font-weight-bold">Line of Business</div>
        <span>{{extension.lineOfBusiness.name}}</span>
      </div>
      <div class="form-group">
        <div class="font-weight-bold">Status</div>
        <span class="text-capitalize">{{extension.status.toLowerCase()}}</span>
      </div>
      <div class="form-group">
        <div class="font-weight-bold">Requested Extension Date</div>
        <div class="input-group">
            <input type="text" maxlength="10" placeholder="MM/DD/YYYY" name="extensionDatepicker" class="form-control form-control-sm" #dp="bsDatepicker" bsDatepicker [(ngModel)]="dpValue" [bsConfig]="dpConfig" placement="top" [disabled]="true" required>
        </div>
      </div>
      <div class="form-group">
        <div class="font-weight-bold">Extension Request Reason</div>
        <textarea class="form-control form-control-sm" id="reason" name="reason" required [(ngModel)]="extension.reason" [disabled]="isUpdateDisabled()"></textarea>
      </div>
      <ng-container *ngIf="extension.status.toLowerCase() === 'approved' || extension.status.toLowerCase() === 'denied'">
        <div class="form-group">
          <div class="font-weight-bold">Decision Date</div>
          <div>{{extension.decisionDate | date:'MM-dd-yyyy'}}</div>
        </div>
        <div class="form-group">
          <div class="font-weight-bold">Extension <span class="text-capitalize">{{extension.status.toLowerCase()}}</span> Date</div>
          <div>{{extension.approvedDate | date:'MM-dd-yyyy'}}</div>
        </div>
        <div class="form-group">
          <div class="font-weight-bold">Decision Comments</div>
          <div>{{extension.decisionComments}}</div>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="bsModalRef.hide()">Close</button>
    <button type="button" class="btn btn-danger" (click)="onDelete()" [disabled]="isUpdateDisabled()">Delete</button>
    <button type="button" class="btn btn-primary" (click)="onUpdate()" [disabled]="!extensionModalForm.form.valid || isUpdateDisabled()">Update Extension</button>
  </div>


