import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingModule } from 'ngx-loading';
import { NcuiModule } from 'common-ui-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesModule } from '../../messages/messages.module';
import { CorrespondenceRoutingModule } from '../correspondence-routing.module';
import { CorrespondenceService } from '../service/correspondence.service';
import { RulesResultsComponent } from './rules-results.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RulesResultsService } from './service/rules-results.service';
import { DxDataGridModule, DxSelectBoxModule } from 'devextreme-angular';
import { OnlynumberDirective } from '../../shared/only-numbers.directive';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MessagesModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    LoadingModule,
    NcuiModule,
    BrowserModule,
    CorrespondenceRoutingModule,
    NgxDatatableModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DxDataGridModule,
    MatDialogModule,
    MatButtonModule
  ],
  declarations: [RulesResultsComponent, OnlynumberDirective],
  providers: [CorrespondenceService, RulesResultsService],
  entryComponents: [ConfirmationDialogComponent],
  bootstrap: [RulesResultsComponent]
})
export class RulesResultsModule { }
/* HttpModule, */