import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../../shared/http-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanyAssignment } from '../model/company-assignment';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Company } from '../../shared/model/company';

@Injectable()
export class UserAdminService {
  userAdminCompany: Company;
  private userAssignmentsUrl = `${environment.apiUrl}userAssignments/`;
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('UserAdminService');
  }

  getCompanyAssignment(cocode: number): Observable<CompanyAssignment> {
    const url = `${this.userAssignmentsUrl}${cocode}`;
    return this.httpClient
      .get<CompanyAssignment>(url);
      // .pipe(catchError(this.handleError('getCompanyAssignment', null)));
  }

  setPrimary(cocode: number, username: string): Observable<CompanyAssignment> {
    const url = `${this.userAssignmentsUrl}${cocode}/primary`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.post<CompanyAssignment>(url, {username: username}, options);
    // .pipe(catchError(this.handleError('setPrimary', null)));
  }

  setSecondary(cocode: number, username: string): Observable<CompanyAssignment> {
    const url = `${this.userAssignmentsUrl}${cocode}/secondary`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.post<CompanyAssignment>(url, {username: username}, options);
    // .pipe(catchError(this.handleError('setPrimary', null)));
  }

  replacePrimary(cocode: number, username: string): Observable<CompanyAssignment> {
    const url = `${this.userAssignmentsUrl}${cocode}/primary`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.patch<CompanyAssignment>(url, {username: username}, options);
    // .pipe(catchError(this.handleError('setPrimary', null)));
  }

  removeUser(cocode: number, username: string): Observable<CompanyAssignment> {
    const url = `${this.userAssignmentsUrl}${cocode}/remove/${username}`;
    return this.httpClient.delete<CompanyAssignment>(url);
      // .pipe(catchError(this.handleError('removeUser', null)));
  }

  setContact(cocode: number, username: string): Observable<CompanyAssignment> {
    const url = `${this.userAssignmentsUrl}${cocode}/contact`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.patch<CompanyAssignment>(url, {username: username}, options);
    // .pipe(catchError(this.handleError('setContact', null)));
  }
}
