import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { HelpDeskService } from './service/help-desk.service';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from '../messages/service/message.service';
import { AlertMessage } from '../messages/model/alert-message';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-help-desk',
  templateUrl: './help-desk.component.html',
  styleUrls: ['./help-desk.component.css']
})
export class HelpDeskComponent implements OnInit, OnDestroy {
  title = 'Help Desk View'; // May want to chnage this...
  searchType = 'user'; // Will be either user or cocode
  searchText = ''; // Text entered into text input
  rows: any[] = [];
  columns: any[] = [];
  isSearchByCollapsed = false;
  faPlus = faPlus;
  faMinus = faMinus;
  private unsubscribe: Subject<void> = new Subject();
  loading = false;

  constructor(
    private service: HelpDeskService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.doUnsubscribe();
  }

  search(): void {
    if (this.searchType === 'user') {
      this.getCompanies();
    } else {
      this.getUsers();
    }
  }

  getCompanies(): void {
    this.doUnsubscribe();
    this.unsubscribe = new Subject();
    this.columns.length = 0;
    this.columns = [
      {name: 'Cocode'},
      {name: 'Company Name'},
      {name: 'Active'},
      {name: 'Statement Type'}
    ];
    this.service.getCompanies(this.searchText).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      results => {
        if (results) {
          this.rows = results;
        } else {
          this.messageService.add(new AlertMessage('No Companies Found', 'info'));
        }
      }
    );
  }

  getUsers(): void {
    this.doUnsubscribe();
    this.unsubscribe = new Subject();
    this.columns.length = 0;
    this.columns = [
      {name: 'Cocode'},
      {name: 'Company Name'},
      {name: 'Contact Name'},
      {name: 'Administrator'},
      {name: 'Secondary Contact'},
      {name: 'Phone Number'},
      {name: 'Email'}
    ];
    this.service.getUsers(+this.searchText).pipe(takeUntil(this.unsubscribe)).subscribe(
      results => {
        if (results && results.length > 0) {
          this.rows = results;
        }
      }
    );
  }

  doUnsubscribe(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
