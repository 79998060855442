<ng-container *ngIf="loading; else filingSummary">
    <ngx-loading [show]="loading"></ngx-loading>
</ng-container>

<ng-template #filingSummary>
    <ngx-loading [show]="loading"></ngx-loading>
    <div *ngIf="filingMatrix" id="filing-summary" class="row">
        <div *ngIf="!sidebarCollapsed" class="col-md-2 col-sm-3 bg-light sidebar d-print-none">
            <div class="sidebar-sticky">
                <div id="collapseSidebar" class="pt-3 pb-2 mb-3 px-4 border-bottom">
                    <span class="cursor-pointer" (click)="sidebarCollapsed = true">
                        <fa-icon [icon]="faDoubleLeft"></fa-icon>&nbsp;Collapse Sidebar
                    </span>
                </div>
                <!-- Actions -->
                <div id="filingSummaryActions" class="pb-2 mb-3 px-4 border-bottom">
                    <h4>Actions</h4>
                    <div id="actions">
                        <div class="pb-2">
                            <button id="save" type="button" class="btn btn-secondary btn-sm btn-block"
                                (click)="downloadDisplayedData()">Download Displayed Data
                            </button>
                        </div>
                        <div class="pb-2">
                            <button id="close" type="button" class="btn btn-danger btn-sm btn-block"
                                (click)="close()">Close
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Print Options -->
                <div id="filingSummaryPrintOptions" class="pb-2 mb-3 px-4 border-bottom">
                    <h4>
                        <span class="cursor-pointer" (click)="isPrintOptionsCollapsed = !isPrintOptionsCollapsed"
                            [attr.aria-expanded]="!isPrintOptionsCollapsed" aria-controls="printOptions">
                            <fa-icon [icon]="isPrintOptionsCollapsed ? faPlus : faMinus"></fa-icon> Print Options
                        </span>
                    </h4>
                    <div id="printOptions" [collapse]="isPrintOptionsCollapsed">
                        <div class="pb-2">
                            <!-- TODO: Future Enhancement
                            <button type="button" class="btn btn-secondary btn-sm btn-block"
                                [disabled]="filingMatrix || !filingMatrix" (click)="printAllSubmittedFilings()">
                                Print all Submitted Filings
                            </button> -->

                            <button type="button" class="btn btn-secondary btn-sm btn-block" (click)="print()">
                                Print Displayed Data
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <main role="main" id="filingSummaryMainDisplay" [ngClass]="getMainStyle()">
            <app-messages [parentName]="'filingSummary'"></app-messages>
            <div id="mainContent" *ngIf="filingMatrix" class="pt-3 pb-3 main-content d-print-block">
                <div class="row">
                    <div class="col-12">
                        <h3>
                            <ng-container *ngIf="sidebarCollapsed">
                                <span class="cursor-pointer" (click)="sidebarCollapsed = false" title="Expand Sidebar">
                                    <fa-icon [icon]="faDoubleRight"></fa-icon>
                                </span>
                            </ng-container>
                            {{title}}
                            <button *ngIf="sidebarCollapsed" class="btn btn-secondary float-right d-print-none"
                                (click)="print()">
                                Print Displayed Data
                            </button>
                            <hr />
                        </h3>
                        <label>Line of Business</label>
                        <div class="bottomPadding">
                            <select id="select" class="selection" (change)="getSummary($event)" required>
                                <option value="null" disabled hidden>Choose One</option>
                                <option *ngFor="let lob of lobName;let i=index" [value]="i">
                                    {{ lob }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedIndex !== null; else chooseLob" class="row pb-3">
                    <div class="col-6" *ngFor="let filing of stateTableData">
                        <div class="card">
                            <div class="card-body">
                                <table id="summaryTable" class="table">
                                    <thead>
                                        <tr class="center">
                                            <th scope="col">State</th>
                                            <th scope="col">Submission Date</th>
                                            <th scope="col">Status of Filing</th>
                                            <th scope="col">Waiver Status</th>
                                            <th scope="col">Extension Status</th>
                                            <th scope="col">Extension Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let filingValue of filing">
                                            <td class="center">
                                                <div style="color: rgb(0, 255, 0);"
                                                    *ngIf="filingValue.filings[selectedIndex].status.code === 'FILED' || 
                                        (!filingValue.filings[selectedIndex].waiver ? '-' : filingValue.filings[selectedIndex].waiver.status) === 'APPROVED'"
                                                    else #red>{{filingValue.state.stabbr}}
                                                    <sup style="color: rgb(255, 0, 0);"
                                                        *ngIf="filingValue.filings[selectedIndex].required"
                                                        class="font" data-html2canvas-ignore="false">
                                                        <fa-icon [icon]="faAsterisk"></fa-icon>
                                                    </sup>
                                                </div>
                                                <div #red style="color: rgb(255, 0, 0);"
                                                    *ngIf="filingValue.filings[selectedIndex].required && 
                                        (filingValue.filings[selectedIndex].status.code !== 'FILED' && 
                                        (!filingValue.filings[selectedIndex].waiver ? '-' : filingValue.filings[selectedIndex].waiver.status) !== 'APPROVED')"
                                                    else #black>{{filingValue.state.stabbr}}
                                                    <sup style="color: rgb(255, 0, 0);"
                                                        *ngIf="filingValue.filings[selectedIndex].required"
                                                        class="font" data-html2canvas-ignore="false">
                                                        <fa-icon [icon]="faAsterisk"></fa-icon>
                                                    </sup>
                                                </div>
                                                <div #black style="color: rgb(0, 0, 0);"
                                                    *ngIf="!filingValue.filings[selectedIndex].required && 
                                        (filingValue.filings[selectedIndex].status.code !== 'FILED' && 
                                        (!filingValue.filings[selectedIndex].waiver ? '-' : filingValue.filings[selectedIndex].waiver.status) !== 'APPROVED')">
                                                    {{filingValue.state.stabbr}}
                                                </div>
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].submissionDate ? '-' : filingValue.filings[selectedIndex].submissionDate }}
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].status ? '-' : filingValue.filings[selectedIndex].status.displayText }}
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].waiver ? '-' : filingValue.filings[selectedIndex].waiver.status }}
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].extension ? '-' : filingValue.filings[selectedIndex].extension.status }}
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].extension ? '-' : filingValue.filings[selectedIndex].extension.endDate }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #chooseLob>
                    <div class="pt-3 pb-3 main-content">
                        <h2 style="font-size: 25px;" class="text-center">Please choose a Line of Business</h2>
                    </div>
                </ng-template>
                <div class="row" data-html2canvas-ignore="true">
                    <footer class="col-12 footer d-print-none">
                        <ncui-footer></ncui-footer>
                    </footer>
                </div>
            </div>
        </main>

        <main role="main" id="filingSummaryMainDisplay" class="d-none col-sm-12 ml-sm-auto d-print-block">
            <app-messages [parentName]="'filingSummary'"></app-messages>
            <div id="mainContent" *ngIf="filingMatrix" class="pt-3 pb-3 main-content d-print-block">
                <div class="row">
                    <div class="col-12">
                        <h3>
                            <ng-container *ngIf="sidebarCollapsed">
                                <span class="cursor-pointer" (click)="sidebarCollapsed = false" title="Expand Sidebar">
                                    <fa-icon [icon]="faDoubleRight"></fa-icon>
                                </span>
                            </ng-container>
                            {{title}}
                            <button *ngIf="sidebarCollapsed" class="btn btn-secondary float-right d-print-none"
                                (click)="print()">
                                Print Displayed Data
                            </button>
                            <hr />
                        </h3>
                        <label>Line of Business: {{ selectedLobForPrinting }}</label>
                    </div>
                </div>
                <div *ngIf="selectedIndex !== null; else chooseLob" class="row pb-3">
                    <div class="col-6" *ngFor="let filing of stateTableData">
                        <div class="card">
                            <div class="card-body">
                                <table id="summaryTable" class="table">
                                    <thead>
                                        <tr class="center">
                                            <th scope="col">State</th>
                                            <th scope="col">Submission Date</th>
                                            <th scope="col">Status of Filing</th>
                                            <th scope="col">Waiver Status</th>
                                            <th scope="col">Extension Status</th>
                                            <th scope="col">Extension Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let filingValue of filing">
                                            <td class="center">
                                                <div style="color: rgb(0, 255, 0);"
                                                    *ngIf="filingValue.filings[selectedIndex].status.code === 'FILED' || 
                                        (!filingValue.filings[selectedIndex].waiver ? '-' : filingValue.filings[selectedIndex].waiver.status) === 'APPROVED'"
                                                    else #red>{{filingValue.state.stabbr}}
                                                    <sup style="color: rgb(255, 0, 0);"
                                                        *ngIf="filingValue.filings[selectedIndex].required"
                                                        class="font" data-html2canvas-ignore="false">
                                                        <fa-icon [icon]="faAsterisk"></fa-icon>
                                                    </sup>
                                                </div>
                                                <div #red style="color: rgb(255, 0, 0);"
                                                    *ngIf="filingValue.filings[selectedIndex].required && 
                                        (filingValue.filings[selectedIndex].status.code !== 'FILED' && 
                                        (!filingValue.filings[selectedIndex].waiver ? '-' : filingValue.filings[selectedIndex].waiver.status) !== 'APPROVED')"
                                                    else #black>{{filingValue.state.stabbr}}
                                                    <sup style="color: rgb(255, 0, 0);"
                                                        *ngIf="filingValue.filings[selectedIndex].required"
                                                        class="font" data-html2canvas-ignore="false">
                                                        <fa-icon [icon]="faAsterisk"></fa-icon>
                                                    </sup>
                                                </div>
                                                <div #black style="color: rgb(0, 0, 0);"
                                                    *ngIf="!filingValue.filings[selectedIndex].required && 
                                        (filingValue.filings[selectedIndex].status.code !== 'FILED' && 
                                        (!filingValue.filings[selectedIndex].waiver ? '-' : filingValue.filings[selectedIndex].waiver.status) !== 'APPROVED')">
                                                    {{filingValue.state.stabbr}}
                                                </div>
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].submissionDate ? '-' : filingValue.filings[selectedIndex].submissionDate }}
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].status ? '-' : filingValue.filings[selectedIndex].status.displayText }}
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].waiver ? '-' : filingValue.filings[selectedIndex].waiver.status }}
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].extension ? '-' : filingValue.filings[selectedIndex].extension.status }}
                                            </td>
                                            <td class="center">
                                                {{ !filingValue.filings[selectedIndex].extension ? '-' : filingValue.filings[selectedIndex].extension.endDate }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-template #chooseLob>
                    <div class="pt-3 pb-3 main-content">
                        <h2 style="font-size: 25px;" class="text-center">Please choose a Line of Business</h2>
                    </div>
                </ng-template>
                <div class="row" data-html2canvas-ignore="true">
                    <footer class="col-12 footer d-print-none">
                        <ncui-footer></ncui-footer>
                    </footer>
                </div>
            </div>
        </main>

    </div>
</ng-template>