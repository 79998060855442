
export const MOCK_REVIEW_LETTER: any = {
  letterStatus: 'FIRST_CORRESPONDENCE',
  subjectLine: 'NAIC &cocode &year Market Conduct Annual Statement filing',
  header: 'NAIC Market Conduct Annual Statement Data Validation Notification',
  regarding: 'NAIC &year Market Conduct Annual Statement filing',
  contact: 'Lacey Morris\nArmed Forces Insurance',
  letterBody: '',
  signature: 'Julio Lara\nData Reporting Analyst II\nNAIC - Market Regulatory Services\n1100 Walnut Street Suite 1500\nKansas City, MO 64106-2197\njlara@naic.org\nFax: (816) 768-7547  Phone: (816) 783-8283',
  statesImpacted: 'States Impacted: Participating State Contact(s): AK, AL, CA, MO, WY',
  warnings: 'CONSISTENCY AND TEXTUAL WARNINGS:\n\n1. Rule: MCAS premiums are expected to be within 20% (+/-) of the Financial Annual Statement (FAS) PPA. Refer to MCAS FAQ\'s(PXABN030009)\n' +
    '2. Rule: COL: Q26 should correspond to the date range of median claim reported on Q27-Q32. Refer to MCAS User Guide.(PZABN030165)\n' +
    '3. Rule: COMP: Q26 should correspond to the date range of median claim reported on Q27-Q32. Refer to MCAS User Guide.(PZABN030265)\n' +
    '4. Rule: BI: Q26 should correspond to the date range of median claim reported on Q27-Q32. Refer to MCAS User Guide(PZABN030365)\n' +
    '5. Rule: PD: Q26 should correspond to the date range of median claim reported on Q27-Q32. Refer to MCAS User Guide(PZABN030465)\n' +
    '6. Rule: UMBI & UIMBI: Q26 should correspond to the date range of median claim reported on Q27-Q32. Refer to MCAS User Guide(PZABN030565)\n' +
    '7. Rule: UMPD & UIMPD: Q26 should correspond to the date range of median claim reported on Q27-Q32. Refer to MCAS User Guide(PZABN030665)\n' +
    '8. Rule: MP: Q26 should correspond to the date range of median claim reported on Q27-Q32. Refer to MCAS User Guide(PZABN030765)\n' +
    '9. Rule: CSL: Q26 should correspond to the date range of median claim reported on Q27-Q32. Refer to MCAS User Guide(PZABN030865)\n' +
    '10. Rule: PIP: Q26 should correspond to the date range of median claim reported on Q27-Q32. Refer to MCAS User Guide(PZABN030965)',
  addtlComments: 'Fix your warnings'
}
