import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectCriteriaComponent} from './select-criteria.component';
import { RulesResultsComponent } from '../rules-results/rules-results.component';

const routes: Routes = [
  { path: 'selectCriteria', component: SelectCriteriaComponent },
  { path: 'rulesResults', component: RulesResultsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class SelectCriteriaRoutingModule { }
