import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-cocodes',
  templateUrl: './no-cocodes.component.html',
  styleUrls: ['./no-cocodes.component.css']
})
export class NoCocodesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
