import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from '../shared/role.guard';
import { CorrespondenceComponent } from './correspondence.component';
import {ReviewLetterComponent} from './review-letter/review-letter.component';

const routes: Routes = [
  { path: 'correspondence', component: CorrespondenceComponent },
  { path: 'reviewLetter', component: ReviewLetterComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CorrespondenceRoutingModule { }
