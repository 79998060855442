import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../shared/model/user';
import { CompanyAssignment } from '../model/company-assignment';
import { BsModalRef } from '../../../../node_modules/ngx-bootstrap/modal';

@Component({
  selector: 'app-user-remove',
  templateUrl: './user-remove.component.html',
  styleUrls: ['./user-remove.component.css']
})
export class UserRemoveComponent implements OnInit {
  @Input() user: User;
  @Input() cocode: number;
  title: string;
  remove = false;
  companyAssignment: CompanyAssignment;


  constructor(
    public bsModalRef: BsModalRef,
  ) {
   }

  ngOnInit() {
    this.title = `Remove ${this.user.firstName} ${this.user.lastName}`;
  }

  removeUser() {
    this.remove = true;
    this.bsModalRef.hide();
  }

}
