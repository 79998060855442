<div class="tab-content active">

  <div [formGroup]="sectionForm">
    <div [formGroupName]="section.key" class="well well-lg" [id]="section.key">
      <div class="row pb-3">
        <div class="col-8">
          <h4>
            <span [ngClass]="getSectionStyles()">
              {{index === 0 ? formatSectionName(section.key) : section.name}}
            </span>
          </h4>
        </div>
        <div *ngIf="index === 0" class="col-4 clearfix">
          <span class="float-right">
            <button *ngIf="getActiveSection() === '' || previous && previous.length > 0" type="button" class="btn btn-primary mr-2" [title]="formatSectionName(previous)" (click)="goTo('previous')">Previous</button>
            <button *ngIf="next && next.length > 0" type="button" class="btn btn-primary mr-2" [title]="formatSectionName(next)" (click)="goTo('next')">Next</button>
            <button *ngIf="getActiveSection().length > 0" type="button" class="btn btn-primary" (click)="goTo('summary')">Summary</button>
          </span>
        </div>
      </div>

      <div>
        <div class="row pb-2">
          <div class="col-12">
              <ng-container *ngIf="sectionErrors && sectionErrors.length > 0">
                <div class="alert alert-danger">
                  <ul *ngFor="let error of sectionErrors" style="list-style-type: disc !important;">
                    <li>{{error}}</li>
                  </ul>
                </div>
              </ng-container>
              <ng-container *ngIf="sectionWarnings && sectionWarnings.length > 0">
                <div class="alert alert-warning">
                  <ul *ngFor="let warning of sectionWarnings" style="list-style-type: disc !important;">
                    <li>{{warning}}</li>
                  </ul>
                </div>
              </ng-container>
          </div>
        </div>
        <div class="row" *ngIf="section.questions.length > 0">
          <div class="col-4"></div>
          <ng-container *ngIf="inputLabels.length > 0">
              <div *ngFor="let label of inputLabels" class="col">
              <h5>{{label}}</h5>
            </div>
          </ng-container>
        </div>
        <hr />
        <div>
          <!-- Questions -->
          <ng-container *ngFor="let question of section.questions; let isEven = even;">
            <div [formGroupName]="question.key" class="well well-lg" [class.bg-light]="isEven" [id]="question.key">
              <div class="form-group row">
                <div class="col-4">
                  <b>{{getQuestionNumber(question.key)}}</b>&nbsp;&nbsp;&nbsp;&nbsp;{{question.text}}
                </div>

                <!-- Inputs -->
                <ng-container *ngFor="let input of question.inputs">
                  <ng-container [ngSwitch]="input.controlType">
                    <div class="col">
                      <!-- Switch Case: Dropdown -->
                      <ng-container *ngSwitchCase="'dropdown'">
                          <select [id]="input.key" [name]="input.key" [formControlName]="input.key"
                          [ngClass]="getInputStyle(input)" [attr.title]="input.label"
                          (change)="disableChildren(input.disableableInputs, input.key, question.key)">
                          <option value="" disabled hidden>Choose One</option>
                          <option *ngFor="let opt of input.options" [value]="opt.key">{{opt.value}}</option>
                        </select>
                      </ng-container>

                      <!-- Switch Case: TextArea -->
                      <ng-container *ngSwitchCase="'textarea'">
                          <textarea [ngClass]="getInputStyle(input)" [id]="input.key" [name]="input.key"
                          [attr.placeholder]="input.label" [formControlName]="input.key" [rows]="input.rows" [attr.title]="input.label"
                          (keyup)="disableChildren(input.disableableInputs, input.key, question.key)">
                        </textarea>
                      </ng-container>

                      <ng-container *ngSwitchDefault>
                        <ng-container [ngSwitch]="input.dataType">
                          <ng-container *ngSwitchCase="'integer'">
                            <input [formControlName]="input.key" [ngClass]="getInputStyle(input)"
                              [id]="input.key" type="text" appOnlyDigits [allowDecimal]="false" [attr.title]="input.label" [attr.placeholder]="input.label"
                              >
                          </ng-container>

                          <!-- Switch Case: Decimal -->
                          <ng-container *ngSwitchCase="'decimal'">
                            <input [formControlName]="input.key" [ngClass]="getInputStyle(input)"
                              [id]="input.key" type="text" appOnlyDigits [allowDecimal]="true" [attr.title]="input.label" [attr.placeholder]="input.label"
                              >
                          </ng-container>

                          <ng-container *ngSwitchDefault>
                            <input [formControlName]="input.key" [ngClass]="getInputStyle(input)"
                              [id]="input.key" type="text" [attr.title]="input.label" [attr.placeholder]="input.label"
                              (keyup)="disableChildren(input.disableableInputs, input.key, question.key)">
                          </ng-container>
                        </ng-container>
                      </ng-container>

                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <div class="row">
                <div class="col">
                  <!-- Question Errors -->
                  <ng-container *ngIf="questionErrors[question.key] && questionErrors[question.key].length > 0">
                    <div class="alert alert-danger">
                      <ul *ngFor="let error of questionErrors[question.key]" style="list-style-type: disc !important;">
                        <li>{{error}}</li>
                      </ul>
                    </div>
                  </ng-container>
                  <!-- Question Warnings -->
                  <ng-container *ngIf="questionWarnings[question.key] && questionWarnings[question.key].length > 0">
                    <div class="alert alert-warning">
                      <ul *ngFor="let warning of questionWarnings[question.key]" style="list-style-type: disc !important;">
                        <li>{{warning}}</li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- More Sections -->
          <ng-container *ngFor="let child of section.sections; let i = index">
            <app-filing-section [section]="child" [parentId]="getSectionId()" [disabled]="disabled" [parentPath]="path"></app-filing-section>
          </ng-container>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>
