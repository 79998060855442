<ng-container *ngIf="visible; else notVisible">
  <ng-container *ngIf="checkRegulatorRole(); else RegulatorRole">
  <div class="input-group my-datepicker">
    <input type="text" maxlength="10" (ngModelChange)="changeFollowupDate(params.data.id, $event)"
      placeholder="MM/DD/YYYY" name="bsDatepicker" class="form-control" #dp="bsDatepicker" bsDatepicker
      [(ngModel)]="dpValue" [bsConfig]="dpConfig" placement="bottom" clearBtn="true" readonly>
    <div class="input-group-append">
      <button id="clearFollowupDate" type="button" class="btn btn-secondary" 
      style="background-color: #e9ecef;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;" 
      (click)="reset(params.data.id)">
        <fa-icon [icon]="faTimes" style= "color: #6c757d"></fa-icon>
      </button>
      <button type="button" class="btn btn-secondary" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen">
        <fa-icon [icon]="faCalendar"></fa-icon>
      </button>
    </div>
  </div>
  </ng-container>
</ng-container>

<ng-template #RegulatorRole> {{displayFollowupDateForRegulator}} </ng-template>
<ng-template #notVisible> - </ng-template>