import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-user-session-inactive-popup',
  templateUrl: './user-session-inactive-popup.component.html',
  styleUrls: ['./user-session-inactive-popup.component.css']
})
export class UserSessionInactivePopupComponent implements OnInit, OnDestroy {
  name: string = 'Session Counter Example';
  sessionTimer: any;
  defaultTimeoutSeconds: number = 10;
  defaultShowPopupSeconds: number;
  timeoutSeconds: number;
  sessionMessage: string;
  showTimeoutPopup: boolean = false;
  @Input() countdownDuration: number;
  @Output() extendSessionClick: EventEmitter<void> = new EventEmitter<void>();

  remainingCountdown: number;
  private countdownInterval: Subscription;

  constructor(
    public bsModalRef: BsModalRef,
    private oauthService: OAuthService,
  ) { }

  ngOnInit() {
    this.startCountdown();
  }

  startCountdown() {
    if (this.countdownDuration > 0) {
      this.remainingCountdown = this.countdownDuration;

      // Starting a countdown interval
      this.countdownInterval = interval(1000).subscribe(() => {
        this.remainingCountdown = Math.max(this.remainingCountdown - 1, 0); // Ensuring remainingCountdown doesn't go below 0

        if (this.remainingCountdown === 0) {
          this.logout(); // Triggering logout when countdown reaches 0
        }
      });
    } else {
      this.remainingCountdown = 0;
      this.logout(); // Triggering the logout
    }
  }

  ngOnDestroy() {
    if (this.countdownInterval) {
      this.countdownInterval.unsubscribe();
    }
  }

  extendSession() {
    this.extendSessionClick.emit();
    this.bsModalRef.hide();
  }

  logout() {
    console.log("user logged out due to inactivity")
    this.oauthService.logOut();
    this.bsModalRef.hide();
  }
}
