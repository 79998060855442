<ngx-loading [show]="loading"></ngx-loading>
<div class="row">
  <nav class="col-md-2 d-none d-md-block bg-light sidebar d-print-none">
    <div *ngIf="user" class="sidebar-sticky">
      <!-- Select Filing Matrix -->
      <div id="selectFilingMatrix" class="pt-3 pb-2 mb-3 px-4 border-bottom d-print-none">
        <h4>Select Filing Matrix</h4>
        <app-company-year-select (companyYear)="setCompanyYear($event)" [user]="user" [userHistory]="userHistory[0]">
        </app-company-year-select>
      </div>

      <!-- Actions -->
      <div *ngIf="!selectFilingMatrixSummary">
        <div id="filingMatrixActions" class="pb-2 mb-3 px-4 border-bottom">
          <h4>
            <span (click)="isActionsCollapsed = !isActionsCollapsed" [attr.aria-expanded]="!isActionsCollapsed"
              aria-controls="actions" style="cursor: pointer;">
              <fa-icon [icon]="isActionsCollapsed ? faPlus : faMinus"></fa-icon> Actions
            </span>
          </h4>
          <div id="actions" [collapse]="isActionsCollapsed">
            <div class="pb-2">
              <button *ngIf="isExternalUser()" type="button" class="btn btn-secondary btn-sm btn-block mr-2"
                (click)="openWaiverExtensionRequest('waiver')" [disabled]="!filingMatrix || !year.isCurrentYear || !priorThreeYearData()">
                Waivers
              </button>
            </div>
            <div class="pb-2" >
              <button *ngIf="isExternalUser()" type="button" class="btn btn-secondary btn-sm btn-block mr-2"
                (click)="openWaiverExtensionRequest('extension')" [disabled]="!filingMatrix || !year.isCurrentYear || !priorThreeYearData()">
                Extensions
              </button>
            </div>
            <div class="pb-2">
              <button *ngIf="isExternalUser()" type="button" class="btn btn-primary btn-sm btn-block mr-2"
                (click)="openDataFileUpload()" [disabled]="!filingMatrix || !year.isCurrentYear || !priorThreeYearData()">
                Upload Data File
              </button>
            </div>
            <div class="pb-2">
              <button type="button" class="btn btn-primary btn-sm btn-block mr-2" (click)="goToCompanyRatios()"
                [disabled]="!filingMatrix || !priorThreeYearData()">Company Ratios</button>
            </div>
            <div class="pb-2">
              <button *ngIf="isCurrentYear()" id="filingSummary" type="button" class="btn btn-primary btn-sm btn-block mr-2"
                (click)="goToFilingSummary()" [disabled]="!filingMatrix || !year.isCurrentYear || !priorThreeYearData()">
                Filing Summary
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Filters -->
      <ng-container *ngIf="!loading && filingMatrix">
        <div id="filingMatrixFilters" *ngIf="!loading && filingMatrix" class="sticky-sticky pb-2 px-4 border-bottom">
          <h4>
            <span class="cursor-pointer" (click)="isFiltersCollapsed = !isFiltersCollapsed"
              [attr.aria-expanded]="!isFiltersCollapsed" aria-controls="filters">
              <fa-icon [icon]="isFiltersCollapsed ? faPlus : faMinus"></fa-icon> Filters
              <button class="btn btn-sm btn-outline-secondary float-right"
                (click)="clearFilters(); filterFilings(); resetDataForFilters();">Clear</button>
            </span>
          </h4>
          <div id="filters" [collapse]="isFiltersCollapsed">
            <!-- State Filter -->
            <div id="filingMatrixStateFilter" class="mb-3 border-bottom">
              <h5>
                <span class="cursor-pointer" (click)="isStateFilterCollapsed = !isStateFilterCollapsed"
                  [attr.aria-expanded]="!isStateFilterCollapsed" aria-controls="stateFilter">
                  <fa-icon [icon]="isStateFilterCollapsed ? faPlus : faMinus"></fa-icon> State
                </span>
              </h5>
              <div id="stateFilter" [collapse]="isStateFilterCollapsed">
                <input type="text" class="form-control" aria-label="Search State" id="stateFilter"
                  [(ngModel)]="stateFilterTerm" aria-describedby="stateFilter-addon" #stateFilter
                  (keyup)="filterFilings(stateFilter.value)">
              </div>
            </div>
            <!-- Line of Business Filter -->
            <div id="filingMatrixLineOfBusinessFilter" class="pb-2 mb-3 border-bottom">
              <h5>
                <span (click)="isLOBFilterCollapsed = !isLOBFilterCollapsed" class="cursor-pointer"
                  [attr.aria-expanded]="!isLOBFilterCollapsed" aria-controls="lobFilter">
                  <fa-icon [icon]="isLOBFilterCollapsed ? faPlus : faMinus"></fa-icon> Line of Business
                </span>
              </h5>
              <div id="lobFilter" [collapse]="isLOBFilterCollapsed">
                <div *ngFor="let lobFilter of lobFilters" class="form-check">
                  <input class="form-check-input" type="checkbox" value="lobFilter.term"
                    [(ngModel)]="lobFilter.filtered" (click)="filterByLOB(lobFilter)"
                    id="{{(lobFilter.term + 'Checkbox')}}">
                  <label class="form-check-label" for="{{(lobFilter.term + 'Checkbox')}}">{{lobFilter.name}}</label>
                </div>
              </div>
            </div>
            <!-- Filing Status Filter -->
            <div *ngIf="priorThreeYearData()" id="filingMatrixFilingStatusFilter" class="pb-2 mb-3 border-bottom">
              <h5>
                <span (click)="isFsFilterCollapsed = !isFsFilterCollapsed" class="cursor-pointer"
                  [attr.aria-expanded]="!isFsFilterCollapsed" aria-controls="fsFilters">
                  <fa-icon [icon]="isFsFilterCollapsed ? faPlus : faMinus"></fa-icon> Filing Status
                </span>
              </h5>
              <div id="fsFilters" [collapse]="isFsFilterCollapsed">
                <div *ngFor="let fsFilter of filingStatusFilters" class="form-check">
                  <input class="form-check-input" type="checkbox" value="fsFilter.term" [(ngModel)]="fsFilter.filtered"
                    (click)="filterByFilingStatus(fsFilter)" id="{{(fsFilter.term + 'Checkbox')}}">
                  <label class="form-check-label" for="{{(fsFilter.term + 'Checkbox')}}">{{fsFilter.name}}</label>
                </div>
              </div>
            </div>
            <!-- Required Filter -->
            <div id="filingMatrixRequiredFilter" class="pb-2 mb-3 border-bottom">
              <h5>
                <span (click)="isRequiredFilterCollapsed = !isRequiredFilterCollapsed" class="cursor-pointer"
                  [attr.aria-expanded]="!isRequiredFilterCollapsed" aria-controls="requiredFilter">
                  <fa-icon [icon]="isRequiredFilterCollapsed ? faPlus : faMinus"></fa-icon> Required
                </span>
              </h5>
              <div id="requiredFilter" [collapse]="isRequiredFilterCollapsed">
                <div *ngFor="let requiredFilter of requiredFilters" class="form-check">
                  <input class="form-check-input" type="checkbox" value="requiredFilter.term"
                    [(ngModel)]="requiredFilter.filtered" (click)="filterByRequired(requiredFilter.term)"
                    id="{{(requiredFilter.term + 'Checkbox')}}">
                  <label class="form-check-label"
                    for="{{(requiredFilter.term + 'Checkbox')}}">{{requiredFilter.name}}</label>
                </div>
              </div>
            </div>
            <!-- Locked Filter -->
            <div *ngIf="!year.isCurrentYear" id="filingMatrixLockedFilter" class="pb-2 mb-3 border-bottom">
                <h5>
                  <span (click)="isLockedFilterCollapsed = !isLockedFilterCollapsed" class="cursor-pointer"
                    [attr.aria-expanded]="!isLockedFilterCollapsed" aria-controls="lockedFilter">
                    <fa-icon [icon]="isLockedFilterCollapsed ? faPlus : faMinus"></fa-icon> Lock/Unlock
                  </span>
                </h5>
                <div id="lockedFilter" [collapse]="isLockedFilterCollapsed">
                  <div *ngFor="let lockedFilter of lockedFilters" class="form-check">
                    <input class="form-check-input" type="checkbox" value="lockedFilter.term"
                      [(ngModel)]="lockedFilter.filtered" (click)="filterByLocked(lockedFilter.term)"
                      id="{{(lockedFilter.term + 'Checkbox')}}">
                    <label class="form-check-label"
                      for="{{(lockedFilter.term + 'Checkbox')}}">{{lockedFilter.name}}</label>
                  </div>
                </div>
              </div>
            <!-- Warnings Filter -->
            <div *ngIf="priorThreeYearData()" id="filingMatrixWarningsFilter" class="pb-2 mb-3 border-bottom">
              <h5>
                <span class="cursor-pointer" (click)="isWarningsFilterCollapsed = !isWarningsFilterCollapsed"
                  [attr.aria-expanded]="!isWarningsFilterCollapsed" aria-controls="warningsFilter">
                  <fa-icon [icon]="isWarningsFilterCollapsed ? faPlus : faMinus"></fa-icon> Warnings
                </span>
              </h5>
              <div id="warningsFilter" [collapse]="isWarningsFilterCollapsed">
                <div *ngFor="let warningsFilter of warningsFilters" class="form-check">
                  <input class="form-check-input" type="checkbox" value="warningsFilter.term"
                    [(ngModel)]="warningsFilter.filtered" (click)="filterByWarnings(warningsFilter.term)"
                    id="{{(warningsFilter.term + 'Checekbox')}}">
                  <label class="form-check-label"
                    for="{{(warningsFilter.term + 'Checekbox')}}">{{warningsFilter.name}}</label>
                </div>
              </div>
            </div>
            <!-- Errors Filter -->
            <div *ngIf="priorThreeYearData()" id="filingMatrixErrorsFilter" class="pb-2 mb-3 border-bottom">
              <h5>
                <span class="cursor-pointer" (click)="isErrorsFilterCollapsed = !isErrorsFilterCollapsed"
                  [attr.aria-expanded]="!isErrorsFilterCollapsed" aria-controls="errorsFilter">
                  <fa-icon [icon]="isErrorsFilterCollapsed ? faPlus : faMinus"></fa-icon> Errors
                </span>
              </h5>
              <div id="errorsFilter" [collapse]="isErrorsFilterCollapsed">
                <div *ngFor="let errorsFilter of errorsFilters" class="form-check">
                  <input class="form-check-input" type="checkbox" value="errorsFilter.term"
                    [(ngModel)]="errorsFilter.filtered" (click)="filterByErrors(errorsFilter.term)"
                    id="{{(errorsFilter.term + 'Checekbox')}}">
                  <label class="form-check-label"
                    for="{{(errorsFilter.term + 'Checekbox')}}">{{errorsFilter.name}}</label>
                </div>
              </div>
            </div>
            <!-- Extension Status Filter -->
            <div *ngIf="priorThreeYearData()" id="filingMatrixExtensionStatusFilter" class="pb-2 mb-3 border-bottom">
              <h5>
                <span class="cursor-pointer" (click)="isExtensionFilterCollapsed = !isExtensionFilterCollapsed"
                  [attr.aria-expanded]="!isExtensionFilterCollapsed" aria-controls="extensionFilter">
                  <fa-icon [icon]="isExtensionFilterCollapsed ? faPlus : faMinus"></fa-icon> Extension Status
                </span>
              </h5>
              <div id="extensionFilter" [collapse]="isExtensionFilterCollapsed">
                <div *ngFor="let extensionFilter of extensionFilters" class="form-check">
                  <input class="form-check-input" type="checkbox" value="extensionFilter.term"
                    [(ngModel)]="extensionFilter.filtered" (click)="filterByExtensionStatus(extensionFilter.term)"
                    id="{{(extensionFilter.term + 'ExtensionChecekbox')}}">
                  <label class="form-check-label"
                    for="{{(extensionFilter.term + 'ExtensionChecekbox')}}">{{extensionFilter.name}}</label>
                </div>
              </div>
            </div>
            <!-- Waiver Status Filter -->
            <div *ngIf="priorThreeYearData()" id="filingMatrixWaiverStatusFilter" class="pb-2 mb-3">
              <h5>
                <span class="cursor-pointer" (click)="isWaiverFilterCollapsed = !isWaiverFilterCollapsed"
                  [attr.aria-expanded]="!isWaiverFilterCollapsed" aria-controls="waverFilter">
                  <fa-icon [icon]="isWaiverFilterCollapsed ? faPlus : faMinus"></fa-icon> Waiver Status
                </span>
              </h5>
              <div id="waverFilter" [collapse]="isWaiverFilterCollapsed">
                <div *ngFor="let waiverFilter of waiverFilters" class="form-check">
                  <input class="form-check-input" type="checkbox" value="waiverFilter.term"
                    [(ngModel)]="waiverFilter.filtered" (click)="filterByWaiverStatus(waiverFilter.term)"
                    id="{{(waiverFilter.term + 'WaiverChecekbox')}}">
                  <label class="form-check-label"
                    for="{{(waiverFilter.term + 'WaiverChecekbox')}}">{{waiverFilter.name}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </nav>


  <!-- Main - Screen -->
  <main *ngIf="!loading && !isSummaryMatrix" role="main" class="col-md-9 ml-sm-auto col-lg-10 d-print-none">
    <app-messages [parentName]="'filingMatrix'"></app-messages>
    <div *ngIf="filingMatrix && priorThreeYearData() else priorThreeYear" id="filingMatrixMain" [ngClass]="getInputStyle()" >
      <div class="row">
        <div class="col-12">
          <div class="pb-2 mb-3 border-bottom">
            <h3>{{title}}
              </h3>
          </div>
        </div>
      </div>

      <div class="row clearfix">
        <div class="col-12 clearfix">
          <button id="panelToggle" type="button" class="btn btn-link btn-sm float-right" (click)="setActivePanels()"
            style="color: rgb(0, 0, 0)">
            {{dataService.getExpansionText()}}
          </button>
        </div>
      </div>

      <div *ngIf="matrixFilteredFilings && hasVisibleResults(); else noFilteredFilings" class="row">
        <div class="col-12">
          <go-top-button [animate]="true" [speed]="50" [acceleration]="2" [scrollDistance]="300" [styles]="{
                        'border-radius': '20px 20px 20px 20px',
                        'top': '85%',
                        'bottom': '15%',
                        'right': '5px',
                        'color': '#2c6ba0',
                        'border': '5px solid',
                        'line-height': '20px',
                        'z-index': '9000'
                      }">
            <fa-icon [icon]="faArrowUp"></fa-icon>
          </go-top-button>
          <accordion>
            <ng-container *ngFor="let filteredFiling of matrixFilteredFilings; let i = index">
              <accordion-group *ngIf="filteredFiling.visible" [(isOpen)]="filteredFiling.matrixExpanded">
                <div accordion-heading class="clearfix">
                  <div id="{{(filteredFiling.stateFiling.state.stabbr + 'Panel')}}" class="row cursor-pointer"
                    #accordionGroup>
                    <div class="col-7">
                      <span class="accordion-title">
                        {{filteredFiling.stateFiling.state.stateName}}
                      </span>
                    </div>
                    <div class="col-4 clearfix">
                      <div class="float-right">
                        <span *ngIf="stateTotalErrors(filteredFiling.stateFiling.filings) > 0"
                          class="badge badge-pill badge-danger count-badges">
                          Total Errors: {{stateTotalErrors(filteredFiling.stateFiling.filings)}}
                        </span>
                        &nbsp;&nbsp;
                        <span *ngIf="stateTotalWarnings(filteredFiling.stateFiling.filings) > 0"
                          class="badge badge-pill badge-warning count-badges">
                          Total Warnings: {{stateTotalWarnings(filteredFiling.stateFiling.filings)}}
                        </span>
                      </div>
                    </div>
                    <div class="col-1 clearfix">
                      <span class="float-right" [title]="filteredFiling.matrixExpanded ? 'Collapse' : 'Expand'">
                        <fa-icon [icon]="filteredFiling.matrixExpanded ? faCaretUp : faCaretDown"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>
                <table class="table table-hover table-sm">
                  <thead>
                    <tr stye="font-size: 14px">
                      <th></th>
                      <th class="text-center">Status</th>
                      <th class="text-center">Warnings</th>
                      <th class="text-center">Errors</th>
                      <th class="text-center">Waiver</th>
                      <th class="text-center">Extension</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let filing of filteredFiling.stateFiling.filings">
                      <tr *ngIf="filing.visible" [ngClass]="getRowColor(filing.warnings.length, filing.errors.length)"
                        style="font-size: 14px">
                        <!-- Line of Business -->
                        <td class="align-middle" width="25%">
                          <span style="font-weight: bold">
                            <ng-container
                              *ngIf="filing.status.code !== 'NOT_STARTED' && filing.status.code !== 'IN_PROGRESS' && filing.status.code !== 'FILED' && !year.isCurrentYear; else noFiling">
                              {{filing.lineOfBusiness.name}}
                            </ng-container>
                            <ng-template #noFiling>
                              <a [routerLink]=""
                                (click)="goToFiling(filteredFiling.stateFiling.state, filing.lineOfBusiness, filing.status)"
                                style="cursor: pointer; color: black !important;">
                                {{filing.lineOfBusiness.name}}
                              </a>
                            </ng-template>
                            <sup *ngIf="filing.required" style="color: red">
                              <fa-icon [icon]="faAsterisk"></fa-icon>
                            </sup>
                            <sup *ngIf="!year.isCurrentYear" style=" color: black !important; font-size: 13px !important;">
                                <fa-icon [icon]="filing.locked ? faLock : faLockOpen"></fa-icon>
                              </sup>
                          </span>
                        </td>
                        <!-- Status -->
                        <td class="text-center align-middle" width="15%">
                          {{filing.status.displayText}}
                        </td>
                        <!-- Warnings -->
                        <td class="text-center align-middle" width="15%">
                          <button *ngIf="filing.warnings.length > 0; else noWarnings" type="button" class="btn btn-link btn-sm text-left" (click)="openWarningsErrorsModule('warnings', filing.lineOfBusiness, filing.warnings)">{{lobErrorLength(filing.warnings)}}</button>
                          <ng-template #noWarnings>
                            {{lobWarningLength(filing.warnings)}}
                          </ng-template>
                        </td>
                        <!-- Errors -->
                        <td class="text-center align-middle" width="15%">
                          <button *ngIf="filing.errors.length > 0; else noErrors" type="button" class="btn btn-link btn-sm text-left" (click)="openWarningsErrorsModule('errors', filing.lineOfBusiness, filing.errors)">{{lobErrorLength(filing.errors)}}</button>
                          <ng-template #noErrors>{{lobErrorLength(filing.errors)}}</ng-template>
                        </td>
                        <td *ngIf="filing.waiver; else noWaiver" class="text-center align-middle" width="15%">
                          <button type="button" class="btn btn-link btn-sm text-left" (click)="openWaiver(filing.waiver)"><span class="text-capitalize">{{filing.waiver.status}}</span></button>
                        </td>
                        <ng-template #noWaiver>
                          <td class="text-center align-middle" width="15%">
                            <span></span>
                          </td>
                        </ng-template>
                        <td *ngIf="filing.extension; else noExtension" class="text-center align-middle" width="15%">
                          <button type="button" class="btn btn-link btn-sm text-left" (click)="openExtension(filing.extension)"><span class="text-capitalize">{{filing.extension.status}}</span></button>
                        </td>
                        <td *ngIf="filing.extension && year.year > 2021; else noExtension" class="text-center align-middle" width="15%">
                          <button type="button" class="btn btn-link btn-sm text-left" (click)="openMultipleExtensions(filing.extension, filing.lineOfBusiness, filteredFiling.stateFiling.state)"><span class="text-capitalize">History</span></button>
                        </td>
                        <ng-template #noExtension>
                          <td class="text-center align-middle" width="15%">
                            <span></span>
                          </td>
                        </ng-template>
                      </tr>
                    </ng-container>

                  </tbody>
                </table>
              </accordion-group>
            </ng-container>

          </accordion>
        </div>
      </div>
    </div>
<div class="row">
    <footer class="col-12 footer d-print-none">
      <ncui-footer></ncui-footer>
    </footer>
</div>

</main>

<main *ngIf="!loading && !isSummaryMatrix" role="main" class="d-none col-sm-12 ml-sm-auto d-print-block ">
  <app-messages [parentName]="'filingMatrix'"></app-messages>
    <div *ngIf="filingMatrix; else noFilingMatrix" id="filingMatrixMain" [ngClass]="getInputStyle()">
      <div class="row">
        <div class="col-12">
          <div class="pb-2 mb-3 border-bottom">
            <h3>{{title}}</h3>
          </div>
        </div>
      </div>

        <div class="row">
          <div class="col-12">
            <button id="panelToggle" type="button" class="btn btn-link btn-sm pull-right" (click)="setActivePanels()" style="color: black">
              {{dataService.getExpansionText()}}
            </button>
          </div>
        </div>

        <div *ngIf="matrixFilteredFilings && hasVisibleResults(); else noFilteredFilings" class="row">
          <div class="col-12">
            <accordion>
              <ng-container *ngFor="let filteredFiling of matrixFilteredFilings; let i = index">
                <accordion-group *ngIf="filteredFiling.visible" [(isOpen)]="filteredFiling.matrixExpanded">
                  <div accordion-heading class="clearfix">
                    <div id="{{(filteredFiling.stateFiling.state.stabbr + 'Panel')}}" class="row cursor-pointer" #accordionGroup>
                      <div class="col-7">
                        <span class="accordion-title">
                          {{filteredFiling.stateFiling.state.stateName}}
                        </span>
                      </div>
                      <div class="col-4">
                        <div class="pull-right">
                          <span *ngIf="stateTotalErrors(filteredFiling.stateFiling.filings) > 0" class="badge badge-pill badge-danger count-badges">
                            Total Errors: {{stateTotalErrors(filteredFiling.stateFiling.filings)}}
                          </span>
                          &nbsp;&nbsp;
                          <span *ngIf="stateTotalWarnings(filteredFiling.stateFiling.filings) > 0" class="badge badge-pill badge-warning count-badges">
                              Total Warnings: {{stateTotalWarnings(filteredFiling.stateFiling.filings)}}
                            </span>
                        </div>
                      </div>
                      <div class="col-1 clearfix">
                        <span class="float-right" [title]="filteredFiling.matrixExpanded ? 'Collapse' : 'Expand'">
                          <fa-icon [icon]="filteredFiling.matrixExpanded ? faCaretUp : faCaretDown"></fa-icon>
                        </span>
                      </div>
                    </div>
                  </div>
                  <table class="table table-hover table-sm">
                    <thead>
                      <tr stye="font-size: 14px">
                        <th></th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Warnings</th>
                        <th class="text-center">Errors</th>
                        <th class="text-center">Waiver</th>
                        <th class="text-center">Extension</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let filing of filteredFiling.stateFiling.filings">
                        <tr *ngIf="filing.visible" [ngClass]="getRowColor(filing.warnings.length, filing.errors.length)" style="font-size: 14px">
                          <!-- Line of Business -->
                          <td class="align-middle" width="25%">
                            <span style="font-weight: bold">
                              <ng-container *ngIf="filing.status.code !== 'NOT_STARTED' && filing.status.code !== 'IN_PROGRESS' && filing.status.code !== 'FILED' && !year.isCurrentYear; else noFiling">
                                {{filing.lineOfBusiness.name}}
                              </ng-container>
                              <ng-template #noFiling>
                                <a [routerLink]="" (click)="goToFiling(filteredFiling.stateFiling.state, filing.lineOfBusiness, filing.status)" style="cursor: pointer; color: black !important;">
                                  {{filing.lineOfBusiness.name}}
                                </a>
                              </ng-template>
                              <sup *ngIf="filing.required" style="color: black">
                                <fa-icon [icon]="faAsterisk"></fa-icon>
                              </sup>
                          </span>
                        </td>
                        <!-- Status -->
                        <td class="text-center align-middle" width="15%">
                          {{filing.status.displayText}}
                        </td>
                        <!-- Warnings -->
                        <td class="text-center align-middle" width="15%">
                          <button *ngIf="filing.warnings.length > 0; else noWarnings" type="button"
                            class="btn btn-link btn-sm text-left"
                            (click)="openWarningsErrorsModule('warnings', filing.lineOfBusiness, filing.warnings)">{{filing.warnings.length}}</button>
                          <ng-template #noWarnings>
                            {{filing.warnings.length}}
                          </ng-template>
                        </td>
                        <!-- Errors -->
                        <td class="text-center align-middle" width="15%">
                          <button *ngIf="filing.errors.length > 0; else noErrors" type="button"
                            class="btn btn-link btn-sm text-left"
                            (click)="openWarningsErrorsModule('errors', filing.lineOfBusiness, filing.errors)">{{filing.errors.length}}</button>
                          <ng-template #noErrors>{{filing.errors.length}}</ng-template>
                        </td>
                        <td *ngIf="filing.waiver; else noWaiver" class="text-center align-middle" width="15%">
                          <button type="button" class="btn btn-link btn-sm text-left"
                            (click)="openWaiver(filing.waiver)"><span
                              class="text-capitalize">{{filing.waiver.status}}</span></button>
                        </td>
                        <ng-template #noWaiver>
                          <td class="text-center align-middle" width="15%">
                            <span></span>
                          </td>
                        </ng-template>
                        <td *ngIf="filing.extension; else noExtension" class="text-center align-middle" width="15%">
                          <button type="button" class="btn btn-link btn-sm text-left"
                            (click)="openExtension(filing.extension)"><span
                              class="text-capitalize">{{filing.extension.status}}</span></button>
                        </td>
                        <ng-template #noExtension>
                          <td class="text-center align-middle" width="15%">
                            <span></span>
                          </td>
                        </ng-template>
                      </tr>
                    </ng-container>

                  </tbody>
                </table>
              </accordion-group>
            </ng-container>

          </accordion>
        </div>
      </div>
    </div>
    <div class="row">
      <footer class="col-12 footer d-print-none">
        <ncui-footer></ncui-footer>
      </footer>
    </div>

  </main>
</div>

<ng-template #pleaseChoose>
    <div class="pt-3 pb-3 main-content">
      <h2 class="text-center">{{ !isSuperUser() ? 'Please choose a company and year' : 'Please enter a cocode and choose year' }}</h2>
    </div>
</ng-template>

<ng-template #noFilingMatrix>
  <div *ngIf="company && year; else pleaseChoose" class="pt-3 pb-3">
    <h2 class="text-center">No results</h2>
  </div>
</ng-template>

<ng-template #noFilteredFilings>
  <div class="pt-3 pb-3">
    <h2 class="text-center">No results</h2>
  </div>
</ng-template>

<ng-template #priorThreeYear>
  <div *ngIf="filingMatrix && !priorThreeYearData() else noFilingMatrix" id="filingMatrixMain" [ngClass]="getInputStyle()">
    <div class="row">
      <div class="col-12">
        <div class="pb-2 mb-3 border-bottom">
          <h3>{{title}}
            </h3>
        </div>
      </div>
    </div>

    <div class="row clearfix">
      <div class="col-12 clearfix">
        <button id="panelToggle" type="button" class="btn btn-link btn-sm float-right" (click)="setActivePanels()"
          style="color: rgb(0, 0, 0)">
          {{dataService.getExpansionText()}}
        </button>
      </div>
    </div>

    <div *ngIf="matrixFilteredFilings && hasVisibleResults(); else noFilteredFilings" class="row">
      <div class="col-12">
        <go-top-button [animate]="true" [speed]="50" [acceleration]="2" [scrollDistance]="300" [styles]="{
                      'border-radius': '20px 20px 20px 20px',
                      'top': '85%',
                      'bottom': '15%',
                      'right': '5px',
                      'color': '#2c6ba0',
                      'border': '5px solid',
                      'line-height': '20px',
                      'z-index': '9000'
                    }">
          <fa-icon [icon]="faArrowUp"></fa-icon>
        </go-top-button>
        <accordion>
            <ng-container *ngFor="let filteredFiling of matrixFilteredFilings; let i = index">
              <accordion-group *ngIf="filteredFiling.visible" [(isOpen)]="filteredFiling.matrixExpanded">
                <div accordion-heading class="clearfix">
                  <div id="{{(filteredFiling.stateFiling.state.stabbr + 'Panel')}}" class="row cursor-pointer"
                    #accordionGroup>
                    <div class="col-7">
                      <span class="accordion-title">
                        {{filteredFiling.stateFiling.state.stateName}}
                      </span>
                    </div>
                    <div class="col-4 clearfix">
                      <div class="float-right">
                      </div>
                    </div>
                    <div class="col-1 clearfix">
                      <span class="float-right" [title]="filteredFiling.matrixExpanded ? 'Collapse' : 'Expand'">
                        <fa-icon [icon]="filteredFiling.matrixExpanded ? faCaretUp : faCaretDown"></fa-icon>
                      </span>
                    </div>
                  </div>
                </div>
                <table class="table table-hover table-sm">
                  <thead>
                    <tr stye="font-size: 14px" class="text-center">
                      <th></th>
                      <th class="text-center">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let filing of filteredFiling.stateFiling.filings">
                      <tr *ngIf="filing.visible"
                        style="font-size: 14px">
                        <!-- Line of Business -->
                        <td class="align-middle" width="25%">
                          <span style="font-weight: bold">
                            <ng-container>
                              {{filing.lineOfBusiness.name}}
                            </ng-container>
                            <sup *ngIf="filing.required" style="color: red">
                              <fa-icon [icon]="faAsterisk"></fa-icon>
                            </sup>
                          </span>
                        </td>
                        <!-- Download Pdf -->
                        <td class="text-center align-middle" width="15%">
                            <a [href]="yearUrl" role="button" target="_blank" style="color: #0056b3;"
                               (click)="downloadPriorThreeYearPdf(filteredFiling.stateFiling.state, filing.lineOfBusiness)">
                               PDF
                            </a>
                        </td>
                      </tr>
                    </ng-container>

                  </tbody>
                </table>
              </accordion-group>
            </ng-container>

        </accordion>
      </div>
    </div>
  </div>
</ng-template>
