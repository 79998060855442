<ng-container *ngIf="visible; else notVisible">
    <button id="addNotes" type="button" class="btn btn-primary" (click)="openAnalystCompanyNotes(params.data.id, params.data, analystCompanyModal)">{{buttonText()}}</button>
</ng-container>

<ng-template #notVisible> - </ng-template>

<ng-template #analystCompanyModal>
  <ng-container *ngIf="checkRegulatorRole(); else RegulatorRole">
    <div id="analyst-company-header" class="modal-header">
        <h4 id="analyst-company-title" class="modal-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      
      <form [formGroup]="modalForm" id="analystCompanyForm" name="analystCompanyForm" (ngSubmit)="submitForm(modalForm.value)">
        <div id="previewBody" class="modal-body" style="height: 650px;">
          <div class="container">
            <!-- <app-messages [parentName]="'previewLetter'"></app-messages> -->
            <div class="form-group">
              <label><b>Analyst Notes</b></label>
              <textarea class="form-control" id="analystNotes" name="analystNotes" style="height: 15rem;"
                [formControl]="modalForm.controls['analystNotes']">{{params.data.analystCompanyNotes.analystNote}}
                </textarea>
            </div>
      
            <div class="form-group">
              <label style="padding-top: 2rem;"><b>Company Response</b></label>
              <textarea class="form-control" id="companyResponse" name="companyResponse" style="height: 15rem;"
                [formControl]="modalForm.controls['companyResponse']">{{params.data.analystCompanyNotes.companyResponse}}
                </textarea>
            </div>
          </div>
        </div>
      
        <div class="modal-footer">
          <button id="analyst-company-cancel" type="button" class="btn btn-link btn-sm" (click)="cancel()">Cancel</button>
          <button id="analyst-company-save" type="submit" class="btn btn-primary">
            Save Changes
          </button>
        </div>
      </form>
    </ng-container>
  </ng-template>

  <ng-template #RegulatorRole>
    <div id="analyst-company-header" class="modal-header">
      <h4 id="analyst-company-title" class="modal-title">{{title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    <form [formGroup]="modalForm" id="analystCompanyForm" name="analystCompanyForm" (ngSubmit)="submitForm(modalForm.value)">
      <div id="previewBody" class="modal-body" style="height: 650px;">
        <div class="container">
          <!-- <app-messages [parentName]="'previewLetter'"></app-messages> -->
          <div class="form-group">
            <label><b>Analyst Notes</b></label>
            <textarea class="form-control" id="analystNotes" name="analystNotes" style="height: 15rem;"
              [formControl]="modalForm.controls['analystNotes']" readonly>{{params.data.analystCompanyNotes.analystNote}}
              </textarea>
          </div>
    
          <div class="form-group">
            <label style="padding-top: 2rem;"><b>Company Response</b></label>
            <textarea class="form-control" id="companyResponse" name="companyResponse" style="height: 15rem;"
              [formControl]="modalForm.controls['companyResponse']" readonly>{{params.data.analystCompanyNotes.companyResponse}}
              </textarea>
          </div>
        </div>
      </div>
    
      <div class="modal-footer">
        <button id="analyst-company-cancel" type="button" class="btn btn-link btn-sm" (click)="cancel()">Close</button>
      </div>
    </form>
  </ng-template>