
<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <table class="table table-hover table-sm">
    <thead>
    <tr stye="font-size: 14px">
      <th class="text-center">State Name</th>
      <th class="text-center">Line of Business</th>
      <th class="text-center">Approved Date</th>
      <th class="text-center">Requested Extension Date</th>
      <th class="text-center">Status</th>
    </tr>
    </thead>
    <tbody>
  <ng-container *ngFor="let extension of waiverExtension" class="text-center align-middle" width="15%">
    <tr>
      <td class="text-center">{{extension.state.stateName}}</td>
      <td class="text-center">{{extension.approvedDate | date:'MM-dd-yyyy'}}</td>
      <td class="text-center">{{extension.endDate | date:'MM-dd-yyyy'}}</td>
      <td>
        <button type="button" class="btn btn-link btn-sm text-center" (click)="openExtension(extension)"><span class="text-capitalize">{{extension.status}}</span></button>
      </td>
    </tr>
    </ng-container>
    </tbody>
  </table>
<!--  <form #extensionModalForm="ngForm">-->
<!--    <div class="form-group">-->
<!--      <div class="font-weight-bold">State</div>-->
<!--      <div>{{extension.state.stateName}}</div>-->
<!--    </div>-->
<!--    <div class="form-group">-->
<!--      <div class="font-weight-bold">Line of Business</div>-->
<!--      <span>{{extension.lineOfBusiness.name}}</span>-->
<!--    </div>-->
<!--    <div class="form-group">-->
<!--      <div class="font-weight-bold">Status</div>-->
<!--      <span class="text-capitalize">{{extension.status.toLowerCase()}}</span>-->
<!--    </div>-->
<!--    <div class="form-group">-->
<!--      <div class="font-weight-bold">Requested Extension Date</div>-->
<!--      <div class="input-group">-->
<!--        <input type="text" maxlength="10" placeholder="MM/DD/YYYY" name="extensionDatepicker" class="form-control form-control-sm" #dp="bsDatepicker" bsDatepicker [(ngModel)]="dpValue" [bsConfig]="dpConfig" placement="top" [disabled]="isUpdateDisabled()" required>-->
<!--        <div class="input-group-append">-->
<!--          <button type="button" class="btn btn-secondary" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" [disabled]="isUpdateDisabled()">-->
<!--            <fa-icon [icon]="faCalendar"></fa-icon>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="form-group">-->
<!--      <div class="font-weight-bold">Extension Request Reason</div>-->
<!--      <textarea class="form-control form-control-sm" id="reason" name="reason" required [(ngModel)]="extension.reason" [disabled]="isUpdateDisabled()"></textarea>-->
<!--    </div>-->
<!--    <ng-container *ngIf="extension.status.toLowerCase() === 'approved' || extension.status.toLowerCase() === 'denied'">-->
<!--      <div class="form-group">-->
<!--        <div class="font-weight-bold">Decision Date</div>-->
<!--        <div>{{extension.decisionDate}}</div>-->
<!--      </div>-->
<!--      <div class="form-group">-->
<!--        <div class="font-weight-bold">Extension <span class="text-capitalize">{{extension.status.toLowerCase()}}</span> Date</div>-->
<!--        <div>{{extension.approvedDate}}</div>-->
<!--      </div>-->
<!--      <div class="form-group">-->
<!--        <div class="font-weight-bold">Decision Comments</div>-->
<!--        <div>{{extension.decisionComments}}</div>-->
<!--      </div>-->
<!--    </ng-container>-->
<!--  </form>-->
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-link btn-sm" (click)="close()">Close</button>
<!--  <button type="button" class="btn btn-danger" (click)="onDelete()" [disabled]="isUpdateDisabled()">Delete</button>-->
<!--  <button type="button" class="btn btn-primary" (click)="onUpdate()" [disabled]="!extensionModalForm.form.valid || isUpdateDisabled()">Update Extension</button>-->
</div>



