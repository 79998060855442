import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HandleError, HttpErrorHandler } from '../../shared/http-error-handler.service';
import { HttpClient } from '@angular/common/http';
import { Company } from '../model/company';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class HelpDeskService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler,
  ) {
    this.handleError = httpErrorHandler.createHandleError('HelpDeskService');
   }

   getCompanies(userName: string): Observable<Company[]> {
     const url = `${environment.apiUrl}helpDesk/companies/${userName}`;
     return this.httpClient.get<Company[]>(url)
     .pipe(catchError(this.handleError('getCompanies', null)));
   }

   getUsers(cocode: number): Observable<User[]> {
     const url = `${environment.apiUrl}helpDesk/users/${cocode}`;
     return this.httpClient.get<User[]>(url)
     .pipe(catchError(this.handleError('getUsers', null)));
   }
}
