import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { User } from '../../shared/model/user';
import { UserHistory } from '../model/user-history';
import { Company } from '../../shared/model/company';
import { Year } from '../../shared/model/year';
import { FilingMatrix } from '../model/filing-matrix';
import { Subscription, Subject } from 'rxjs';
import { StateFiling } from '../model/state-filing';
import { FilteredFiling } from '../model/filtered-filing';
import { McsUserService } from '../../service/mcs-user.service';
import { FilingMatrixService } from '../services/filing-matrix.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../data/data.service';
import { MessageService } from '../../messages/service/message.service';
import { CompanyService } from '../company-year-select/services/company.service';
import { takeUntil } from 'rxjs/operators';
import { faAsterisk, faArrowUp, faAngleDoubleLeft, faAngleDoubleRight, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import pdfMake from 'pdfmake/build/pdfmake';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-summary-filing-matrix',
  templateUrl: './summary-filing-matrix.component.html',
  styleUrls: ['./summary-filing-matrix.component.css']
})
export class SummaryFilingMatrixComponent implements OnInit {
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  @ViewChild('content', { static: false }) content: ElementRef;
  filingMatrix: FilingMatrix;
  loading = false;
  user: User;
  userHistory: UserHistory[] = [];
  years: number;
  companyName: string;
  cocodes: string;
  company: Company;
  cocode: number;
  year: Year;
  title: string;
  state: string[] = [];
  submissionDate: Date;
  subscriptions: Subscription[] = [];
  filing: any[] = [];
  private unsubscribe: Subject<void> = new Subject();
  selectedLob: string;
  lobName: string[] = [];
  faAsterisk = faAsterisk;
  faArrowUp = faArrowUp;
  faDoubleLeft = faAngleDoubleLeft;
  faDoubleRight = faAngleDoubleRight;
  faPlus = faPlus;
  faMinus = faMinus;
  selectedIndex: number = null;
  stateTableData: any[][];
  disable = false;
  sidebarCollapsed = false;
  navigationList: boolean;
  selectedLobForPrinting: string;


  constructor(
    private userService: McsUserService,
    private filingMatrixService: FilingMatrixService,
    private route: ActivatedRoute,
    private router: Router,
    public dataService: DataService,
    public messageService: MessageService,
    private companyService: CompanyService,
  ) { }

  ngOnInit() {
    this.getUser();
    this.loading = true;
    this.selectedLob = '';
    this.navigationList = this.filingMatrixService.filingSummaryNavigation;
    this.years = +this.route.snapshot.paramMap.get('year');
    this.companyName = this.route.snapshot.paramMap.get('company');
    this.cocodes = this.route.snapshot.paramMap.get('cocode');
  }

  getUser(): void {
    this.user = this.userService.getStoredUser();
    this.userHistory = this.userService.getUserHistory();
    this.loading = false;
    if (this.userHistory.length > 0) {
      this.loading = true;
      this.year = this.userHistory[0].year;
      this.company = this.userHistory[0].company;
      this.cocode = this.userHistory[0].cocode;
      this.getFiling();
    }
  }

  getFiling(): void {
    this.filingMatrixService.getFilingMatrixByCompanyAndYear(this.company ? this.company.cocode : this.cocode, this.year)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        filingMatrix => {
          if (!_.isNull(filingMatrix)) {
            this.filingMatrix = filingMatrix;
            this.title = `Filing Summary for ${this.companyService.getDisplayName(filingMatrix.company.longName, filingMatrix.company.cocode)} - ${filingMatrix.year.year}`;
            this.getTable();
            this.getLob();
          } else {
            this.loading = false;
          }
        });
  }

  getTable() {
    this.stateTableData = [[], []];
    this.filingMatrix.stateFilings.forEach(filing => {
      let tempFiling = { filings: filing.filings, state: filing.state }
      if (this.stateTableData[0].length < 25) {
        this.stateTableData[0].push(tempFiling);
      } else {
        this.stateTableData[1].push(tempFiling);
      }
      this.loading = false;
    });
  }

  getLob() {
    this.filingMatrix.stateFilings.forEach(filings => {
      filings.filings.forEach(lob => {
        if (this.lobName.indexOf(lob.lineOfBusiness.name) === -1) {
          this.lobName.push(lob.lineOfBusiness.name);
        }
      });
    });
  }

  getSummary(event) {
    this.selectedIndex = event.target.selectedOptions[0].value;
    this.selectedLobForPrinting = this.lobName[this.selectedIndex]
  }

  downloadDisplayedData() {
    let companyName = this.companyName;
    const filename = `${companyName}.pdf`;
    let width = document.getElementById("filingSummaryMainDisplay").offsetWidth;
    let height = document.getElementById("filingSummaryMainDisplay").offsetHeight;
    html2canvas(document.getElementById('filingSummaryMainDisplay'), { logging: false })
      .then(canvas => {
        let pdf = new jsPDF('L', 'mm', [width, height]);
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, width, height);
        pdf.save(filename);
      });
  }

  print(): void {
    window.print();
  }

  close() {
    this.router.navigate(['/filingMatrix']);
  }

  getMainStyle(): string[] {
    if (this.sidebarCollapsed) {
      return ['col-12', 'ml-sm-auto', 'd-print-none'];
    } else {
      return ['col-md-9', 'ml-sm-auto', 'col-lg-10', 'd-print-none'];
    }
  }

  printAllSubmittedFilings() {
    /* TODO: Build this after API is ready */
  }

}
