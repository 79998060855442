import { Component, Input, OnInit } from '@angular/core';
import { LineOfBusiness } from '../model/line-of-business';
import { WarningError } from '../model/warning-error';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-warnings-errors',
  templateUrl: './warnings-errors.component.html',
  styleUrls: ['./warnings-errors.component.css']
})
export class WarningsErrorsComponent implements OnInit {
  @Input() type: string;
  @Input() lineOfBusiness: LineOfBusiness;
  @Input() items: WarningError[];
  public title: string;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.title = `${this.lineOfBusiness.name} ${this.type.charAt(0).toUpperCase() + this.type.slice(1)}`;
  }

  print(): void {
    let printContents, popup;
    printContents = document.getElementById('print-section').innerHTML;
    popup = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popup.document.write(`
      <html>
        <head>
          <title>Print tab</title>
        </head>
    <body onload="popup.print();">${printContents}</body>
      </html>`
    );
    popup.print();
    popup.close();
  }
}
