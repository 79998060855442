import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ReviewLetter } from '../model/review-letter';
import { MOCK_REVIEW_LETTER } from '../../../mock/data/mock-review-letter';
import { Company } from '../../rules-results/model/company';

@Injectable({
  providedIn: 'root'
})
export class ReviewLetterService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    public httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('ReviewLetterService');
  }

  getReviewLetter(id: string, action: string): Observable<any> {
    let url = `${environment.apiUrl}correspondences/${id}/reviewLetter`;
    let headers = new HttpHeaders().append('Content-Type', 'application/json');
    let params = new HttpParams().set('action', action);
    let options = { headers: headers, params: params };

    return this.httpClient.get<ReviewLetter>(url, options)
      .pipe(catchError(this.handleError('getReviewLetter', null)));
  }

  postSendLetter(correspondenceId: string, letter: ReviewLetter): Observable<ReviewLetter> {
    const url = `${environment.apiUrl}correspondences/${correspondenceId}/sendLetter`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.post<ReviewLetter>(url, letter, options)
      .pipe(catchError(this.handleError('postSendLetter', null)));
  }

  putSaveLetter(correspondenceId: string, corrLetter: ReviewLetter) {
    let url = `${environment.apiUrl}correspondences/${correspondenceId}/reviewLetter`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.put(url, corrLetter, options)
      .pipe(catchError(this.handleError('putLetter', null)));

  }

  setReviewLetterFields(reviewLetter: ReviewLetter, cocode: number, companyContactName: string, shortName: string): any {
    reviewLetter.header = 'NAIC Market Conduct Annual Statement Data Validation Notification Field';
    reviewLetter.regarding = `Cocode: (${cocode}) NAIC Market Conduct Annual Statement Data Validation Notification`;
    reviewLetter.contact = `${companyContactName}` + '\n' + `${shortName}`;
    reviewLetter.statesImpacted = `Participating State Contact(s): `;
    return reviewLetter;
  }
}
