import { Component, TemplateRef } from '@angular/core';
import { faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ICellRenderer } from 'ag-grid-community';

@Component({
  selector: 'app-change-status-renderer',
  templateUrl: './change-status-renderer.component.html',
  styleUrls: ['./change-status-renderer.component.css']
})
export class ChangeStatusRendererComponent implements ICellRenderer {
  params: any;
  faTimes = faTimes;
  fas = fas;
  deleteKey: string;
  modalRef: BsModalRef;
  visible: boolean;
  changeStatus: any[];
  selectedStatus: string;
  correspondenceId: string;
  status: any;

  constructor(
    private modalService: BsModalService,
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.onlyForDraft();
    this.optionsForChangeStatus();
  }

  onlyForDraft() {
    if (this.params.data.letterProgress === 'Draft') {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  refresh(): boolean {
    return true;
  }

  optionsForChangeStatus () {
    this.selectedStatus = this.params.data.status;
    // First Correspondence
    if (this.params.data.status === '1st Correspondence') {
      this.changeStatus = [
        { name: 'Company Work in Progress', value: 'WORK_IN_PROGRESS', disabled: 'false' },
        { name: 'Resolved', value: 'RESOLVED', disabled: 'false'  },
        { name: 'State Handling', value: 'STATE_HANDLING', disabled: 'false'  },
        { name: '1st Correspondence', value: 'FIRST_CORRESPONDENCE', disabled: 'false' },
        { name: '2nd Notice', value: 'SECOND_NOTICE', disabled: 'false'  },
        { name: 'No Action Required', value: 'NO_ACTION_REQUIRED', disabled: 'false'  }
      ];
    } 
    // Second Notice
    else if (this.params.data.status === '2nd Notice') {
      this.changeStatus = [
        { name: 'Company Work in Progress', value: 'WORK_IN_PROGRESS', disabled: 'false' },
        { name: 'Resolved', value: 'RESOLVED', disabled: 'false'  },
        { name: 'State Handling', value: 'STATE_HANDLING', disabled: 'false'  },
        { name: '1st Correspondence', value: 'FIRST_CORRESPONDENCE', disabled: 'false' },
        { name: '2nd Notice', value: 'SECOND_NOTICE', disabled: 'false'  },
        { name: 'No Action Required', value: 'NO_ACTION_REQUIRED', disabled: 'false'  }
      ];
    }
    // Company Work in Progress
    else if (this.params.data.status === 'Company Work in progress') {
      this.changeStatus = [
        { name: 'Resolved', value: 'RESOLVED', disabled: 'false'  },
        { name: 'State Handling', value: 'STATE_HANDLING', disabled: 'false'  },
        { name: '1st Correspondence', value: 'FIRST_CORRESPONDENCE', disabled: 'false' },
        { name: 'Company Work in Progress', value: 'WORK_IN_PROGRESS', disabled: 'true' },
        { name: '2nd Notice', value: 'SECOND_NOTICE', disabled: 'false'  },
        { name: 'No Action Required', value: 'NO_ACTION_REQUIRED', disabled: 'false'  }
      ];
    } 
    // State Handling
    else if (this.params.data.status === 'State Handling') {
      this.changeStatus = [
        { name: 'Resolved', value: 'RESOLVED', disabled: 'false'  },
        { name: '1st Correspondence', value: 'FIRST_CORRESPONDENCE', disabled: 'true' },
        { name: 'Company Work in Progress', value: 'WORK_IN_PROGRESS', disabled: 'true' },
        { name: 'State Handling', value: 'STATE_HANDLING', disabled: 'true'  },
        { name: '2nd Notice', value: 'SECOND_NOTICE', disabled: 'true'  },
        { name: 'No Action Required', value: 'NO_ACTION_REQUIRED', disabled: 'true'  }
      ];
    } 
    // Resolved and No Action Required
    else if (this.params.data.status === 'Resolved' || this.params.data.status === 'No Action Required') {
      this.changeStatus = [
        { name: '1st Correspondence', value: 'FIRST_CORRESPONDENCE', disabled: 'true' },
        { name: 'Company Work in Progress', value: 'WORK_IN_PROGRESS', disabled: 'true' },
        { name: 'Resolved', value: 'RESOLVED', disabled: 'true'  },
        { name: 'State Handling', value: 'STATE_HANDLING', disabled: 'true'  },
        { name: '2nd Notice', value: 'SECOND_NOTICE', disabled: 'true'  },
        { name: 'No Action Required', value: 'NO_ACTION_REQUIRED', disabled: 'true'  }
      ];
    } 
  }

  getId(id: string, $event){
    this.correspondenceId = id;
    this.status = $event.target.value;
    let statusId = this.status.split(/\s+/).pop();
    let letterStatus: any = {
      letterStatus: statusId
    };
    this.params.context.componentParent.changeLetterStatus(this.correspondenceId, letterStatus);
  }

}
