import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MessageService } from '../../messages/service/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReviewLetter } from './model/review-letter';
import { ReviewLetterService } from './service/review-letter.service';
import { takeUntil, take } from 'rxjs/operators';
import { User } from '../../shared/model/user';
import { McsUserService } from '../../service/mcs-user.service';
import { RulesResults } from '../rules-results/model/rules-results';
import { RulesResultsService } from '../rules-results/service/rules-results.service';
import { AlertMessage } from '../../messages/model/alert-message';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { PreviewLetterComponent } from '../preview-letter/preview-letter.component';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-review-letter',
  templateUrl: './review-letter.component.html',
  styleUrls: ['./review-letter.component.css']
})
export class ReviewLetterComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();
  modalSubscriptions: Subscription[];
  bsModalRef: BsModalRef;
  title: string;
  loading = false;
  reviewLetter: ReviewLetter;
  cocode: number;
  shortName: string;
  year: number;
  correspondenceId: string;
  companyContactName: string;
  lob: string;
  lobDesc: string;
  user: User;
  rulesResults: RulesResults;
  rules: any[] = [];
  results: any[] = [];
  letterStatus: any[];
  id: string;
  actionsViewQuickYear: number;
  actionsViewQuickCocode: number;
  actionsViewQuickFlag: boolean;
  actionsViewQuickLob: string;
  company: any;
  actionsViewQuickCompanyContact: string;
  actionsViewQuickStatus: string;
  actionsViewQuickcompanyName: string;
  disableSendButton: boolean;
  action: string;
  currentLetterStatus: string = '1st Correspondence';
  disableSend: boolean = false;
  initialLetterStatus: string;
  selectedLetterStatus: any;


  constructor(
    private userService: McsUserService,
    private reviewLetterService: ReviewLetterService,
    private rulesResultsService: RulesResultsService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    public dialog: MatDialog
  ) {
    this.modalSubscriptions = [];
  }

  ngOnInit() {
    this.title = `Review Details`;
    this.correspondenceId = this.route.snapshot.paramMap.get('correspondenceId');
    this.year = +this.route.snapshot.paramMap.get('year');
    this.cocode = +this.route.snapshot.paramMap.get('cocode');
    this.shortName = this.route.snapshot.paramMap.get('companyName');
    this.lob = this.route.snapshot.paramMap.get('lob');
    this.lobDesc = this.route.snapshot.paramMap.get('lobDesc');
    this.companyContactName = this.route.snapshot.paramMap.get('contact');
    this.action = 'edit';
    this.getDataFromDashboard();
    this.setReviewLetter();
  }

  ngOnDestroy(): void {
    this.messageService.clear();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getDataFromDashboard() {
    this.id = this.route.snapshot.paramMap.get('id'); //Correspondence ID from dashboard
    this.actionsViewQuickYear = +this.route.snapshot.paramMap.get('actionsViewQuickYear');
    this.actionsViewQuickCocode = +this.route.snapshot.paramMap.get('actionsViewQuickCocode');
    this.actionsViewQuickFlag = this.route.snapshot.paramMap.has('actionsViewQuickFlag');
    this.actionsViewQuickLob = this.route.snapshot.paramMap.get('actionsViewQuickLob');
    this.actionsViewQuickCompanyContact = this.route.snapshot.paramMap.get('actionsViewQuickCompanyContact');
    this.actionsViewQuickStatus = this.route.snapshot.paramMap.get('actionsViewQuickStatus');
    this.actionsViewQuickcompanyName = this.route.snapshot.paramMap.get('actionsViewQuickcompanyName');
    if (this.actionsViewQuickFlag === true) {
      this.action = 'view';
      this.correspondenceId = this.id;
      this.year = this.actionsViewQuickYear;
      this.cocode = this.actionsViewQuickCocode;
      this.lobDesc = this.actionsViewQuickLob;
      this.companyContactName = this.actionsViewQuickCompanyContact;
      this.shortName = this.actionsViewQuickcompanyName;
    }
  }

  letterStatusOptions() {
    this.letterStatus = [
      { letterStatusCode: 'FIRST_CORRESPONDENCE', letterStatusName: '1st Correspondence', disabled: 'false' },
      { letterStatusCode: 'SECOND_NOTICE', letterStatusName: '2nd Notice', disabled: 'false' },
      { letterStatusCode: 'NO_ACTION_REQUIRED', letterStatusName: 'No Action Required', disabled: 'false' },
      { letterStatusCode: 'WORK_IN_PROGRESS', letterStatusName: 'Company Work in Progress', disabled: 'true' },
      { letterStatusCode: 'STATE_HANDLING', letterStatusName: 'State Handling', disabled: 'true' },
      { letterStatusCode: 'RESOLVED', letterStatusName: 'Resolved', disabled: 'true' }
    ];

    if (this.reviewLetter.letterStatus !== '') {
      this.currentLetterStatus = this.letterStatus.find(letterStatus => letterStatus.letterStatusCode === this.reviewLetter.letterStatus).letterStatusName;
    }

    if (this.currentLetterStatus === 'No Action Required' || this.currentLetterStatus === 'Company Work in Progress' || this.currentLetterStatus === 'State Handling' || this.currentLetterStatus === 'Resolved') {
      this.disableSend = true;
    } else {
      this.disableSend = false;
    }
  }

  setLetterStatus() {
    if (this.selectedLetterStatus !== undefined) {
      this.reviewLetter.letterStatus = this.selectedLetterStatus;
    } else {
      this.reviewLetter.letterStatus = this.letterStatus.find(letterStatus => letterStatus.letterStatusName === this.currentLetterStatus).letterStatusCode;
    }

    if (this.selectedLetterStatus === 'NO_ACTION_REQUIRED') {
      this.disableSend = true;
    } else {
      this.disableSend = false;
    }
  }

  setReviewLetter() {
    this.loading = true;
    this.reviewLetterService.getReviewLetter(this.correspondenceId, this.action)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        reviewLetter => {
          this.reviewLetter = reviewLetter;
          this.initialLetterStatus = this.reviewLetter.letterStatus;
          this.letterStatusOptions();
          this.disableSendButton = this.reviewLetter.warnings.includes('No Failed Validations found.');
          this.loading = false;
        }
      );
  }

  sendLetter() {
    this.loading = true;
    if (this.actionsViewQuickFlag === true) {
      this.reviewLetter.letterStatus = this.letterStatus.find(letterStatus => letterStatus.letterStatusName === this.actionsViewQuickStatus).letterStatusCode;
    }
    this.reviewLetterService.postSendLetter(this.correspondenceId, this.reviewLetter)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.letterStatusOptions();
          this.initialLetterStatus = this.reviewLetter.letterStatus;
            this.messageService.clear();
            this.messageService.add(new AlertMessage('Letter Sent', 'success', true, 0));
          this.loading = false;
        }, (error: HttpErrorResponse) => {
            this.messageService.clear();
            this.messageService.add(new AlertMessage('Error Sending Letter', 'danger', true, 0));
          this.loading = false;
        }
      );
  }

  saveChanges() {
    if (this.selectedLetterStatus === 'FIRST_CORRESPONDENCE' || this.selectedLetterStatus === 'SECOND_NOTICE') {
      this.statusCheck();
    } else {
      this.loading = true;
      this.reviewLetterService.putSaveLetter(this.correspondenceId, this.reviewLetter)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          () => {
          this.letterStatusOptions();
          this.initialLetterStatus = this.reviewLetter.letterStatus;
            this.messageService.clear();
            this.messageService.add(new AlertMessage('Letter Saved', 'success', true, 0));
            this.loading = false;
          }, (error: HttpErrorResponse) => {
            this.messageService.clear();
            this.messageService.add(new AlertMessage('Error Saving Letter', 'danger', true, 0));
            this.loading = false;
          }
        );
    }
  }

  saveClose() {
    if (this.selectedLetterStatus === 'FIRST_CORRESPONDENCE' || this.selectedLetterStatus === 'SECOND_NOTICE') {
      this.statusCheck();
    } else {
      this.loading = true;
      this.reviewLetterService.putSaveLetter(this.correspondenceId, this.reviewLetter)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          () => {
            this.messageService.clear();
            this.messageService.add(new AlertMessage('Letter Saved', 'success', true, 0));
            this.router.navigate(['/correspondence']);
            this.loading = false;
          }, (error: HttpErrorResponse) => {
            this.messageService.clear();
            this.messageService.add(new AlertMessage('Error Saving Letter', 'danger', true, 0));
            this.loading = false;
          }
        );
    }
    // this.currentLetterStatus === 'No Action Required';
  }

  statusCheck() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: `Selected Letter Status is ${this.letterStatus.find(letterStatus => letterStatus.letterStatusCode === this.reviewLetter.letterStatus).letterStatusName}, Letter must be sent to retain the Status`
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reviewLetter.letterStatus = this.initialLetterStatus;
        this.selectedLetterStatus = undefined;
        if(this.reviewLetter.letterStatus === 'NO_ACTION_REQUIRED') {
          this.disableSend = true;
        } else {
          this.disableSend = false;
        }
  }
    });
  }

  previous() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: 'Any Changes Will be Lost. Do you wish to continue?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/rulesResults', {
          reviewPageCorrespondenceId: this.correspondenceId,
          reviewPageFlag: true,
          company: this.cocode,
          year: this.year,
          lob: this.lob,
          lobDesc: this.lobDesc
        }], { skipLocationChange: true });
      }
    });
  }

  public previewLetter(): void {
    if (this.actionsViewQuickFlag === true) {
      this.reviewLetter.letterStatus = this.letterStatus.find(letterStatus => letterStatus.letterStatusName === this.actionsViewQuickStatus).letterStatusCode;
    }
    this.messageService.clear();
    const initialState = {
      cocode: this.cocode,
      year: this.year,
      reviewLetter: this.reviewLetter
    };
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      }));
    this.bsModalRef = this.modalService.show(PreviewLetterComponent, { initialState: initialState, class: 'modal-lg' });
  }

  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }
}
