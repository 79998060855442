<ngx-loading [show]="loading"></ngx-loading>
<form *ngIf="actionsViewQuickFlag === false; else nonEditableForm" name="corrLetterForm" #corrLetterForm="ngForm">
  <div class="row">

    <nav class="col-md-2 d-none d-md-block bg-light sidebar d-print-none">
      <div class="sidebar-sticky">
        <div id="reviewActions" class="pt-3 pb-2 mb-3 px-4 border-bottom d-print-none">
          <h4 class="text-center">Actions</h4>
        </div>
        <div id="actions" [collapse]="false">
          <div class="pb-2">
            <button id="btnPrevious" type="button" class="btn btn-primary btn-sm btn-block mr-2" (click)="previous()">
              Previous
            </button>
          </div>
          <div class="pb-2">
            <button id="btnSaveChanges" type="button" class="btn btn-primary btn-sm btn-block mr-2" (click)="saveChanges()" [disabled]="!corrLetterForm.valid">
              Save Changes
            </button>
          </div>
          <div class="pb-2">
            <button id="btnSaveClose" type="button" class="btn btn-primary btn-sm btn-block mr-2" (click)="saveClose()" [disabled]="!corrLetterForm.valid">
              Save & Close
            </button>
          </div>
          <div class="pb-2">
            <button id="btnViewPdf" type="button" class="btn btn-primary btn-sm btn-block mr-2" (click)="previewLetter()">
              Preview Letter
            </button>
          </div>
          <div class="pb-2">
            <button id="btnSendLetter" type="button" class="btn btn-primary btn-sm btn-block mr-2"  (click)="sendLetter()" [disabled]="!corrLetterForm.valid || disableSendButton || disableSend">
              Send Letter
            </button>
          </div>
        </div>
      </div>
    </nav>

    <main *ngIf="!loading" role="main" class="col-md-10 ml-sm-auto d-print-none">
      <app-messages [parentName]="'correspondence'"></app-messages>
      <div class="pt-3 pb-3 main-content d-print-block">
        <div class="row">
          <div class="col-12">
            <h3>
              {{title}}
              <hr>
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <h4 class="font-weight-bold mb-0">Company</h4>
          </div>
          <div class="col-1">
            <h4 class="font-weight-bold mb-0">Year</h4>
          </div>
          <div class="col-4">
            <h4 class="font-weight-bold mb-0">Contact</h4>
          </div>
          <div class="col-3">
            <h4 class="font-weight-bold mb-0">Line of Business</h4>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            {{cocode + ' - ' + shortName}}
          </div>
          <div class="col-1">
            {{year}}
          </div>
          <div class="col-4">
            {{companyContactName}}
          </div>
          <div class="col-3">
            {{lobDesc}}
          </div>
        </div>

        <div class="row pt-4">
          <div class="col-2"><label id="lblSubjectLine" for="inputSubjectLine" class="font-weight-bold float-right" style="text-align:right">Current Letter Status:</label></div>
          <div class="col-md-10 pb-2">
            {{currentLetterStatus}}
          </div>
        </div>
        <div class="row">
        <div class="col-2"><label id="lblLetterStatus" for="optionLetterStatus" class="font-weight-bold float-right" style="text-align:right">Set Letter Status:</label></div>
          <div class="col-md-10 pb-2">
            <select id="optionLetterStatus" name="letterStatus" class="col-md-3" (change)="setLetterStatus()" [(ngModel)]="selectedLetterStatus">
              <option value="undefined" disabled hidden>Choose One</option>
              <option *ngFor="let status of letterStatus" [ngValue]="status.letterStatusCode" [disabled]="status.disabled === 'true'">{{status.letterStatusName}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label id="lblSubjectLine" for="inputSubjectLine" class="font-weight-bold float-right" style="text-align:right">Email Subject Line:</label></div>
          <div class="col-md-10 pb-2">
            <input id="inputSubjectLine" name="subjectLine" class="col-md-10" [(ngModel)]="reviewLetter.subjectLine"/>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label id="lblHeader" for="inputHeader" class="font-weight-bold float-right">Header:</label></div>
          <div class="col-md-10 pb-2">
            <input id="inputHeader" name="header" class="col-md-10" [(ngModel)]="reviewLetter.header"/>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label id="lblRegarding" for="inputRegarding" class="font-weight-bold float-right">RE:</label></div>
          <div class="col-md-10 pb-2">
            <input id="inputRegarding" name="regarding" class="col-md-10" [(ngModel)]="reviewLetter.regarding"/>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label id="lblLetterContact" for="txtLetterContact" class="font-weight-bold float-right">Contact:</label></div>
          <div class="col-md-10">
            <textarea id="txtLetterContact" name="contact" class="col-md-10" [(ngModel)]="reviewLetter.contact" #letterContact="ngModel" style="height: 50px;">{{reviewLetter.contact}}</textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label id="lblLetterBody" for="txtLetterBody" class="font-weight-bold float-right">Letter:</label></div>
          <div class="col-md-10">
            <textarea id="txtLetterBody" name="letterBody" class="col-md-10" [(ngModel)]="reviewLetter.letterBody" #letterBody="ngModel" style="height: 400px;">{{reviewLetter.letterBody}}</textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label id="lblSignature" for="txtSignature" class="font-weight-bold float-right">Signature:</label></div>
          <div class="col-md-10">
            <textarea id="txtSignature" name="signature" class="col-md-10" [(ngModel)]="reviewLetter.signature" #signature="ngModel" style="height: 155px;"> {{reviewLetter.signature}}</textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label id="lblStatesImpacted" for="txtStatesImpacted" class="font-weight-bold float-right" style="text-align:right">States Impacted:</label></div>
          <div class="col-md-10">
            <textarea id="txtStatesImpacted" name="statesImpacted" class="col-md-10" [(ngModel)]="reviewLetter.statesImpacted" #statesImpacted="ngModel" style="height: 45px;">{{reviewLetter.statesImpacted}}</textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label id="lblWarnings" for="txtWarnings" class="font-weight-bold float-right">Warnings:</label></div>
          <div class="col-md-10">
            <textarea id="txtWarnings" name="warnings" class="col-md-10" [(ngModel)]="reviewLetter.warnings" #warnings="ngModel" style="height: 300px;" >{{reviewLetter.warnings}}</textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-2"><label id="lblAddlComments" for="txtAddlComments" class="font-weight-bold float-right" style="text-align:right">Additional Comments:</label></div>
          <div class="col-md-10">
            <textarea id="txtAddlComments" name="addtlComments" class="col-md-10" [(ngModel)]="reviewLetter.additionalComments" #addtlComments="ngModel" style="height: 100px;"> {{reviewLetter.additionalComments}}</textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <footer class="col-12 footer d-print-none">
          <ncui-footer></ncui-footer>
        </footer>
      </div>
    </main>
  </div>
</form>

<ng-template #nonEditableForm>
  <form name="corrLetterForm" #corrLetterForm="ngForm">
    <div class="row">
  
      <nav class="col-md-2 d-none d-md-block bg-light sidebar d-print-none">
        <div class="sidebar-sticky">
          <div id="reviewActions" class="pt-3 pb-2 mb-3 px-4 border-bottom d-print-none">
            <h4 class="text-center">Actions</h4>
          </div>
          <div id="actions" [collapse]="false">
            <div class="pb-2">
              <button id="btnPrevious" type="button" class="btn btn-primary btn-sm btn-block mr-2" [disabled]="true" (click)="previous()">
                Previous
              </button>
            </div>
            <div class="pb-2">
              <button id="btnSaveChanges" type="button" class="btn btn-primary btn-sm btn-block mr-2" (click)="saveChanges()" [disabled]="actionsViewQuickFlag">
                Save Changes
              </button>
            </div>
            <div class="pb-2">
              <button id="btnSaveClose" type="button" class="btn btn-primary btn-sm btn-block mr-2" (click)="saveClose()" [disabled]="actionsViewQuickFlag">
                Save & Close
              </button>
            </div>
            <div class="pb-2">
              <button id="btnViewPdf" type="button" class="btn btn-primary btn-sm btn-block mr-2" (click)="previewLetter()">
                View Letter
              </button>
            </div>
            <div class="pb-2">
              <button id="btnSendLetter" type="button" class="btn btn-primary btn-sm btn-block mr-2"  (click)="sendLetter()">
                Send Letter
              </button>
            </div>
          </div>
        </div>
      </nav>
  
      <main *ngIf="!loading" role="main" class="col-md-10 ml-sm-auto d-print-none">
        <app-messages [parentName]="'correspondence'"></app-messages>
        <div class="pt-3 pb-3 main-content d-print-block">
          <div class="row">
            <div class="col-12">
              <h3>
                {{title}}
                <hr>
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <h4 class="font-weight-bold mb-0">Company</h4>
            </div>
            <div class="col-1">
              <h4 class="font-weight-bold mb-0">Year</h4>
            </div>
            <div class="col-4">
              <h4 class="font-weight-bold mb-0">Contact</h4>
            </div>
            <div class="col-3">
              <h4 class="font-weight-bold mb-0">Line of Business</h4>
            </div>
          </div>
  
          <div class="row">
            <div class="col-4">
              {{cocode + ' - ' + shortName}}
            </div>
            <div class="col-1">
              {{year}}
            </div>
            <div class="col-4">
              {{companyContactName}}
            </div>
            <div class="col-3">
              {{lobDesc}}
            </div>
          </div>

          <div class="row pt-4">
            <div class="col-2"><label id="lblSubjectLine" for="inputSubjectLine" class="font-weight-bold float-right" style="text-align:right">Letter Status:</label></div>
            <div class="col-md-10 pb-2">
              {{actionsViewQuickStatus}}
            </div>
          </div>
          <div class="row">
          <div class="col-2"><label id="lblLetterStatus" for="optionLetterStatus" class="font-weight-bold float-right" style="text-align:right">Set Letter Status:</label></div>
            <div class="col-md-10 pb-2">
              <select disabled id="optionLetterStatus" name="letterStatus" class="col-md-3" (change)="setLetterStatus()" [(ngModel)]="reviewLetter.letterStatus" required>
                <option value="null" disabled hidden> </option>
                <option *ngFor="let status of letterStatus" [ngValue]="status.letterStatusCode" [selected]="selectedStatus" [disabled]="status.disabled === 'true'">{{status.letterStatusName}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-2"><label id="lblSubjectLine" for="inputSubjectLine" class="font-weight-bold float-right" style="text-align:right">Email Subject Line:</label></div>
            <div class="col-md-10 pb-2">
              <input readonly onfocus="this.blur()" id="inputSubjectLine" name="subjectLine" class="col-md-10 form-control form-control-sm" [(ngModel)]="reviewLetter.subjectLine"/>
            </div>
          </div>
          <div class="row">
            <div class="col-2"><label id="lblHeader" for="inputHeader" class="font-weight-bold float-right">Header:</label></div>
            <div class="col-md-10 pb-2">
              <input readonly onfocus="this.blur()" id="inputHeader" name="header" class="col-md-10 form-control form-control-sm" [(ngModel)]="reviewLetter.header"/>
            </div>
          </div>
          <div class="row">
            <div class="col-2"><label id="lblRegarding" for="inputRegarding" class="font-weight-bold float-right">RE:</label></div>
            <div class="col-md-10 pb-2">
              <input readonly onfocus="this.blur()" id="inputRegarding" name="regarding" class="col-md-10 form-control form-control-sm" [(ngModel)]="reviewLetter.regarding"/>
            </div>
          </div>
          <div class="row">
            <div class="col-2"><label id="lblLetterContact" for="txtLetterContact" class="font-weight-bold float-right">Contact:</label></div>
            <div class="col-md-10">
              <textarea readonly onfocus="this.blur()" id="txtLetterContact" name="contact" class="col-md-10" [(ngModel)]="reviewLetter.contact" style="height: 50px;"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-2"><label id="lblLetterBody" for="txtLetterBody" class="font-weight-bold float-right">Letter:</label></div>
            <div class="col-md-10">
              <textarea readonly onfocus="this.blur()" id="txtLetterBody" name="letterBody" class="col-md-10" [(ngModel)]="reviewLetter.letterBody" style="height: 400px;"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-2"><label id="lblSignature" for="txtSignature" class="font-weight-bold float-right">Signature:</label></div>
            <div class="col-md-10">
              <textarea readonly onfocus="this.blur()" id="txtSignature" name="signature" class="col-md-10" [(ngModel)]="reviewLetter.signature" style="height: 155px;"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-2"><label id="lblStatesImpacted" for="txtStatesImpacted" class="font-weight-bold float-right" style="text-align:right">States Impacted:</label></div>
            <div class="col-md-10">
              <textarea readonly onfocus="this.blur()" id="txtStatesImpacted" name="statesImpacted" class="col-md-10" [(ngModel)]="reviewLetter.statesImpacted" style="height: 45px;"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-2"><label id="lblWarnings" for="txtWarnings" class="font-weight-bold float-right">Warnings:</label></div>
            <div class="col-md-10">
              <textarea readonly onfocus="this.blur()" id="txtWarnings" name="warnings" class="col-md-10" [(ngModel)]="reviewLetter.warnings" style="height: 300px;" ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-2"><label id="lblAddlComments" for="txtAddlComments" class="font-weight-bold float-right" style="text-align:right">Additional Comments:</label></div>
            <div class="col-md-10">
              <textarea readonly onfocus="this.blur()" id="txtAddlComments" name="addtlComments" class="col-md-10" [(ngModel)]="reviewLetter.additionalComments" style="height: 100px;"></textarea>
            </div>
          </div>
        </div>
  
        <div class="row">
          <footer class="col-12 footer d-print-none">
            <ncui-footer></ncui-footer>
          </footer>
        </div>
      </main>
    </div>
  </form>
</ng-template>
