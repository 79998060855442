import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpDeskRoutingModule } from './help-desk-routing.module';
import { HelpDeskComponent } from './help-desk.component';
import { HelpDeskService } from './service/help-desk.service';
import { FormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MessagesModule } from '../messages/messages.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NcuiModule } from 'common-ui-components';
import { LoadingModule } from 'ngx-loading';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    CollapseModule.forRoot(),
    MessagesModule,
    LoadingModule,
    NgxDatatableModule,
    NcuiModule,
    HelpDeskRoutingModule
  ],
  declarations: [HelpDeskComponent],
  providers: [
    HelpDeskService
  ]
})
export class HelpDeskModule { }
