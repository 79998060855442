import { StateFiling } from './state-filing';

export class FilteredFiling {
  stateFiling: StateFiling;
  matrixExpanded: boolean;
  visible: boolean;

  constructor(stateFiling: StateFiling, matrixExpanded: boolean, visible: boolean) {
    this.stateFiling = stateFiling;
    this.matrixExpanded = matrixExpanded;
    this.visible = visible;
  }
}
