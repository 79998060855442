import { Injectable } from '@angular/core';
import { Company } from '../../shared/model/company';
import { Year } from '../../shared/model/year';
import { McasState } from '../model/mcas-state';
import { LineOfBusiness } from '../model/line-of-business';
import * as _ from 'lodash';

@Injectable()
export class DataService {
  company: Company;
  year: Year;
  state: McasState;
  lineOfBusiness: LineOfBusiness;
  private allPanelsActive = false;
  private activePanels: string[];
  private expansionIcon = 'fa fa-plus';

  constructor() {
    this.activePanels = [];
  }

  getActivePanels(): string[] {
    return this.activePanels;
  }

  setActivePanels(activePanels: string[]): void {
    if (this.activePanels.length > 0) {
      this.activePanels.length = 0;
    }
    this.activePanels = _.cloneDeep(activePanels);
  }

  addActivePanel(activePanel: string): void {
    this.activePanels.push(activePanel);
  }

  isAllPanelsActive(): boolean {
    return this.allPanelsActive;
  }

  setAllPanelsActive(allPanelsActive: boolean): void {
    this.allPanelsActive = allPanelsActive;
  }

  getExpansionIcon(): string {
    return this.expansionIcon;
  }

  private setExpansionIcon(): void {
    this.expansionIcon = this.isAllPanelsActive() ? 'fa fa-minus' : 'fa fa-plus';

  }

  getExpansionText(): string {
    return this.isAllPanelsActive() ? 'Collapse All' : 'Expand All';
  }

  setExpansion(): void {
    this.setExpansionIcon();
  }
}
