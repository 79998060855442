import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Extension} from '../../model/extension';
import {Company} from '../../../shared/model/company';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MessageService} from '../../../messages/service/message.service';
import {WaiverExtensionService} from '../services/waiver-extension.service';
import {McsUserService} from '../../../service/mcs-user.service';
import * as moment from 'moment/moment';
import {AlertMessage} from '../../../messages/model/alert-message';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import {ExtensionModalComponent} from '../extension-modal/extension-modal.component';
import {Observable, Subscription} from 'rxjs';
import {Year} from '../../../shared/model/year';
import * as _ from 'lodash';

@Component({
  selector: 'app-multiple-extensions-modal',
  templateUrl: './multiple-extensions-modal.component.html',
  styleUrls: ['./multiple-extensions-modal.component.css']
})
export class MultipleExtensionsModalComponent implements OnInit, OnDestroy {
  modalSubscriptions: Subscription[];
  loading: boolean;
  title: string;
  @Input() extension: Extension;
  @Input() company: Company;
  @Input() year: Year;
  @Input() state: any;
  @Input() lob: any;
  updated = false;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  dpValue: any;
  faCalendar = faCalendar;
  waiverExtension: Extension[];
  waiverExtensionSort: Extension[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    public messageService: MessageService,
    private service: WaiverExtensionService,
    private userService: McsUserService,
    private modalService: BsModalService,
    private waiverExtensionService: WaiverExtensionService
  ) {
    this.dpConfig.containerClass = 'theme-dark-blue';
    this.dpConfig.showWeekNumbers = false;
    this.dpConfig.minDate = moment().toDate();
  }

  ngOnInit() {
    this.getMultipleExtensions();

    this.service.setBsModalRef(this.bsModalRef);
    this.title = `Extension Request`;
    this.dpValue = moment(this.extension.endDate, 'YYYY-MM-DD').toDate();
    if (this.extension.lineOfBusiness.dueDate) {
      this.dpConfig.maxDate = moment(this.extension.lineOfBusiness.dueDate).add(60, 'days').toDate();
    } else {
      this.dpConfig.maxDate = moment().add(60, 'days').toDate();
    }
  }

  ngOnDestroy() {
  }

  onDelete(): void {
    this.service.deleteExtension(this.company.cocode, this.extension)
      .subscribe(
        () => {
          this.updated = true;
          const message = `Extension for ${this.company.longName} has been deleted for ${this.extension.state.stabbr}`;
          this.messageService.add(new AlertMessage(message, 'success', true, 0));
          this.bsModalRef.hide();
        }
      );
  }


  onUpdate(): void {
    this.extension.endDate = moment(this.dpValue).format('YYYY-MM-DD');
    this.service.putExtension(this.company.cocode, this.extension)
      .subscribe(
        response => {
          if (response) {
            this.updated = true;
            const message = `Extension for ${this.company.longName} has been updated for ${this.extension.state.stabbr}`;
            this.messageService.add(new AlertMessage(message, 'success', true, 0));
            this.bsModalRef.hide();
          }
        });
    this.bsModalRef.hide();
  }

  isUpdateDisabled(): boolean {
    return this.userService.isInternalUser() || (this.extension.status.toLowerCase() === 'approved' || this.extension.status.toLowerCase() === 'denied');
  }

  public openExtension(extension: Extension): void {
    this.messageService.clear();
    const initialState = {
      extension: extension,
      company: this.company
    };
    this.bsModalRef = this.modalService.show(ExtensionModalComponent, { initialState: initialState, class: 'modal-lg' });
  }



  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }


  public getMultipleExtensions(): void {

    this.waiverExtensionService.getMultipleExtensions(this.company.cocode, this.year)
      .subscribe(
        waiverExtension => {
          if (!_.isNull(waiverExtension)) {
            this.waiverExtension = waiverExtension;
            this.waiverExtension = this.waiverExtension.filter(extension => {
              return extension.state.stabbr == this.state.stabbr && extension.lineOfBusiness.name == this.lob.name;
            })
          } else {
            this.loading = false;
          }
        }
      );
  }

  setBsModalRef(bsModalRef) {
    this.bsModalRef = bsModalRef;
  }

  getBsModalRef() {
    return this.bsModalRef;
  }
  close() {
    let bsModalRef: BsModalRef = this.service.getBsModalRef();
    bsModalRef.hide();
  }
}
