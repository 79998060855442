/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageService } from '../messages/service/message.service';
import { environment } from '../../environments/environment';
import { mcsMockBackend } from '../mock/mcs-mock-backend';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private messageService: MessageService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.mock) {
      const url: string = request.url;
      const method: string = request.method;

      return mcsMockBackend(url, method, request) || next.handle(request);
    } else {
      return next.handle(request)
        .pipe(tap(() => { //Success
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              window.open(environment.auth.redirectBaseUrl, '_self');
            }
          } else {
            console.error('error in request.interceptor');
          }
        }));
    }
  }
}
