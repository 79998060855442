import { Component, OnInit, OnDestroy, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { faPlus, faMinus, faLockOpen, faLock, faCircle, faAngleDoubleLeft, faAngleDoubleRight, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { FilingWizardService } from './service/filing-wizard.service';
import { takeUntil } from 'rxjs/operators';
import { FilingBlank } from './model/filing-blank';
import { NavigationItem } from './model/navigation-item';
import { CompanyService } from '../company-year-select/services/company.service';
import { McsUserService } from '../../service/mcs-user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MessageService } from '../../messages/service/message.service';
import { AlertMessage } from '../../messages/model/alert-message';
import { DataService } from '../data/data.service';
import { FormGroup } from '@angular/forms';
import { User } from '../../shared/model/user';
import { FilingData } from './model/filing-data';
import { DialogService } from '../../service/dialog.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';

@Component({
  selector: 'app-filing-wizard',
  templateUrl: './filing-wizard.component.html',
  styleUrls: ['./filing-wizard.component.css']
})
export class FilingWizardComponent implements OnInit, OnDestroy {
  year: number;
  company: string;
  cocode: string;
  state: string;
  stateName: string;
  lobName: string;
  lobCode: string;
  status: string;
  title: string;
  faPlus = faPlus;
  faMinus = faMinus;
  faLockOpen = faLockOpen;
  faLock = faLock;
  faUnlock = faUnlock;
  faCircle = faCircle;
  faDoubleLeft = faAngleDoubleLeft;
  faDoubleRight = faAngleDoubleRight;
  isActionsCollapsed = false;
  isPrintOptionsCollapsed = false;
  isFileNavigationCollapsed = false;
  private unsubscribe: Subject<void> = new Subject();
  currentYearUrl: string;
  previousYearUrl: string;
  loading = false;
  filingBlank: FilingBlank;
  navigationList: NavigationItem[] = [];
  modalRef: BsModalRef;
  filingData: FilingData;
  warningsComment: string;
  form: FormGroup;
  group: any = {};
  user: User;
  isValidated = false;
  sidebarCollapsed = false;
  backdropConfig = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  isCurrentYear: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private filingWizardService: FilingWizardService,
    private companyService: CompanyService,
    private userService: McsUserService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private dataService: DataService,
    public dialogService: DialogService,
    private changeDetectionRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.year = +this.route.snapshot.paramMap.get('year');
    this.company = this.route.snapshot.paramMap.get('company');
    this.cocode = this.route.snapshot.paramMap.get('cocode');
    this.state = this.route.snapshot.paramMap.get('state');
    this.stateName = this.route.snapshot.paramMap.get('stateName');
    this.lobName = this.route.snapshot.paramMap.get('lobName');
    this.lobCode = this.route.snapshot.paramMap.get('lobCode');
    this.status = this.route.snapshot.paramMap.get('status');
    this.user = this.userService.getStoredUser();
    this.currentYearUrl = `${environment.isitePDFUrl}cocode=${this.cocode}&year=${this.year}&stateName=${this.stateName}&lob=${this.lobCode}&stateAbbr=${this.state}&companyName=${this.company.replace(' ', '+')}`;
    this.previousYearUrl = `${environment.isitePDFUrl}cocode=${this.cocode}&year=${this.year - 1}&stateName=${this.stateName}&lob=${this.lobCode}&stateAbbr=${this.state}&companyName=${this.company.replace(' ', '+')}`;
    this.getNavigation();
    this.isCurrentYear = new Date().getFullYear() === this.year;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getNavigation() {
    this.loading = true;
    this.filingWizardService.getFilingNavigation(this.lobCode, this.year, this.cocode, this.state)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        navItems => {
          this.navigationList = navItems.navigationItems;
          this.navigationList.push({ name: 'Summary', target: '', subNav: [] });
          this.filingWizardService.activeSection = this.navigationList[0].target;
          this.filingWizardService.lastTarget = this.setLastTarget(this.navigationList);
          this.getFilingBlank(this.navigationList[0].target);
        },
        error => {
          this.loading = false;
        }
      );
  }

  // FIXME:: Make this work
  private getFirstTarget(): string {
    this.navigationList.forEach(item => {
      if (item.target && item.target.length > 0) {
        return item.target;
      }
    });
    return null;
  }

  private setLastTarget(navList: NavigationItem[]): string {
    let lastTarget = '';
    navList.forEach(item => {
      if (item.target && item.target.length > 0) {
        lastTarget = item.target;
      } else if (item.subNav.length > 0) {
        lastTarget = this.setLastTarget(item.subNav);
      }
    });
    return lastTarget;
  }

  getLastTarget(): string {
    return this.filingWizardService.lastTarget;
  }

  getActiveSection(): string {
    return this.filingWizardService.activeSection;
  }

  getFilingBlank(target: string) {
    this.messageService.clear();
    window.scroll(0, 0);
    this.loading = true;
    if (target === 'summary') {
      target = '';
    }
    this.filingWizardService.getFilingBlank(this.lobCode, this.year, this.cocode, this.state, target)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        filingBlank => {
          this.filingBlank = filingBlank;
          this.setTitle();
          this.filingWizardService.activeSection = target;
          this.showStaticMessages();
          this.loading = false;
        }, error => {
          this.loading = false;
        }
      );
  }

  isSummary(): boolean {
    return this.filingWizardService.activeSection.toLowerCase() === '';
  }

  isProcessing(): boolean {
    return this.status === 'PROCESSING';
  }

  showStaticMessages(): void {
    if (!this.isValidated && this.isSummary()) {
      this.messageService.add(new AlertMessage('Please press Save and Validate prior to submitting your filing', 'info', false, 0, 'filingWizard'));
    }
  }

  isInternal(): boolean {
    return this.userService.isInternalUser();
  }

  isDataChanged(): boolean {
    return this.filingWizardService.dataChanged;
  }

  goToTarget(nextBlank?: string) {
    this.isValidated = false;
    this.messageService.clear();
    this.showStaticMessages();
    let targetBlank = nextBlank || this.filingWizardService.activeSection;
    if (targetBlank === 'summary') {
      targetBlank = '';
    }
    this.loading = true;
    this.filingData = this.prepareFilingData(targetBlank);
    window.scroll(0, 0);
    this.filingWizardService.putFilingData(this.filingData, targetBlank)
      .subscribe(
        filingBlank => {
          this.filingWizardService.dataChanged = false;
          if (filingBlank && filingBlank !== null) {
            this.filingBlank = filingBlank;
            this.filingWizardService.activeSection = targetBlank;
          }
          this.loading = false;
        }
      );
  }

  onSave(nextBlank?: string) {
    this.isValidated = false;
    this.messageService.clear();
    let targetBlank = nextBlank || this.filingWizardService.activeSection;
    if (targetBlank === 'summary') {
      targetBlank = '';
    }
    this.loading = true;
    this.filingData = this.prepareFilingData(targetBlank);
    window.scroll(0, 0);
    this.filingWizardService.putFilingData(this.filingData, targetBlank)
      .subscribe(
        filingBlank => {
          this.filingWizardService.dataChanged = false;
          if (filingBlank && filingBlank !== null) {
            this.filingBlank = filingBlank;
            this.filingWizardService.activeSection = targetBlank;
            this.showStaticMessages();
            this.messageService.add(new AlertMessage('Filing saved', 'success', true, 10000, 'filingWizard'));
          }
          this.loading = false;
        }
      );
  }

  onValidate(nextBlank?: string) {
    this.messageService.clear();
    this.filingWizardService.sectionErrors = [];
    this.filingWizardService.sectionWarnings = [];
    let targetBlank = nextBlank || this.filingWizardService.activeSection;
    if (targetBlank === 'summary') {
      targetBlank = '';
    }
    this.loading = true;
    this.filingData = this.prepareFilingData();
    window.scroll(0, 0);
    this.filingWizardService.validateFilingData(this.filingData, this.lobCode, this.year, targetBlank)
      .subscribe(
        filingBlank => {
          if (filingBlank && filingBlank !== null) {
            this.isValidated = true;
            this.showStaticMessages();
            this.filingWizardService.dataChanged = false;
            this.filingBlank = filingBlank;
            const errorsMessage = `has ${this.totalErrors()} total errors`;
            const warningMessage = `has ${this.totalWarnings()} total warnings`;
            const sections = this.filingBlank.sections;
            let message = '';
            if (sections.length > 1) {
              message = `Filing has been validated with ${this.totalErrors()} total errors and ${this.totalWarnings()} total warnings`;
            } else {
              message = `Filing has been validated. ${this.filingBlank.sections[0].name}  ${errorsMessage} and ${warningMessage}`;
            }
            const alertType = this.hasErrors() ? 'danger' : this.hasWarnings() ? 'warning' : 'success';
            this.messageService.add(new AlertMessage(message, alertType, true, 0, 'filingWizard'));
            this.setTitle();
            this.filingWizardService.activeSection = targetBlank;
          }
          this.loading = false;
        }
      );
  }

  onSubmit(template: TemplateRef<any>) {
    this.loading = false;
    if (this.hasWarnings()) {
      this.modalRef = this.modalService.show(template, this.backdropConfig);
    } else {

      this.submitFiling();
    }
  }

  submitFiling(): void {
    this.loading = true;
    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.filingData = this.prepareFilingData();
    window.scroll(0, 0);
    this.filingWizardService.postFilingData(this.filingData, this.lobCode, this.year)
      .subscribe(
        filingBlank => {
          this.filingWizardService.dataChanged = false;
          this.filingBlank = filingBlank;
          const errorsMessage = `with errors`;
          const warningMessage = `with warnings`;
          if (this.hasErrors()) {
            this.messageService.add(new AlertMessage(`${this.filingData.year.year} ${this.filingData.lineOfBusiness.name} filing for ${this.filingData.state.stateName} was submitted ${errorsMessage}. Please correct and resubmit.`, 'danger', true, 0, 'filingWizard'));
          } else if (this.hasWarnings()) {
            this.messageService.add(new AlertMessage(`${this.filingData.year.year} ${this.filingData.lineOfBusiness.name} filing for ${this.filingData.state.stateName} was submitted ${warningMessage}.`, 'success', true, 0));
            this.router.navigate(['/filingMatrix'], { skipLocationChange: true });
          } else {
            this.messageService.add(new AlertMessage(`${this.filingData.year.year} ${this.filingData.lineOfBusiness.name} filing for ${this.filingData.state.stateName} has been submitted`, 'success', true, 0));
            this.router.navigate(['/filingMatrix'], { skipLocationChange: true });
          }
          this.loading = false;
        }, (error: HttpErrorResponse) => {
          this.messageService.add(new AlertMessage('Error submitting the filing', 'danger', true, 0));
          this.loading = false;
        }
      );
  }

  putLock(unlock: boolean): void {
    this.loading = true;
    this.filingWizardService.lockUnlock(unlock, this.lobCode, this.year, this.cocode, this.state)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        filingBlank => {
          if (_.isNull(filingBlank)) {
            this.loading = false;
            this.router.navigate(['/filingMatrix']);
          } else {
            this.filingBlank = filingBlank;
            this.setTitle();
            this.loading = false;
          }
        }
      );
  }

  isFilingUnlocked(): boolean {
    if (!this.filingBlank.year.isCurrentYear) {
      if (this.filingBlank.locked === undefined) {
        return false;
      } else {
        return this.filingBlank.locked;
      }
    } else {
      return true;
    }
  }

  isFormDisabled(): boolean {
    if (this.isFilingUnlocked() && this.userService.isExternalUser()) {
      return false;
    } else {
      return true;
    }
  }

  setTitle(): void {
    this.title = `${this.filingBlank.lineOfBusiness.name} - ${this.companyService.getDisplayName(this.company, +this.cocode)} ${this.year} - ${this.stateName}`;
  }

  isInputsDisabled(): boolean {
    return !this.filingBlank.year.isCurrentYear;
  }

  onCancel() {
    this.filingWizardService.sectionErrors = [];
    this.filingWizardService.sectionWarnings = [];
    this.router.navigate(['/filingMatrix']);
  }

  prepareFilingData(nextBlank?: string): FilingData {
    let data = new FilingData();
    data.year = this.dataService.year;
    data.company = this.dataService.company;
    data.state = this.dataService.state;
    data.lineOfBusiness = this.dataService.lineOfBusiness;
    data.data = this.filingWizardService.data;
    data.comments = this.warningsComment;
    data.nextBlank = nextBlank && nextBlank.length > 0 ? nextBlank : null;
    return data;
  }

  hasErrors(): boolean {
    return this.filingWizardService.totalErrors(this.filingBlank) > 0;
  }

  hasWarnings(): boolean {
    return this.filingWizardService.totalWarnings(this.filingBlank) > 0;
  }

  totalErrors(): number {
    return this.filingWizardService.totalErrors(this.filingBlank);
  }

  totalWarnings(): number {
    return this.filingWizardService.totalWarnings(this.filingBlank);
  }

  print(): void {
    window.print();
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  getCancelButtonText(): string {
    if (!this.filingBlank.year.isCurrentYear) {
      return 'Close';
    }

    if (this.filingWizardService.dataChanged) {
      return 'Cancel';
    } else {
      return 'Close';
    }
  }

  getStyle(): string[] {
    return this.filingWizardService.activeSection === 'summary' ? ['nav-link', 'active'] : ['nav-link'];
  }

  isSubmitDissabled(): boolean {
    if (this.isValidated && !this.hasErrors() && !this.isProcessing()) {
      return false;
    }
    return true;
  }

  goTo(section: string): void {
    if (this.isDataChanged()) {
      if (section === 'next') {
        this.goToTarget(this.filingBlank.next);
      } else if (section === 'previous') {
        this.goToTarget(this.isSummary() ? this.getLastTarget() : this.filingBlank.previous);
      } else {
        this.goToTarget('summary');
      }
    } else {
      if (section === 'next') {
        this.getFilingBlank(this.filingBlank.next);
      } else if (section === 'previous') {
        this.getFilingBlank(this.isSummary() ? this.getLastTarget() : this.filingBlank.previous);
      } else {
        this.getFilingBlank('summary');
      }
    }
  }

  formatSectionName(sectionName: string): string {
    return this.filingWizardService.formatSectionName(sectionName);
  }

  getMainStyle(): string[] {
    if (this.sidebarCollapsed) {
      return ['col-12', 'ml-sm-auto', 'd-print-none'];
    } else {
      return ['col-md-9', 'ml-sm-auto', 'col-lg-10', 'd-print-none'];
    }
  }

  isPreviousYearPrintAvailable(): boolean {
    let lobStartDates: any = {
      'LPI': 2018
    };
    let selectedLob = this.filingBlank.lineOfBusiness.code;
    return lobStartDates[selectedLob] !== this.filingBlank.year.year;
  }

}
