import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[notOnlyWhitespace]',
  providers: [{provide: NG_VALIDATORS, useExisting: NotOnlyWhitespaceDirective , multi: true}]
})
export class NotOnlyWhitespaceDirective implements Validator {
  constructor() {}

  validate(control: AbstractControl): {[key: string]: any} {
    if (control.value === null) {
      return null;
    }
    if (control.value === undefined) {
      return null;
    }
    if (control.value.length === 0) {
      return null;
    }
    let trimmedValue = _.trim(control.value);
    let isWhitespace = trimmedValue.length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

}

export function notOnlyWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    if (control.value === null) {
      return null;
    }
    if (control.value === undefined) {
      return null;
    }
    if (control.value.length === 0) {
      return null;
    }
    let trimmedValue = _.trim(control.value);
    let isWhitespace = trimmedValue.length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  };
}
