import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoRoleComponent } from './no-role/no-role.component';
import { NoCocodesComponent } from './no-cocodes/no-cocodes.component';
import { AppShutDownComponent } from './app-shut-down/app-shut-down.component';

const routes: Routes = [
  { path: 'noRole', component: NoRoleComponent },
  { path: 'noCocodes', component: NoCocodesComponent },
  { path: 'appClosed', component: AppShutDownComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
