import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesModule } from '../messages/messages.module';
import { FormsModule, ReactiveFormsModule } from '../../../node_modules/@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingModule } from 'ngx-loading';
import { NcuiModule } from 'common-ui-components';
import { CorrespondenceComponent } from './correspondence.component';
import { CorrespondenceService } from './service/correspondence.service';
import { CorrespondenceRoutingModule } from './correspondence-routing.module';
import { SelectCriteriaModule } from './select-criteria/select-criteria.module';
import { RulesResultsModule } from './rules-results/rules-results.module';
import { ReviewLetterModule } from './review-letter/review-letter.module';
import { PreviewLetterModule } from './preview-letter/preview-letter.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardService } from './dashboard/service/dashboard.service';
import { AgGridModule } from 'ag-grid-angular';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { DeleteCorrespondenceRendererComponent } from './dashboard/cell-renderer/delete-correspondence-renderer/delete-correspondence-renderer.component';
import { PdfRendererComponent } from './dashboard/cell-renderer/pdf-renderer/pdf-renderer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ChangeStatusRendererComponent } from './dashboard/cell-renderer/change-status-renderer/change-status-renderer.component';
import { ActionsRendererComponent } from './dashboard/cell-renderer/actions-renderer/actions-renderer.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FinalResolutionRendererComponent } from './dashboard/cell-renderer/final-resolution-renderer/final-resolution-renderer.component';
import { AnalystCompanyNotesRendererComponent } from './dashboard/cell-renderer/analyst-company-notes-renderer/analyst-company-notes-renderer.component';
import { FollowupDateRendererComponent } from './dashboard/cell-renderer/followup-date-renderer/followup-date-renderer.component';
import { ClosedDateRendererComponent } from './dashboard/cell-renderer/closed-date-renderer/closed-date-renderer.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MessagesModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    LoadingModule,
    NcuiModule,
    CorrespondenceRoutingModule,
    SelectCriteriaModule,
    RulesResultsModule,
    ReviewLetterModule,
    PreviewLetterModule,
    AgGridModule.withComponents([ClosedDateRendererComponent, FollowupDateRendererComponent, DeleteCorrespondenceRendererComponent, PdfRendererComponent, ChangeStatusRendererComponent, ActionsRendererComponent, FinalResolutionRendererComponent, AnalystCompanyNotesRendererComponent]),
    MatDialogModule,
    MatButtonModule,
    PdfJsViewerModule,
  ],
  declarations: [CorrespondenceComponent,
    DashboardComponent,
    ConfirmationDialogComponent,
    ChangeStatusRendererComponent,
    DeleteCorrespondenceRendererComponent,
    ActionsRendererComponent,
    PdfRendererComponent,
    FinalResolutionRendererComponent,
    AnalystCompanyNotesRendererComponent,
    ClosedDateRendererComponent,
    FollowupDateRendererComponent],
  providers: [CorrespondenceService, DashboardService],
  entryComponents: []
})
export class CorrespondenceModule { }
