import { Component, OnInit } from '@angular/core';
import { McsUserService } from '../service/mcs-user.service';
import { User } from '../shared/model/user';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-mcas-toolbar',
  templateUrl: './mcas-toolbar.component.html',
  styleUrls: ['./mcas-toolbar.component.css']
})
export class McasToolbarComponent implements OnInit {
  user: User;
  isNavCollapsed = true;
  mcasHelpUrl = environment.mcasHelpUrl;
  appClosed: boolean;

  constructor(
    private userService: McsUserService,
  ) { }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.appClosed = this.userService.isAppClosed;
  }

  isExternalUser(role: string) {
    if (role === 'MCAS_NAIC_PR') {
      return true;
    } else {
      return false;
    }
  }

  isMcasAdmin(role: string) {
    if (role === 'MCAS_NAIC_PR') {
      return true;
    } else if (role === 'MCAS_USER_PR') {
      return true;
    } else {
      return false;
    }
  }

  isRegulator(role: string) {
    if (role === 'MCAS_ISITE_USER_PR') {
      return true;
    } else {
      return false;
    }
  }

}
