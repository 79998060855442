export const MOCK_MCAS_STATES: any[] = [
  {id: 1, stateName: 'Alabama', stabbr: 'AL'},
  {id: 2, stateName: 'Alaska', stabbr: 'AK'},
  {id: 3, stateName: 'Arizona', stabbr: 'AZ'},
  {id: 4, stateName: 'Arkansas', stabbr: 'AR'},
  {id: 5, stateName: 'California', stabbr: 'CA'},
  {id: 6, stateName: 'Colorado', stabbr: 'CO'},
  {id: 7, stateName: 'Connecticut', stabbr: 'CT'},
  {id: 8, stateName: 'Delaware', stabbr: 'DE'},
  {id: 9, stateName: 'District of Columbia', stabbr: 'DC'},
  {id: 10, stateName: 'Florida', stabbr: 'FL'},
  {id: 11, stateName: 'Georgia', stabbr: 'GA'},
  {id: 12, stateName: 'Hawaii', stabbr: 'HI'},
  {id: 13, stateName: 'Idaho', stabbr: 'ID'},
  {id: 14, stateName: 'Illinois', stabbr: 'IL'},
  {id: 15, stateName: 'Indiana', stabbr: 'IN'},
  {id: 16, stateName: 'Iowa', stabbr: 'IA'},
  {id: 17, stateName: 'Kansas', stabbr: 'KS'},
  {id: 18, stateName: 'Kentucky', stabbr: 'KY'},
  {id: 19, stateName: 'Louisiana', stabbr: 'LA'},
  {id: 20, stateName: 'Maine', stabbr: 'ME'},
  {id: 21, stateName: 'Montana', stabbr: 'MT'},
  {id: 22, stateName: 'Nebraska', stabbr: 'NE'},
  {id: 23, stateName: 'Nevada', stabbr: 'NV'},
  {id: 24, stateName: 'New Hampshire', stabbr: 'NH'},
  {id: 25, stateName: 'New Jersey', stabbr: 'NJ'},
  {id: 26, stateName: 'New Mexico', stabbr: 'NM'},
  {id: 27, stateName: 'New York', stabbr: 'NY'},
  {id: 28, stateName: 'North Carolina', stabbr: 'NC'},
  {id: 29, stateName: 'North Dakota', stabbr: 'ND'},
  {id: 30, stateName: 'Ohio', stabbr: 'OH'},
  {id: 31, stateName: 'Oklahoma', stabbr: 'OK'},
  {id: 32, stateName: 'Oregon', stabbr: 'OR'},
  {id: 33, stateName: 'Maryland', stabbr: 'MD'},
  {id: 34, stateName: 'Massachusetts', stabbr: 'MA'},
  {id: 35, stateName: 'Michigan', stabbr: 'MI'},
  {id: 36, stateName: 'Minnesota', stabbr: 'MN'},
  {id: 37, stateName: 'Mississippi', stabbr: 'MS'},
  {id: 38, stateName: 'Missouri', stabbr: 'MO'},
  {id: 39, stateName: 'Pennsylvania', stabbr: 'PA'},
  {id: 40, stateName: 'Rhode Island', stabbr: 'RI'},
  {id: 41, stateName: 'South Carolina', stabbr: 'SC'},
  {id: 42, stateName: 'South Dakota', stabbr: 'SD'},
  {id: 43, stateName: 'Tennessee', stabbr: 'TN'},
  {id: 44, stateName: 'Texas', stabbr: 'TX'},
  {id: 45, stateName: 'Utah', stabbr: 'UT'},
  {id: 46, stateName: 'Vermont', stabbr: 'VT'},
  {id: 47, stateName: 'Virginia', stabbr: 'VA'},
  {id: 48, stateName: 'Washington', stabbr: 'WA'},
  {id: 49, stateName: 'West Virginia', stabbr: 'WV'},
  {id: 50, stateName: 'Wisconsin', stabbr: 'WI'},
  {id: 51, stateName: 'Wyoming', stabbr: 'WY'}
];
