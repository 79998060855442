import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';
import { Disclaimer } from '../../shared/model/disclaimer';

@Injectable()
export class DisclaimerService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler,
  ) {
    this.handleError = httpErrorHandler.createHandleError('DisclaimerService');
  }

  getDisclaimer(): Observable<Disclaimer> {
    const url = `${environment.apiUrl}disclaimer`;
    return this.httpClient.get<Disclaimer>(url)
      .pipe(catchError(this.handleError('getDisclaimer', null)));
  }

  agree(): Observable<any> {
    const url = `${environment.apiUrl}disclaimer`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.httpClient.put(url, {}, options);
  }

}
