import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MessagesModule} from '../../messages/messages.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import {LoadingModule} from 'ngx-loading';
import {NcuiModule} from 'common-ui-components';
import {SelectCriteriaComponent} from './select-criteria.component';
import {SelectCriteriaRoutingModule} from './select-criteria-routing.module';
import { RulesResultsModule } from '../rules-results/rules-results.module';

@NgModule({
  declarations: [SelectCriteriaComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MessagesModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    LoadingModule,
    NcuiModule,
    SelectCriteriaRoutingModule,
    RulesResultsModule
  ],
  providers: [],
  entryComponents: []
})
export class SelectCriteriaModule { }
