import {LineOfBusiness} from '../../correspondence/select-criteria/model/line-of-business';


export const MOCK_CORRESPONDENCE_LOB: LineOfBusiness[] =[
    { lobCode: 'ANNUITY', lobName: 'Annuity' },
    { lobCode: 'HEALTH', lobName: 'Health' },
    { lobCode: 'DI', lobName: 'Disability Income' },
    { lobCode: 'LTC', lobName: 'Long Term Care' },
    { lobCode: 'LIFE', lobName: 'Life' },
];
