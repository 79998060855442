import { Component, Inject, HostListener } from '@angular/core';
import { environment } from '../environments/environment';
import { McsUserService } from './service/mcs-user.service';
import { OAuthService, JwksValidationHandler, AuthConfig } from 'angular-oauth2-oidc';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DOCUMENT } from '@angular/common';
import { TimeoutComponent } from './timeout/timeout.component';
import { timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  timeoutWarningTime = 6300000;
  maxBeforeLogout = 7080000;
  loggedIn = false;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  constructor(
    private oauthService: OAuthService,
    private userService: McsUserService,
    private modalService: BsModalService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (environment.mock) {
      this.loggedIn = true;
    } else {
      this.configure();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    this.userService.setLastActivity();
  }

  @HostListener('document:keypress', ['$event'])
  onDocumentKeypress(event: Event): void {
    this.userService.setLastActivity();
  }

  private configure(): void {
    const config: AuthConfig = {
      strictDiscoveryDocumentValidation: true,
      issuer: `${environment.auth.issuer}`,
      redirectUri: environment.auth.redirectBaseUrl,
      silentRefreshRedirectUri: environment.auth.redirectBaseUrl + '/silent-refresh.html',
      logoutUrl: `${environment.auth.logoutUrl}`,
      clientId: environment.auth.clientId,
      tokenEndpoint: `${environment.auth.tokenEndpoint}`,
      userinfoEndpoint: `${environment.auth.userInfoEndpoint}`,
      scope: 'openid profile email',
      timeoutFactor: 0.75,
      responseType: 'code',
      showDebugInformation: true,
    };

    this.oauthService.configure(config);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.loadDiscoveryDocument().then(() => {
      this.oauthService.tryLoginCodeFlow().then((response) => {
        this.loadUserProfile();
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  private loadUserProfile(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.oauthService.loadUserProfile()
        .then(userProfile => {
          this.userService.setStoredUserProfile(userProfile);
          this.loggedIn = true;
        });
    } else {
      this.oauthService.initCodeFlow();
    }
  }

  private getEvents(): void {
    let contractCount = 0;
    const contractTimer = timer(1000, 1000);
    contractTimer.subscribe(
      value => {
        contractCount = value;
        if (contractCount === this.timeoutWarningTime) {
          this.modalRef = this.modalService.show(TimeoutComponent, this.config);
        }
        if (contractCount === this.maxBeforeLogout) {
          this.oauthService.logOut();
        }
      }
    );

    // this.oauthService.events.subscribe(e => {
    //   if (e.type === 'token_expires') {
    //     let now = moment();
    //     let lastActivity = moment(this.userService.getLastActivity());
    //     if (now.diff(lastActivity, 'minutes') <= 20) {
    //       this.oauthService.silentRefresh()
    //         .then(() => {})
    //         .catch(err => console.error('refresh error', err));
    //     } else {
    //       this.modalRef = this.modalService.show(TimeoutComponent, this.config);
    //     }
    //   }
    // });
  }

}
