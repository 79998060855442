import { FilingMatrixService } from '../services/filing-matrix.service';
import { MessageService } from '../../messages/service/message.service';
import { FilingMatrix } from '../model/filing-matrix';
import { Component, Input, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Company } from '../../shared/model/company';
import { Year } from '../../shared/model/year';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs/operators';
import { AlertMessage } from '../../messages/model/alert-message';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { CompanyService } from '../company-year-select/services/company.service';

@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.css'],
})
export class DataUploadComponent implements OnInit, OnDestroy {
  @Input() company: Company;
  @Input() year: Year;
  @ViewChild('file', {static: true}) file;
  dragAreaClass = 'file-upload-drag-area';
  fileExt = 'CSV';
  maxFiles = 1;
  title: string;
  private unsubscribe: Subject<void> = new Subject();
  files: any[];
  error = false;
  isFileUploading: boolean;
  uploadingFileName: string;
  uploadPercentage: number;
  validFiles: boolean;
  cancelButtonText = 'Cancel Upload';
  loading = false;

  progress;
  canBeClosed = true;
  primaryButtonText = 'Upload';
  showCancelButton = true;
  uploading = false;
  uploadSuccessful = false;
  canAdd = true;

  constructor(
    public bsModalRef: BsModalRef,
    public messageService: MessageService,
    private matrixService: FilingMatrixService,
    private companyService: CompanyService,
  ) {}

  ngOnInit() {
    this.files = [];
    this.title = `${this.companyService.getDisplayName(this.company.longName, this.company.cocode)} - ${this.year.year} Data Upload`;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getFiles(files: any): void {
    this.files = [];
    Object.keys(files).forEach(key => {
      this.files.push(files[key]);
    });
  }

  onFileChange(event) {
    this.loading = true;
    const files = event.target.files;
    if (this.isValidFiles(files)) {
      this.getFiles(files);
      this.files.forEach((file: any) => {
        this.messageService.add(new AlertMessage(`${file.name} is ready for upload`, 'success', true, 0, 'dataUpload'));
        this.uploadingFileName = file.name;
      });
    }
    this.loading = false;
  }

  isValidFiles(files): boolean {
    if (((files.length + this.files.length) > this.maxFiles) || (files.length > this.maxFiles)) {
      this.messageService.add(new AlertMessage(`File upload limit is ${this.maxFiles} files`, 'danger', true, 0, 'dataUpload'));
      return false;
    }
    return this.isValidFileExtension(files);
  }

  private isValidFileExtension(files): boolean {
    let validExtension = true;
    const extensions = this.fileExt.split(',').map(function (x) {
      return x.toLocaleUpperCase().trim();
    });
    Object.keys(files).forEach(key => {
      let file = files[key];
      const ext = file.name.toUpperCase().split('.').pop() || file.name;
      if (extensions.indexOf(ext) === -1) {
        this.messageService.add(new AlertMessage(`Invalid extension for file ${file.name}. Accepted file formats: ${extensions}`, 'danger', true, 0, 'dataUpload'));
        validExtension = false;
      }
    });
    return validExtension;
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    this.dragAreaClass = 'file-upload-drop-area';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event) {
    this.dragAreaClass = 'file-upload-drop-area';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event) {
    this.dragAreaClass = 'file-upload-drag-area';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    this.dragAreaClass = 'file-upload-drag-area';
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event) {
    this.loading = true;
    this.dragAreaClass = 'file-upload-drag-area';
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (this.isValidFiles(files)) {
      this.getFiles(files);
      this.files.forEach((file: any) => {
        this.messageService.add(new AlertMessage(`${file.name} is ready for upload`, 'success', true, 0, 'dataUpload'));
      });
    }
    this.loading = false;
  }

  saveFiles() {
    if (this.files.length > 0) {
      const formData: FormData = new FormData();
      const fileNames: Array<string> = [];
      this.files.forEach((file, index) => {
        if (file) {
          fileNames.push(file.name);
          // const fileBlob = new Blob(file);
          formData.append('file', file, file.name); // TODO: note that the service only supports single file uploads at this time
        }
      });
      this.messageService.clear();
      this.loading = true;
      this.matrixService.postUploadData(this.company.cocode, this.year, formData)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        event => {
          this.uploadingFileName = fileNames[0];
          if (event.type === HttpEventType.UploadProgress) {
            this.isFileUploading = true;
            this.uploadPercentage = Math.round((100 * event.loaded / event.total));
            if (this.uploadPercentage === 100) {
              this.loading = true;
            }
            this.uploadingFileName = fileNames.length > 1 ? fileNames.join(', ') : fileNames[0];
          } else if (event instanceof HttpResponse) {
            this.messageService.add(new AlertMessage(`Your data file has been uploaded. Please note that any data elements containing formatting errors were not uploaded. States for which data has been uploaded will show 'In-Progress' on the Filing Matrix for the corresponding Line of Business. Please review and submit each filing.` , 'success', true, 0, 'dataUpload'));
            this.isFileUploading = false;
            this.files = [];
            this.error = false;
            this.dragAreaClass = 'file-upload-drag-area';
            this.loading = false;
          } else {
            this.loading = false;
          }
        }, error => {
          this.loading = false;
          this.files = [];
          this.error = true;
          this.dragAreaClass = 'file-upload-drag-area';
          this.loading = false;
          this.messageService.add(new AlertMessage(`Your data file contains errors! Please refer to the CSV Data Upload instructions on the MCAS webpage, then re-upload the file. ${error.error}`, 'danger', true, 0, 'dataUpload'));
        });
    }
  }

  cancel() {
    this.files = [];
    this.error = false;
    this.dragAreaClass = 'file-upload-drag-area';
    this.messageService.clear();
  }

  close() {
    this.files = [];
    this.error = false;
    this.messageService.clear();
    this.bsModalRef.hide();
  }

  readyForUpload(): boolean {
    return this.files.length > 0;
  }

}
