import { Component } from '@angular/core';
import { faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import { ICellRenderer } from 'ag-grid-community';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { User } from '../../../../shared/model/user';
import { McsUserService } from '../../../../service/mcs-user.service';

@Component({
  selector: 'app-followup-date-renderer',
  templateUrl: './followup-date-renderer.component.html',
  styleUrls: ['./followup-date-renderer.component.css']
})
export class FollowupDateRendererComponent implements ICellRenderer {
  params: any;
  faTimes = faTimes;
  fas = fas;
  visible: boolean;
  
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  dpValue: Date;
  
  faCalendar = faCalendar;
  followupDate: string;
  user: User;
  displayFollowupDateForRegulator: string;

  constructor( 
    private userService: McsUserService
  ) {
    this.dpConfig.containerClass = 'theme-dark-blue';
    this.dpConfig.showWeekNumbers = false;
    this.dpConfig.minDate = moment().toDate();
    this.dpConfig.maxDate = moment().add(60, 'days').toDate();
   }

  agInit(params: any): void {
    this.params = params;
    this.user = this.userService.getStoredUser();
    this.onlyForSent();
    if(this.params.data.followupDate === '-') {
    this.dpValue = null;
  } else {
    this.dpValue = moment(this.params.data.followupDate, 'YYYY-MM-DD').toDate();
    }
    this.dpConfig.maxDate = moment(this.params.data.closedDate, 'YYYY-MM-DD').toDate();
    this.displayFollowupDateForRegulator = moment(this.dpValue).format('YYYY-MM-DD');
    if (this.displayFollowupDateForRegulator === 'Invalid date') {
      this.displayFollowupDateForRegulator = '-';
    } else {
      this.displayFollowupDateForRegulator = this.displayFollowupDateForRegulator;
    }
  }

  refresh(): boolean {
    return true;
  }
  
  checkRegulatorRole() : boolean {
    if(this.user.role === "MCAS_ISITE_USER_PR") {
      return false;
    } else {
      return true;
    }
  }
  
  onlyForSent() {
    if (this.params.data.letterProgress === 'Sent' || this.params.data.letterProgress === 'No Letter') {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  reset(correspondenceId: string) {
    this.dpValue = null;
    this.followupDate = '';
    this.params.context.componentParent.setFollowupDate(correspondenceId, this.followupDate);
    // console.log(correspondenceId)
  }

  changeFollowupDate(correspondenceId: any, event: any) {
    this.followupDate = moment(event).format('YYYY-MM-DD');
    if (this.followupDate === "Invalid date") {
      this.followupDate = '';
    } else {
      this.followupDate = moment(event).format('YYYY-MM-DD');
    }
    this.params.context.componentParent.setFollowupDate(correspondenceId, this.followupDate);
  }
}
