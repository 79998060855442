import { MOCK_YEARS } from './mock-years';
import { MOCK_NOT_STARTED } from './mock-filing-statuses';
import { MOCK_WARNINGS } from './mock-messages';
import { MOCK_MCAS_STATES } from './mock-mcas-states';

export const MOCK_HEALTH_FILING: any = {
  state: MOCK_MCAS_STATES[0],
  lineOfBusiness: {id: 6, code: 'HEALTH', name: 'Health', year: 2017},
  year: MOCK_YEARS[MOCK_YEARS.length - 1],
  status: MOCK_NOT_STARTED,
  sections: [
    {
      key: 'HE-INT',
      sectionNumber: 1,
      name: 'Interrogatories',
      description: '',
      sections: [
        {
          key: 'HE-INT-INEX',
          sectionNumber: 2,
          name: 'In-Exchange',
          description: '',
          sections: [],
          questions: [
            {
              key: 'HE-INT-INEX-01',
              text: 'Does the company have Individual Health insurance coverage other than transitional, grandfathered, multi-state, catastrophic, or student data to report?',
              inputs: [
                {
                  key: 'HE-INT-INEX-01-response',
                  name: 'response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [
                    {key: 'LTC-INT-01-response', message: MOCK_WARNINGS[0]},
                    {key: 'LTC-INT-01-response', message: MOCK_WARNINGS[1]}
                  ],
                  errors: [],
                  value: 'yes'
                }
              ]
            },
            {
              key: 'HE-INT-INEX-02',
              text: 'Does the company have Small Group Health insurance coverage other than transitional, grandfathered, or multi-state policies data to report?',
              inputs: [
                {
                  key: 'HE-INT-INEX-02-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  value: 'no'
                }
              ]
            },
            {
              key: 'HE-INT-INEX-03',
              text: 'Does the company have Catastrophic data to report?',
              inputs: [
                {
                  key: 'HE-INT-INEX-03-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  disableableInputs : {
                    disabledValue : 'no',
                    disabledInputs : [
                      'HE-INT.HE-INT-INEX.HE-INT-INEX-04.HE-INT-INEX-04-explanation'
                    ]
                  },
                  value: 'no'
                }
              ]
            },
            {
              key : 'HE-INT-INEX-04',
              text : 'If Yes, blah blah blah?',
              inputs : [
                  {
                      key : 'HE-INT-INEX-04-explanation',
                      label : 'Response',
                      controlType : 'textbox',
                      options : [],
                      disabled : false,
                      required : false
                  }
              ]
          },
          ]
        },
        {
          key: 'HE-INT-OOEX',
          sectionNumber: 2,
          name: 'Out-of-Exchange',
          description: '',
          sections: [],
          questions: [
            {
              key: 'HE-INT-OOEX-09',
              text: 'Does the company have Individual Health insurance coverage other than transitional, grandfathered, multi-state, catastrophic, or student data to report?',
              inputs: [
                {
                  key: 'HE-INT-OOEX-09-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  value: 'no'
                }
              ]
            },
            {
              key: 'HE-INT-OOEX-10',
              text: 'Does the company have Small Group Health insurance coverage other than transitional, grandfathered, or multi-state policies data to report?',
              inputs: [
                {
                  key: 'HE-INT-OOEX-10-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  value: 'yes'
                }
              ]
            },
            {
              key: 'HE-INT-OOEX-11',
              text: 'Does the company have Grandfathered or Transitional plan data to report?',
              inputs: [
                {
                  key: 'HE-INT-OOEX-11-response',
                  label: 'Response',
                  controlType: 'dropdown',
                  options: [{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}],
                  disabled: false,
                  warnings: [],
                  errors: [],
                  value: 'yes'
                }
              ]
            },
          ]
        }
      ],
      questions: []
    },
    {
      key: 'HE-INEX',
      sectionNumber: 1,
      name: 'In-exchange',
      description: '',
      sections: [
        {
          key: 'HE-INEX-IH',
          sectionNumber: 2,
          name: 'Individual Health',
          description: 'insurance coverage other than transitional, grandfathered, multi-state, catastrophic or student',
          sections: [
            {
              key: 'HE-INEX-IH-PA',
              sectionNumber: 3,
              name: 'Policy Administration',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-INEX-IH-PA-19',
                  text: 'Earned premiums for Reporting Year.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PA-19-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-19-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-19-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-19-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-19-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-IH-PA-20',
                  text: 'Number of new policies issued during the period.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PA-20-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-20-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-20-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-20-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-20-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-IH-PA-21',
                  text: 'Number of policies renewed during the period.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PA-21-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-21-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-21-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-21-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PA-21-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            },
            {
              key: 'HE-INEX-IH-PRAUTH',
              sectionNumber: 3,
              name: 'Prior Authorizations',
              description: 'Prospective Utilization Review Requests Excluding Pharmacy',
              sections: [],
              questions: [
                {
                  key: 'HE-INEX-IH-PRAUTH-30',
                  text: 'Number of prior authorizations requested.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-30-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-IH-PRAUTH-31',
                  text: 'Number of prior authorizations approved.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-31-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-IH-PRAUTH-32',
                  text: 'Number of prior authorizations denied.',
                  inputs: [
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-IH-PRAUTH-32-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            }
          ],
          questions: []
        },
        {
          key: 'HE-INEX-SGH',
          sectionNumber: 2,
          name: 'Small Group Health',
          description: 'insurance coverage other than transitional, grandfathered or multi-state policies',
          sections: [
            {
              key: 'HE-INEX-SGH-PA',
              sectionNumber: 3,
              name: 'Policy Administration',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-INEX-SGH-PA-19',
                  text: 'Earned premiums for Reporting Year.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PA-19-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-19-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-19-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-19-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: []
                    },
                    {
                      key: 'HE-INEX-SGH-PA-19-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-SGH-PA-20',
                  text: 'Number of new policies issued during the period.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PA-20-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-20-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-20-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-20-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-20-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-SGH-PA-21',
                  text: 'Number of policies renewed during the period.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PA-21-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-21-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-21-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-21-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PA-21-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                }
              ]
            },
            {
              key: 'HE-INEX-SGH-PRAUTH',
              sectionNumber: 3,
              name: 'Prior Authorizations',
              description: 'Prospective Utilization Review Requests Excluding Pharmacy',
              sections: [],
              questions: [
                {
                  key: 'HE-INEX-SGH-PRAUTH-30',
                  text: 'Number of prior authorizations requested.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-30-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-SGH-PRAUTH-31',
                  text: 'Number of prior authorizations approved.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-31-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-INEX-SGH-PRAUTH-32',
                  text: 'Number of prior authorizations denied.',
                  inputs: [
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-INEX-SGH-PRAUTH-32-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            }
          ],
          questions: []
        },
      ],
      questions: []
    },
    {
      key: 'HE-OOEX',
      sectionNumber: 1,
      name: 'Out-of-exchange',
      description: '',
      sections: [
        {
          key: 'HE-OOEX-IH',
          sectionNumber: 2,
          name: 'Individual Health',
          description: 'insurance coverage other than transitional, grandfathered, multi-state, catastrophic or student',
          sections: [
            {
              key: 'HE-OOEX-IH-PA',
              sectionNumber: 3,
              name: 'Policy Administration',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-OOEX-IH-PA-76',
                  text: 'Earned premiums for Reporting Year.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-76-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-76-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-76-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-76-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-76-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-IH-PA-77',
                  text: 'Number of new policies issued during the period.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-77-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-77-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-77-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-77-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-77-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-IH-PA-78',
                  text: 'Number of policies renewed during the period.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-78-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-78-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-78-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-78-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-78-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                }
              ]
            },
            {
              key: 'HE-OOEX-IH-PA-PRAUTH',
              sectionNumber: 3,
              name: 'Prior Authorizations (Prospective Utilization Review Requests) Excluding Pharmacy',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-OOEX-IH-PA-PRAUTH-87',
                  text: 'Number of prior authorizations requested.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-87-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-IH-PA-PRAUTH-88',
                  text: 'Number of prior authorizations approved.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-88-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-IH-PA-PRAUTH-89',
                  text: 'Number of prior authorizations denied.',
                  inputs: [
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-IH-PA-PRAUTH-89-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            }
          ],
          questions: []
        },
        {
          key: 'HE-OOEX-SGH',
          sectionNumber: 2,
          name: 'Small Group Health',
          description: 'insurance coverage other than transitional, grandfathered or multi-state policies',
          sections: [
            {
              key: 'HE-OOEX-SGH-PA',
              sectionNumber: 3,
              name: 'Policy Administration',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-OOEX-SGH-PA-76',
                  text: 'Earned premiums for Reporting Year.',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PA-76-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-76-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-76-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-76-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-76-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-SGH-PA-77',
                  text: 'Number of new policies issued during the period.\n',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PA-77-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-77-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-77-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-77-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-77-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-SGH-PA-78',
                  text: 'Number of policies renewed during the period.\n',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PA-78-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: []
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-78-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-78-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-78-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PA-78-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                }
              ]
            },
            {
              key: 'HE-OOEX-SGH-PRAUTH',
              sectionNumber: 3,
              name: 'Prior Authorizations (Prospective Utilization Review Requests) Excluding Pharmacy',
              description: '',
              sections: [],
              questions: [
                {
                  key: 'HE-OOEX-SGH-PRAUTH-87',
                  text: 'Number of prior authorizations requested.',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-87-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-SGH-PRAUTH-88',
                  text: 'Number of prior authorizations approved.',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-88-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
                {
                  key: 'HE-OOEX-SGH-PRAUTH-89',
                  text: 'Number of prior authorizations denied.',
                  inputs: [
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-bronze',
                      label: 'Bronze',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-silver',
                      label: 'Silver',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-gold',
                      label: 'Gold',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-platinum',
                      label: 'Platinum',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: true,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                    {
                      key: 'HE-OOEX-SGH-PRAUTH-89-total',
                      label: 'Total',
                      controlType: 'textbox',
                      type: 'number',
                      disabled: false,
                      warnings: [],
                      errors: [],
                      value: 123
                    },
                  ]
                },
              ]
            }
          ],
          questions: []
        }
      ],
      questions: []
    }
  ]
};

