export class Year {
  id: number;
  year: number;
  isCurrentYear: boolean;

  constructor(year?: number, isCurrentYear?: boolean) {
    this.year = year || new Date().getFullYear();
    this.isCurrentYear = isCurrentYear || false;
  }
}
