<div class="modal-header ">
  <h4 class="modal-title ">{{title}}</h4>
  <button type="button" class="close " aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body ">
  <div class="container">
    <div *ngFor="let message of items" class="row mb-2">
      <div class="col-12">
        {{message}}
      </div>
    </div>
  </div>
</div>
<div class="modal-footer ">
  <button id="btnPrint" type="button" class="btn btn-primary btn-sm" (click)="print()">Print</button>
  <button type="button" class="btn btn-primary btn-sm" (click)="bsModalRef.hide()">Close</button>
</div>

<div id="print-section" class="d-none">
  <div class="modal-header ">
    <h4 class="modal-title ">{{title}}</h4>
  </div>

  <div class="modal-body ">
    <div class="container">
      <div *ngFor="let message of items" class="row mb-2">
        <div class="col-12">
          {{message}}
        </div>
      </div>
    </div>
  </div>
</div>