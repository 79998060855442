export const MOCK_YEARS: any[] = [
  {year: 2007, isCurrentYear: false},
  {year: 2008, isCurrentYear: false},
  {year: 2009, isCurrentYear: false},
  {year: 2010, isCurrentYear: false},
  {year: 2011, isCurrentYear: false},
  {year: 2012, isCurrentYear: false},
  {year: 2013, isCurrentYear: false},
  {year: 2014, isCurrentYear: false},
  {year: 2015, isCurrentYear: false},
  {year: 2016, isCurrentYear: false},
  {year: 2017, isCurrentYear: false},
  {year: 2018, isCurrentYear: true}
];

