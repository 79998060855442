import { Subscription } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilingMatrix } from '../../model/filing-matrix';
import { MessageService } from '../../../messages/service/message.service';
import { AlertMessage } from '../../../messages/model/alert-message';
import { Waiver } from '../../model/waiver';
import { McsUserService } from '../../../service/mcs-user.service';
import { WaiverExtensionService } from '../services/waiver-extension.service';
import { Company } from '../../../shared/model/company';

@Component({
  selector: 'app-waivers-modal',
  templateUrl: './waivers-modal.component.html',
  styleUrls: ['./waivers-modal.component.css']
})
export class WaiversModalComponent implements OnInit {
  title: string;
  @Input() waiver: Waiver;
  @Input() company: Company;
  updated = false;
  matrix: FilingMatrix;
  subscriptions: Subscription[];

  constructor(
    public bsModalRef: BsModalRef,
    private messageService: MessageService,
    private userService: McsUserService,
    private service: WaiverExtensionService,
  ) {
    this.subscriptions = [];
   }

  ngOnInit() {
    this.title = `Waiver Request`;
  }

  onDelete(): void {
    this.service.deleteWaiver(this.company.cocode, this.waiver)
    .subscribe(() => {
      this.updated = true;
      this.messageService.add(new AlertMessage(`Waiver for ${this.company.longName} has been deleted for ${this.waiver.state.stabbr}`, 'success'));
      this.bsModalRef.hide();
    });
  }

  onUpdate(): void {
    this.service.putWaiver(this.company.cocode, this.waiver)
    .subscribe(() => {
      this.updated = true;
      this.messageService.add(new AlertMessage(`Waiver for ${this.company.longName} has been updated for ${this.waiver.state.stabbr}`, 'success'));
      this.bsModalRef.hide();
    });
  }

  isUpdateDisabled(): boolean {
    return this.userService.isInternalUser() || (this.waiver.status.toLowerCase() === 'approved' || this.waiver.status.toLowerCase() === 'denied');
  }

  isDeleteDisabled(): boolean {
    return this.waiver.status.toLowerCase() === 'approved' || this.waiver.status.toLowerCase() === 'denied';
  }

}
