import { Component, TemplateRef } from '@angular/core';
import { faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ICellRenderer } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { User } from '../../../../shared/model/user';
import { McsUserService } from '../../../../service/mcs-user.service';

@Component({
  selector: 'app-analyst-company-notes-renderer',
  templateUrl: './analyst-company-notes-renderer.component.html',
  styleUrls: ['./analyst-company-notes-renderer.component.css']
})
export class AnalystCompanyNotesRendererComponent implements ICellRenderer {
  params: any;
  faTimes = faTimes;
  fas = fas;
  modalRef: BsModalRef;
  visible: boolean;
  modalForm: FormGroup;
  
  modalSubscriptions: Subscription[];
  correspondenceId: string;
  notes: any;
  title: string;
  user: User

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private userService: McsUserService
  ) {
    this.modalSubscriptions = [];
   }

  agInit(params: any): void {
    this.params = params;
    this.user = this.userService.getStoredUser();
    this.title = "Analyst Notes and Company Response";
    this.onlyForSent();
  }

  refresh(): boolean {
    return true;
  }

  buttonText(): string {
    if(this.user.role === "MCAS_ISITE_USER_PR") {
      return 'View Notes';
    } else {
      return 'Add Notes';
    }
  }

  onlyForSent() {
    if (this.params.data.letterProgress === 'Sent' || this.params.data.letterProgress === 'No Letter') {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  checkRegulatorRole() : boolean {
    if(this.user.role === "MCAS_ISITE_USER_PR") {
      return false;
    } else {
      return true;
    }
  }

  openAnalystCompanyNotes(id: string, notes: any, template: TemplateRef<any>): void {
    this.modalForm = this.fb.group({
      'analystNotes': [notes.analystCompanyNotes.analystNote, Validators.required],
      'companyResponse': [notes.analystCompanyNotes.companyResponse, Validators.required]
    });
    this.correspondenceId = id;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  cancel() {
    this.modalRef.hide();
  }

  submitForm(value: any) {
    let notes: any = {
      analystNotes: value.analystNotes,
      companyResponse: value.companyResponse
    };
    this.modalRef.hide();
    this.params.context.componentParent.updateAnalystCompanyNotes(this.correspondenceId, notes);
  }

}
