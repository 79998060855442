import { Component, OnInit, NgZone } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OAuthService } from 'angular-oauth2-oidc';
import * as moment from 'moment';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.css']
})
export class TimeoutComponent implements OnInit {
  title: string;
  buttons: any[] = [];
  message: string;

  constructor(
    public modalRef: BsModalRef,
    private oauthService: OAuthService,
    private ngZone: NgZone,
  ) { }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        let timeLeft = moment().diff(moment(this.oauthService.getAccessTokenExpiration()), 'minutes');
        if (timeLeft === 0) {
          this.logout();
        }
        this.ngZone.run(() => {
          this.message = `The current session will expire in ${Math.abs(timeLeft)} minutes. Please choose to continue or logout. If logging out any unsaved data will be lost.`;
        });
      }, 1000);
    });
  }

  logout(): void {
    this.oauthService.logOut();
    this.modalRef.hide();
  }

  continue(): void {
    this.oauthService.silentRefresh()
      .then(() => {})
      .catch(err => console.error('refresh error', err));
    this.modalRef.hide();
  }

}
