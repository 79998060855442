import { Company } from '../../shared/model/company';
import { LineOfBusiness } from './line-of-business';
import { McasState } from './mcas-state';
import { Year } from '../../shared/model/year';

export class Extension {
  company: Company;
  year: Year;
  lineOfBusiness: LineOfBusiness;
  state: McasState;
  approvedDate: string;
  status: string;
  reason: string;
  endDate: string;
  decisionDate?: string;
  decisionComments?: string;
  extensionNumber?: number;

  constructor(company?: Company, year?: Year, lineOfBusiness?: LineOfBusiness, state?: McasState,
              approvedDate?: string, status?: string, reason?: string, endDate?: string, decisionDate?: string, decisionComments?: string, extensionNumber?: number) {
    this.company = company || null;
    this.year = year || null;
    this.lineOfBusiness = lineOfBusiness || null;
    this.state = state || null;
    this.approvedDate = approvedDate || null;
    this.status = status || '';
    this.reason = reason || '';
    this.endDate = endDate || null;
    this.decisionDate = decisionDate || null;
    this.decisionComments = decisionComments || '';
    this.extensionNumber = extensionNumber || null;
  }
}
