<ng-container *ngIf="message">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Timeout Warning</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>{{message}}</p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="modalRef.hide()">Close</button>
    <button type="button" class="btn btn-danger" (click)="logout()">Logout</button>
    <button type="button" class="btn btn-primary" (click)="continue()">Continue</button>
  </div>
</ng-container>
