import { Injectable } from '@angular/core';
import { McsUserService } from '../service/mcs-user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  internalAccess = ['/disclaimer', '/filingMatrix', '/filing', '/userAdmin', '/correspondence'];
  externalAccess = ['/disclaimer', '/filingMatrix', '/filing', '/userAdmin'];
  regulatorlAccess = ['/disclaimer', '/correspondence'];
  helpDeskAccess = ['/helpDesk'];

  constructor(private userService: McsUserService) { }

  hasCorrectRole(url: string): boolean {
    if (this.userService.isExternalUser()) { // External Check
      return this.checkForRole(this.externalAccess, url);
    } else if (this.userService.isInternalUser()) { // Internal Check
      return this.checkForRole(this.internalAccess, url);
    } else if (this.userService.isRegulator()) { // Regulator Check
      return this.checkForRole(this.regulatorlAccess, url);
    } else if (this.userService.isHelpDeskUser()) { // Help Desk Check
      return this.checkForRole(this.helpDeskAccess, url);
    } else {
      return false;
    }
  }

  private checkForRole(routes: string[], url: string): boolean {
    let hasAccess = false;
      routes.forEach((route: string) => {
        if (url.startsWith(route)) { hasAccess = true; }
      });
      return hasAccess;
  }

}
