<ng-container *ngFor="let message of service.messages; let i = index">
  <div *ngIf="isVisible(message)" class="pt-3">
    <alert *ngIf="isDismissableTime(message)" type="{{message.type}}" [dismissOnTimeout]="message.dismissTime"
      (onClosed)="service.remove(i)" [dismissible]="message.dismissable">
      <span class="multi-lines-text">{{message.message}}</span>
    </alert>
    <alert *ngIf="!isDismissableTime(message)" type="{{message.type}}" (onClosed)="service.remove(i)" [dismissible]="message.dismissable">
      <span class="multi-lines-text">{{message.message}}</span>
    </alert>
  </div>

</ng-container>
