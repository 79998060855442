export const FilingYearOptions = [
    { name: '2022', value: 2022 },
    { name: '2023', value: 2023 },
    { name: '2024', value: 2024 },
  ];

export const StatusOptions = [
    { name: 'All', value: 'All' },
    { name: '1st Correspondence', value: '1st Correspondence' },
    { name: '2nd Notice', value: '2nd Notice' },
    { name: 'Company Work in Progress', value: 'Company Work in Progress' },
    { name: 'State Handling', value: 'State Handling' },
    { name: 'No Action Required', value: 'No Action Required' },
    { name: 'Resolved', value: 'Resolved' },
  ];

export const LobOptions = [
    { name: 'All', value: 'ALL' },
    { name: 'Annuity', value: 'ANNUITY' },
    { name: 'Disability Income', value: 'DI' },
    { name: 'Health', value: 'HEALTH' },
    { name: 'Homeowners', value: 'HO' },
    { name: 'Lender Placed Insurance', value: 'LPI' },
    { name: 'Life', value: 'LIFE' },
    { name: 'Long Term Care', value: 'LTC' },
    { name: 'Pet', value: 'PET' },
    { name: 'Private Flood', value: 'PF' },
    { name: 'Private Passenger Auto', value: 'PPA' },
    { name: 'Short-Term Limited Duration', value: 'STLD' },
    { name: 'Travel', value: 'TRVL' },
    { name: 'Other Health', value: 'OTHEALTH' }
  ];
