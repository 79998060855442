import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotOnlyWhitespaceDirective } from './not-only-whitespace.directive';
import {DataTableHelperService} from './data-table-helper.service';
import { NotNumericDirective } from './not-numeric.directive';
import { OnlyDigitsDirective } from './only-digits.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NotOnlyWhitespaceDirective, NotNumericDirective, OnlyDigitsDirective],
  providers: [DataTableHelperService],
  exports: [NotOnlyWhitespaceDirective, NotNumericDirective, OnlyDigitsDirective]
})
export class SharedModule { }
