import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-role',
  templateUrl: './no-role.component.html',
  styleUrls: ['./no-role.component.css']
})
export class NoRoleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
