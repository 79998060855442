import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../../shared/http-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class CorrespondenceService {
  private correspondenceUrl = `${environment.apiUrl}correspondence/`;
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('CorrespondenceService');
  }

}
