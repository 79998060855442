import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private roleService: RoleService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.checkRole(state.url);
  }

  checkRole(url: string): boolean {
    const correctRole = this.roleService.hasCorrectRole(url);
    if (correctRole) {
      return true;
    }

    this.router.navigate(['/noRole']);
    return false;
  }
}
