import { Injectable } from '@angular/core';
import {HandleError, HttpErrorHandler} from '../../../shared/http-error-handler.service';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import {LineOfBusiness} from '../model/line-of-business';
import {List} from 'lodash';
import { Dashboard } from '../../dashboard/model/dashboard';
import { Dashboards } from '../../dashboard/model/dashboards';

@Injectable({
  providedIn: 'root'
})
export class SelectCriteriaService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    public httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('SelectCriteriaService');
  }

  getFiledLOBs(year: number, cocode: number): Observable<any> {
    let url = `${environment.apiUrl}lineofbusiness`;
    const params = new HttpParams().set('cocode', String(cocode)).set('year', String(year));
    const options = {params: params};

    return this.httpClient.get<List<LineOfBusiness>>(url, options)
      .pipe(catchError(this.handleError('getFiledLOBs', null)));
  }

  postStartNewLetter(letterBody: any): Observable<Dashboards> {
    const url = `${environment.apiUrl}correspondences/newLetter`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.post<Dashboards>(url,letterBody, options)
    .pipe(catchError(this.handleError('postStartNewLetter', null)));
  }
}
