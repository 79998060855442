import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAdminRoutingModule } from './user-admin-routing.module';
import { UserAdminComponent } from './user-admin.component';
import { MessagesModule } from '../messages/messages.module';
import { FormsModule, ReactiveFormsModule } from '../../../node_modules/@angular/forms';
import { UserAdminService } from './service/user-admin.service';
import { UserRemoveComponent } from './user-remove/user-remove.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingModule } from 'ngx-loading';
import { NcuiModule } from 'common-ui-components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MessagesModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    LoadingModule,
    NcuiModule,
    UserAdminRoutingModule
  ],
  declarations: [UserAdminComponent, UserRemoveComponent],
  providers: [UserAdminService],
  entryComponents: [UserRemoveComponent]
})
export class UserAdminModule { }
