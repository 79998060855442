<ngx-loading [show]="loading"></ngx-loading>
<div class="row">
  <main *ngIf="!loading" role="main" class="col-sm-12 ml-sm-auto d-print-block">
    <app-messages [parentName]="'correspondence'"></app-messages>
    <div class="pt-3 pb-3 main-content d-print-block">
      <div class="row">
        <div class="col-12">
          <h3>
            {{title}}
            <hr>
          </h3>
        </div>
      </div>

      <ng-container *ngIf="checkRegulatorRole()">
        <div class="row pb-3">
          <div class="col-12">
            <button id="newLetter" type="button" class="btn btn-primary" (click)="startNewLetter()">Start New
              Letter</button>
          </div>
        </div>
      </ng-container>

      <div class="row pb-3">
        <div class="col-12">
          <app-dashboard [dashboard]="dashboard"> </app-dashboard>
        </div>
      </div>

    </div>
    <div class="row">
      <footer class="col-12 footer d-print-block">
        <ncui-footer></ncui-footer>
      </footer>
    </div>
  </main>
</div>