import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../shared/model/user';
import { Subject, Subscription } from 'rxjs';
import { McsUserService } from '../service/mcs-user.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MOCK_HELP_DESK, MOCK_INTERNAL, MOCK_EXTERNAL_ADMIN, MOCK_EXTERNAL, MOCK_EXTERNAL_NO_COCODES, MOCK_EXTERNAL_BAD_ROLE, MOCK_REGULATOR } from '../mock/data/mock-users';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppShutDownServive } from '../shared/appShutDown.service';
import { ApplicationAdminService } from '../application-admin/service/application-admin.service';
import { ApplicationAdministration } from '../application-admin/model/application-administration';
import { SessionService } from '../service/session.service';
// import * as moment from 'moment';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit, OnDestroy {
  environmentName = environment.envName;
  user: User;
  error = false;
  message: string;
  private unsubscribe: Subject<void> = new Subject();
  loading = false;
  mock = environment.mock;
  mockUsers = ['Industry', 'Admin', 'NAIC', 'Help Desk', 'No Cocodes', 'Bad Role', 'Regulator'];
  selectedMockUser = 'Industry';
  applicationAdministartion: ApplicationAdministration;
  subscriptions: Array<Subscription> = [];


  constructor(
    private mcsUserService: McsUserService,
    private oauthService: OAuthService,
    private applicationAdminService: ApplicationAdminService,
    private router: Router,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.environmentName = environment.envName;
    if (!this.mock) {
      this.getUser();
    } else {
      this.mcsUserService.setStoredUserProfile({nickname: 'test', given_name: 'test'});
      this.setMockUser();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getUser() {
    this.loading = true;
    this.mcsUserService.getMcsUser(this.mcsUserService.getStoredUserProfile().preferred_username)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        mcsUser => {
          if (mcsUser) {
            this.mcsUserService.setStoredUser(mcsUser);
            this.sessionService.configureSessionTimeout();
            if (mcsUser.role === 'MCAS_HELP_PR') {
              this.router.navigate(['helpDesk']);
            } else {
              this.user = mcsUser;
              this.user.isAuthenticated = true;
              this.user['name'] = this.mcsUserService.getStoredUserProfile().given_name;
              this.router.navigate(['disclaimer']);
            }
          }
          this.loading = false;
        }
      );
      this.getApplicationInfo();
  }

  getApplicationInfo(): void {
    const sub = this.applicationAdminService.getApplicationInfo()
      .subscribe(
        applicationInfo => {
          this.applicationAdministartion = applicationInfo;
          this.mcsUserService.isAppClosed = this.applicationAdministartion.turnOnOrOff;
          this.mcsUserService.appMessage = this.applicationAdministartion.offMessage;
        }
      );
    this.subscriptions.push(sub);
  }

  getName(): any {
    return { name: this.mcsUserService.getStoredUserProfile().given_name };
  }

  setMockUser() {
    this.sessionService.configureSessionTimeout();
    if (this.selectedMockUser === 'Help Desk') {
      this.user = MOCK_HELP_DESK;
    } else if (this.selectedMockUser === 'NAIC') {
      this.user = MOCK_INTERNAL;
    } else if (this.selectedMockUser === 'Admin') {
      this.user = MOCK_EXTERNAL_ADMIN;
    } else if (this.selectedMockUser === 'No Cocodes') {
      this.user = MOCK_EXTERNAL_NO_COCODES;
    } else if (this.selectedMockUser === 'Bad Role') {
      this.user = MOCK_EXTERNAL_BAD_ROLE;
    } else if (this.selectedMockUser === 'Regulator') {
      this.user = MOCK_REGULATOR;
    } else {
      this.user = MOCK_EXTERNAL;
    }
    this.user['name'] = this.user.firstName;
    this.mcsUserService.setStoredUser(this.user);
    if (this.selectedMockUser === 'Help Desk') {
      this.router.navigate(['helpDesk']);
    } else {
      this.router.navigate(['disclaimer']);
    }
  }

  getEnvironmentName(): string {

    return this.environmentName;
  }

  onActivate() {
    window.scroll(0, 0);
  }

  logout() {
    if (environment.mock) {
      this.router.navigate(['']);
    } else {
      this.oauthService.logOut();
    }
  }

}
