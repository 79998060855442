import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { McasState } from '../model/mcas-state';
import { LineOfBusiness } from '../model/line-of-business';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';
import {catchError, map} from 'rxjs/operators';
import { Waiver } from '../../model/waiver';
import { Extension } from '../../model/extension';
import { environment } from '../../../../environments/environment';
import {Year} from '../../../shared/model/year';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Injectable()
export class WaiverExtensionService {

  readonly handleError: HandleError;
  bsModalRef: BsModalRef;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('WaiverExtensionService');
  }

  postWaivers(cocode: number, waivers: Waiver[]): Observable<any> {
    let url = `${environment.apiUrl}waivers/${cocode}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.httpClient.post(url, waivers, options);
      // .pipe(catchError(this.handleError('postWaivers', null)));
  }

  putWaiver(cocode: number, waiver: Waiver): Observable<any> {
    let url = `${environment.apiUrl}waivers/${cocode}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.httpClient.put(url, waiver, options)
      .pipe(catchError(this.handleError('putWaivers', null)));
  }

  deleteWaiver(cocode: number, waiver: Waiver): Observable<any> {
    let url = `${environment.apiUrl}waivers/${cocode}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers, body: waiver };

    return this.httpClient.delete(url, options)
      .pipe(catchError(this.handleError('deleteWaiver', null)));
  }

  postExtensions(cocode: number, extensions: Extension[]): Observable<any> {
    let url = `${environment.apiUrl}extensions/${cocode}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.httpClient.post(url, extensions, options);
      // .pipe(catchError(this.handleError('postExtensions', null)));
  }

  putExtension(cocode: number, extension: Extension): Observable<any> {
    let url = `${environment.apiUrl}extensions/${cocode}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.httpClient.put(url, extension, options)
      .pipe(catchError(this.handleError('putExtension', null)));
  }

  deleteExtension(cocode: number, extension: Extension): Observable<any> {
    let url = `${environment.apiUrl}extensions/${cocode}`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers, body: extension };

    return this.httpClient.delete(url, options)
      .pipe(catchError(this.handleError('deleteExtension', null)));
  }

  getStates(): Observable<McasState[]> {
    let url = `${environment.apiUrl}mcasstates`;

    return this.httpClient.get<McasState[]>(url)
      .pipe(catchError(this.handleError('getStates', [])));
  }

  getLinesOfBusiness(): Observable<LineOfBusiness[]> {
    let url = `${environment.apiUrl}linesofbusiness`;

    return this.httpClient.get<LineOfBusiness[]>(url)
      .pipe(catchError(this.handleError('getLinesOfBusiness', [])));
  }

  /**
   *
   * @param cocode
   * @param year
   */
  public getMultipleExtensions(cocode: number, year: Year): Observable<Extension[]> {
    const url = `${environment.apiUrl}extensions/${cocode}`
    let params = new HttpParams().set('year', year.year.toString());

    return this.httpClient.get<Extension[]>(url, {params})
      .pipe(map(extension => extension.sort((a,b) =>

        Date.parse(b.endDate) - Date.parse(a.endDate)
      )),catchError(this.handleError('getMultipleExtensions', null)));
  }

  setBsModalRef(bsModalRef: BsModalRef) {
    this.bsModalRef = bsModalRef;
  }

  getBsModalRef() {
    return this.bsModalRef;
  }
}
