<ng-container *ngIf="company && year && matrix">

  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-messages [parentName]="'waivers-extensions'"></app-messages>
    <div class="row">
      <div class="col-12">
        <p>{{instructions}}</p>
      </div>
    </div>

    <div *ngIf="lobs && lobs.length > 0; else noLobs" class="row">
      <div class="col-12">
        <form #waiverForm="ngForm">
          <div class="form-group">
            <label for="lobs">Available Line of Business</label>
            <select class="form-control" id="lobs" name="lobs" required [(ngModel)]="selectedLob" (change)="getStates()">
              <option [ngValue]="null" disabled hidden>Choose One</option>
              <option *ngFor="let lob of lobs" [ngValue]="lob">{{lob.name}}</option>
            </select>
          </div>
          <hr/>
          <div *ngIf="type === 'extension'">
          <div class="form-group" *ngIf="selectedLob">
            <label for="extensionRequested">Extension Requested</label>
            <select class="form-control" id="extensionRequested" name="extensionRequested" required [(ngModel)]="selectedExtensionRequest" (change)="setExtensionDate()">
              <option [ngValue]="null" disabled hidden>Choose One</option>
              <option *ngFor="let extension of extensionRequested" [ngValue]="extension">{{extension.label}}</option>
            </select>
          </div>
        </div>

          <div *ngIf="availableStates.length > 0">
            <hr/>
            <label>Available States</label>
            <div class="row">
              <div *ngFor="let state of availableStates" class="col-md-2">
                <label [ngClass]="['btn', state.required ? 'btn-outline-primary' : 'btn-outline-secondary']" name="state.state.stabbr" [(ngModel)]="state.selected"
                  btnCheckbox role="button">
                  <span class="text-monospace">{{state.state.stabbr}}</span>
                </label>
              </div>
            </div>
            <!-- <div *ngIf="type === 'extension'">
              <hr/>
              <label>Extension Date Requested</label>
              <span>{{selectedExtensionRequest}}</span>
              <div class="row">
                <div class="col-12">
                  <div class="input-group">
                    <input type="text" maxlength="10" placeholder="MM/DD/YYYY" name="bsDatepicker" class="form-control" #dp="bsDatepicker" bsDatepicker [(ngModel)]="dpValue" [bsConfig]="dpConfig" placement="top" required readOnly>
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen">
                        <fa-icon [icon]="faCalendar"></fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <hr/>
            <label>Reason</label>
            <div class="row">
              <div class="col-12">
                <textarea class="form-control" id="waiverReason" name="waiverReason" [(ngModel)]="reason" #reasonTextarea="ngModel" required notOnlyWhitespace>
                      {{reason}}
                    </textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="bsModalRef.hide()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="submitDisabled() || clicked" (click)="submit()">
      Submit {{type.charAt(0).toUpperCase() + type.slice(1)}}
    </button>
  </div>

</ng-container>

<ng-template #noLobs>
  <div class="row">
    <div class="col-12">
      <h4>No lines available for waiver</h4>
    </div>
  </div>
</ng-template>
