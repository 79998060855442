import { WaiversModalComponent } from './waivers-extensions/waivers-modal/waivers-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilingMatrixComponent } from './filing-matrix.component';
import { FormsModule } from '@angular/forms';
import { CompanyYearSelectModule } from './company-year-select/company-year-select.module';
import { DataUploadModule } from './data-upload/data-upload.module';
import { DataUploadComponent } from './data-upload/data-upload.component';
import { WaiversExtensionsComponent } from './waivers-extensions/waivers-extensions.component';
import { WaiversExtensionsModule } from './waivers-extensions/waivers-extensions.module';
import { WarningsErrorsModule } from './warnings-errors/warnings-errors.module';
import { WarningsErrorsComponent } from './warnings-errors/warnings-errors.component';
import { FilingMatrixRoutingModule } from './filing-matrix-routing.module';
import { DataService } from './data/data.service';
import { DataModule } from './data/data.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ExtensionModalComponent } from './waivers-extensions/extension-modal/extension-modal.component';
import { MessagesModule } from '../messages/messages.module';
import { GoTopButtonModule } from 'ng2-go-top-button';
import { BrowserAnimationsModule } from '../../../node_modules/@angular/platform-browser/animations';
import { LoadingModule } from 'ngx-loading';
import { FilingMatrixService } from './services/filing-matrix.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FilingWizardModule } from './filing-wizard/filing-wizard.module';
import { NcuiModule } from 'common-ui-components';
import { SummaryFilingMatrixModule } from './summary-filing-matrix/summary-filing-matrix.module';
import {
  MultipleExtensionsModalComponent
} from './waivers-extensions/multiple-extensions-modal/multiple-extensions-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GoTopButtonModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    FilingMatrixRoutingModule,
    CollapseModule.forRoot(),
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    LoadingModule,
    NcuiModule,
    DataModule,
    MessagesModule,
    CompanyYearSelectModule,
    SummaryFilingMatrixModule,
    DataUploadModule,
    FilingWizardModule,
    WaiversExtensionsModule,
    WarningsErrorsModule,
  ],
  declarations: [FilingMatrixComponent],
  providers: [
    DataService,
    FilingMatrixService,
  ],
  exports: [FilingMatrixComponent],
  entryComponents: [
    DataUploadComponent,
    WaiversExtensionsComponent,
    WarningsErrorsComponent,
    WaiversModalComponent,
    ExtensionModalComponent,
    MultipleExtensionsModalComponent
  ]
})
export class FilingMatrixModule {
}
