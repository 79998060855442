import { MessageService } from '../../messages/service/message.service';
import { McasState } from './model/mcas-state';
import { StateFiling } from '../model/state-filing';
import { LineOfBusiness } from '../model/line-of-business';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WaiverExtensionService } from './services/waiver-extension.service';
import { Company } from './model/company';
import { Year } from './model/year';

import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as _ from 'lodash';
import { Filing } from '../model/filing';
import { AvailableStates } from './model/available-states';
import { AlertMessage } from '../../messages/model/alert-message';
import { FilingMatrix } from '../model/filing-matrix';
import { Waiver } from '../model/waiver';
import { Extension } from '../model/extension';
import * as moment from 'moment';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { multipleExtensions, MultipleExtensions } from './model/multiple-extensions';

@Component({
  selector: 'app-waivers-extensions',
  templateUrl: './waivers-extensions.component.html',
  styleUrls: ['./waivers-extensions.component.css'],
})
export class WaiversExtensionsComponent implements OnInit, OnDestroy {
  @Input() company: Company;
  @Input() year: Year;
  @Input() type: string;
  @Input() matrix: FilingMatrix;
  @Input() state: McasState;
  updated = false;
  title: string;
  instructions = '';
  subs: Subscription[] = [];
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  dpValue: Date;

  extension: Extension[] = [];
  filteredExtensions: Extension[] = [];
  lobs: LineOfBusiness[] = [];

  selectedLob: LineOfBusiness = null;
  reason = '';
  availableStates: AvailableStates[] = [];

  faCalendar = faCalendar;
  clicked : boolean;
  extensionRequested: MultipleExtensions[];

  selectedExtensionRequest: MultipleExtensions;
  lob: LineOfBusiness;

  constructor(
    public bsModalRef: BsModalRef,
    private service: WaiverExtensionService,
    private messageService: MessageService,
  ) {
    this.dpConfig.containerClass = 'theme-dark-blue';
    this.dpConfig.showWeekNumbers = false;
    this.dpConfig.minDate = moment().toDate();
    this.dpConfig.maxDate = moment().add(60, 'days').toDate();
    this.lobs = [];
  }

  ngOnInit() {
    this.extensionRequested = multipleExtensions;
    this.title = `${_.upperFirst(this.type)} Request for ${this.company.longName} (${this.company.cocode})`;
    if (this.type === 'waiver') {
      this.instructions = `A waiver can be requested for the states where your company is required to file that line of business. Multiple waiver requests for states with the same line of business may be initiated at the same time by simply selecting each state before pressing the submit waiver button. Each request will display by state on the filing matrix.`;
    }
    if (this.type === 'extension') {
      this.instructions = `An extension can be requested for any states regardless if your company is required to file that line of business. Multiple extension requests for states with the same line of business may be initiated at the same time by simply selecting each state before pressing the submit extension button. Each request will display by state on the filing matrix. The states that you are required to file in will display in blue below.`;
    }
    this.getLinesOfBusiness();
    this.clicked = false;
  }

  getLinesOfBusiness() {
    this.lobs.length = 0;
    this.matrix.stateFilings.forEach((stateFiling: StateFiling) => {
      stateFiling.filings.forEach((filing: Filing) => {
        if (this.type === 'waiver') {
          if (filing.required && !_.find(this.lobs, { 'code': filing.lineOfBusiness.code })) {
            if (this.canSubmitWaiver(filing.waiver)) {
              this.lobs.push(filing.lineOfBusiness);
            }
          }
        } else {
          if (!_.find(this.lobs, {'code': filing.lineOfBusiness.code})) {
            this.lobs.push(filing.lineOfBusiness);
          }
        }
      });
    });
  }

  canSubmitWaiver(waiver: Waiver): boolean {
    if (_.isNull(waiver)) {
      return true;
    } else {
      if (waiver.status.toLowerCase() === 'pending') {
        return false;
      }
      if (waiver.status.toLowerCase() === 'approved') {
        return false;
      }
      if (waiver.status.toLowerCase() === 'denied') {
        return true;
      }
    }
    return false;
  }

  canSubmitExtension(extension: Extension, waiver: Waiver): boolean {
    if (_.isNull(extension)) {
      return this.canSubmitWaiver(waiver);
    } else {
      if (extension.status.toLowerCase() === 'pending') {
        return false;
      }
      if (extension.status.toLowerCase() === 'approved') {
        return this.canSubmitWaiver(waiver);
      }
      if (extension.status.toLowerCase() === 'denied') {
        return this.canSubmitWaiver(waiver);
      }
    }
    return false;
  }

  /**
   * This method handles setting the variable that will be set as the extension requested due date, and is fired
   * when an option in the dropdown is selected.
   */
  setExtensionDate() {
    this.availableStates.length = 0;
    let lob: LineOfBusiness = this.lob;
    this.dpValue = moment(lob.dueDate).add(this.selectedExtensionRequest.value, 'day').toDate();
    this.matrix.stateFilings.forEach((stateFiling: StateFiling) => {
      stateFiling.filings.forEach((filing: Filing) => {
        if (this.type === 'extension') {
          if (filing.lineOfBusiness.code === lob.code
            && !this.alreadyAvailable(stateFiling.state)) {
              if (this.isExtensionApproved(filing.extension, this.selectedExtensionRequest)) {
                this.availableStates.push(new AvailableStates(stateFiling.state, filing.required, false));
              }
          }
        }
      });
    });
  }

  getStates() {
    this.availableStates.length = 0;
    this.lob = this.selectedLob;
    this.selectedExtensionRequest = null;
    this.filteredExtensions = this.extension.filter(extension => extension.lineOfBusiness.name == this.lob.name && extension.year.year == this.year.year);
    if(this.type === 'waiver') {
      this.waiver(this.lob);
    }
  }

  waiver(lob) {
    this.matrix.stateFilings.forEach((stateFiling: StateFiling) => {
      stateFiling.filings.forEach((filing: Filing) => {
          if (filing.lineOfBusiness.code === lob.code && filing.required && !this.alreadyAvailable(stateFiling.state)) {
            if (this.canSubmitWaiver(filing.waiver)) {
              this.availableStates.push(new AvailableStates(stateFiling.state, filing.required, false));
            }
          }
      });
    });
  }

  isExtensionApproved(extension: Extension, selectedExtensionRequest: MultipleExtensions): boolean {
    if (selectedExtensionRequest.extensionNumber === 1) {
      if((_.isNull(extension))) {
        return true;
      } else {
        return false;
      }
    } else if (!extension) {
      return false;
    } else if(extension.status.toLowerCase() === 'approved' && extension.extensionNumber === 1 && selectedExtensionRequest.extensionNumber === 2) {
      return true;
    } else if (extension.status.toLowerCase() === 'approved' && extension.extensionNumber === 2 && selectedExtensionRequest.extensionNumber === 3) {
      return true;
    } else if (extension.status.toLowerCase() === 'approved' && extension.extensionNumber === 3 && selectedExtensionRequest.extensionNumber === 4){
      return true;
    } else {
      return false;
    }
  }

  private alreadyAvailable(state: McasState): boolean {
    let available: string[] = [];

    this.availableStates.forEach((availableState: AvailableStates) => {
      available.push(availableState.state.stabbr);
    });

    return _.indexOf(available, state.stabbr) > -1;
  }

  hasSelectedStates(): boolean {
    const selectedStates = _.filter(this.availableStates, { 'selected': true });
    return selectedStates.length > 0;
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  submit(): void {
    this.clicked = true;
    if (this.type === 'waiver') {
      this.waiverSubmit();
    } else {
      this.extensionSubmit();
    }
  }

  waiverSubmit(): void {
    let waivers = this.prepareWaivers();
    let states = [];
    waivers.forEach(waiver => {
      states.push(waiver.state.stabbr);
    });
    this.subs.push(
      this.service.postWaivers(this.company.cocode, waivers)
        .subscribe(
          response => {
            if (response) {
              this.updated = true;
              let message = `Waiver${waivers.length > 1 ? 's' : ''} for ${this.company.longName} ${waivers.length > 1 ? 'have' : 'has'} been submitted for ${states.join(', ')}`;
              this.messageService.add(new AlertMessage(message, 'success', true, 0));
              this.bsModalRef.hide();
            } else {
              this.updated = false;
              let message = `Waiver${waivers.length > 1 ? 's' : ''} for ${this.company.longName} ${waivers.length > 1 ? 'have' : 'has'} failed for ${states.join(', ')}`;
              this.messageService.add(new AlertMessage(message, 'danger', true, 0, 'waivers-extensions'));
            }
          },
          err => {
            this.updated = false;
            let message = `Waiver${waivers.length > 1 ? 's' : ''} for ${this.company.longName} ${waivers.length > 1 ? 'have' : 'has'} failed for ${states.join(', ')}`;
            this.messageService.add(new AlertMessage(message, 'danger', true, 0, 'waivers-extensions'));
          }
        )
    );
  }

  /**
   * This method is fired when the "Submit Extension" button is called from the Extension Request page, and will send
   * the extension to the MCAS Service for saving.
   */
  extensionSubmit(): void {
    let extensions = this.prepareExtension();
    let states = [];
    extensions.forEach(extension => {
      states.push(extension.state.stabbr);
    });
    this.subs.push(
      this.service.postExtensions(this.company.cocode, extensions)
        .subscribe(
          response => {
            if (response) {
              this.updated = true;
              const message = `Extension${extensions.length > 1 ? 's' : ''} for ${this.company.longName} ${extensions.length > 1 ? 'have' : 'has'} been submitted for ${states.join(', ')}`;
              this.messageService.add(new AlertMessage(message, 'success', true, 0));
              this.bsModalRef.hide();
            } else {
              this.updated = false;
              const message = `Extension${extensions.length > 1 ? 's' : ''} for ${this.company.longName} ${extensions.length > 1 ? 'have' : 'has'} failed for ${states.join(', ')}`;
              this.messageService.add(new AlertMessage(message, 'danger', true, 0, 'waivers-extensions'));
            }
          },
          err => {
            this.updated = false;
            const message = `Extension${extensions.length > 1 ? 's' : ''} for ${this.company.longName} ${extensions.length > 1 ? 'have' : 'has'} failed for ${states.join(', ')}`;
            this.messageService.add(new AlertMessage(message, 'danger', true, 0, 'waivers-extensions'));
          }
        )
    );
  }

  prepareWaivers(): Waiver[] {
    let waiverArray: Waiver[] = [];
    this.availableStates.forEach((selectedState: AvailableStates) => {
      if (selectedState.selected) {
        let waiver = new Waiver();
        waiver.company = this.company;
        waiver.year = this.year;
        waiver.state = selectedState.state;
        waiver.lineOfBusiness = this.selectedLob;
        waiver.reason = this.reason;
        waiver.status = 'PENDING';
        waiverArray.push(waiver);
      }
    });
    return waiverArray;
  }

  /**
   * This method is called within the submitExtension method. It populates the extension and prepares it for saving.
   */
  prepareExtension(): Extension[] {
    let extensionArray: Extension[] = [];
    this.availableStates.forEach((selectedState: AvailableStates) => {
      if (selectedState.selected) {
        let extension = new Extension();
        extension.company = this.company;
        extension.year = this.year;
        extension.lineOfBusiness = this.selectedLob;
        extension.state = selectedState.state;
        extension.status = 'PENDING';
        extension.reason = this.reason;
        extension.extensionNumber = this.selectedExtensionRequest.extensionNumber;
        extension.endDate = moment(this.dpValue).format('YYYY-MM-DD');
        extensionArray.push(extension);
      }
    });
    return extensionArray;
  }

  showSubmit(): boolean {
    let lobs = this.lobs && this.lobs.length > 0;
    if (lobs) {
      return true;
    }
    return false;
  }

  submitDisabled(): boolean {
    let hasSelectedStates = this.hasSelectedStates();
    if (!hasSelectedStates) {
      return true;
    }
    if (this.onlyWhitespace(this.reason)) {
      return true;
    }
    if (this.type === 'extension') {
      if (this.dpValue === null || this.dpValue === undefined) {
        return true;
      }
    }
    return false;
  }

  private onlyWhitespace(text: string): boolean {
    if (text === null) {
      return true;
    }
    if (text === undefined) {
      return true;
    }
    if (text.length === 0) {
      return true;
    }
    let trimmedValue = _.trim(text);
    let isWhitespace = trimmedValue.length === 0;
    return isWhitespace;
  }

}
