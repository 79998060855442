import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../../shared/http-error-handler.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Company } from '../../shared/model/company';
import { ApplicationAdministration } from '../model/application-administration';

@Injectable()
export class ApplicationAdminService {
  userAdminCompany: Company;
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('ApplicationAdminService');
  }

  getApplicationInfo(): Observable<ApplicationAdministration> {
    const url = `${environment.apiUrl}applicationStatus`;
    console.log()
    return this.httpClient.get<ApplicationAdministration>(url)
      .pipe(catchError(this.handleError('getApplicationInfo', null)));
  }

  putApplicationInfo(applicationInfo: ApplicationAdministration): Observable<any> {
    const url = `${environment.apiUrl}applicationStatus`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.put<any>(url, applicationInfo, options)
      .pipe(catchError(this.handleError('postApplicationInfo', null)));
  }
  
}
