import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';
import { RulesResults } from '../model/rules-results';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Results } from '../model/results';
import { Rules } from '../model/rules';

@Injectable()
export class RulesResultsService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('RulesResultsService');
  }

  getRulesResulesByCompanyYearAndLob(correspondenceId: string): Observable<RulesResults> {
    const url = `${environment.apiUrl}correspondences/${correspondenceId}/ruleResults`;

    return this.httpClient.get<RulesResults>(url)
      .pipe(catchError(this.handleError('getRulesResulesByCompanyYearAndLob', null)));
  }

  putSaveRuleResults(correspondenceId: string, ruleResults: RulesResults) {
    let url = `${environment.apiUrl}correspondences/${correspondenceId}/ruleResults`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.put(url, ruleResults, options)
      .pipe(catchError(this.handleError('putSaveRuleResults', null)));
  }

  filteringTable(warningSelectedToFilter: any, rulesResultsTable: Rules[], resetRulesResults: Rules[], enteredThreshold: any): Rules[] {
    let filteredWarnings: any[];
    let listOfThresholds: any[] = [];
    // Warning Filter
    const warning = {
      filter: [warningSelectedToFilter]
    }
    if (warningSelectedToFilter === 1) {
      rulesResultsTable = resetRulesResults;
    } else {
      rulesResultsTable = rulesResultsTable.filter(a =>
        a.ruleResults.some(b => warning.filter.includes(b.resultStatus)
        ))
        .map(element => {
          let n = Object.assign({}, element, {
            'ruleResults': element.ruleResults.filter(
              subElement => warning.filter.includes(subElement.resultStatus)
            )
          })
          return n;
        });
    }
    filteredWarnings = rulesResultsTable;
    // Threshold Filter
    if (enteredThreshold === undefined) {
      enteredThreshold = 0;
    }
    for (let i = (-enteredThreshold); i <= enteredThreshold; i++) {
      listOfThresholds.push(i);
    }
    const threshold = {
      filter: listOfThresholds
    }
    if (listOfThresholds.length === 0 || listOfThresholds.length === 1) {
      rulesResultsTable = filteredWarnings;
    } else {
      rulesResultsTable = filteredWarnings.filter(a =>
        a.ruleResults.some(b =>
          threshold.filter.includes(b.difference)
        ))
        .map(element => {
          let n = Object.assign({}, element, {
            'ruleResults': element.ruleResults.filter(
              subElement => threshold.filter.includes(subElement.difference)
            )
          })
          return n;
        });
    }
    return rulesResultsTable;
  }
}
