import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { ReviewLetter } from '../review-letter/model/review-letter';
import { takeUntil } from 'rxjs/operators';
import { PreviewLetterService } from './service/preview-letter.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DashboardService } from '../dashboard/service/dashboard.service';

@Component({
  selector: 'app-preview-letter',
  templateUrl: './preview-letter.component.html',
  styleUrls: ['./preview-letter.component.css'],
})
export class PreviewLetterComponent implements OnInit {
  @ViewChild('previewLetter', { static: true }) previewLetter;
  @Input() cocode: number;
  @Input() year: number;
  @Input() reviewLetter: ReviewLetter;
  @Input() id: string;
  @Input() flagForViewingPdfFromDashboard: boolean;
  @Input() pdfLink: string;
  private unsubscribe: Subject<void> = new Subject();
  title: string;

  constructor(
    private previewLetterService: PreviewLetterService,
    private dashboardService: DashboardService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    if (this.flagForViewingPdfFromDashboard === false || this.flagForViewingPdfFromDashboard === undefined) {
      this.title = 'Preview Letter';
      this.viewPDF();
    } else {
      this.title = 'View Letter';
      this.viewPdfFromDashboard();
    }
  }

  viewPdfFromDashboard() {
    this.dashboardService.getPDF(this.id, this.pdfLink).subscribe((response) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      this.previewLetter.pdfSrc = blob;
      this.previewLetter.refresh();
    });
  }

  viewPDF() {
    let cocodeToString = this.cocode.toString();
    let yearToString = this.year.toString();
    this.previewLetterService.postViewPdf(cocodeToString, yearToString, this.reviewLetter)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        response => {
          const blob = new Blob([response], { type: 'application/pdf' });
          this.previewLetter.pdfSrc = blob;
          this.previewLetter.refresh();
        }
      );
  }

}
