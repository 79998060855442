import { Dashboards } from "../../correspondence/dashboard/model/dashboards";

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}


export const MOCK_CORRESPONDENCE_DASHBOARD: Dashboards = {
    'id': '34',
    dashboard: [
        {
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'id': 'asasas',
            'status': '1st Correspondence',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': [
                {
                    'dateSent': '2020-10-17T20:10:54.878+00:00',
                    'pdfLink': 'Pdf.link'
                },
                {
                    'dateSent': '2020-09-17T20:10:54.878+00:00',
                    'pdfLink': 'Pdf.link'
                }
            ]
        }, {
            'id': 'asasas',
            'status': '2nd Notice',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2021,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasasasdf',
            'status': 'COMPANY_WORK_IN_PROGRESS',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2022,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'ewrasdfsf',
            'status': 'STATE_HANDLING',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': '',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2025,
            'lobCode': 'HEALTH',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': [
                {
                    'dateSent': '2020-10-17T20:10:54.878+00:00',
                    'pdfLink': 'Pdf.link'
                },
                {
                    'dateSent': '2020-10-17T20:10:54.878+00:00',
                    'pdfLink': 'Pdf.link'
                }
            ]
        }, {
            'id': 'dfgkldhfgjkh',
            'status': 'Refiled with Comments',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'jhsifusmnsdf',
            'status': 'NO_ACTION_REQUIRED',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Draft',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'id': 'asasas',
            'status': '1st Correspondence',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasas',
            'status': '2nd Notice',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2021,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasasasdf',
            'status': 'COMPANY_WORK_IN_PROGRESS',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2022,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'ewrasdfsf',
            'status': 'STATE_HANDLING',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2025,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'dfgkldhfgjkh',
            'status': 'Refiled with Comments',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'jhsifusmnsdf',
            'status': 'NO_ACTION_REQUIRED',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Draft',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'id': 'asasas',
            'status': '1st Correspondence',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasas',
            'status': '2nd Notice',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2021,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasasasdf',
            'status': 'COMPANY_WORK_IN_PROGRESS',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2022,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'ewrasdfsf',
            'status': 'STATE_HANDLING',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2025,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'dfgkldhfgjkh',
            'status': 'Refiled with Comments',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'jhsifusmnsdf',
            'status': 'NO_ACTION_REQUIRED',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Draft',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'id': 'asasas',
            'status': '1st Correspondence',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasas',
            'status': '2nd Notice',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2021,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasasasdf',
            'status': 'COMPANY_WORK_IN_PROGRESS',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2022,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'ewrasdfsf',
            'status': 'STATE_HANDLING',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2025,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'dfgkldhfgjkh',
            'status': 'Refiled with Comments',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'jhsifusmnsdf',
            'status': 'NO_ACTION_REQUIRED',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Draft',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'id': 'asasas',
            'status': '1st Correspondence',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasas',
            'status': '2nd Notice',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2021,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasasasdf',
            'status': 'COMPANY_WORK_IN_PROGRESS',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2022,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'ewrasdfsf',
            'status': 'STATE_HANDLING',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2025,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'dfgkldhfgjkh',
            'status': 'Refiled with Comments',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'jhsifusmnsdf',
            'status': 'NO_ACTION_REQUIRED',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Draft',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'id': 'asasas',
            'status': '1st Correspondence',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasas',
            'status': '2nd Notice',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2021,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasasasdf',
            'status': 'COMPANY_WORK_IN_PROGRESS',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2022,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'ewrasdfsf',
            'status': 'STATE_HANDLING',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2025,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'dfgkldhfgjkh',
            'status': 'Refiled with Comments',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'jhsifusmnsdf',
            'status': 'NO_ACTION_REQUIRED',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Draft',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'id': 'asasas',
            'status': '1st Correspondence',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasas',
            'status': '2nd Notice',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2021,
            'lobCode': 'ANNUITY',
            'letterProgress': 'No Letter',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'asasasasdf',
            'status': 'COMPANY_WORK_IN_PROGRESS',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2022,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'ewrasdfsf',
            'status': 'STATE_HANDLING',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2025,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'dfgkldhfgjkh',
            'status': 'Refiled with Comments',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }, {
            'id': 'jhsifusmnsdf',
            'status': 'NO_ACTION_REQUIRED',
            'changeStatus': 'Refiled with Comments',
            'cocode': 60051,
            'groupCode': 123456,
            'numValidationsSent': 2056,
            'analystNotes': '0123456789',
            'companyResponse': '9876543210',
            'finalResolution': 'Refiled with Comments',
            'companyName': ' Marsh Shpg South El Monte Insurance',
            'filingYear': 2019,
            'lobCode': 'ANNUITY',
            'letterProgress': 'Sent',
            'discoverDate': '2020-05-17T20:10:54.878+00:00',
            'correspondenceDate': '2020-03-17T20:10:54.878+00:00',
            'followupDate': '2020-06-17T20:10:54.878+00:00',
            'closedDate': '2020-10-17T20:10:54.878+00:00',
            'senderId': 'ahgyrm',
            'sentPDFList': []
        }]
};