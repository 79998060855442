export const MOCK_HELP_DESK_COMPANIES: any[] = [
    {cocode: 123456, companyName: 'AAA Comp', active: true, statementType: 'L'},
    {cocode: 234567, companyName: 'BBB Comp', active: true, statementType: 'L'},
    {cocode: 345678, companyName: 'CCC Comp', active: false, statementType: 'P'},
    {cocode: 456789, companyName: 'DDD Comp', active: true, statementType: 'L'},
    {cocode: 567890, companyName: 'EEE Comp', active: false, statementType: 'L'},
    {cocode: 678901, companyName: 'FFF Comp', active: true, statementType: 'P'},
    {cocode: 789012, companyName: 'GGG Comp', active: true, statementType: 'P'},
    {cocode: 890123, companyName: 'HHH Comp', active: false, statementType: 'L'}
];
