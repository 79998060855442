import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { McsUserService } from './service/mcs-user.service';
import { DisclaimerModule } from './disclaimer/disclaimer.module';
import { FilingMatrixModule } from './filing-matrix/filing-matrix.module';
import { McasToolbarModule } from './mcas-toolbar/mcas-toolbar.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogService } from './service/dialog.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { NcuiModule } from 'common-ui-components';
import { MessageService } from './messages/service/message.service';
import { HttpErrorHandler } from './shared/http-error-handler.service';
import { UserAdminModule } from './user-admin/user-admin.module';
import { LoadingModule } from '../../node_modules/ngx-loading';
import { FormsModule } from '../../node_modules/@angular/forms';
import { CommonModule } from '../../node_modules/@angular/common';
import { DataService } from './filing-matrix/data/data.service';
import { FilingMatrixService } from './filing-matrix/services/filing-matrix.service';
import { HelpDeskModule } from './help-desk/help-desk.module';
import { RequestInterceptor } from './shared/request.interceptor';
import { LandingComponent } from './landing/landing.component';
import { NoRoleComponent } from './no-role/no-role.component';
import { NoCocodesComponent } from './no-cocodes/no-cocodes.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { TimeoutComponent } from './timeout/timeout.component';
import { MessagesModule } from './messages/messages.module';
import { ApplicationAdminModule } from './application-admin/application-admin.module';
import { AppShutDownComponent } from './app-shut-down/app-shut-down.component';
import { CorrespondenceModule } from './correspondence/correspondence.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MultipleExtensionsModalComponent } from './filing-matrix/waivers-extensions/multiple-extensions-modal/multiple-extensions-modal.component';
import { NgIdleModule } from '@ng-idle/core';
import { UserSessionInactivePopupComponent } from './user-session-inactive-popup/user-session-inactive-popup.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true
      }
    }),
    ModalModule.forRoot(),
    NgIdleModule.forRoot(),
    SharedModule,
    BrowserAnimationsModule,
    LoadingModule,
    NcuiModule,
    MessagesModule,
    DisclaimerModule,
    FilingMatrixModule,
    McasToolbarModule,
    UserAdminModule,
    CorrespondenceModule,
    ApplicationAdminModule,
    HelpDeskModule,
    AppRoutingModule,
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LandingComponent,
    NoRoleComponent,
    NoCocodesComponent,
    TimeoutComponent,
    AppShutDownComponent,
    UserSessionInactivePopupComponent
  ],
  providers: [
    DialogService,
    McsUserService,
    DataService,
    FilingMatrixService,
    HttpErrorHandler,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
  ],
  entryComponents: [TimeoutComponent, UserSessionInactivePopupComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  /*constructor(router: Router) {
    console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }*/
}
