import { Component, OnInit } from '@angular/core';
import { ApplicationAdminService } from '../application-admin/service/application-admin.service';
import { ApplicationAdministration } from '../application-admin/model/application-administration';
import { Subscription } from 'rxjs';
import { McsUserService } from '../service/mcs-user.service';

@Component({
  selector: 'app-shut-down',
  templateUrl: './app-shut-down.component.html',
  styleUrls: ['./app-shut-down.component.css']
})
export class AppShutDownComponent implements OnInit {
  message: string;
  appInfo: ApplicationAdministration;
  subscriptions: Array<Subscription> = [];
  title: string;

  constructor(
    private mcsUserService: McsUserService
  ) { }

  ngOnInit() {
    this.title = this.mcsUserService.appMessage;
  }

}
