<ngx-loading [show]="loading"></ngx-loading>
<main role="main" class="col ml-sm-auto px-4">
  <div id="disclaimer">
    <div class="row pl-2 pr-2">
      <div class="col-12 page-title">
        <h1>{{title}}</h1>
      </div>
    </div>
    <div class="row pl-2 pr-2">
      <div class="col-12">
        <app-messages [parentName]="'disclaimer'"></app-messages>
      </div>
    </div>
    <div *ngIf="disclaimer" class="row pl-2 pr-2">
      <div class="col-12">
        <div id="disclaimerDiv" class="multi-lines-text ml-3 mr-3">
          {{disclaimer.disclaimer}}
        </div>
        <br>
        <div class="mb-3" style="text-align: center;">
          <button id="btn:disclaimer:Agree" class="btn btn-primary" type="button" (click)="agree()">I Accept</button>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer d-print-none">
    <ncui-footer></ncui-footer>
  </footer>
</main>
