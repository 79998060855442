import { MOCK_WARNINGS, MOCK_ERRORS } from './mock-messages';
import { MOCK_COMPANIES } from './mock-companies';
import { MOCK_YEARS } from './mock-years';
import { MOCK_LINES_OF_BUSINESS } from './mock-lines-of-business';
import { MOCK_MCAS_STATES } from './mock-mcas-states';
import { MOCK_WAIVERS } from './mock-waivers';
import { MOCK_EXTENSIONS } from './mock-extensions';
import { MOCK_NOT_STARTED, MOCK_FILED, MOCK_IN_PROGRESS } from './mock-filing-statuses';

export const MOCK_FILING_MATRIX: any[] = [
  {
    id: 1,
    company: MOCK_COMPANIES[0],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    stateFilings: [
      {
        state: MOCK_MCAS_STATES[0],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: true,
            status: MOCK_FILED,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[0] }
            ],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: true,
            status: MOCK_NOT_STARTED,
            warnings: [
              { row: 7, message: MOCK_WARNINGS[0] },
              { row: 15, message: MOCK_WARNINGS[0] }
            ],
            errors: [],
            waiver: null,
            extension: null
          },
        ]
      },
      {
        state: MOCK_MCAS_STATES[1],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: true,
            status: MOCK_FILED,
            warnings: [],
            errors: [
              { row: 7, message: MOCK_ERRORS[0] },
              { row: 16, message: MOCK_ERRORS[0] }
            ],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: true,
            status: MOCK_FILED,
            warnings: [
              { row: 5, message: MOCK_WARNINGS[0] },
              { row: 8, message: MOCK_WARNINGS[0] }
            ],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      }
    ]
  },
  {
    id: 2,
    company: MOCK_COMPANIES[0],
    year: MOCK_YEARS[MOCK_YEARS.length - 2],
    linesOfBusiness: MOCK_LINES_OF_BUSINESS,
    stateFilings: [
      {
        state: MOCK_MCAS_STATES[0],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_FILED,
            warnings: [{ row: 10, message: MOCK_WARNINGS[0] }, { row: 15, message: MOCK_WARNINGS[0] }],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 2, message: MOCK_WARNINGS[0] },
              { row: 5, message: MOCK_WARNINGS[0] }
            ],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_FILED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      },
      {
        state: MOCK_MCAS_STATES[1],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: true,
            status: MOCK_FILED,
            warnings: [ { row: 6, message: MOCK_WARNINGS[0] }, { row: 7, message: MOCK_WARNINGS[0] } ],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: true,
            status: MOCK_FILED,
            warnings: [{ row: 9, message: MOCK_WARNINGS[0] }, { row: 11, message: MOCK_WARNINGS[0] }],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_FILED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      }
    ]
  },
  {
    id: 3,
    company: MOCK_COMPANIES[1],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    linesOfBusiness: MOCK_LINES_OF_BUSINESS,
    stateFilings: [
      {
        state: MOCK_MCAS_STATES[0],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [{ row: 3, message: MOCK_ERRORS[0] }, { row: 9, message: MOCK_ERRORS[0] }],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: true,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[1] }
            ],
            errors: [],
            waiver: MOCK_WAIVERS[0],
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      },
      {
        state: MOCK_MCAS_STATES[1],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [
              { row: 3, message: MOCK_ERRORS[0] },
              { row: 9, message: MOCK_ERRORS[0] }
            ],
            waiver: null,
            extension: MOCK_EXTENSIONS[0]
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[0] }
            ],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      }
    ]
  },
  {
    id: 4,
    company: MOCK_COMPANIES[2],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    linesOfBusiness: MOCK_LINES_OF_BUSINESS,
    stateFilings: [
      {
        state: MOCK_MCAS_STATES[0],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [{ row: 3, message: MOCK_ERRORS[0] }, { row: 9, message: MOCK_ERRORS[0] }],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: true,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[1] }
            ],
            errors: [],
            waiver: MOCK_WAIVERS[0],
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      },
      {
        state: MOCK_MCAS_STATES[1],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [
              { row: 3, message: MOCK_ERRORS[0] },
              { row: 9, message: MOCK_ERRORS[0] }
            ],
            waiver: null,
            extension: MOCK_EXTENSIONS[0]
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[0] }
            ],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      }
    ]
  },
  {
    id: 5,
    company: MOCK_COMPANIES[3],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    linesOfBusiness: MOCK_LINES_OF_BUSINESS,
    stateFilings: [
      {
        state: MOCK_MCAS_STATES[0],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [{ row: 3, message: MOCK_ERRORS[0] }, { row: 9, message: MOCK_ERRORS[0] }],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: true,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[1] }
            ],
            errors: [],
            waiver: MOCK_WAIVERS[0],
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      },
      {
        state: MOCK_MCAS_STATES[1],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [
              { row: 3, message: MOCK_ERRORS[0] },
              { row: 9, message: MOCK_ERRORS[0] }
            ],
            waiver: null,
            extension: MOCK_EXTENSIONS[0]
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[0] }
            ],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      }
    ]
  },
  {
    id: 6,
    company: MOCK_COMPANIES[4],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    linesOfBusiness: MOCK_LINES_OF_BUSINESS,
    stateFilings: [
      {
        state: MOCK_MCAS_STATES[0],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [{ row: 3, message: MOCK_ERRORS[0] }, { row: 9, message: MOCK_ERRORS[0] }],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: true,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[1] }
            ],
            errors: [],
            waiver: MOCK_WAIVERS[0],
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      },
      {
        state: MOCK_MCAS_STATES[1],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [
              { row: 3, message: MOCK_ERRORS[0] },
              { row: 9, message: MOCK_ERRORS[0] }
            ],
            waiver: null,
            extension: MOCK_EXTENSIONS[0]
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[0] }
            ],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      }
    ]
  },
  {
    id: 7,
    company: MOCK_COMPANIES[5],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    linesOfBusiness: MOCK_LINES_OF_BUSINESS,
    stateFilings: [
      {
        state: MOCK_MCAS_STATES[0],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [{ row: 3, message: MOCK_ERRORS[0] }, { row: 9, message: MOCK_ERRORS[0] }],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: true,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[1] }
            ],
            errors: [],
            waiver: MOCK_WAIVERS[0],
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      },
      {
        state: MOCK_MCAS_STATES[1],
        filings: [
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [],
            errors: [
              { row: 3, message: MOCK_ERRORS[0] },
              { row: 9, message: MOCK_ERRORS[0] }
            ],
            waiver: null,
            extension: MOCK_EXTENSIONS[0]
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
            required: false,
            status: MOCK_IN_PROGRESS,
            warnings: [
              { row: 3, message: MOCK_WARNINGS[0] },
              { row: 9, message: MOCK_WARNINGS[0] }
            ],
            errors: [],
            waiver: null,
            extension: null
          },
          {
            lineOfBusiness: MOCK_LINES_OF_BUSINESS[5],
            required: false,
            status: MOCK_NOT_STARTED,
            warnings: [],
            errors: [],
            waiver: null,
            extension: null
          }
        ]
      }
    ]
  }
];
