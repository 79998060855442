<ngx-loading [show]="loading"></ngx-loading>
<header>
  <ncui-header [hideAppHeader]="true" [loggedInUser]="getName()" [realm]="environmentName" (logout)="logout()"></ncui-header>
  <div *ngIf="!loading" class="app-header">
    <app-mcas-toolbar></app-mcas-toolbar>
  </div>
</header>
<div class="container-fluid" *ngIf="user && !loading; else noUser">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <router-outlet name="popup"></router-outlet>
</div>
<ng-template #noUser>
  <nav class="col-md-2 d-none d-md-block bg-light sidebar d-print-none">
    <div *ngIf="user" class="sidebar-sticky"></div>
  </nav>
  <main *ngIf="!loading" role="main" class="col-md-9 ml-sm-auto col-lg-10 d-print-none">
    <app-messages></app-messages>
  </main>
  <!-- <div *ngIf="!loading" class="col-12 mx-auto main-display text-center pt-2">
    <h2>User is not authorized</h2>
  </div> -->
</ng-template>
