import { Company } from '../../shared/model/company';
import { Year } from '../../shared/model/year';
import { LineOfBusiness } from './line-of-business';
import { McasState } from './mcas-state';

export class Waiver {
  company: Company;
  year: Year;
  state: McasState;
  lineOfBusiness: LineOfBusiness;
  endDate: string;
  approvedDate: string;
  status: string;
  reason: string;
  decisionDate?: string;
  decisionComments?: string;

  constructor(company?: Company, year?: Year, state?: McasState, lineOfBusiness?: LineOfBusiness, endDate?: string,
    approvedDate?: string, status?: string, reason?: string, decisionDate?: string, decisionComments?: string) {
    this.company = company || null;
    this.year = year || null;
    this.state = state || null;
    this.lineOfBusiness = lineOfBusiness || null;
    this.endDate = endDate || null;
    this.approvedDate = approvedDate || null;
    this.status = status || '';
    this.reason = reason || '';
    this.decisionDate = decisionDate || null;
    this.decisionComments = decisionComments || '';
    }
}
