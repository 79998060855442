import { Injectable } from '@angular/core';
import { McsUserService } from '../service/mcs-user.service';

@Injectable({
  providedIn: 'root'
})

export class AppShutDownServive {

  constructor(private userService: McsUserService) { }

  isAppClosed(): boolean {
    if (this.userService.isExternalUser()) {
      let appClosed = this.userService.isAppClosed;
      if ((appClosed === true)) { 
        return false;   
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}

