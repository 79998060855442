export class McasState {
  id: number;
  stateName: string;
  stabbr: string;

  constructor(stateName?: string, stabbr?: string) {
    this.stateName = stateName || '';
    this.stabbr = stabbr || '';
  }
}
