import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppShutDownServive } from './appShutDown.service';

@Injectable({
  providedIn: 'root'
})
export class AppShutDownGuard implements CanActivate {

    constructor(private appShutDownService: AppShutDownServive, private router: Router) {}
  
    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.checkIfAppIsClosed();
    }
  
    checkIfAppIsClosed(): boolean {
      if (this.appShutDownService.isAppClosed()) {
        return true;
      }
      this.router.navigate(['/appClosed']);
      return false;
    }
  }
