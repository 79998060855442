import { CompanyAssignment } from '../../user-admin/model/company-assignment';
import { MOCK_USERS } from './mock-users';
import { MOCK_COMPANIES } from './mock-companies';

export const MOCK_COMPANY_ASSIGNMENTS: CompanyAssignment[] = [
  {
  company: MOCK_COMPANIES[0],
  primaryUser: MOCK_USERS[0],
  secondaryUser: [MOCK_USERS[2], MOCK_USERS[4]]
},
{
  company: MOCK_COMPANIES[1],
  primaryUser: MOCK_USERS[1],
  secondaryUser: [MOCK_USERS[2], MOCK_USERS[4]]
},
{
  company: MOCK_COMPANIES[2],
  primaryUser: MOCK_USERS[2],
  secondaryUser: [MOCK_USERS[2], MOCK_USERS[4]]
},
{
  company: MOCK_COMPANIES[3],
  primaryUser: MOCK_USERS[3],
  secondaryUser: [MOCK_USERS[2], MOCK_USERS[4]]
},
];
