import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MessageService } from '../../../messages/service/message.service';
import { AlertMessage } from '../../../messages/model/alert-message';
import { Extension } from '../../model/extension';
import { WaiverExtensionService } from '../services/waiver-extension.service';
import { Company } from '../../../shared/model/company';
import { McsUserService } from '../../../service/mcs-user.service';
import * as moment from 'moment';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-extension-modal',
  templateUrl: './extension-modal.component.html',
  styleUrls: ['./extension-modal.component.css']
})
export class ExtensionModalComponent implements OnInit, OnDestroy {
  title: string;
  @Input() extension: Extension;
  @Input() company: Company;
  updated = false;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  dpValue: any;
  faCalendar = faCalendar;

  constructor(
    public bsModalRef: BsModalRef,
    public messageService: MessageService,
    private service: WaiverExtensionService,
    private userService: McsUserService,
  ) {
    this.dpConfig.containerClass = 'theme-dark-blue';
    this.dpConfig.showWeekNumbers = false;
    this.dpConfig.minDate = moment().toDate();
   }

  ngOnInit() {
    this.title = `Extension Request`;
    this.dpValue = moment(this.extension.endDate, 'YYYY-MM-DD').toDate();
    if (this.extension.lineOfBusiness.dueDate) {
      this.dpConfig.maxDate = moment(this.extension.lineOfBusiness.dueDate).add(60, 'days').toDate();
    } else {
      this.dpConfig.maxDate = moment().add(60, 'days').toDate();
    }
  }

  ngOnDestroy() {

  }

  onDelete(): void {
    this.service.deleteExtension(this.company.cocode, this.extension)
    .subscribe(
      () => {
        this.updated = true;
        const message = `Extension for ${this.company.longName} has been deleted for ${this.extension.state.stabbr}`;
        this.messageService.add(new AlertMessage(message, 'success', true, 0));
        this.bsModalRef.hide();
      }
    );
  }

  onUpdate(): void {
    this.extension.endDate = moment(this.dpValue).format('YYYY-MM-DD');
    this.service.putExtension(this.company.cocode, this.extension)
    .subscribe(
      response => {
        if (response) {
          this.updated = true;
          const message = `Extension for ${this.company.longName} has been updated for ${this.extension.state.stabbr}`;
          this.messageService.add(new AlertMessage(message, 'success', true, 0));
          this.bsModalRef.hide();
        }
      });
    this.bsModalRef.hide();
  }

  isUpdateDisabled(): boolean {
    return this.userService.isInternalUser() || (this.extension.status.toLowerCase() === 'approved' || this.extension.status.toLowerCase() === 'denied');
  }

}
