<button *ngIf="visible; else notVisible" id="btn:remove:{{params.value}}" class="btn btn-danger" (click)="deleteCorrespondence(params.value.id, deleteCorrespondenceModal)">
 X
</button>

<ng-template #deleteCorrespondenceModal>
  <div class="modal-header">
    <h3 class="modal-title">Delete Correspondence</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure you wish to delete this Correspondence? This cannot be undone.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="cancelDelete()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="confirmDelete()">Delete</button>
  </div>
</ng-template>

<ng-template #notVisible>-</ng-template>
