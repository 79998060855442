import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NavigationItem } from '../model/navigation-item';
import { FilingWizardService } from '../service/filing-wizard.service';
import { faPlus, faMinus, faDotCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.css']
})
export class NavListComponent implements OnInit, OnChanges {
  @Input() navItem: NavigationItem;
  @Input() level: number;
  @Input() parentId: string;
  @Input() activeSection: string;
  @Output() target = new EventEmitter<string>();
  public isCollapsed = true;


  faPlus = faPlus;
  faMinus = faMinus;
  faDotCircle = faDotCircle;
  faCircle = faCircle;

  constructor(private filingWizardService: FilingWizardService) {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.activeSection && !_.isNull(this.activeSection)) {
      this.goToNav(this.activeSection);
    }
  }

  isDataChanged(): boolean {
    return this.filingWizardService.dataChanged;
  }

  getStyle(): string[] {
    return this.filingWizardService.activeSection === this.navItem.target ? ['nav-link', 'active'] : ['nav-link'];
  }

  getParentStyle(): string[] {
    let style: string[] = ['nav-link'];
    const activeSection = this.filingWizardService.activeSection;
    if (this.navItem.subNav && this.navItem.subNav.length > 0) {
      this.navItem.subNav.forEach((item: NavigationItem) => {
        if (item.target === activeSection) {
          style.push('active');
          this.isCollapsed = false;
        }
      });
    }
    return style;
  }

  getSubNavStyle(): string[] {
    return ['nav', 'nav-pills', 'flex-column', `ml-${this.level + 2}`];
  }

  getCollapseId(): string {
    return `collapse${this.getSectionId()}`;
  }

  getSectionId(): string {
    let sectionId = '';
    if (this.parentId) {
      sectionId = `${this.parentId}-${this.navItem.name}`;
    } else {
      sectionId = this.navItem.name;
    }
    return sectionId;
  }

  goToSection(target: string): void {
    this.filingWizardService.activeSection = target;
    this.target.emit(target);
  }

  goToNav(target: string): void {
    try {
      const navItem = document.getElementById(target);
      if (navItem && navItem !== null) {
        navItem.scrollIntoView();

      const supportPageOffset = window.pageYOffset !== undefined;
      const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');

      const scrolledY = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

      const yScroll = scrolledY - 124;

      if (scrolledY) {
        window.scroll(0, yScroll);
      }
      }

    } catch (e) {
      console.error('scroll not working');
      console.error(e);
    }
  }

  setCollapse(section: string) {
    const sectionCollapsed = this.filingWizardService.isSectionCollapsed(section);
    const parent = this.filingWizardService.getSectionCollapsedParent(section);
    if (sectionCollapsed) {
      this.filingWizardService.setSectionCollapsed(section, false, parent);
    }
    if (parent && parent !== null) {
      this.setCollapse(parent);
    }
  }

}
