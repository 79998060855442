import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataUploadComponent } from './data-upload.component';
import { FormsModule } from '@angular/forms';
import { MessagesModule } from '../../messages/messages.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { LoadingModule } from 'ngx-loading';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MessagesModule,
    ProgressbarModule.forRoot(),
    LoadingModule
  ],
  declarations: [DataUploadComponent],
  exports: [DataUploadComponent]
})
export class DataUploadModule { }
