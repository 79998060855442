import { Component, TemplateRef } from '@angular/core';
import { faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ICellRenderer } from 'ag-grid-community';
import { FinalResolution } from '../../model/finalResolution';
import { User } from '../../../../shared/model/user';
import { McsUserService } from '../../../../service/mcs-user.service';

@Component({
  selector: 'app-final-resolution-renderer',
  templateUrl: './final-resolution-renderer.component.html',
  styleUrls: ['./final-resolution-renderer.component.css']
})
export class FinalResolutionRendererComponent implements ICellRenderer {
  params: any;
  faTimes = faTimes;
  fas = fas;
  deleteKey: string;
  modalRef: BsModalRef;
  visible: boolean;
  finalResolution: FinalResolution[];
  selectedStatus: string;
  correspondenceId: string;
  status: any;
  user: User;

  constructor(
    private modalService: BsModalService,
    private userService: McsUserService
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.user = this.userService.getStoredUser();
    this.checkRegulatorRole();
    this.onlyForSent();
    this.optionsForFinalResolution();
    // console.log(this.params.data.finalResolution.finalResolution);
  }

  checkRegulatorRole() : boolean {
    if(this.user.role === "MCAS_ISITE_USER_PR") {
      return false;
    } else {
      return true;
    }
  }

  onlyForSent() {
    if (this.params.data.letterProgress === 'Sent' || this.params.data.letterProgress === 'No Letter') {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  refresh(): boolean {
    return true;
  }

  optionsForFinalResolution() {
    this.finalResolution = [
      { name: '', value: 'NO_STATUS' },
      { name: 'Refiled', value: 'REFILED' },
      { name: 'Refiled with Comments', value: 'REFILED_WITH_COMMENTS' },
      { name: 'Comments only', value: 'COMMENTS_ONLY' }
    ];
    if (this.params.data.finalResolution.finalResolution === 'Refiled') {
      this.selectedStatus = this.finalResolution[1].value;
    } else if (this.params.data.finalResolution.finalResolution === 'Refiled with Comments') {
      this.selectedStatus = this.finalResolution[2].value;
    } else if (this.params.data.finalResolution.finalResolution === 'Comments Only') {
      this.selectedStatus = this.finalResolution[3].value;
    } else {
      this.selectedStatus = null;
    }
  }

  getId(id: string, $event) {
    this.correspondenceId = id;
    this.status = $event.target.value;
    let finalResolution: any = {
      finalResolution: this.status
    };
    this.params.context.componentParent.setFinalResolution(this.correspondenceId, finalResolution);
  }

}
