<ngx-loading [show]="loading"></ngx-loading>

<main *ngIf="!loading" role="main">
  <div class="pt-3 pb-3 main-content d-print-block">

    <div class="row">
      <div class="col-12">
        <h3> {{title}} </h3>
        <hr>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>

    <form name="appInfoForm" (ngSubmit)="save()" #appInfoForm="ngForm">
      <div class="form-group">

        <!-- Turn OFF Application -->
        <div class="checkbox">
          <label style="padding-left: 5px;"> 
            <input 
                type="checkbox" 
                aria-label="required" 
                title="TurnOffApp" 
                name="turnOffApp" 
                [ngModel]="applicationAdministartion?.turnOnOrOff" 
                (ngModelChange)="applicationAdministartion.turnOnOrOff = $event" 
                #turnOffApp="ngModel"><!-- (change)="turnOff($event)" -->
                Turn OFF MCAS Application
          </label>
        </div>
      </div>

      <!-- Shut Down Message -->
      <div class="form-group">
        <div style="display:-webkit-box; flex-direction: row; padding-right: 12rem; padding-left: 26px;" class="text">
          <label for="shutDownMessage" id="shutDownMessage" style="padding-right: 10px;"> MCAS Shut Down Message:
          </label>
          <textarea 
              type="text" 
              class="md-textarea md-textarea-auto form-control" 
              title="shutDownMessage"
              aria-describedby="shutDownMessage" 
              name="shutDownMessage" 
              style="height: 20rem;" 
              #shutDownMessage="ngModel" 
              [ngModel]="applicationAdministartion?.offMessage" 
              (ngModelChange)="applicationAdministartion.offMessage = $event" 
              [disabled]="!applicationAdministartion?.turnOnOrOff"
              notOnlyWhitespace>
          </textarea><!--   -->
        </div>
      </div>

      <!-- TODO: Audit Trail -->
      <div>
        <button type="button" class="btn btn-secondary" disabled hidden><i class="fa fa-floppy-o"></i> Audit Trail</button>
        <!-- Save & Cancel -->
        <div class="float-right">
          <button type="button" class="btn btn-link btn-sm fa fa-floppy-o" (click) = "cancel()"> Cancel</button>
          <button id="save_button" type="submit" class="btn btn-primary" [disabled]="!appInfoForm.form.valid || !appInfoForm.form.dirty || (applicationAdministartion?.offMessage === '' && applicationAdministartion?.turnOnOrOff)">
            <i class="fa fa-floppy-o"></i> Save
          </button>
        </div>
      </div>

    </form>

  </div>

    <div class="row">
      <footer class="col-12 footer d-print-none">
        <ncui-footer></ncui-footer>
      </footer>
    </div>

</main>