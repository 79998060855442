<div class="btn-group">
  <button id="drop:editViewQuickSend" class="btn btn-primary btn-md dropdown-toggle" type="button" data-toggle="dropdown"
    aria-haspopup="true" aria-expanded="false">
    {{title}}
  </button>
  <div class="dropdown-menu">
    <a class="dropdown-item" id="ln:edit"
      (click)=edit(params.value.id)>
      Edit
    </a>
    <div *ngIf="visible" class="dropdown-divider"></div>
    <a *ngIf="visible" class="dropdown-item" id="ln:viewQuickSend"
      (click)=viewQuickSend(params.value.id)>
      View/Quick Send
    </a>
  </div>