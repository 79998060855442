import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FilingBlank } from '../model/filing-blank';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { NavigationItem } from '../model/navigation-item';
import { FormSection } from '../model/form-section';
import { FormQuestion } from '../model/form-question';
import { MockFilingBlank } from '../model/mock-filing-blanks';
import { FilingData } from '../model/filing-data';
import * as _ from 'lodash';
import { InputErrorWarning } from '../model/input-error-warning';

@Injectable({
  providedIn: 'root'
})
export class FilingWizardService {
  readonly handleError: HandleError;
  private sectionCollapsed = {};
  data: any = {};
  dataChanged = false;
  activeSection = '';
  lastTarget = '';
  sectionErrors: InputErrorWarning[] = [];
  sectionWarnings: InputErrorWarning[] = [];
  sectionForm: FormSection;

  filingBlanks = MockFilingBlank;

  navigation: NavigationItem[] = [
    { name: 'INTERROGATORIES', target: 'INTERROGATORIES', subNav: [] },
    { name: 'IN-EXCHANGE (INDIVIDUAL)', target: '', subNav: [
      { name: 'POLICY ADMINISTRATION', target: 'INEXCHANGEINDIVIDUALPOLICYADMINISTRATION', subNav: [] },
      { name: 'PRIOR AUTHORIZATIONS (PROSPECTIVE UTILIZATION REVIEW REQUESTS) EXCLUDING PHARMACY', target: 'INEXCHANGEINDIVIDUALPRIORAUTHORIZATIONSEXCLUDINGPHARMACY', subNav: []}]
      // { name: 'PRIOR AUTHORIZATIONS (PROSPECTIVE UTILIZATION REVIEW REQUESTS) - PHARMACY ONLY', target: 'INEXCHANGEINDIVIDUALPRIORAUTHORIZATIONSPHARMACYONLY', subNav: [] },
      // { name: 'CLAIMS ADMINISTRATION (EXCLUDING PHARMACY)', target: 'INEXCHANGEINDIVIDUALCLAIMSADMINISTRATIONEXCLUDINGPHARMACY', subNav: [] },
      // { name: 'CLAIMS ADMINISTRATION (PHARMACY ONLY)', target: 'INEXCHANGEINDIVIDUALCLAIMSADMINISTRATIONPHARMACYONLY', subNav: [] },
      // { name: 'CONSUMER REQUESTED INTERNAL REVIEWS (GRIEVANCES - INCLUDING PHARMACY)', target: 'INEXCHANGEINDIVIDUALCONSUMERREQUESTEDINTERNALREVIEWSGRIEVANCESINCLUDINGPHARMACY', subNav: [] },
      // { name: 'CONSUMER REQUESTED EXTERNAL REVIEWS (INCLUDING PHARMACY)', target: 'INEXCHANGEINDIVIDUALCONSUMERREQUESTEDEXTERNALREVIEWSINCLUDINGPHARMACY', subNav: [] }]
    }
  ];

  constructor(
    private httpClient: HttpClient,
    public httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      'FilingWizardService'
    );
  }

  getFilingBlank(code: string, year: number, cocode: string, state: string, target?: string): Observable<FilingBlank> {
    let url = `${environment.apiUrl}filingblank`;
    let params;
    if (target && target.length > 0) {
      params = new HttpParams()
        .set('lob', code)
        .set('year', String(year))
        .set('cocode', cocode)
        .set('stabbr', state)
        .set('target', target);
    } else {
      params = new HttpParams()
      .set('lob', code)
      .set('year', String(year))
      .set('cocode', cocode)
      .set('stabbr', state);
    }

    const options = { params: params };

    return this.httpClient.get<FilingBlank>(url, options)
    .pipe(catchError(this.handleError('getFilingBlank', null)));
  }

  getFilingNavigation(code: string, year: number, cocode: string, state: string): Observable<any> {
    let url = `${environment.apiUrl}filingblank/navigation`;

    const params = new HttpParams().set('lob', code).set('year', String(year));
    const options = {params: params};

    return this.httpClient.get<any>(url, options)
      .pipe(catchError(this.handleError('getFilingBlank', null)));
  }

  // Used for saving filing data
  putFilingData(filingData: FilingData, returnTarget: string): Observable<any> {
    let url = `${environment.apiUrl}filingblank/filingdata`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params;
    let options;
    if (returnTarget && returnTarget.length > 0) {
      params = new HttpParams().set('returnTarget', returnTarget);
    }
    if (params) {
      options = { headers: headers, params: params };
    } else {
      options = { headers: headers };
    }

    return this.httpClient.put(url, filingData, options)
    .pipe(catchError(this.handleError('putFilingData', null)));

  }

  // Used for submitting filing data
  postFilingData(filingData: FilingData, code: string, year: number): Observable<FilingBlank> {
    let url = `${environment.apiUrl}filingblank/filingdata/submission`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams().set('lobCode', code).set('year', String(year));
    let options = { headers: headers, params: params };

    // return of(new FilingBlank);
    return this.httpClient.post<FilingBlank>(url, filingData, options);
      // .pipe(catchError(this.handleError('postFilingData', null)));
  }

  validateFilingData(filingData: FilingData, code: string, year: number, returnTarget: string): Observable<FilingBlank> {
    let url = `${environment.apiUrl}filingblank/filingdata/validation`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params;
    let options;
    if (returnTarget && returnTarget.length > 0) {
      params = new HttpParams().set('lobCode', code).set('year', String(year)).set('returnTarget', returnTarget);
    } else {
      params = new HttpParams().set('lobCode', code).set('year', String(year));
    }
    options = { headers: headers, params: params };

    return this.httpClient.post<FilingBlank>(url, filingData, options)
      .pipe(catchError(this.handleError('validateFilingData', null)));
  }

  prepareFilingData(filingData: { [k: string]: any }) {
    Object.keys(filingData).forEach(section => {
      let sections = section.split('-');
      if (isNaN(Number(sections[sections.length - 1]))) {
        this.prepareFilingData(filingData[section]);
      } else {
        /*console.log('key:: ' + section);
        console.log('value:: ' + JSON.stringify(filingData[section]));*/
      }
    });
  }

  setSectionCollapsed(section: string, collapsed: boolean, parentId: string): void {
    this.sectionCollapsed[section] = {
      parentId: parentId,
      collapsed: collapsed
    };
  }

  isSectionCollapsed(section: string): boolean {
    return this.sectionCollapsed[section].collapsed;
  }

  getSectionCollapsedParent(section: string): any {
    return this.sectionCollapsed[section].parentId;
  }

  totalErrors(filing: FilingBlank): number {
    let totalErrors = 0;
    if (filing) {
      filing.sections.forEach((section: FormSection) => {
        this.countErrors(section);
      });
    }
    totalErrors = this.sectionErrors.length;
    return totalErrors;
  }

  totalWarnings(filing: FilingBlank): number {
    let totalWarnings = 0;
    if (filing) {
      filing.sections.forEach((section: FormSection) => {
        this.countWarnings(section);
      });
    }
    totalWarnings = this.sectionWarnings.length;
    return totalWarnings;
  }

  countErrors(section: FormSection): number {
    this.sectionForm = section;
    let errorCount = 0;
    section.questions.forEach((question: FormQuestion) => {
      let errors: string[] = [];
      question.inputs.forEach(input => {
        if (input.errors) {
          input.errors.forEach(error => {
            if (errors.indexOf(error.message) === -1) {
              errors.push(error.message);
            }
            if (this.sectionErrors.indexOf(error.message) === -1) {
              this.sectionErrors.push(error.message);
            }
          });
          errorCount = this.sectionErrors.length;
        }
      });
    });
    return errorCount;
  }

  countWarnings(section: FormSection): number {
    let warningCount = 0;
    section.questions.forEach((question: FormQuestion) => {
      let warnings: string[] = [];
      question.inputs.forEach(input => {
        if (input.warnings) {
          input.warnings.forEach(warning => {
            if (warnings.indexOf(warning.message) === -1) {
              warnings.push(warning.message);
            }
            if (this.sectionWarnings.indexOf(warning.message) === -1) {
              this.sectionWarnings.push(warning.message);
            }
          });
          warningCount = this.sectionWarnings.length;
        }
      });
    });
    return warningCount;
  }

  lockUnlock(unlock: boolean, code: string, year: number, cocode: string, state: string): Observable<FilingBlank> {
    let status = unlock ? true : false;
    let url = `${environment.apiUrl}filingblank`;
    const params = new HttpParams().set('cocode', cocode).set('year', String(year)).set('lobCode', code).set('stabbr', state).set('locked', String(status));
    const options = {params: params};

    return this.httpClient.put(url, {}, options)
      .pipe(catchError(this.handleError('lockUnlock', null)));
  }

  formatSectionName(sectionName: string): string {
    let sections = sectionName.split('.');
    return sections.join(' > ');
  }
}


