import { NgModule } from '@angular/core';
import { FilingMatrixComponent } from './filing-matrix.component';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../shared/role.guard';
import { FilingWizardComponent } from './filing-wizard/filing-wizard.component';
import { CocodesGuard } from '../shared/cocodes.guard';
import { SummaryFilingMatrixComponent } from './summary-filing-matrix/summary-filing-matrix.component';
import { AppShutDownGuard } from '../shared/appShutDown.guard';


const filingMatrixRoutes: Routes = [
  { path: 'filingMatrix', component: FilingMatrixComponent, canActivate: [RoleGuard, CocodesGuard, AppShutDownGuard] },
  { path: 'summaryFilingMatrix', component: SummaryFilingMatrixComponent},
  { path: 'filingWizard', component: FilingWizardComponent, canActivate: [RoleGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(filingMatrixRoutes)
  ],
  exports: [RouterModule]
})
export class FilingMatrixRoutingModule { }
