import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimerComponent } from './disclaimer.component';
import {DisclaimerRoutingModule} from './disclaimer-routing.module';
import { DisclaimerService } from './service/disclaimer.service';
import {MessagesModule} from '../messages/messages.module';
import { LoadingModule } from '../../../node_modules/ngx-loading';
import { NcuiModule } from 'common-ui-components';

@NgModule({
  imports: [
    CommonModule,
    LoadingModule,
    NcuiModule,
    MessagesModule,
    DisclaimerRoutingModule
  ],
  declarations: [DisclaimerComponent],
  providers: [DisclaimerService],
  exports: [DisclaimerComponent]
})
export class DisclaimerModule { }
