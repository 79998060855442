<div id="previewHeader" class="modal-header">
  <h4 id="previewLetter" class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div id="previewBody" class="modal-body" style="height: 650px;">
  <!-- <app-messages [parentName]="'previewLetter'"></app-messages> -->
  <ng2-pdfjs-viewer #previewLetter [externalWindow]="false"
    [downloadFileName]="'MCAS_CORRESPONDENCE.pdf'" [openFile]="false" [viewBookmark]="false" [download]="false"
    scroll="false"></ng2-pdfjs-viewer>
</div>

