import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataModule } from '../data/data.module';
import { HttpClientModule } from '@angular/common/http';
import { SummaryFilingMatrixComponent } from './summary-filing-matrix.component';
import { YearsService } from '../company-year-select/services/years.service';
import { CompanyService } from '../company-year-select/services/company.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoTopButtonModule } from 'ng2-go-top-button';
import { LoadingModule } from 'ngx-loading';
import { NcuiModule } from 'common-ui-components';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { MessagesModule } from '../../messages/messages.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataModule,
    FontAwesomeModule,
    GoTopButtonModule,
    LoadingModule,
    NcuiModule,
    CollapseModule.forRoot(),
    MessagesModule
  ],
  declarations: [SummaryFilingMatrixComponent],
  providers: [YearsService, CompanyService],
  exports: [SummaryFilingMatrixComponent]
})
export class SummaryFilingMatrixModule { }
