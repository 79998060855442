import { LineOfBusiness } from '../../model/line-of-business';
import { McasState } from '../../model/mcas-state';
import { Year } from '../../../shared/model/year';
import { Company } from '../../../shared/model/company';

export class FilingData {
  year: Year;
  company: Company;
  state: McasState;
  lineOfBusiness: LineOfBusiness;
  // sections: SectionData[];
  data: any;
  comments: string;
  nextBlank: string;
}

export class SectionData {
  section: string;
  rows: RowData[];
}

export class RowData {
  row: string;
  data: {[k: string]: any};
}
