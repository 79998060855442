import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import { ReviewLetter } from '../../review-letter/model/review-letter';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PreviewLetterService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    public httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('PreviewLetterService');
  }

  postViewPdf(cocode: string, year: string, reviewLetter: ReviewLetter): Observable<Blob> {

    const url = `${environment.apiUrl}letter`;
    let params = new HttpParams().set('cocode', cocode).set('year', year);

    return this.httpClient.post(url, reviewLetter, {
      params: params,
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }).pipe(catchError(this.handleError('postViewPdf', null)));
  }

}
