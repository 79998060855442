import { Component, ViewChild, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { id } from '@swimlane/ngx-datatable';
import { template } from 'lodash';
import { DashboardService } from '../../service/dashboard.service';
import { PreviewLetterComponent } from '../../../../correspondence/preview-letter/preview-letter.component';
import { Subject, Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-pdf-renderer',
  templateUrl: './pdf-renderer.component.html',
  styleUrls: ['./pdf-renderer.component.css']
})
export class PdfRendererComponent implements ICellRendererAngularComp {
  @ViewChild('viewPdf', { static: true }) viewPdf;
  params: any;
  faTimes = faTimes;
  fas = fas;
  deleteKey: string;
  modalRef: BsModalRef;
  visible: boolean;
  private unsubscribe: Subject<void> = new Subject();
  modalSubscriptions: Subscription[];
  bsModalRef: BsModalRef;
  data: any[];
  singlePdf: boolean;
  selectedPdf: string = null;
  title: string;


  constructor(
    private modalService: BsModalService,
    private dashboardService: DashboardService
  ) {
    this.modalSubscriptions = [];
  }

  agInit(params: any): void {
    this.params = params;
    this.title = 'PDF';
    this.onlyForSent();
    this.data = params.data.sentPDFList;
    this.data = this.data.sort((a, b) => +new Date(b.dateSent) - +new Date(a.dateSent));
    this.data.forEach(a => {
      a.dateSent = moment(a.dateSent).format('YYYY-MM-DD');
    });
    if (this.data.length === 0 || this.data.length === 1) {
      this.singlePdf = false;
    } else {
      this.singlePdf = true;
    }
  }

  refresh(): boolean {
    return false;
  }

  onlyForSent() {
    if (this.params.data.letterProgress === 'Sent') {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  checkForSentPdfList(): boolean {
    if (this.params.data.sentPDFList === []) {
      return true;
    } else {
      return false;
    }
  }

  public viewPdfFromLink(id: string, data: any): void {
    console.log("data From Link: ", data.pdf.pdf);
    const initialState = {
      id: id,
      flagForViewingPdfFromDashboard: true,
      pdfLink: data.pdf.pdf
    };
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      }));
    this.bsModalRef = this.modalService.show(PreviewLetterComponent, { initialState: initialState, class: 'modal-lg' });
  }

  public viewPdfFromList(id: string, data: any): void {
    const initialState = {
      id: id,
      flagForViewingPdfFromDashboard: true,
      pdfLink: data
    };
    this.selectedPdf = null;
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      }));
    this.bsModalRef = this.modalService.show(PreviewLetterComponent, { initialState: initialState, class: 'modal-lg' });
  }

  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }
}
