import { MOCK_COMPANIES } from './mock-companies';
import { MOCK_YEARS } from './mock-years';
import { MOCK_MCAS_STATES } from './mock-mcas-states';
import { MOCK_LINES_OF_BUSINESS } from './mock-lines-of-business';

export const MOCK_EXTENSIONS: any[] = [
  {
    id: 1,
    company: MOCK_COMPANIES[1],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    lineOfBusiness: MOCK_LINES_OF_BUSINESS[0],
    state: MOCK_MCAS_STATES[1],
    requestedDate: '2017-10-23T09:23:16',
    approvedDate: '',
    status: 'PENDING',
    reason: 'Test pending extension',
    endDate: '2017-11-15T00:00:00'
  },
  {
    id: 2,
    company: MOCK_COMPANIES[1],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    state: MOCK_MCAS_STATES[1],
    lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
    requestedDate: '2017-07-08T10:36:47',
    approvedDate: '2017-08-01T13:07:58',
    status: 'APPROVED',
    reason: 'Test approved extension',
    endDate: '2017-09-15T00:00:00'
  },
  {
    id: 3,
    company: MOCK_COMPANIES[1],
    year: MOCK_YEARS[MOCK_YEARS.length - 1],
    state: MOCK_MCAS_STATES[1],
    lineOfBusiness: MOCK_LINES_OF_BUSINESS[2],
    requestedDate: '2017-07-08T10:36:47',
    approvedDate: '2017-08-01T13:07:58',
    status: 'DENIED',
    reason: 'Test denied extension',
    endDate: '2017-09-15T00:00:00'
  }
];


