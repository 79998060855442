<ng-container *ngIf="!isSuperUser(); else superUserSelect">
  <div *ngIf="companies && companies.length > 0 && years && years.length > 0">
      <div class="form-group">
        <label for="company">Company</label>
        <select class="form-control form-control-sm" id="company" name="company" required [(ngModel)]="selectedCompany">
          <option [ngValue]="null" disabled hidden>Choose One</option>
          <option *ngFor="let company of companies" [ngValue]="company">{{getCompanyDisplayName(company)}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="year">Year</label>
        <select class="form-control form-control-sm" id="year" name="year" required [(ngModel)]="selectedYear" [compareWith]='customCompareYear'>
          <option value="null" disabled hidden>Choose One</option>
          <option *ngFor="let year of years" [ngValue]="year">{{year.year}}</option>
        </select>
      </div>

      <div class="row pb-2 ">
        <button type="button" class="btn btn-primary btn-sm btn-block ml-3 mr-2" (click)="setCompanyYear()" [disabled]="!selectedCompany || !selectedYear">Select</button>
      </div>
    </div>
</ng-container>


<ng-template #superUserSelect>
<div *ngIf="years && years.length > 0">
    <div class="form-group">
        <label for="cocode">Cocode</label>
        <input type="text" id="cocode" name="cocode" class="form-control form-control-sm" required [(ngModel)]="enteredCocode">
      </div>
      <div class="form-group">
        <label for="year">Year</label>
        <select class="form-control form-control-sm" id="year" name="year" required [(ngModel)]="selectedYear" [compareWith]='customCompareYear'>
          <option value="null" disabled hidden>Choose One</option>
          <option *ngFor="let year of years" [ngValue]="year">{{year.year}}</option>
        </select>
      </div>
      <div class="row pb-2">
        <button type="button" class="btn btn-primary btn-sm btn-block" (click)="setCocodeYear()" [disabled]="!enteredCocode || !selectedYear">Select</button>
      </div>
</div>
</ng-template>

