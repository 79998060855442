import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../shared/model/user';
import { McsUserService } from '../service/mcs-user.service';
import { Subscription, Subject } from 'rxjs';
import { ApplicationAdminService } from './service/application-admin.service';
import { MessageService } from '../messages/service/message.service';
import { ApplicationAdministration } from './model/application-administration';
import { AlertMessage } from '../messages/model/alert-message';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-application-admin',
  templateUrl: './application-admin.component.html',
  styleUrls: ['./application-admin.component.css']
})
export class ApplicationAdminComponent implements OnInit, OnDestroy {
  title: string;
  user: User;
  modalSubscriptions: Subscription[];
  subscriptions: Array<Subscription> = [];
  loading = false;
  applicationOnFlag: boolean;
  applicationOffFlag: boolean;
  applicationAdministartion: ApplicationAdministration;
  messageText: string;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private userService: McsUserService,
    private applicationAdminService: ApplicationAdminService,
    private messageService: MessageService,
    private router: Router,
    ) {
    this.modalSubscriptions = [];
  }

  ngOnInit(): void {
    this.user = this.userService.getStoredUser();
    this.title = 'Application Status'
    this.getApplicationInfo();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
    this.unsubscribe.complete();
  }
  
  getApplicationInfo(): void {
    const sub = this.applicationAdminService.getApplicationInfo()
      .subscribe(
        applicationInfo => {
          this.applicationAdministartion = applicationInfo;
        }
      );
    this.subscriptions.push(sub);
  }

  save() {
    const sub = this.applicationAdminService.putApplicationInfo(this.applicationAdministartion)
    .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          if (this.applicationAdministartion.turnOnOrOff === true) {
            this.messageText = 'Application has been Turned OFF';
            this.messageService.clear();
            this.messageService.add(new AlertMessage(this.messageText, 'success'));
          } else {
            this.messageText = 'Application has been Turned ON';
            this.messageService.clear();
            this.messageService.add(new AlertMessage(this.messageText, 'success'));
          }
        }
      );
    //this.subscriptions.push(sub);
  }

  cancel(): void {
    this.router.navigate(['/filingMatrix']);
  }
}
