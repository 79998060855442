<nav class="navbar navbar-light navbar-expand-md global-nav hidden-print second-bar">
  <a class="navbar-brand" href="#">
    <img id="mcas-logo" alt="MCAS" src="../assets/images/mcas_header_logo.png">
  </a>
  <button class="navbar-toggler" (click)="isNavCollapsed = !isNavCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <ng-container *ngIf="user.role === 'MCAS_NAIC_PR';else notAdmin">
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <ul class="navbar-nav mr-auto">
        <li *ngIf="user && user.isDisclaimerAccepted" class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/filingMatrix" (click)="isNavCollapsed = !isNavCollapsed">
            Filing Matrix
          </a>
        </li>

        <li *ngIf="user && user.isDisclaimerAccepted && isMcasAdmin(user.role)" class="nav-item">
          <div class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle" id="drop:admin">
              Administration <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li *ngIf="user && user.isDisclaimerAccepted && isMcasAdmin(user.role)" role="menuitem">
                <a class="dropdown-item" routerLink="/userAdmin" id="ln:userAdmin"
                  (click)="isNavCollapsed = !isNavCollapsed">
                  User Admin
                </a>
              </li>
              <li class="divider dropdown-divider"></li>
              <li *ngIf="user && user.isDisclaimerAccepted && isMcasAdmin(user.role)" role="menuitem">
                <a class="dropdown-item" routerLink="/applicationAdmin" id="ln:applicationAdmin"
                  (click)="isNavCollapsed = !isNavCollapsed">
                  Application Admin
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li *ngIf="user && user.isDisclaimerAccepted && isMcasAdmin(user.role)" class="nav-item"
          routerLinkActive="active">
          <a id="ln:correspondence" class="nav-link" routerLink="/correspondence"
            (click)="isNavCollapsed = !isNavCollapsed">
            Correspondence
          </a>
        </li>

        <li class="nav-item">
          <a [href]="mcasHelpUrl" target="_blank" class="nav-link">MCAS Resources</a>
        </li>
      </ul>
    </div>
  </ng-container>
</nav>

<ng-template #notAdmin>
  <ng-container *ngIf="user.role === 'MCAS_USER_PR'; else regulator">
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <ul class="navbar-nav mr-auto">
        <li *ngIf="user && user.isDisclaimerAccepted && !appClosed" class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/filingMatrix" (click)="isNavCollapsed = !isNavCollapsed">
            Filing Matrix
          </a>
        </li>
        <li *ngIf="user && user.isDisclaimerAccepted && isMcasAdmin(user.role) && !appClosed" class="nav-item"
          routerLinkActive="active">
          <a class="nav-link" routerLink="/userAdmin" (click)="isNavCollapsed = !isNavCollapsed">
            User Administration
          </a>
        </li>
        <li class="nav-item">
          <a [href]="mcasHelpUrl" target="_blank" class="nav-link">MCAS Resources</a>
        </li>
      </ul>
    </div>
  </ng-container>
</ng-template>

<ng-template #regulator>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <ul class="navbar-nav mr-auto">

      <li *ngIf="isRegulator(user.role)" class="nav-item"
        routerLinkActive="active">
        <a id="ln:correspondence" class="nav-link" routerLink="/correspondence"
          (click)="isNavCollapsed = !isNavCollapsed">
          Correspondence
        </a>
      </li>

      <li class="nav-item">
        <a [href]="mcasHelpUrl" target="_blank" class="nav-link">MCAS Resources</a>
      </li>
    </ul>
  </div>
</ng-template>
