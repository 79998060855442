<ng-container>
    <div class="modal-header">
      <h4 class="modal-title">{{title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <app-messages [parentName]="'userRemove'"></app-messages>
      <p>Are you sure you would like to remove {{user.firstName}} {{user.lastName}}?</p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-link btn-sm" (click)="bsModalRef.hide()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="removeUser()">Remove</button>
    </div>
  </ng-container>

