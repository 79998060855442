<ng-container *ngIf="loading; else rulesResults">
  <ngx-loading [show]="loading"></ngx-loading>
</ng-container>

<ng-template #rulesResults>
  <ngx-loading [show]="loading"></ngx-loading>
  <div id="correspondance-wizard" class="row">
    <nav *ngIf="!sidebarCollapsed" class="col-md-2 col-sm-3 bg-light sidebar d-print-none">
      <div class="sidebar-sticky">
        <div id="collapseSidebar" class="pt-3 pb-2 mb-3 px-4 border-bottom">
          <span class="cursor-pointer" (click)="sidebarCollapsed = true">
            <fa-icon [icon]="faDoubleLeft"></fa-icon>&nbsp;Collapse Sidebar
          </span>
        </div>
        <!-- Actions -->
        <div id="correspondanceWizardActions" class="pb-2 mb-3 px-4 border-bottom">
          <h4>
            <span class="cursor-pointer" (click)="isActionsCollapsed = !isActionsCollapsed"
              [attr.aria-expanded]="!isActionsCollapsed" aria-controls="printOptions">
              <fa-icon [icon]="isActionsCollapsed ? faPlus : faMinus"></fa-icon> Actions
            </span>
          </h4>
          <div id="actions" [collapse]="isActionsCollapsed">
            <div class="pb-2">
              <button id="saveChanges" type="button" class="btn btn-primary btn-sm btn-block"
                (click)="saveChanges()">Save Changes
              </button>
            </div>
            <div class="pb-2">
              <button id="saveAndClose" type="button" class="btn btn-primary btn-sm btn-block"
                (click)="saveAndClose()">Save and Close
              </button>
            </div>
            <div class="pb-2">
              <button id="resetUnsavedStatus" type="button" class="btn btn-primary btn-sm btn-block"
                (click)="resetUnsavedStatus()">Reset Unsaved Status
              </button>
            </div>
            <div class="pb-2">
              <button id="next" type="button" class="btn btn-primary btn-sm btn-block" (click)="next();"
                [disabled]="disableNextButton">Next
              </button>
            </div>
            <div class="pb-2">
              <button id="cancel" type="button" class="btn btn-danger btn-sm btn-block" (click)="cancel()">Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Main Screen -->
    <main role="main" id="rulesResultsDisplay" [ngClass]="getMainStyle()">
      <app-messages [parentName]="'rulesResults'"></app-messages>
      <div id="rulesResult" class="pt-3 pb-3 main-content d-print-block">

        <div class="row pb-3">
          <div class="col-12">
            <h3>
              <ng-container *ngIf="sidebarCollapsed">
                <span class="cursor-pointer" (click)="sidebarCollapsed = false" title="Expand Sidebar">
                  <fa-icon [icon]="faDoubleRight"></fa-icon>
                </span>
              </ng-container>
              {{title}}
              <hr>
            </h3>
          </div>
        </div>

        <div class="row pb-4">
          <div id="company" class="col-2" style="text-align: center;">
            <h3 style="font-size: 18px;">Company</h3>
            {{company}} - {{companyName}}
          </div>
          <div id="year" class="col-1" style="text-align: center;">
            <h3 style="font-size: 18px;">Year</h3>
            {{year}}
          </div>
          <div id="contact" class="col-1" style="text-align: center;">
            <h3 style="font-size: 18px;">Contact</h3>
            {{contact}}
          </div>
          <div id="lineOfBusiness" class="col-2" style="text-align: center;">
            <h3 style="font-size: 18px;">Line of Business</h3>
            {{lobDesc}}
          </div>


          <div id="currentStatusWarnings" class="col" >
            <div class="float-right" style="text-align: center;">
              <h3 style="font-size: 18px;">Current Status<br /><span>Warnings</span> </h3>
              <select class="form-control form-control-sm" id="year" name="year" (change)="filterByWarnings($event)"
                [(ngModel)]="selectedWarning" required>
                <option [ngValue]="null" disabled hidden></option>
                <option *ngFor="let warning of warnings" [ngValue]="warning.value">
                  {{warning.label}}</option>
              </select>
            </div>
          </div>
          <div id="differenceThreshold" class="col">
            <div style="text-align: center;">
              <h3 style="font-size: 18px;"> Difference <br /><span>Threshold</span> </h3>
              <input type="text" class="form-control" placeholder="Threshold +/-" #thresholdFilter
                (keyup)="filterByThreshold($event)" style="height: 33px;" maxlength="5" numbersOnly>
            </div>
          </div>


        </div>
        <div class="row">
          <div class="col-12">
            <div style="padding-bottom: 10px;">
              <span style="
              padding-left: 8px;">Validations selected in the Include in Letter column will be present in the
                letter.</span>
            </div>
            <div>
              <button id="panelToggle" type="button" class="btn btn-link btn-sm pull-right" (click)="setActivePanels()"
                style="color: #0052cc; padding-top: inherit">
                {{getExpansionText()}}
              </button>
              <div class="float-right">
                <span style="padding-right: 8px;">Include in Letter: </span>
                <button id="includeInLetter" type="button" class="btn btn-link btn-sm pull-right" (click)="selectAll()"
                  style="color: #0052cc; padding-top: inherit">
                  Select All
                </button>
                <button id="includeInLetter" type="button" class="btn btn-link btn-sm pull-right"
                  (click)="deSelectAll()" style="color: #0052cc; padding-top: inherit">
                  Deselect All
                </button>
              </div>
            </div>


            <dx-data-grid id="gridContainer" [dataSource]="rulesResultsTable"
              [masterDetail]="{ enabled: true, autoExpandAll: true, template: 'detail' }" keyExpr="ruleName"
              [showBorders]="true">
              <dxo-paging [enabled]="false" [pageSize]="15"></dxo-paging>
              <dxo-scrolling mode="infinite"></dxo-scrolling>
              <dxi-column dataField="baseTableName" [width]="250" alignment="center"></dxi-column>
              <dxi-column dataField="ruleName" caption="Validation Rule" [width]="170" alignment="center" sortOrder="asc">
              </dxi-column>
              <dxi-column dataField="description"></dxi-column>
              <dxi-column dataField="filing" [width]="100" caption="Filings" alignment="center"></dxi-column>
              <div *dxTemplate="let employee of 'detail'">
                <dx-data-grid [dataSource]="employee.data.ruleResults" [showBorders]="true" [columnAutoWidth]="true"
                  (onEditorPreparing)="includeInLetterValidation($event)">
                  <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false"></dxo-editing>
                  <dxi-column dataField="cocode" [allowEditing]="false" alignment="center"></dxi-column>
                  <dxi-column dataField="stabbr" [allowEditing]="false" caption="State" alignment="center"
                    sortOrder="asc"></dxi-column>
                  <dxi-column dataField="leftSide" [allowEditing]="false" alignment="center"></dxi-column>
                  <dxi-column dataField="rightSide" [allowEditing]="false" alignment="center"></dxi-column>
                  <dxi-column dataField="difference" [allowEditing]="false" alignment="center"></dxi-column>
                  <dxi-column dataField="resultStatus" [allowEditing]="false" alignment="center"
                    caption="Current Status"></dxi-column>
                  <dxi-column dataField="reviewedStatus" alignment="center" showEditorAlways="true" [setCellValue]="setCellValue">
                    <dxo-lookup [dataSource]="reviewStatus" displayExpr="name" valueExpr="value"></dxo-lookup>
                  </dxi-column>
                  <dxi-column dataField="includeInLetter" alignment="center" allowUpdating="true"
                    showEditorAlways="true">
                  </dxi-column>
                  <dxi-column dataField="modifiedByUser" [allowEditing]="false" alignment="center"></dxi-column>
                  <dxi-column dataField="dateModified" [allowEditing]="false" dataType="date" alignment="center">
                  </dxi-column>
                </dx-data-grid>
              </div>
            </dx-data-grid>

          </div>
        </div>
      </div>

      <div class="row">
        <footer class="col-12 footer d-print-none">
          <ncui-footer></ncui-footer>
        </footer>
      </div>
    </main>

    <main *ngIf="!loading" role="main" class="d-none col-sm-12 ml-sm-auto d-print-block">
      <app-messages [parentName]="'correspondence'"></app-messages>
      <div class="pt-3 pb-3 main-content d-print-block">

        <div class="row">
          <div class="col-12">
            <h3>
              <ng-container *ngIf="sidebarCollapsed">
                <span class="cursor-pointer" (click)="sidebarCollapsed = false" title="Expand Sidebar">
                  <fa-icon [icon]="faDoubleRight"></fa-icon>
                </span>
              </ng-container>
              {{title}}
              <hr>
            </h3>
          </div>
        </div>

        <div class="row pb-4">
          <div class="col-1" style="text-align: center;">
            <h3 style="font-size: 18px;">Company</h3>
            {{company}} - {{companyName}}
          </div>
          <div class="col-1" style="text-align: center;">
            <h3 style="font-size: 18px;">Year</h3>
            {{year}}
          </div>
          <div class="col-1" style="text-align: center;">
            <h3 style="font-size: 18px;">Contact</h3>
            {{contact}}
          </div>
          <div class="col-2" style="text-align: center;">
            <h3 style="font-size: 18px;">Line of Business</h3>
            {{lob}}
          </div>
          <div class="col" style="padding-left: 10rem;">
            <div class="float-right" style="text-align: center;">
              <h3 style="font-size: 18px;">Warning</h3>
              <select class="form-control form-control-sm" id="warning" name="warning" [(ngModel)]="war" required>
                <option [ngValue]="null" disabled hidden></option>
                <option *ngFor="let warning of warnings" [ngValue]="warning.value">
                  {{warning.label}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div style="padding-bottom: 10px;">
              <span>Validations set to 3 - Failed will be included in the correspondence</span>
            </div>
            <dx-data-grid id="gridContainer" [dataSource]="rulesResultsTable"
              [masterDetail]="{ enabled: true, template: 'detail' }" keyExpr="ruleName" [showBorders]="true">
              <dxo-paging [enabled]="true" [pageSize]="15"></dxo-paging>

              <dxi-column dataField="baseTableName" [width]="250"></dxi-column>
              <dxi-column dataField="ruleName" caption="Validation Rule" [width]="170" style="text-align: center;">
              </dxi-column>
              <dxi-column dataField="description" alignment="center"></dxi-column>
              <dxi-column dataField="filing" [width]="200" alignment="center"></dxi-column>

              <div *dxTemplate="let employee of 'detail'">
                <dx-data-grid [dataSource]="employee.data.ruleResults" [showBorders]="true" [columnAutoWidth]="true">
                  <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false"></dxo-editing>
                  <dxi-column dataField="cocode" alignment="center"></dxi-column>
                  <dxi-column dataField="stabbr" caption="State" alignment="center"></dxi-column>
                  <dxi-column dataField="leftSide" alignment="center"></dxi-column>
                  <dxi-column dataField="rightSide" alignment="center"></dxi-column>
                  <dxi-column dataField="difference" alignment="center"></dxi-column>
                  <dxi-column dataField="reviewedStatus" alignment="center"></dxi-column>
                  <dxi-column dataField="includeInLetter" alignment="center"></dxi-column>
                  <dxi-column dataField="modifiedByUser" alignment="center"></dxi-column>
                  <dxi-column dataField="dateModified" dataType="date" alignment="center"></dxi-column>
                </dx-data-grid>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>

      <div class="row">
        <footer class="col-12 footer d-print-none">
          <ncui-footer></ncui-footer>
        </footer>
      </div>
    </main>

  </div>
</ng-template>