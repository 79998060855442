
<ng-container *ngIf="company && year">
    <ngx-loading [show]="loading"></ngx-loading>
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>

    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-messages [parentName]="'dataUpload'"></app-messages>

    <div *ngIf="files.length === 0" draggable="true" ngClass="{{dragAreaClass}}">
      <div class="row">
        <div class="col-md-12 text-center">
          <a href="javascript:void(0)" (click)="file.click()">
            Click to browse
          </a>
          or drag & drop to upload your file
          <input type="file" #file [multiple]="(maxFiles > 1)" (change)="onFileChange($event)" style="opacity: 0" />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button id="btnFileClose" class="btn btn-link" (click)="close()" type="button">Close</button>
    <button id="btnFileCancel" class="btn btn-secondary" (click)="cancel()" [disabled]="!readyForUpload()" type="button">{{cancelButtonText}}</button>
    <button id="btnFileUpload" class="btn btn-primary" (click)="saveFiles()" [disabled]="!readyForUpload()" type="button">Upload</button>
  </div>
</ng-container>
