<ngx-loading [show]="loading"></ngx-loading>
<div class="row">
  <!-- Sidebar -->
  <div class="col-md-2 bg-light sidebar">
    <div class="sidebar-sticky">
      <div class="pt-3 pb-2 mb-3 px-4 border-bottom">
        <h4>Search By</h4>
        <div id="searchBy">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="searchType" [(ngModel)]="searchType" value="user" id="userSearchType">
            <label class="form-check-label" for="userSearchType">User</label>
          </div>
          <div class="form-check pb-2">
            <input class="form-check-input" type="radio" name="searchType" [(ngModel)]="searchType" value="cocode" id="cocodeSearchType">
            <label class="form-check-label" for="cocodeSearchType">Cocode</label>
          </div>
          <div class="form-group">
            <input type="text" class="form-control form-control-sm" id="searchText" name="searchText" required [(ngModel)]="searchText">
          </div>
          <div class="pb-2">
            <button type="button" class="btn btn-primary btn-sm btn-block" [disabled]="!searchText" (click)="search()">Search</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Main -->
  <main *ngIf="!loading" role="main" class="col-md-9 ml-sm-auto col-lg-10 d-print-none">
    <app-messages [parentName]="'helpDesk'"></app-messages>
    <div id="helpDeskContent" class="pt-3 pb-3 main-content d-print-block">
        <div class="row">
            <div class="col-12">
              <h3>
                {{title}}
                <hr>
              </h3>
            </div>
          </div>
          <div *ngIf="rows && rows.length > 0" class="row mb-3">
            <div class="col-12">
              <!-- Results go here -->
              <ngx-datatable class="bootstrap" [rows]="rows" [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                [rowHeight]="50">
              </ngx-datatable>
            </div>
          </div>
    </div>
    <div class="row">
        <footer class="col-12 footer d-print-none">
            <ncui-footer></ncui-footer>
          </footer>
    </div>

  </main>

  <main *ngIf="!loading" role="main" class="col-sm-12 ml-sm-auto d-none d-print-block">
      <app-messages [parentName]="'helpDesk'"></app-messages>
      <div id="helpDeskContent" class="pt-3 pb-3 main-content d-print-block">
          <div class="row">
              <div class="col-12">
                <h3>
                  {{title}}
                  <hr>
                </h3>
              </div>
            </div>
            <div *ngIf="rows && rows.length > 0" class="row mb-3">
              <div class="col-12">
                <!-- Results go here -->
                <ngx-datatable class="bootstrap" [rows]="rows" [columns]="columns" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                  [rowHeight]="50">
                </ngx-datatable>
              </div>
            </div>
      </div>
      <div class="row">
          <footer class="col-12 footer d-print-none">
              <ncui-footer></ncui-footer>
            </footer>
      </div>

    </main>
</div>
