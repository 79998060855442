import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MessagesModule} from '../../messages/messages.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import {LoadingModule} from 'ngx-loading';
import {NcuiModule} from 'common-ui-components';
import {ReviewLetterComponent} from './review-letter.component';
import {ReviewLetterRoutingModule} from './review-letter-routing.module';
import { PreviewLetterComponent } from '../preview-letter/preview-letter.component';

@NgModule({
  declarations: [ReviewLetterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MessagesModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    LoadingModule,
    NcuiModule,
    ReviewLetterRoutingModule
  ],
  providers: [],
  entryComponents: [PreviewLetterComponent]
})

export class ReviewLetterModule { }
