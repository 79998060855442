import { Dashboard } from "./dashboard";

export class Dashboards {
    id: string;
    dashboard: Dashboard[];
  
    constructor(id?: string, dashboard?: Dashboard[]) {
      this.id = id || '';
      this.dashboard = dashboard;
    }
  }
  