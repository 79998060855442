export class ReviewLetter {
  letterStatus: string;
  subjectLine: string;
  header: string;
  regarding: string;
  contact: string;
  letterBody: string;
  signature: string;
  statesImpacted: string;
  warnings: string;
  additionalComments: string;

  constructor (status?: string, subject?: string, header?: string, re?: string, contact?: string,
               body?: string, signature?: string, states?: string, warnings?: string, comments?: string) {
   this.letterStatus = status || '';
   this.subjectLine = subject || '';
   this.header = header || '';
   this.regarding = re || '';
   this.contact = contact || '';
   this.letterBody = body || '';
   this.signature = signature || '';
   this.statesImpacted = states || '';
   this.warnings = warnings || '';
   this.additionalComments = comments || '';
  }
}
