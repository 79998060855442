import {Company} from './company';

export class User {
  userId: string;
  username: string;
  type: string;
  role: string;
  cocodesAsPrimary: Company[];
  cocodesAsSecondary: Company[];
  isDisclaimerAccepted: boolean;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  isContact?: boolean;
  isAuthenticated?: boolean;
  fax?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  address?: string;
  jobTitle?: string;
  department?: string;
}
