import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationAdminRoutingModule } from './application-admin-routing.module';
import { ApplicationAdminComponent } from './application-admin.component';
import { MessagesModule } from '../messages/messages.module';
import { FormsModule, ReactiveFormsModule } from '../../../node_modules/@angular/forms';
import { ApplicationAdminService } from './service/application-admin.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingModule } from 'ngx-loading';
import { NcuiModule } from 'common-ui-components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MessagesModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    LoadingModule,
    NcuiModule,
    ApplicationAdminRoutingModule
  ],
  declarations: [ApplicationAdminComponent],
  providers: [ApplicationAdminService]
})
export class ApplicationAdminModule { }
