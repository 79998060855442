import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DisclaimerComponent} from './disclaimer.component';
import { RoleGuard } from '../shared/role.guard';
import { CocodesGuard } from '../shared/cocodes.guard';
import { AppShutDownGuard } from '../shared/appShutDown.guard';

const disclaimerRoutes: Routes = [
  { path: 'disclaimer', component: DisclaimerComponent, canActivate: [RoleGuard, CocodesGuard, AppShutDownGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(disclaimerRoutes)
  ],
  exports: [RouterModule]
})
export class DisclaimerRoutingModule { }
