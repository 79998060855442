export const MOCK_COMPANIES: any[] = [
  {id: 1, cocode: 123456, longName: 'Fir Company', shortName: 'compA', assignedUsers: [{userId: '123456', username: 'userA', isPrimary: true}, {userId: '145678', username: 'userC', isPrimary: false}, ]},
  {id: 2, cocode: 134567, longName: 'Sec Company', shortName: 'compB', assignedUsers: [{userId: '134567', username: 'userD', isPrimary: true}, {userId: '145678', username: 'userC', isPrimary: false},]},
  {id: 3, cocode: 145678, longName: 'Company C', shortName: 'compC', assignedUsers: [{userId: '123456', username: 'userA', isPrimary: true}, {userId: '145678', username: 'userC', isPrimary: false},]},
  {id: 4, cocode: 156789, longName: 'Company D', shortName: 'compD', assignedUsers: [{userId: '123456', username: 'userA', isPrimary: true}, {userId: '145678', username: 'userC', isPrimary: false},]},
  {id: 5, cocode: 167891, longName: 'Company E', shortName: 'compE', assignedUsers: [{userId: '123456', username: 'userA', isPrimary: true}, {userId: '145678', username: 'userC', isPrimary: false},]},
  {id: 6, cocode: 178912, longName: 'Company F', shortName: 'compF', assignedUsers: [{userId: '123456', username: 'userA', isPrimary: true}, {userId: '145678', username: 'userC', isPrimary: false},]},
  {id: 7, cocode: 189123, longName: 'Company G', shortName: 'compG', assignedUsers: [{userId: '123456', username: 'userA', isPrimary: true}, {userId: '145678', username: 'userC', isPrimary: false},]},
  {id: 8, cocode: 191234, longName: 'Company H', shortName: 'compH', assignedUsers: []},
  {id: 9, cocode: 101234, longName: 'Company I', shortName: 'compI', assignedUsers: []},
  {id: 10, cocode: 212345, longName: 'Company J', shortName: 'compJ', assignedUsers: []}
];
