import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilingWizardComponent } from './filing-wizard.component';
import { FilingWizardService } from './service/filing-wizard.service';
// import { TooltipModule } from 'ngx-bootstrap/Tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingModule } from 'ngx-loading';
import { NavListComponent } from './nav-list/nav-list.component';
import { MessagesModule } from '../../messages/messages.module';
import { FilingSectionComponent } from './filing-section/filing-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { QuestionControlService } from '../dynamic-forms/question-control-service';
import { SharedModule } from '../../shared/shared.module';
import { NcuiModule } from 'common-ui-components';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingModule,
    NcuiModule,
    SharedModule,
    CollapseModule.forRoot(),
    // TooltipModule.forRoot(),
    FontAwesomeModule,
    MessagesModule,
  ],
  declarations: [FilingWizardComponent, NavListComponent, FilingSectionComponent],
  providers: [
    FilingWizardService,
    QuestionControlService,
  ]
})
export class FilingWizardModule { }
