import { Component, OnDestroy, OnInit } from '@angular/core';
import { DisclaimerService } from './service/disclaimer.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MessageService } from '../messages/service/message.service';
import { User } from '../shared/model/user';
import { McsUserService } from '../service/mcs-user.service';
import { Disclaimer } from '../shared/model/disclaimer';
import { takeUntil } from '../../../node_modules/rxjs/operators';
import { AlertMessage } from '../messages/model/alert-message';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css'],
})
export class DisclaimerComponent implements OnInit, OnDestroy {
  disclaimer: Disclaimer;
  title = 'Legal Disclaimer';
  user: User;
  loading = false;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private disclaimerService: DisclaimerService,
    private router: Router,
    private userService: McsUserService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    if (this.user.isDisclaimerAccepted) {
      this.checkRegulatorRole();
    }
    this.getDisclaimer();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.messageService.clear();
  }

  checkRegulatorRole() {
    if (this.user.role === "MCAS_ISITE_USER_PR") {
      this.router.navigate(['correspondence']);
    } else {
      this.router.navigate(['filingMatrix']);
    }
  }

  getDisclaimer() {
    this.loading = true;
    this.disclaimerService.getDisclaimer()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        disclaimer => {
          this.loading = false;
          if (disclaimer) {
            this.disclaimer = disclaimer;
          }
        }, error => {
          this.messageService.add(new AlertMessage('Error receiving the disclaimer message', 'danger'));
        }
      );
  }

  agree() {
    this.loading = true;
    this.disclaimerService.agree()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.loading = false;
          this.user.isDisclaimerAccepted = true;
          this.userService.setStoredUser(this.user);
          this.disclaimer.needsAcceptance = false;
          this.checkRegulatorRole();
        },
        () => {
          this.loading = false;
          this.messageService.add(new AlertMessage('Error aggreeing to terms', 'danger'));
        }
      );
  }

}
