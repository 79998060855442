import { Injectable } from "@angular/core";
import { HandleError, HttpErrorHandler } from "../../../shared/http-error-handler.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { Dashboard } from "../model/dashboard";
import { Dashboards } from "../model/dashboards";


@Injectable()
export class DashboardService {
  readonly handleError: HandleError;
  selectedUser: string;
  selectedGroupCode: any;
  selectedCocode: number;
  selectedLob: string;
  selectedStatus: string;
  selectedFilingYear: number;
  filteredRowData: any[];

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('DashboardService');
  }

  getDashboardData(): Observable<Dashboards> {
    const url = `${environment.apiUrl}dashboard`;
    let headers = new HttpHeaders().append('Content-Type', 'application/json');
    let options = { headers: headers };

    return this.httpClient.get<Dashboards>(url, options)
      .pipe(catchError(this.handleError('getDashboardData', null)));
  }

  putChangeStatus(id: string, status: any): Observable<any> {
    const url = `${environment.apiUrl}correspondences/${id}/status`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.put<any>(url, status, options)
      .pipe(catchError(this.handleError('putChangeStatus', null)));
  }

  putAnalystCompanyNotes(id: string, notes: any): Observable<any> {
    const url = `${environment.apiUrl}correspondences/${id}/notes`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.put<any>(url, notes, options)
      .pipe(catchError(this.handleError('putAnalystCompanyNotes', null)));
  }

  putClosedDate(id: string, closedDate: string): Observable<any> {
    const url = `${environment.apiUrl}correspondences/${id}/closedDate`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.put<any>(url, closedDate, options);
    // .pipe(catchError(this.handleError('putClosedDate', null)));
  }

  putFollowUpDate(id: string, followupDate: string): Observable<any> {
    const url = `${environment.apiUrl}correspondences/${id}/followupDate`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.put<any>(url, followupDate, options);
    // .pipe(catchError(this.handleError('putFollowUpDate', null)));
  }

  putFinalResolution(id: string, finalResolution: any): Observable<any> {
    const url = `${environment.apiUrl}correspondences/${id}/finalResolution`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.put<any>(url, finalResolution, options)
      .pipe(catchError(this.handleError('putFinalResolution', null)));
  }

  deleteCorrespondence(id: string): Observable<any> {
    let url = `${environment.apiUrl}correspondences/${id}/deleteDraft`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.httpClient.delete(url, options)
      .pipe(catchError(this.handleError('deleteCorrespondence', null)));
  }

  getPDF(id: string, pdfLink: string) {
    let url = `${environment.apiUrl}correspondences/${id}/sentLetter`;
    const params = new HttpParams().set('pdfKey', pdfLink);
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      params: params
    };
    return this.httpClient.get<any>(url, httpOptions)
      .pipe(catchError(this.handleError('getPDF', null)));
  }

  getCorrespondenceDashboardData(dashboard: Dashboards): any[] {
    const dashboardData: any[] = [];
    dashboard.dashboard.forEach(dash => {
      const data = {};
      data['status'] = !dash.status ? '-' : dash.status;
      data['changeStatus'] = dash;
      data['groupCode'] = (dash.groupCode === null) ? '-' : dash.groupCode;
      data['cocode'] = (dash.cocode === null) ? '-' : dash.cocode;
      data['companyName'] = !dash.companyName ? '-' : dash.companyName;
      data['lobCode'] = !dash.lobCode ? '-' : dash.lobCode;
      data['filingYear'] = !dash.filingYear ? '-' : dash.filingYear;
      data['letterProgress'] = !dash.letterProgress ? '-' : dash.letterProgress;
      data['discoverDate'] = !dash.discoverDate ? '-' : dash.discoverDate;
      data['correspondenceDate'] = !dash.correspondenceDate ? '-' : dash.correspondenceDate;
      data['followupDate'] = !dash.followupDate ? '-' : dash.followupDate;
      data['analystCompanyNotes'] = dash;
      data['closedDate'] = !dash.closedDate ? '-' : dash.closedDate;
      data['finalResolution'] = dash;
      data['actions'] = dash;
      data['senderId'] = !dash.senderId ? '-' : dash.senderId;
      data['delete'] = dash;
      data['numValidationsSent'] = !dash.numValidationsSent ? '-' : dash.numValidationsSent;
      data['pdf'] = dash;
      data['id'] = !dash.id ? '-' : dash.id;
      data['sentPDFList'] = dash.sentPDFList;
      dashboardData.push(data);
    });
    return dashboardData;
  }

  getColumnDefsForAnalyst(): any[] {
    let columnDefs: any[];
    columnDefs = [
      { headerName: 'Status', field: 'status', minWidth: 150 },
      { headerName: 'Change Status', field: 'changeStatus', minWidth: 250, cellRenderer: 'changeStatusRenderer' },
      { headerName: 'Group Code', field: 'groupCode', minWidth: 200 },
      { headerName: 'Cocode', field: 'cocode', minWidth: 130 },
      { headerName: 'Company Name', field: 'companyName', minWidth: 250, },
      { headerName: 'LOB', field: 'lobCode', minWidth: 130 },
      {
        headerName: 'Filing Year', field: 'filingYear', minWidth: 130, cellStyle: params => {
          if (params.data.letterProgress == 'Draft') {
            return { color: 'red' };
          } else {
            return null;
          }
        }
      },
      {
        headerName: 'Letter Progress', field: 'letterProgress', minWidth: 130, cellStyle: params => {
          if (params.value == 'Draft') {
            return { color: 'red' };
          } else {
            return null;
          }
        }
      },
      { headerName: 'Discover Date', field: 'discoverDate', minWidth: 130 },
      { headerName: 'Correspondence Date', field: 'correspondenceDate', minWidth: 130 },
      { headerName: 'Follow-up Date', field: 'followupDate', minWidth: 250, cellRenderer: 'followupDateRenderer' },
      { headerName: 'Analyst/Company Notes', field: 'analystCompanyNotes', minWidth: 250, cellRenderer: 'analystCompanyNotesRenderer' },
      { headerName: 'Closed Date', field: 'closedDate', minWidth: 250, cellRenderer: 'closedDateRenderer' },
      { headerName: 'Final Resolution', field: 'finalResolution', minWidth: 250, cellRenderer: 'finalResolutionRenderer' },
      { headerName: 'Actions', field: 'actions', minWidth: 250, cellRenderer: 'actionsRenderer', cellStyle: { overflow: 'visible' }, sortable: true },
      { headerName: 'ID', field: 'senderId', minWidth: 130 },
      { headerName: 'Delete', field: 'delete', minWidth: 130, cellRenderer: 'deleteCorresponceRenderer' },
      { headerName: 'Validation Count', field: 'numValidationsSent', minWidth: 250 },
      { headerName: 'PDF', field: 'pdf', minWidth: 350, cellRenderer: 'pdfRenderer' }];
    return columnDefs;
  }

  getColumnDefsforRegulator(): any[] {
    let columnDefs: any[];
    columnDefs = [
      { headerName: 'Status', field: 'status', minWidth: 150 },
      { headerName: 'Group Code', field: 'groupCode', minWidth: 200 },
      { headerName: 'Cocode', field: 'cocode', minWidth: 130 },
      { headerName: 'Company Name', field: 'companyName', minWidth: 250, },
      { headerName: 'LOB', field: 'lobCode', minWidth: 130 },
      {
        headerName: 'Filing Year', field: 'filingYear', minWidth: 130, cellStyle: params => {
          if (params.data.letterProgress == 'Draft') {
            return { color: 'red' };
          } else {
            return null;
          }
        }
      },
      { headerName: 'Discover Date', field: 'discoverDate', minWidth: 130 },
      { headerName: 'Correspondence Date', field: 'correspondenceDate', minWidth: 130 },
      { headerName: 'Follow-up Date', field: 'followupDate', minWidth: 250, cellRenderer: 'followupDateRenderer' },
      { headerName: 'Analyst/Company Notes', field: 'analystCompanyNotes', minWidth: 250, cellRenderer: 'analystCompanyNotesRenderer' },
      { headerName: 'Closed Date', field: 'closedDate', minWidth: 250, cellRenderer: 'closedDateRenderer' },
      { headerName: 'Final Resolution', field: 'finalResolution', minWidth: 250, cellRenderer: 'finalResolutionRenderer' },
      { headerName: 'ID', field: 'senderId', minWidth: 130 },
      { headerName: 'Validation Count', field: 'numValidationsSent', minWidth: 250 },
      { headerName: 'PDF', field: 'pdf', minWidth: 250, cellRenderer: 'pdfRenderer' }];
    return columnDefs;
  }

}

