export class AlertMessage {
  message: string;
  type: string;
  dismissable?: boolean;
  dismissTime?: number;
  componentName?: string;

  constructor(message: string, type: string, dismissable?: boolean, dismissTime?: number, componentName?: string) {
    this.message = message;
    this.type = type;
    if (typeof dismissable === 'undefined' || dismissable === null) {
      this.dismissable = true;
    } else {
      this.dismissable = dismissable;
    }
    this.dismissTime = dismissTime || 0;
    this.componentName = componentName || 'all';
  }
}
