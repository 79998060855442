import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, RouteConfigLoadEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { faUnlock, faLock, faLockOpen, faPlus, faMinus, faBars, faArrowUp, faCaretUp, faCaretDown, faAsterisk, faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { RulesResultsService } from './service/rules-results.service';
import { RulesResults } from './model/rules-results';
import { Rules } from './model/rules';
import { DxDataGridModule, DxDataGridComponent } from "devextreme-angular";
import { AlertMessage } from '../../messages/model/alert-message';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../../messages/service/message.service';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-rules-results',
  templateUrl: './rules-results.component.html',
  styleUrls: ['./rules-results.component.css']
})
export class RulesResultsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  company: number;
  year: number;
  lob: string
  title: string;
  correspondenceId: string;
  private unsubscribe: Subject<void> = new Subject();
  loading: boolean = false;
  expanded: boolean = true;
  warnings: any[];
  reviewStatus: any[];
  rulesResults: RulesResults;
  rules: any[] = [];
  results: any[] = [];
  rulesTable: any[];
  contact: string;
  companyName: string;
  resultsWarnings: any[];
  warningStatus: any[] = [];
  selectedWarning: number = 1;
  sidebarCollapsed = false;
  lobDesc: string;
  rulesResultsTable: Rules[];
  resetRulesResults: Rules[];
  filteredRuleResults: Rules[];
  letterStatus: any[];
  private allPanelsActive = false;
  arra = [];
  enteredThreshold: any;
  warningSelectedToFilter: any = 1;
  faUnlock = faUnlock;
  faLock = faLock;
  faLockOpen = faLockOpen;
  faPlus = faPlus;
  faMinus = faMinus;
  faBars = faBars;
  faArrowUp = faArrowUp;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faAsterisk = faAsterisk;
  faDoubleLeft = faAngleDoubleLeft;
  faDoubleRight = faAngleDoubleRight;
  disableNextButton: boolean = true;
  disableSaveButton: boolean = true;
  txtLastName: any;
  id: string;
  actionsEditFlag: boolean;
  actionsEditYear: number;
  actionsEditCocode: number;
  actionsEditLob: string;
  reviewPageFlag: boolean = false;
  reviewPageCorrespondenceId: string;
  // disableSave: any[];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rulesResultsService: RulesResultsService,
    private messageService: MessageService,
    public dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    this.loading = false;
    this.correspondenceId = this.route.snapshot.paramMap.get('correspondenceId');
    this.id = this.route.snapshot.paramMap.get('id'); //Correspondence ID from dashboard
    this.reviewPageCorrespondenceId = this.route.snapshot.paramMap.get('reviewPageCorrespondenceId'); //Correspondence ID from Review Page
    this.reviewPageFlag = this.route.snapshot.paramMap.has('actionsEditFlag'); // Review Page Flag
    this.actionsEditYear = +this.route.snapshot.paramMap.get('actionsEditYear');
    this.actionsEditCocode = +this.route.snapshot.paramMap.get('actionsEditCocode');
    this.actionsEditFlag = this.route.snapshot.paramMap.has('actionsEditFlag');
    this.actionsEditLob = this.route.snapshot.paramMap.get('actionsEditLob');
    this.year = +this.route.snapshot.paramMap.get('year');
    this.company = +this.route.snapshot.paramMap.get('company');
    this.lob = this.route.snapshot.paramMap.get('lob');
    this.lobDesc = this.route.snapshot.paramMap.get('lobDesc');
    if (this.actionsEditFlag === true) {
      this.correspondenceId = this.id;
      this.year = this.actionsEditYear;
      this.company = this.actionsEditCocode;
      this.lobDesc = this.actionsEditLob;
    }
    this.setRuleResultsData();
    // this.nextButtonValidation();
  }

  setRuleResultsData() {
    this.title = `Rules/Results`;
    this.warnings = [
      { value: 1, label: 'All' },
      { value: 2, label: '2 - Validation Failure is Immaterial' },
      { value: 3, label: '3 - Validations Failed' },
      { value: 4, label: '4 - Validation Failure has been Addressed' }
    ];
    this.reviewStatus = [
      { name: 'Not Reviewed', value: 'NOT_REVIEWED' },
      { name: '2 - Immaterial/Reviewed', value: 'IMMATERIAL_REVIEWED' },
      { name: 'Reviewed - No Change', value: 'REVIEWED' }
    ];
    this.getRulesAndResults();
  }

  includeInLetterValidation(event) {

    if (event.parentType === "dataRow" && event.dataField === "reviewStatus") {
      event.editorOptions.onContentReady = args => {
        this.txtLastName = args.component;
      };
    }

    if (event.parentType === "dataRow" && event.dataField === "includeInLetter") {
      if (event.row.data.reviewedStatus === "NOT_REVIEWED") {
        event.row.data.includeInLetter = false;
        event.editorOptions.disabled = event.row.data && event.row.data.reviewedStatus === "NOT_REVIEWED";
      }
      if (event.row.data.reviewedStatus === "IMMATERIAL_REVIEWED") {
        event.row.data.includeInLetter = false;
        event.editorOptions.disabled = event.row.data && event.row.data.reviewedStatus === "IMMATERIAL_REVIEWED";
      }
      if (event.row.data.resultStatus === '2 - Immaterial') {
        event.row.data.includeInLetter = false;
        event.editorOptions.disabled = event.row.data;

      }
    }
    this.nextButtonValidation();
  }
  
  setCellValue(newData, value, currentRowData) {
    newData.reviewedStatus = value;
    if(currentRowData.resultStatus === "3 - Failed" && value === "REVIEWED") {
    newData.includeInLetter = true;
    }
}

  nextButtonValidation() {
    let i = 0;
    this.rulesResultsTable.forEach(a => [
      a.ruleResults.forEach(b => {
        if (b.reviewedStatus === "NOT_REVIEWED") {
          i = i + 1;
        }
      })
    ]);
    if (i > 0) {
      this.disableNextButton = true;
    } else {
      this.disableNextButton = false;
    }
  }

  getRulesAndResults(): void {
    if(this.reviewPageCorrespondenceId !== null) {
      this.correspondenceId = this.reviewPageCorrespondenceId;
    } else {
      this.correspondenceId = this.correspondenceId;
    }
    this.loading = true;
    this.rulesResultsService.getRulesResulesByCompanyYearAndLob(this.correspondenceId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        rulesResults => {
          this.rulesResults = rulesResults;
          this.rulesResultsTable = this.rulesResults.rules;
          this.resetRulesResults = this.rulesResults.rules;
          // this.disableSave = rulesResults.rules;
          this.companyName = rulesResults.company.companyName;
          this.contact = rulesResults.contactName;
          this.changeResultStatusDisplay();
          this.loading = false;
        }
      );
  }

  changeResultStatusDisplay() {
    this.rulesResultsTable.forEach(a => {
      a.filing = a.ruleResults.length;
      a.ruleResults.forEach(b => {
        b.cocode = this.rulesResults.company.cocode;
        if (b.resultStatus === 3) {
          b.resultStatus = "3 - Failed"
        } else if (b.resultStatus === 2) {
          b.resultStatus = "2 - Immaterial"
        } else if (b.resultStatus === 4) {
          b.resultStatus = "4 - Addressed"
        }
        if (b.reviewedStatus === null) {
          b.reviewedStatus = 'NOT_REVIEWED';
        }
      });
    });
  }

  filterByWarnings(event) {
    this.rulesResultsTable = this.resetRulesResults;
    this.warningSelectedToFilter = event.target.selectedIndex;
    this.preDefineWarningsFilters();
    this.rulesResultsTable = this.rulesResultsService.filteringTable(
      this.warningSelectedToFilter,
      this.rulesResultsTable,
      this.resetRulesResults,
      this.enteredThreshold
    );
  }

  filterByThreshold(event) {
    this.rulesResultsTable = this.resetRulesResults;
    this.enteredThreshold = event.target.value;
    this.preDefineWarningsFilters();
    this.rulesResultsTable = this.rulesResultsService.filteringTable(
      this.warningSelectedToFilter,
      this.rulesResultsTable,
      this.resetRulesResults,
      this.enteredThreshold
    );
  }

  preDefineWarningsFilters() {
    if (this.warningSelectedToFilter === 3) {
      this.warningSelectedToFilter = "3 - Failed"
    } else if (this.warningSelectedToFilter === 2) {
      this.warningSelectedToFilter = "2 - Immaterial"
    } else if (this.warningSelectedToFilter === 4) {
      this.warningSelectedToFilter = "4 - Addressed"
    }
  }

  expandAllMasterRows() {
    this.dataGrid.instance.expandAll(-1);
  }
  collapseAllMasterRows() {
    this.dataGrid.instance.collapseAll(-1);
  }

  getExpansionText(): string {
    return this.isAllPanelsActive() ? 'Expand All' : 'Collapse All';
  }

  isAllPanelsActive(): boolean {
    return this.allPanelsActive;
  }

  setActivePanels(): void {
    this.expanded = !this.expanded;
    this.setAllPanelsActive(!this.expanded);
    if (!this.expanded) {
      this.collapseAllMasterRows();
    } else {
      this.expandAllMasterRows();
    }
  }

  setAllPanelsActive(allPanelsActive: boolean): void {
    this.allPanelsActive = allPanelsActive;
  }

  selectAll() {
    this.rulesResultsTable.forEach(a => {
      a.ruleResults.forEach(b => {
        b.includeInLetter = true;
      });
    });
  }

  deSelectAll() {
    this.resetRulesResults.forEach(a => {
      a.ruleResults.forEach(b => {
        b.includeInLetter = false;
      });
    });
  }

  getMainStyle(): string[] {
    if (this.sidebarCollapsed) {
      return ['col-12', 'ml-sm-auto', 'd-print-none'];
    } else {
      return ['col-md-9', 'ml-sm-auto', 'col-lg-10', 'd-print-none'];
    }
  }

  resetDataForSave() {
    this.rulesResults.rules.forEach(a => {
      a.ruleResults.forEach(b => {
        if (b.resultStatus === '2 - Immaterial') {
          b.resultStatus = 2;
        } else if (b.resultStatus === '3 - Failed') {
          b.resultStatus = 3;
        } else if (b.resultStatus === '4 - Addressed') {
          b.resultStatus = 4;
        }
      });
    });
  }

  saveChanges() {
    this.resetDataForSave();
    this.loading = true;
    this.rulesResultsService.putSaveRuleResults(this.correspondenceId, this.rulesResults)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.getRulesAndResults();
          this.allPanelsActive = false;
          this.messageService.clear();
          this.messageService.add(new AlertMessage('Letter Saved', 'success', true, 0));
          this.loading = false;
        }, (error: HttpErrorResponse) => {
          this.messageService.clear();
          this.messageService.add(new AlertMessage('Error Saving Letter', 'danger', true, 0));
          this.loading = false;
        }
      );
    this.changeResultStatusDisplay();
  }

  saveAndClose() {
    this.saveChanges();
    this.router.navigate(['/correspondence']);
  }

  resetUnsavedStatus() {
    this.getRulesAndResults();
  }

  next() {
    this.loading = true;
    this.resetDataForSave();
    this.rulesResultsService.putSaveRuleResults(this.correspondenceId, this.rulesResults)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          this.router.navigate(['/reviewLetter', {
            correspondenceId: this.correspondenceId,
            cocode: this.company,
            companyName: this.companyName,
            year: this.year,
            contact: this.contact,
            lob: this.lob,
            lobDesc: this.lobDesc
          }], { skipLocationChange: true });
        }
      );
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: "Unsaved Changes Will be Lost. Do you wish to continue?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/correspondence']);
      }
    });

  }
}

