<ng-container *ngIf="visible; else notVisible">
  <ng-container *ngIf="checkRegulatorRole(); else RegulatorRole">
    <select name="finalResolution" id="finalResolution" (change)=" getId(params.data.id, $event)"
      [(ngModel)]="selectedStatus">
      <!-- [(ngModel)]="selectedStatus" #finalResolution="ngModel" -->
      <option value="null" disabled hidden></option>
      <option *ngFor="let status of finalResolution" [value]="status.value" [disabled]="status.disabled === 'true'">
        {{status.name}}
      </option>
    </select>
  </ng-container>
</ng-container>

<ng-template #RegulatorRole>
  <ng-container *ngIf="params.data.finalResolution.finalResolution === null; else displayStatus"> - </ng-container>
</ng-template>

<ng-template #displayStatus> {{params.data.finalResolution.finalResolution}} </ng-template>
<ng-template #notVisible> - </ng-template>