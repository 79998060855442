<ngx-loading [show]="loading"></ngx-loading>
<div class="row">
  <!-- sidebar -->
  <nav class="col-md-2 bg-light sidebar d-print-none">
    <div class="sidebar-sticky">
      <ng-container *ngIf="user && userCompanies">
        <div class="pt-3 pb-2 mb-3 px-4 border-bottom">
          <h4>Select Company</h4>
            <div id="selectCompany">
              <ng-container [ngSwitch]="companyInputType()">
                <ng-container *ngSwitchCase="'select'">
                  <div class="form-group">
                    <label for="company">Company</label>
                    <select class="form-control form-control-sm" id="company" name="company" required [(ngModel)]="selectedUserCompany" [disabled]="userCompanies.length === 1">
                      <option [ngValue]="null" disabled hidden>Choose One</option>
                      <option *ngFor="let userCompany of userCompanies" [ngValue]="userCompany">{{getCompanyDisplayName(userCompany.company)}}</option>
                    </select>
                  </div>
                  <div class="pb-2">
                    <button type="button" class="btn btn-primary btn-sm btn-block" (click)="getCompanyAssignments(selectedUserCompany.company.cocode)"
                      [disabled]="!selectedUserCompany || userCompanies.length === 1">Select</button>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                  <div *ngSwitchCase="'text'" class="form-group">
                    <label for="company">Cocode</label>
                    <input type="text" class="form-control form-control-sm" id="company" name="company" required [(ngModel)]="enteredCocode" autocomplete="off">
                  </div>
                  <div class="pb-2">
                    <button type="button" class="btn btn-primary btn-sm btn-block" (click)="getCompanyAssignments(enteredCocode)" [disabled]="!enteredCocode">Select</button>
                  </div>
                </ng-container>
              <ng-container *ngSwitchDefault></ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="companyAssignment && isAdmin()">
        <div class="pb-2 mb-3 px-4 border-bottom">
          <h4>Actions</h4>
          <div id="actions" [collapse]="isActionsCollapsed">
            <div class="form-group">
              <label for="user">New User</label>
              <input type="text" class="form-control form-control-sm" id="user" name="user" required [(ngModel)]="newUser" autocomplete="off">
            </div>
            <div class="pb-2">
              <button type="button" class="btn btn-primary btn-sm btn-block" (click)="addUser()" [disabled]="!newUser">Add User</button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </nav>

  <!-- main screen display -->
  <main *ngIf="!loading" role="main" class="col-md-9 ml-sm-auto col-lg-10 d-print-none">
    <app-messages [parentName]="'userAdmin'"></app-messages>
    <div class="pt-3 pb-3 main-content d-print-block">
        <div class="row">
            <div class="col-12">
              <h3>
                {{title}}
                <hr>
              </h3>
            </div>
          </div>
          <div *ngIf="companyAssignment" class="row">
              <div *ngIf="primaryTableRows && primaryTableRows.length > 0" class="col-12">
                <div class="row pb-5">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">Administrator</h5>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Contact</th>
                              <th scope="col">User Id</th>
                              <th scope="col">Name</th>
                              <th scope="col">Phone</th>
                              <th scope="col">Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of primaryTableRows">
                              <td>
                                <input type="radio" [value]="row.contact" [id]="row.contact" [name]="'contact'" [(ngModel)]="contact" (click)="setContact(row.contact)" [disabled]="!isAdmin()">
                              </td>
                              <td>{{row.userId}}</td>
                              <td>{{row.name}}</td>
                              <td>{{row.phone}}</td>
                              <td>{{row.email}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="secondaryTableRows && secondaryTableRows.length > 0" class="row">
                  <div class="col-12">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">Secondary Users</h5>
                        <table class="table">
                          <thead>
                            <tr>
                              <th *ngIf="isAdmin()" scope="col">Administrator</th>
                              <th scope="col">Contact</th>
                              <th scope="col">User Id</th>
                              <th scope="col">Name</th>
                              <th scope="col">Phone</th>
                              <th scope="col">Email</th>
                              <th *ngIf="isAdmin()" scope="col">Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of secondaryTableRows">
                              <td *ngIf="isAdmin()">
                                <input type="radio" [value]="row.administrator" [name]="'admin'" [(ngModel)]="admin" (click)="setAdmin(row.administrator)">
                              </td>
                              <td>
                                <input type="radio" [value]="row.contact" [id]="row.contact" [name]="'contact'" [(ngModel)]="contact" (click)="setContact(row.contact)" [disabled]="!isAdmin()">
                              </td>
                              <td>{{row.userId}}</td>
                              <td>{{row.name}}</td>
                              <td>{{row.phone}}</td>
                              <td>{{row.email}}</td>
                              <td *ngIf="isAdmin()">
                                <button type="button" class="btn btn-sm btn-danger" (click)="removeUser(row.actions)" [disabled]="row.actions.isContact">Remove</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
    <div class="row">
        <footer class="col-12 footer d-print-none">
            <ncui-footer></ncui-footer>
          </footer>
    </div>
  </main>

  <main *ngIf="!loading" role="main" class="col-sm-12 ml-sm-auto d-none d-print-block">
      <app-messages [parentName]="'userAdmin'"></app-messages>
      <div class="pt-3 pb-3 main-content d-print-block">
          <div class="row">
              <div class="col-12">
                <h3>
                  {{title}}
                  <hr>
                </h3>
              </div>
            </div>
            <div *ngIf="companyAssignment" class="row">
                <div *ngIf="primaryTableRows && primaryTableRows.length > 0" class="col-12">
                  <div class="row pb-5">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title">Administrator</h5>
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Contact</th>
                                <th scope="col">User Id</th>
                                <th scope="col">Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Email</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let row of primaryTableRows">
                                <td>
                                  <input type="radio" [value]="row.contact" [name]="'contact'" [(ngModel)]="contact" (click)="setContact(row.contact)" [disabled]="!isAdmin()">
                                </td>
                                <td>{{row.userId}}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.phone}}</td>
                                <td>{{row.email}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="secondaryTableRows && secondaryTableRows.length > 0" class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-body">
                          <h5 class="card-title">Secondary Users</h5>
                          <table class="table">
                            <thead>
                              <tr>
                                <th *ngIf="isAdmin()" scope="col">Administrator</th>
                                <th scope="col">Contact</th>
                                <th scope="col">User Id</th>
                                <th scope="col">Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Email</th>
                                <th *ngIf="isAdmin()" scope="col">Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let row of secondaryTableRows">
                                <td *ngIf="isAdmin()">
                                  <input type="radio" [value]="row.administrator" [name]="'admin'" [(ngModel)]="admin" (click)="setAdmin(row.administrator)">
                                </td>
                                <td>
                                  <input type="radio" [value]="row.contact" [name]="'contact'" [(ngModel)]="contact" (click)="setContact(row.contact)" [disabled]="!isAdmin()">
                                </td>
                                <td>{{row.userId}}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.phone}}</td>
                                <td>{{row.email}}</td>
                                <td *ngIf="isAdmin()">
                                  <button type="button" class="btn btn-sm btn-danger" (click)="removeUser(row.actions)" [disabled]="row.actions.isContact">Remove</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      </div>
      <div class="row">
          <footer class="col-12 footer d-print-none">
              <ncui-footer></ncui-footer>
            </footer>
      </div>
    </main>
</div>
