import { Component, TemplateRef } from '@angular/core';
import { faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ICellRenderer } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { User } from '../../../../shared/model/user';
import { McsUserService } from '../../../../service/mcs-user.service';

@Component({
  selector: 'app-closed-date-renderer',
  templateUrl: './closed-date-renderer.component.html',
  styleUrls: ['./closed-date-renderer.component.css']
})
export class ClosedDateRendererComponent implements ICellRenderer {
  params: any;
  faTimes = faTimes;
  fas = fas;
  modalRef: BsModalRef;
  visible: boolean;

  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  dpValue: Date;

  faCalendar = faCalendar;
  closedDate: string;
  user: User;
  displayClosedDateForRegulator: string;

  constructor(
    private userService: McsUserService
  ) {
    this.dpConfig.containerClass = 'theme-dark-blue';
    this.dpConfig.showWeekNumbers = false;
    this.dpConfig.minDate = moment().toDate();
  }

  agInit(params: any): void {
    this.params = params;
    this.user = this.userService.getStoredUser();
    this.onlyForSent();
    if (this.params.data.closedDate === '-') {
      this.dpValue = null;
    } else {
      this.dpValue = moment(this.params.data.closedDate, 'YYYY-MM-DD').toDate();
    }
    this.displayClosedDateForRegulator = moment(this.dpValue).format('YYYY-MM-DD');
    if (this.displayClosedDateForRegulator === 'Invalid date') {
      this.displayClosedDateForRegulator = '-';
    } else {
      this.displayClosedDateForRegulator = this.displayClosedDateForRegulator;
    }
  }

  refresh(): boolean {
    return true;
  }

  checkRegulatorRole(): boolean {
    if (this.user.role === "MCAS_ISITE_USER_PR") {
      return false;
    } else {
      return true;
    }
  }

  onlyForSent() {
    if (this.params.data.letterProgress === 'Sent' || this.params.data.letterProgress === 'No Letter') {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  reset(correspondenceId: string) {
    this.dpValue = null;
    this.closedDate = '';
    this.params.context.componentParent.setClosedDate(correspondenceId, this.closedDate);
    // console.log(correspondenceId)
  }

  changeClosedDate(correspondenceId: any, event: any) {
    this.closedDate = moment(event).format('YYYY-MM-DD');
    if (this.closedDate === "Invalid date") {
      this.closedDate = '';
    } else {
      this.closedDate = moment(event).format('YYYY-MM-DD');
    }
    this.params.context.componentParent.setClosedDate(correspondenceId, this.closedDate);
  }


}
